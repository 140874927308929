import ReactApexChart from "react-apexcharts";
import { diagramColors, VALUES_SHOW_TYPES } from "src/constants";
import { generatePieOptions } from "../options/generate-pie-options";
import { Box, Flex, Grid, Text, Title } from "@mantine/core";
import { getTitleByLanguage } from "src/locales";

export const PieChart = ({ data,isSmall=false }) => {
    const withPercentage =
        data?.textEn !== VALUES_SHOW_TYPES.WITHOUT_PERCENTAGE;
    const { options } = generatePieOptions(data, withPercentage, isSmall);

    return (
        <Box>
            <Box bg={'white'}>
            <ReactApexChart
                options={options}
                series={options.series}
                type="pie"
                width="100%"
                height={isSmall ? 300 : 450}
            />
            </Box>
            <Grid w="100%" mt={10}  py={4} px={8} gutter={2} bg="white">
                {data?.diagramFields?.map((item, index) => (
                    <Grid.Col span={3} key={item.id}>
                        <Flex h="fit-content" align="center" gap="xs">
                            <Box bg={diagramColors[index]} style={{borderRadius:"4px"}} w={20} miw={20} mih={10} h={10}/>
                            <Text ta="start" lh={1} fz={10}>{getTitleByLanguage(item)}</Text>
                        </Flex>
                    </Grid.Col>
                ))}
            </Grid>
        </Box>

    );
};
