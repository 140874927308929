import { Accordion, Flex, Text, Title } from "@mantine/core";
import { getTitleByLanguage } from "src/locales";
import styles from "./styles.module.scss";

export const LawsItem = ({ data }: { data: ConsSectionList | undefined }) => {
    return (
        <Accordion.Item
            className={styles.accordion_item}
            value={(data?.id || 1).toString() || ""}
        >
            <Accordion.Control
                style={{ borderBottom: "1px solid var(--borders)" }}
            >
                <Flex align="center" justify="space-between" pr={10}>
                    <Title
                        c="primaryDark"
                        fz={{ base: 16, md: 18 }}
                        order={5}
                        lh={1.3}
                    >
                        {getTitleByLanguage({
                            titleRu: data?.nameRu,
                            titleKg: data?.nameKg,
                        })}
                    </Title>
                    <Text>{data?.articles}</Text>
                </Flex>
            </Accordion.Control>
            <Accordion.Panel>
                <Text
                    lh={1.2}
                    ta="justify"
                    fz={{ base: 14, md: 16 }}
                    c="primaryDark"
                    style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{
                        __html: getTitleByLanguage({
                            titleRu: data?.contentRu,
                            titleKg: data?.contentKg,
                        }),
                    }}
                />
            </Accordion.Panel>
        </Accordion.Item>
    );
};
