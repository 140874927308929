import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const diagramApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        diagramCreate: builder.mutation<
            DiagramResponseType,
            DiagramRequestType
        >({
            query: (body) => ({
                url: Path.Diagram.create,
                method: "POST",
                body,
                cache: "no-store",
            }),
        }),
        diagramGetAll: builder.query<DiagramResponseType[], unknown>({
            query: () => ({
                url: Path.Diagram.getAll,
                method: "GET",
                cache: "no-store",
            }),
        }),
        diagramGetById: builder.query<DiagramResponseType, unknown>({
            query: (id: number | string) => ({
                url: Path.Diagram.getById(id),
                method: "GET",
                cache: "no-store",
            }),
        }),
        diagramUpdate: builder.mutation<
            DiagramRequestType,
            { body: Partial<DiagramRequestType> }
        >({
            query: ({ body }) => ({
                url: Path.Diagram.updateFull,
                method: "PUT",
                body,
                cache: "no-store",
            }),
        }),
        diagramSearch: builder.mutation<
            SearchDiagramResponseType,
            SearchRequestType
        >({
            query: (body) => ({
                url: Path.Diagram.search,
                method: "POST",
                cache: "no-store",
                body,
            }),
        }),
        diagramSearchAll: builder.mutation<
            SearchDiagramResponseType,
            SearchRequestType
        >({
            query: (body) => ({
                url: Path.Diagram.searchAll,
                method: "POST",
                cache: "no-store",
                body,
            }),
        }),
        diagramGetAllYears: builder.query<string[], unknown>({
            query: () => ({
                url: Path.Diagram.getAllYears,
                cache: "no-store",
            }),
        }),
        diagramDelete: builder.mutation({
            query: (id) => ({
                url: Path.Diagram.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["Statistic"],
        }),
    }),
});

export const {
    useDiagramCreateMutation,
    useDiagramGetAllQuery,
    useDiagramGetByIdQuery,
    useDiagramSearchMutation,
    useDiagramUpdateMutation,
    useDiagramGetAllYearsQuery,
    useDiagramSearchAllMutation,
    useDiagramDeleteMutation,
} = diagramApiSlice;
