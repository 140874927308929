import { List, Text } from "@mantine/core";
import { getTitleByLanguage } from "src/locales";
import { ListType } from "src/types/data-models/global";

export const AppealListItem = ({ item }: { item: ListType }) => (
    <List.Item my={8}>
        <Text
            fz={{ base: "md", sm: "lg" }}
            style={{ whiteSpace: "pre-wrap" }}
            fw={400}
            c="blueDark"
        >
            {getTitleByLanguage(item)}
        </Text>
        <List>
            {item.children &&
                item.children.map((el, idx) => (
                    <List.Item key={idx} my={20}>
                        <Text
                            style={{ whiteSpace: "pre-wrap" }}
                            fz={{ base: "md", sm: "lg" }}
                            fw={400}
                            c="blueDark"
                        >
                            {getTitleByLanguage(el)}
                        </Text>
                    </List.Item>
                ))}
        </List>
    </List.Item>
);
