import { Box, Grid, Text, Title } from "@mantine/core";
import { SpacingValue, SystemProp, createStyles } from "@mantine/styles";
import { useTranslation } from "react-i18next";

type Props = {
    label: React.ReactNode;
    value?: React.ReactNode;
    display?: boolean;
    my?: SystemProp<SpacingValue>;
    spanFirst?: number;
    spanTwo?: number;
};

export const CustomText = ({
    label,
    value,
    display,
    my = 10,
    spanFirst = 6,
    spanTwo = 6,
}: Props) => {
    const { classes } = useStyles();
    const { t } = useTranslation();

    if (display) {
        return (
            <Grid align="center" my={my} className={classes.box}>
                <Grid.Col span={{ base: 12, md: spanFirst }} px={8} py={4}>
                    <Text fw={600}>{t(`${label}`)}:</Text>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: spanTwo }} px={8} py={4}>
                    <Title fz={22} c="secondaryDark">
                        {value || "-"}
                    </Title>
                </Grid.Col>
            </Grid>
        );
    }

    return (
        <>
            {
                <Box className={classes.box} py={2}>
                    <Text fw={600}>{t(`${label}`)}:</Text>
                    <Box>{value || "-"}</Box>
                </Box>
            }
        </>
    );
};

const useStyles = createStyles({
    box: {
        width: "99%",
        borderBottom: "1px solid #d5d3d3",
        fontSize: "13px",
        "& div": {
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
    },
});
