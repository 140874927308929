import { Box, CSSProperties } from "@mantine/core";
import { ReactNode, useEffect, useState } from "react";
import style from "./styles.module.scss";

interface IProps {
    speed: number;
    children: ReactNode;
    styles?: CSSProperties;
}

export const ParallaxLayer = ({ speed, children, styles }: IProps) => {
    const [position, setPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const yPos = -scrollY * speed;
            setPosition(yPos);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [speed]);
    return (
        <Box
            bg="primary"
            className={style.parallax_item}
            style={{
                transform: `translate(0, ${position}px)`,
                ...styles,
            }}
        >
            <div className="bg-img">{children}</div>
        </Box>
    );
};
