import { apiSlice } from "src/store/apiSlice";
import { SelectDto } from "src/types/data-models/global";
import { Path } from "src/utils";

export const employeeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEmployeeMe: builder.query({
            query: () => ({
                url: Path.Employee.getMe,
                method: "GET",
            }),
            providesTags: ["Phone", "Email", "Employee"],
        }),
        getSelectData: builder.query<SelectDto[], string>({
            query: (path) => ({
                url: path,
                method: "GET",
            }),
            providesTags: ["Position"],
        }),
        employeeEditPhone: builder.mutation({
            query: (phone) => ({
                url: Path.Employee.editPhone(phone),
                method: "PUT",
            }),
            invalidatesTags: ["Phone"],
        }),
        employeeEditEmail: builder.mutation({
            query: (email) => ({
                url: Path.Employee.editEmail(email),
                method: "PUT",
            }),
            invalidatesTags: ["Email"],
        }),
    }),
});

export const {
    useGetEmployeeMeQuery,
    useEmployeeEditPhoneMutation,
    useEmployeeEditEmailMutation,
    useGetSelectDataQuery,
} = employeeApiSlice;
