import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const prosecutorOfficeSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createProsecutorOffice: builder.mutation<
            ProsecutorResponseType,
            ProsecutorRequestType
        >({
            query: (body) => ({
                url: Path.ProsecutorOffice.create,
                method: "POST",
                body,
                cache: "no-cache",
            }),
        }),
        getAllProsecutorOffices: builder.query<
            ProsecutorResponseType[],
            unknown
        >({
            query: () => ({
                url: Path.ProsecutorOffice.getAll,
                method: "GET",
                cache: "no-cache",
            }),
            providesTags: ["ProsecutorOffice"],
        }),
        getProsecutorOffice: builder.query<ProsecutorResponseType, string>({
            query: (id) => ({
                url: Path.ProsecutorOffice.getById(id),
                method: "GET",
                cache: "no-cache",
            }),
        }),
        updateProsecutorOffice: builder.mutation<
            ProsecutorRequestType,
            { id: number; body: Partial<ProsecutorRequestType> }
        >({
            query: ({ id, body }) => ({
                url: Path.ProsecutorOffice.update(id),
                method: "PUT",
                cache: "no-cache",
                body,
            }),
        }),
        deleteProsecutorOffice: builder.mutation({
            query: (id) => ({
                url: Path.ProsecutorOffice.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["ProsecutorOffice"],
        }),
        officeSearch: builder.mutation<
            ProsecutorSearchResponse,
            ProsecutorSearchRequestType
        >({
            query: (postData) => ({
                url: Path.ProsecutorOffice.search,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
            invalidatesTags: ["ProsecutorOffice"],
        }),
    }),
});

export const {
    useCreateProsecutorOfficeMutation,
    useDeleteProsecutorOfficeMutation,
    useGetProsecutorOfficeQuery,
    useUpdateProsecutorOfficeMutation,
    useGetAllProsecutorOfficesQuery,
    useOfficeSearchMutation,
} = prosecutorOfficeSlice;
