import { Flex, Image, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getTitleByLanguage } from "src/locales";
import { NewsResponse } from "src/types/data-models/news";
import { sliceText } from "src/utils";

interface NewsItemProps {
    news: NewsResponse;
    textLimit?: number;
}

export const NewsItem100years = ({
    news,
    textLimit = 50,
}: NewsItemProps) => {
    const { t, i18n } = useTranslation();
    const sm = useMediaQuery("(max-width: 768px)");
    const newsTitle = getTitleByLanguage(news);

    return (
        <Paper
            variant="bordered"
            pos="relative"
            w="100%"
            h={!sm ? "208px" : undefined}
            mt={"25px"}
            px={{ base: "10px", sm: "20px" }}
            pb={{ base: 12, sm: "30px" }}
            pt={{ base: 18, sm: "30px" }}
        >
            <Link
                key={news?.id}
                to={`/100yearsnews/${news?.id}/${i18n.language}`}
            >
                <Flex
                    gap={{ base: 8, sm: 20 }}
                    direction="row"
                    align="center"
                    justify="start"
                >
                    <Flex w={{ base: 160, sm: 190, xl: 250 }}>
                        {news && news?.photoAlbum?.photo ? (
                            <Image
                                src={`data:image/webp;base64,${news?.photoAlbum?.photo}`}
                                miw={{ base: 160, sm: 190, xl: 250 }}
                                h={{ base: 100, xl: 140 }}
                                alt=""
                            />
                        ) : (
                            <Flex
                                miw={{ base: 160, sm: 190, xl: 250 }}
                                h={{ base: 100, xl: 140 }}
                                style={{ borderRadius: "4px" }}
                                bg={"primaryDark"}
                                align="center"
                                justify="center"
                            >
                                <Image height="100%" src="/logo.png" />
                            </Flex>
                        )}
                    </Flex>
                    <Flex direction="column" justify="space-between">
                        <Title
                            c="blueDark"
                            fw="normal"
                            lh={1.2}
                            fz={{ base: 12, xs: 14, sm: 18, lg: 20 }}
                            mb={{ base: 10, xl: 20 }}
                            w="100%"
                        >
                            {sliceText("xl", { xl: textLimit }, newsTitle)}
                        </Title>
                        <Text
                            fz={{ base: 12, sm: 14, lg: 18 }}
                            c="primaryDark"
                        >
                            {t("statics.more")}...
                        </Text>
                    </Flex>
                </Flex>
            </Link>
        </Paper >
    );
};
