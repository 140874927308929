interface RingProgressProps {
    value?: number;
    size: number;
    thickness: number;
    color: string;
    item?: SearchDiagramFiealdsResponseType;
    className?: string;
    focused: number;
    maxValue: number;
}

export const RingProgress = ({
    value,
    size,
    thickness,
    color,
    item,
    className,
    focused,
    maxValue,
}: RingProgressProps) => {
    const circumference = 2 * Math.PI * (size / 2);
    const percentage = (value ? value / maxValue : 35) * 100 - 10;
    const progress = (percentage / 100) * circumference;

    return (
        <svg
            width={size}
            height={size}
            style={{ position: "absolute", transform: "rotate(-90deg)" }}
        >
            <circle
                style={{ transition: "350ms" }}
                fill="none"
                stroke={focused === item?.id ? "#b2b6c7" : "#DBDFF1"}
                cx={`${size / 2}`}
                cy={`${size / 2}`}
                r={`${(size - thickness) / 2}`}
                strokeWidth={thickness}
            />
            <circle
                className={className}
                fill="none"
                opacity={1}
                strokeLinecap="round"
                cx={`${size / 2}`}
                cy={`${size / 2}`}
                r={`${(size - thickness) / 2}`}
                strokeWidth={thickness}
                strokeDasharray={`${circumference}`}
                strokeDashoffset={`${circumference - progress}`}
                stroke={color}
                style={{
                    transition: "stroke-dashoffset 0.3s",
                }}
            />
        </svg>
    );
};
