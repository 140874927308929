import { Breadcrumbs } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import { BreadcrumbLink } from "./breadcrumb-link";
export { BreadcrumbLink } from "./breadcrumb-link";

export const AppBreadcrumbs = () => {
    const { pathname } = useLocation();
    const routes = pathname.split("/").filter((item) => item !== "");

    const links = routes.map((link, index) => {
        const to = routes.slice(0, index + 1).join("/");
        const disabled = index === routes.length - 1;

        return (
            link !== "create" && (
                <BreadcrumbLink
                    key={index}
                    to={`/${to}`}
                    disabled={disabled}
                    link={link}
                />
            )
        );
    });

    return (
        <Breadcrumbs separator={<IconChevronRight size={14} stroke={1.5} />}>
            {links}
        </Breadcrumbs>
    );
};
