import { Box, Divider, Flex, Grid, Image, Input, Paper, Text } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomLoader, CustomModal } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useGetAppealQuery, useGetProsecutorOfficeQuery } from "src/store";
import { getAttachmentUrl } from "src/utils";

type Props = {
    appealId: string;
};

export const AppealDetails = ({ appealId }: Props) => {
    const [isOpened, setIsOpened] = useState<IAttachment | null>(null);
    const { t } = useTranslation()
    const { data: appealData, isLoading } = useGetAppealQuery(appealId, {
        refetchOnMountOrArgChange: true,
    });
    const { data: officeData } = useGetProsecutorOfficeQuery(`${appealData?.prosecutorOfficeId}`, {
        refetchOnMountOrArgChange: true,
    });
    return (
        <Paper shadow="sm" p="xl">
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <Paper shadow="sm" p="xl">
                    {appealData ? (
                        <Box mt={50}>
                            <Grid>
                                <Grid.Col span={{ base: 12, md: 4 }}>
                                    <Input.Wrapper label={t("form.label.mapPointId")}>
                                        <Input
                                            value={getTitleByLanguage({
                                                titleKg: officeData?.mapPoint?.cityOrRegionNameKg,
                                                titleRu: officeData?.mapPoint?.cityOrRegionNameRu
                                            })}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 4 }}>
                                    <Input.Wrapper label={t("form.label.detailAddress")}>
                                        <Input
                                            value={getTitleByLanguage({
                                                titleKg: officeData?.mapPoint?.addressDetailsKg,
                                                titleRu: officeData?.mapPoint?.addressDetailsRu
                                            })}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 4 }}>
                                    <Input.Wrapper label="Филиал">
                                        <Input
                                            value={getTitleByLanguage(officeData)}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <Input.Wrapper label={t("form.label.email")}>
                                        <Input
                                            value={officeData?.email}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <Input.Wrapper label={t("form.label.phone")}>
                                        <Input
                                            value={officeData?.phone}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Divider w="100%" h={2} />
                                <Grid.Col span={{ base: 12, md: 4 }}>
                                    <Input.Wrapper label={t("form.label.fullName")}>
                                        <Input
                                            value={appealData?.fullName}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 3 }}>
                                    <Input.Wrapper label={t("form.label.country")}>
                                        <Input
                                            value={appealData?.country}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 3 }}>
                                    <Input.Wrapper label={t("form.label.region")}>
                                        <Input
                                            value={appealData?.region}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 3 }}>
                                    <Input.Wrapper label={t("form.label.city")}>
                                        <Input value={appealData?.city} disabled />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 3 }}>
                                    <Input.Wrapper label={t("form.label.district")}>
                                        <Input
                                            value={appealData?.district}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 3 }}>
                                    <Input.Wrapper label={t("form.label.addressDetails")}>
                                        <Input
                                            value={`улица ${appealData?.street}, дом ${appealData.houseNumber}`}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 3 }}>
                                    <Input.Wrapper label="Email">
                                        <Input value={appealData?.email} disabled />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 3 }}>
                                    <Input.Wrapper label="Телефон номер">
                                        <Input value={appealData?.phone} disabled />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Input.Wrapper label="Тема">
                                        <Input
                                            value={appealData?.subjectOfTheAppeal}
                                            disabled
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Input.Wrapper label={t("form.label.appeal-text")}>
                                        <Text>{appealData?.textOfTheAppeal}</Text>
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Input.Wrapper label="Фото">
                                        {appealData?.files ? (
                                            <Flex wrap="wrap" gap={10}>
                                                {appealData?.files?.map(el => (
                                                    <Image
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => setIsOpened(el)}
                                                        w={200}
                                                        src={getAttachmentUrl(el.id, el.uuid)}
                                                    />
                                                ))}
                                            </Flex>
                                        ) : (
                                            <Text>{t("form.label.not-uploaded")}</Text>
                                        )}
                                    </Input.Wrapper>
                                </Grid.Col>
                                <CustomModal
                                    size="xl"
                                    labelSubmit={"delete"}
                                    open={!!isOpened?.id}
                                    handleClose={() => setIsOpened(null)}
                                    button={false}
                                >
                                    {isOpened?.id && isOpened?.uuid ?
                                        <Image
                                            style={{ cursor: "pointer" }}
                                            w={750}
                                            src={getAttachmentUrl(isOpened?.id, isOpened?.uuid)}
                                        /> : null}
                                </CustomModal>
                            </Grid>
                        </Box>
                    ) : null}
                </Paper>}
        </Paper>
    );
};
