export const validateEmail = (value?: string): boolean => {
    if (value) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(value);
    }
    return false;
};

export const validatePhoneNumber = (value?: string): string | void => {
    if (value && value.length >= 10) {
        const phonePatterns = [
            /^\+996\s\d{3}\s\d{2}\s\d{2}\s\d{2}$/, // +996 XXX XX XX XX
            /^\+996\s\d{3}\s\d{3}\s\d{3}$/, // +996 XXX XXX XXX
            /^\+996\d{9}$/, // +996XXXXXXXXX
            /^0\d{3}\s\d{3}\s\d{3}$/, // 0XXX XXX XXX
            /^0\s\d{3}\s\d{2}\s\d{2}\s\d{2}$/, // 0 XXX XX XX XX
            /^0\d{9}$/, // 0XXXXXXXXX
        ];
        const status = phonePatterns.some((pattern) => pattern.test(value));

        if (!status) {

            return "form.validation.phoneNumber";

        } else {
            return;
        }
    }
    return "form.validation.has10Characters";
};
