import { Box, Flex, Grid, Image, Modal, Text } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { getTitleByLanguage } from "src/locales";
import { HTMLRender } from "src/utils";

interface PersonCardProps {
    person: CardType;
}

export const PersonCard = ({ person }: PersonCardProps) => {
    const [opened, { close, open }] = useDisclosure(false);
    const smallScreen = useMediaQuery("(max-width: 768px)");
    return (
        <>
            <Grid.Col
                onClick={open}
                span={{ base: 12, xs: 6, md: 4, lg: 3 }}
                style={{
                    cursor: "pointer",
                }}
            >
                <Flex align="center" justify="center" w="100%">
                    <Image
                        src={`data:image/webp;base64,${person?.photo}`}
                        w="100%"
                        alt=""
                    />
                </Flex>
                <Box ta="center">
                    <Text c="blueDark" fz={{ base: "md", sm: "xl" }}>
                        {getTitleByLanguage(person.fullName)}
                    </Text>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: person.education.titleRu,
                        }}
                        c="blueDark"
                        fz={{ base: "md", sm: "lg" }}
                    />
                </Box>
            </Grid.Col>
            {person?.shortBiography.titleRu && (
                <Modal
                    size={smallScreen ? "100%" : "70%"}
                    opened={opened}
                    onClose={close}
                    centered
                >
                    <HTMLRender
                        htmlContent={getTitleByLanguage(person.shortBiography)}
                    />
                </Modal>
            )}
        </>
    );
};
