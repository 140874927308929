import {
    Box,
    Button,
    Flex,
    Image,
    Text,
    Title
} from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { CustomSelectOpen, NewsItem, RouteTitles } from "src/components";
import { PhotoNewsItem, VideoNewsItem } from "src/components/news-card";
import { NEWS_100_YEAR_ID } from "src/constants";
import { scrollToTop } from "src/hooks";
import { getTitleByLanguage } from "src/locales";
import { useGetAllMapQuery, useGetByTypeCodeQuery, useNewsSearchMutation } from "src/store";
import { TypeCodesEnum } from "src/types";
import { NewsResponse, NewsSearchResponseType } from "src/types/data-models/news";
import { NewsBody } from "./news-body";
import styles from "./styles.module.scss";

interface SearchParams {
    activeTag?: string;
}

export const NewsModule = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [activeRegion, setActiveRegion] = useState<string | null>(null);
    const [activePage, setActivePage] = useState(1);
    const [date, setDate] = useState<Date | null>(null);
    const [calendarShowed, setCalendarShowed] = useState(false);
    const [activeTags, setActiveTags] = useState<Reference>();
    const handleCalendarClick = () => setCalendarShowed((prev) => !prev);
    const { data: mapPoints } = useGetAllMapQuery({
        refetchOnMountOrArgChange: false,
    });

    const regionNames = mapPoints?.map((el) =>
        getTitleByLanguage({
            titleKg: el.cityOrRegionNameKg,
            titleRu: el.cityOrRegionNameRu,
        })
    ) || [""];
    const [newsData, setNewsData] = useState<NewsSearchResponseType>()
    const [searchNews, { isLoading }] = useNewsSearchMutation();
    const { data: tags } = useGetByTypeCodeQuery(TypeCodesEnum.NEWS_TAG, {});

    const getNewsData = useCallback(
        async (mapPointId: number | null, page: number, date: Date | null, activeTags?: number | null) => {
            const adjustedDate = date
                ? new Date(date.getTime() + 24 * 60 * 60 * 1000)
                : null;
            try {
                const res = await searchNews({
                    filter: {
                        mapPointId: mapPointId || null,
                        publishedAt: adjustedDate?.toISOString(),
                        tags: activeTags ? [activeTags] : undefined,
                    },
                    pageRequest: { limit: 7, page: page - 1 },
                    sorting: {
                        sortBy: "PUBLISHED_AT",
                        sortDirection: "DESC",
                    },
                });
                if ("data" in res) {
                    let result = res.data
                    if (!activeTags) {
                        result = {
                            content: res.data?.content?.filter(el => !el.tags?.length),
                            numberOfElements: res.data?.numberOfElements,
                            page: res.data?.page,
                            totalElements: res.data?.totalElements,
                            totalPages: res.data?.totalPages,
                        }
                    } else {
                        result = {
                            content: res.data?.content?.filter(el => !el.tags?.find(tag => tag.id === NEWS_100_YEAR_ID)),
                            numberOfElements: res.data?.numberOfElements,
                            page: res.data?.page,
                            totalElements: res.data?.totalElements,
                            totalPages: res.data?.totalPages,
                        }
                    }
                    setNewsData(result)
                }
            } catch {
                console.error("error");
            }
        },
        [searchNews, activeTags]
    );

    const newsContent = newsData?.content?.map((el: NewsResponse) => {
        if (activeTags?.titleEn === "video") return <VideoNewsItem key={el.id} data={el} />
        if (activeTags?.titleEn === "image") return <PhotoNewsItem key={el.id} data={el} />
        else return <Box w="100%">
            <NewsItem key={el.id} news={el} textLimit={120} />
        </Box>
    }
    )

    useEffect(() => {
        scrollToTop();
    }, [activePage]);

    useEffect(() => {
        const mapPointId =
            mapPoints?.find(
                (el) =>
                    el.addressDetailsRu === activeRegion ||
                    el.addressDetailsKg === activeRegion
            )?.id || null;
        getNewsData(mapPointId || null, activePage || 1, date, activeTags?.id);
    }, [activeRegion, activePage, date, getNewsData, mapPoints, activeTags]);

    useEffect(() => {
        const params: SearchParams = {
            activeTag: searchParams.get("activeTag") || undefined,
        };

        if (params.activeTag) {
            const item = tags?.find(el => `${el.id}` === params.activeTag)
            setActiveTags(item);
        }
    }, [searchParams]);
    return (
        <Box>
            <Box
                mih="100vh"
                className="container"
                mx="auto"
                pos="relative"
                style={{ overflow: "hidden" }}
            >
                <RouteTitles />
                <Title
                    c="secondaryDark"
                    mb={{ base: 10, md: 32 }}
                    fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}
                >
                    {t("titles.news")}
                </Title>
                <Flex
                    className={styles.news_top}
                    justify="space-between"
                    align={{ base: "start", sm: "center" }}
                    mb={{ base: 32, md: 80 }}
                    wrap="nowrap"
                >
                    <Flex wrap="wrap" gap={16}>
                        <Text
                            className={styles.all_news_btn}
                            bg={activeTags ? "primary" : "primaryDark"}
                            p={{ base: "4px 16px", sm: "8px 24px" }}
                        >
                            <Button
                                onClick={() => {
                                    setActiveRegion(null);
                                    setDate(null);
                                    setActiveTags(undefined);
                                }}
                                c={activeTags ? "primaryDark" : "primary"}
                                variant="simple"
                                fz={16}
                                fw={400}
                            >
                                {t("label.all-news")}
                            </Button>
                        </Text>
                        {tags?.filter(el => el.titleEn === "video" || el.titleEn === "image")?.map((el) => (
                            <Text
                                key={el.id}
                                className={styles.all_news_btn}
                                bg={activeTags?.id === el.id ? "primaryDark" : "primary"}
                                p={{ base: "4px 16px", sm: "8px 24px" }}
                            >
                                <Button
                                    onClick={() => setActiveTags(el)}
                                    variant="simple"
                                    c={activeTags?.id === el.id ? "primary" : "primaryDark"}
                                    fz={16}
                                    fw={400}
                                >
                                    {getTitleByLanguage(el)}
                                </Button>
                            </Text>
                        ))}
                        <CustomSelectOpen
                            bg="primary"
                            color="primaryDark"
                            value={activeRegion || t("label.region_news")}
                            onChange={(value) => setActiveRegion(value || null)}
                            data={regionNames}
                            defaultValue={t("label.region_news")}
                            checkedIcon={false}
                        />
                    </Flex>
                    <Button
                        className={styles.calendar_btn}
                        variant="simple"
                        onClick={handleCalendarClick}
                    >
                        <Flex align="center" gap={5} mr={15}>
                            <Title
                                display={{ base: "none", sm: "block" }}
                                order={5}
                                fw={400}
                                mr={20}
                                c="primaryDark"
                            >
                                {t("titles.news-dates")}
                            </Title>
                            <Image w={22} src="/icons/calendar.svg" />
                        </Flex>
                    </Button>
                </Flex>
                <NewsBody
                    activePage={activePage}
                    calendarShowed={calendarShowed}
                    date={date}
                    isLoading={isLoading}
                    newsContent={newsContent}
                    newsData={newsData}
                    setActivePage={setActivePage}
                    setDate={setDate}
                />
            </Box>
        </Box>
    );
};
