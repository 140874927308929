import { Box } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { NewsItem100years } from "src/components/news-card";
import { NEWS_100_YEAR_ID } from "src/constants";
import { scrollToTop } from "src/hooks";
import { useNewsSearchMutation } from "src/store";
import { NewsResponse, NewsSearchResponseType } from "src/types/data-models/news";
import { NewsBody } from "../news/news-body";

export const MeriopriatiaModule = () => {
    const [activePage, setActivePage] = useState(1);
    const [date, setDate] = useState<Date | null>(null);
    const [calendarShowed, setCalendarShowed] = useState(false);
    const [newsData, setNewsData] = useState<NewsSearchResponseType>();
    const [searchNews, { isLoading }] = useNewsSearchMutation();

    const handleCalendarClick = () => setCalendarShowed((prev) => !prev);

    const getNewsData = useCallback(
        async (page: number, date: Date | null, activeTag?: number | null) => {
            const adjustedDate = date
                ? new Date(date.getTime() + 24 * 60 * 60 * 1000)
                : null;
            try {
                const res = await searchNews({
                    filter: {
                        publishedAt: adjustedDate?.toISOString(),
                        tags: [NEWS_100_YEAR_ID],
                    },
                    pageRequest: { limit: 7, page: page - 1 },
                    sorting: {
                        sortBy: "PUBLISHED_AT",
                        sortDirection: "DESC",
                    },
                });
                if ("data" in res) {
                    const result = {
                        content: res.data.content?.filter(el => !!el.tags?.find(tag => tag.id === NEWS_100_YEAR_ID)),
                        numberOfElements: res.data?.numberOfElements,
                        page: res.data?.page,
                        totalElements: res.data?.totalElements,
                        totalPages: res.data?.totalPages,
                    };
                    setNewsData(result);
                }
            } catch {
                console.error("error");
            }
        },
        [searchNews]
    );

    const newsContent = newsData?.content?.map((el: NewsResponse) =>
        <Box w="100%">
            <NewsItem100years key={el.id} news={el} textLimit={120} />
        </Box>
    );

    useEffect(() => {
        scrollToTop();
    }, [activePage]);

    useEffect(() => {
        getNewsData(activePage, date);
    }, [activePage, date, getNewsData]);

    return (
        <Box className="container" mt={{ base: 40, sm: 0 }}>
            <NewsBody
                activePage={activePage}
                calendarShowed={calendarShowed}
                date={date}
                isLoading={isLoading}
                newsContent={newsContent}
                newsData={newsData}
                setActivePage={setActivePage}
                setDate={setDate}
            />
        </Box>
    );
};
