import { Box, Image, Modal, Paper, SimpleGrid, Text, UnstyledButton } from "@mantine/core";
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { FC, ReactNode, useEffect, useState } from "react";
import { HoverText } from "../hover-text";

type Props = {
    label: string;
    isLoading?: boolean;
    defaultValue?: string;
    error?: ReactNode;
    onChange: (files: File[]) => void;
    accept?: string[];
    children?: ReactNode;
    withDeleteIcon?: boolean
};

export const CustomDropzone: FC<Props> = ({
    label,
    isLoading,
    defaultValue,
    error,
    onChange,
    accept,
    children,
    withDeleteIcon = true,

}) => {
    const [files, setFiles] = useState<FileWithPath[]>([]);
    const [opened, { toggle }] = useDisclosure();
    const [active, setActive] = useState("");

    const handleDrop = (acceptedFiles: FileWithPath[]) => {
        setFiles((current) => [...current, ...acceptedFiles]);
    };
    const handleDelete = (index: number) => {
        setFiles((current) => {
            const newFiles = [...current];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    const handlePreview = (file: FileWithPath, index: number) => {
        let fileUrl: string;

        switch (file.type) {
            case MIME_TYPES.pdf:
                fileUrl = "/pdf.png";
                break;
            case MIME_TYPES.docx:
                fileUrl = "/word.png";
                break;
            case MIME_TYPES.xlsx:
                fileUrl = "/excel.png";
                break;
            case MIME_TYPES.xls:
                fileUrl = "/excel.png";
                break;
            case MIME_TYPES.zip:
                fileUrl = "/zip.png";
                break;
            case MIME_TYPES.jpeg:
                fileUrl = URL.createObjectURL(file);
                break;
            case MIME_TYPES.png:
                fileUrl = URL.createObjectURL(file);
                break;
            case MIME_TYPES.gif:
                fileUrl = URL.createObjectURL(file);
                break;
            case MIME_TYPES.webp:
                fileUrl = URL.createObjectURL(file);
                break;
            default:
                fileUrl = "";
        }

        return (
            <>
                {file?.type === "application/pdf" ?
                    <Paper p={8} style={{ overflow: "hidden" }}>
                        <Image w={100} alt="" src="/document.png" />
                        <Text style={{ overflow: "hidden", width: "200px" }}>{file.name.slice(0, 70)}</Text>
                    </Paper>
                    :
                    <>
                        <Image
                            w={100}
                            radius={"sm"}
                            defaultValue={defaultValue ? defaultValue : ""}
                            key={index}
                            src={fileUrl}
                            m={"xs"}
                            fit="cover"
                            onLoad={() => URL.revokeObjectURL(fileUrl)}
                            onClick={() =>
                                window.open(URL.createObjectURL(file), "_blank")
                            }
                            style={{
                                cursor: "pointer",
                            }}
                            alt=""
                        />
                        {file?.name?.length > 10 ? (
                            <HoverText
                                size="xs"
                                width={200}
                                text={file?.name?.slice(0, 10) + "..."}
                                hoverText={file?.name}
                            />
                        ) : (
                            <Text size="xs">{file?.name}</Text>
                        )}

                    </>}
            </>
        );
    };

    const previews = files.map((file, index) => {
        return (
            <Box key={index}>
                {withDeleteIcon ?
                    <UnstyledButton
                        onClick={() => handleDelete(index)}
                    >
                        <IconTrash color="red" />
                    </UnstyledButton>
                    : null}
                {handlePreview(file, index)}
            </Box>
        );
    });

    useEffect(() => {
        onChange(files);
    }, [files]);

    return (
        <div>
            <Dropzone
                loading={isLoading ?? false}
                accept={
                    accept ?? [
                        MIME_TYPES.jpeg,
                        MIME_TYPES.png,
                        MIME_TYPES.pdf,
                        MIME_TYPES.docx,
                        MIME_TYPES.xlsx,
                        MIME_TYPES.zip,
                    ]
                }
                onDrop={handleDrop}
                style={(theme) => ({
                    border: error
                        ? `0.125rem dashed ${theme.colors.red[5]}`
                        : "0.125rem dashed #ced4da",
                    borderRadius: "0.25rem",
                    padding: "1rem",
                    cursor: "pointer",
                })}
            >
                <Text style={{ textAlign: "center" }}>{label}</Text>
            </Dropzone>
            {error && (
                <Text
                    color="red"
                    mt="xs"
                    style={{
                        fontSize: "calc(0.875rem - 0.125rem)",
                        lineHeight: "1.2",
                    }}
                >
                    {error}
                </Text>
            )}
            <SimpleGrid cols={10} mt="md">
                {previews}
            </SimpleGrid>
            {children}
            <Modal size="xl" opened={opened} onClose={toggle}>
                <Image src={active} alt="" />
            </Modal>
        </div>
    );
};
