import { AppShell, Group, ScrollArea } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { createStyles } from "@mantine/styles";
import { ReactNode, useState } from "react";
import { AppBreadcrumbs, Navbar, Sidebar } from "src/components";
import { useLocalStorage } from "src/hooks";

type Props = {
    children: ReactNode;
};
const widths = {
    navbar: { max: 280, min: 90 },
    main: { max: 300, min: 110 },
};
export const AppShellCustom = ({ children }: Props) => {
    const [open, setOpen] = useState<boolean>(true);
    const { classes } = useStyles();
    const profile = useLocalStorage<Profile>("profile").getItem();
    const middleScreen = useMediaQuery("(max-width: 992px)");

    return (
        <ScrollArea.Autosize mah={"100dvh"} mx="auto" scrollbarSize={6}>
            <AppShell
                padding={20}
                className={classes.appShell}
                header={{ height: 60 }}
                navbar={{ width: 300, breakpoint: "sm" }}
            >
                <AppShell.Header>
                    <Navbar />
                </AppShell.Header>
                {!middleScreen && (
                    <AppShell.Navbar
                        w={open ? widths.navbar.max : widths.navbar.min}
                        style={{ transition: "300ms" }}
                    >
                        <Sidebar open={open} setOpen={setOpen} />
                    </AppShell.Navbar>
                )}
                <AppShell.Main
                    bg="rgba(var(--primary-dark), 0.2)"
                    pt={80}
                    pr={20}
                    mb={{ base: 200, md: 0 }}
                    pl={
                        !middleScreen
                            ? open
                                ? widths.main.max
                                : widths.main.min
                            : 15
                    }
                >
                    <Group style={{ gap: "5px" }}>
                        {profile && <AppBreadcrumbs />}
                    </Group>
                    {children}
                </AppShell.Main>
                {middleScreen && (
                    <AppShell.Footer w="100%" bg="baseDark">
                        <Sidebar open={open} setOpen={setOpen} />
                    </AppShell.Footer>
                )}
            </AppShell>
        </ScrollArea.Autosize>
    );
};

const useStyles = createStyles({
    appShell: {
        "& main": {
            width: "100%",
            transition: "all 0.3s ease",
        },
    },
});
