import { Box, Button, Flex, Image } from "@mantine/core";
import { Dispatch, SetStateAction, useEffect } from "react";
import { ChangeLanguage, PagesList } from "src/components";

interface BVI {
    Bvi: any;
    isvek: boolean;
}

declare global {
    interface Window {
        isvek: BVI;
    }
}

interface IProps {
    setSearchActive: Dispatch<SetStateAction<boolean>>;
}

export const LaptopMenu = ({ setSearchActive }: IProps) => {
    const bvi = window.isvek;

    useEffect(() => {
        new bvi.Bvi({
            target: ".bvi-class",
            fontSize: 24,
            theme: "black",
        });
    }, [bvi.Bvi]);
    return (
        <Flex gap={19} mx={-10}>
            <Box mt={3} display={{ base: "none", xxl: "block" }}>
                <PagesList />
            </Box>
            <Flex
                display={{ base: "none", sm: "flex" }}
                gap={10}
                align="center"
            >
                <Flex
                    display={{ base: "none", md: "flex" }}
                    gap={10}
                    align="center"
                    mb={10}
                >
                    <Button
                        onClick={() => setSearchActive((prev) => !prev)}
                        variant="simple"
                        mt={8}
                    >
                        <Image width={24} src="/icons/search.svg" />
                    </Button>
                    <a
                        href="/"
                        className="bvi-class"
                        style={{
                            color: "#868e96",
                            margin: "5px 0 0",
                        }}
                    >
                        <Image src="/icons/icon-eye.svg" />
                    </a>
                </Flex>
                <ChangeLanguage />
            </Flex>
        </Flex>
    );
};
