import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/hooks";
import { getTitleByLanguage } from "src/locales";

type Props = {
    to: string;
    disabled: boolean;
    link: string;
};

export const BreadcrumbLink = ({ to, disabled, link }: Props) => {
    const { t, i18n } = useTranslation();

    const select = useAppSelector((state) => state.breadcrumbsStore.select);
    const title =
        select &&
        select.titleEn.replace(/\s+/g, "").charAt(0).toLowerCase() +
        select.titleEn.slice(1);
    const selectTitle = title && title.replace(/\s/g, "");

    const breadcrumb = Number(link)
        ? link
        : selectTitle === link
            ? getTitleByLanguage(select)
            : t(`pages.title.${link}`);

    return (
        <Text
            c="black"
            component={Link}
            fz={{ base: 12, md: 14 }}
            to={`${to}/${i18n.language}`}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
        >
            {breadcrumb}
        </Text>
    );
};
