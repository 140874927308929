import { Button, Divider, Flex, Grid, NumberInput, Select, Text, Title } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconCheck, IconTrash } from "@tabler/icons-react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomInputText } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useGetAllByTypeCodeQuery, useGetAllMapQuery } from "src/store";
import { FieldValueItem } from "./field-value-item";
import { TypeCodesEnum } from "src/types";

type ItemProps = {
    index: number;
    handleDeleteField: (index: number) => void;
    withPercentage: boolean;
    form: UseFormReturnType<
        DiagramRequestType,
        (values: DiagramRequestType) => DiagramRequestType
    >;
    length: number;
    mainPage: boolean
};

export const FieldItem = ({
    handleDeleteField,
    index,
    form,
    mainPage,
    withPercentage
}: ItemProps) => {
    const [newOrder, setNewOrder] = useState(form.values.fieldRequest?.[index]?.order);
    const { t } = useTranslation();

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setNewOrder(value)
    };

    const handleSaveOrder = (value: number) => () => {
        form.setFieldValue(`fieldRequest.${index}.order`, value)
    }

    const { data: mapPointID } = useGetAllMapQuery([], {
        refetchOnMountOrArgChange: true,
    });

    const mapPointIDSelectData = mapPointID?.map((item) => {
        return {
            value: `${item.id}`,
            label: `${item.cityOrRegionNameRu}`,
        };
    });

    const { data: typeCodes } = useGetAllByTypeCodeQuery(TypeCodesEnum.CARD_INFO_TYPE)
    const countriesID = typeCodes?.map(el => ({
        label: getTitleByLanguage(el),
        value: el.titleEn
    }))


    const percentageValue = useMemo(() => {
        const total = form.values.totalPercentage
        const currentValue = form.values.fieldRequest[index].fieldValue
        if (currentValue) {
            return (currentValue / total) * 100
        } else {
            return 0
        }
    }, [form])

    const handleChangeValue = (value: string | number) => {
        if (value && parseFloat(`${value}`)) {
            form.setFieldValue(`fieldRequest.${index}.fieldValue`, value)
        } else {
            form.setFieldValue(`fieldRequest.${index}.fieldValue`, 0)
        }
    }

    const handleOnChangePercentage = (value: string | number) => {
        const total = form.values.totalPercentage;
        const valueNum = parseFloat(`${value}`);
        if (value && !isNaN(valueNum)) {
            const valueFromPercentage = (valueNum / 100) * total;
            handleChangeValue(valueFromPercentage);
        } else {
            handleChangeValue(0);
        }
    };

    const values = form.values.fieldRequest?.[index]?.valueRequest?.map((el, valueIndex) => (
        <FieldValueItem
            fieldIndex={index}
            valueIndex={valueIndex}
            form={form}
            key={valueIndex}
        />
    ))

    useEffect(() => {
        setNewOrder(form.values.fieldRequest?.[index]?.order);
    }, [index, form]);
    return (
        <>
            <Grid key={index} align="end" py={20}>
                <Grid.Col mt={15}>
                    <Grid>
                        {mainPage ?
                            <Grid.Col span={{ base: 12, md: 4 }}>
                                <Select
                                    mt={15}
                                    {...form.getInputProps(`fieldRequest.${index}.code`)}
                                    placeholder={t`form.label.country`}
                                    value={form.values.fieldRequest?.[index]?.code}
                                    required
                                    error={form.errors.type}
                                    data={countriesID}
                                />
                            </Grid.Col> : null}
                        <Grid.Col span={4}>
                            <Flex>
                                <input
                                    max={100}
                                    min={0}
                                    style={{
                                        width: "80px",
                                        border: "1px solid black",
                                        padding: "0 10px",
                                        background: "none",
                                        color: "black",
                                    }}
                                    onChange={handleOnChange}
                                    value={newOrder}
                                />
                                <Button
                                    onClick={handleSaveOrder(newOrder)}
                                    bg={newOrder === form.values.fieldRequest?.[index]?.order ? "gray" : "green"} px={5}>
                                    <IconCheck />
                                </Button>
                            </Flex>
                        </Grid.Col>

                    </Grid>
                </Grid.Col>

                <Grid.Col span={12}>
                    <Select
                        mt={15}
                        {...form.getInputProps(`fieldRequest.${index}.regionId`)}
                        placeholder={t`form.label.mapPointId`}
                        required
                        error={form.errors.mapPointId}
                        value={`${form.values.fieldRequest[index].regionId}`}
                        data={mapPointIDSelectData || []}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4 }}>
                    <Text lh={1.2} c="green">
                        {t("descriptions.recommended-40-words")}
                    </Text>
                    <CustomInputText
                        {...form.getInputProps(`fieldRequest.${index}.titleRu`)}
                        label={t`form.label.titleRu`}
                        formData={form.values.fieldRequest[index].titleRu}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4 }}>
                    <CustomInputText
                        {...form.getInputProps(`fieldRequest.${index}.titleKg`)}
                        label={t`form.label.titleKg`}
                        formData={form.values.fieldRequest[index].titleKg}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 6, md: 1.5 }}>
                    <NumberInput
                        label={t`form.label.value`}
                        value={form.values.fieldRequest[index].fieldValue}
                        onChange={handleChangeValue}
                        min={0}
                    />
                </Grid.Col>
                {withPercentage &&
                    <Grid.Col span={{ base: 6, md: 1.5 }}>
                        <NumberInput
                            onChange={handleOnChangePercentage}
                            label={t`form.label.percentFrom` + " %"}
                            value={percentageValue}
                            min={0}
                        />
                    </Grid.Col>}
                <Grid.Col span={1}>
                    <Button
                        onClick={() => handleDeleteField(index)}
                        variant="simple"
                    >
                        <IconTrash color="red" />
                    </Button>
                </Grid.Col>
                <Flex gap={10} align="center">
                    <Title fz={18} lh={1.2}>
                        {t`form.label.values`}
                    </Title>
                    <Text
                        style={{ cursor: "pointer", color: "blue", }}
                        onClick={() => form.insertListItem(`fieldRequest.${index}.valueRequest`, {
                            value: 0,
                            valueName: "",
                        })}>
                        Добавить значение
                    </Text>
                </Flex>
                {values}
                <br />
            </Grid>
            <Divider />
        </>
    );
};
