import { Box, Grid, Paper, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Contacts, CustomLoader } from "src/components";
import { useGetMapQuery } from "src/store";

type Props = {
    mapId: string;
};

export const MapDetails = ({ mapId }: Props) => {
    const { t } = useTranslation();

    const { data: mapData, isLoading } = useGetMapQuery(mapId, {
        refetchOnMountOrArgChange: true,
    });

    return (
        <Paper shadow="sm" p="xl">
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <Paper shadow="sm" p="xl">
                    <Grid style={{ borderBottom: "1px solid gray" }} align="center">
                        <Grid.Col span={4} px={8} py={4}>
                            <Text fw={600}>{t(`data.news.title`)}:</Text>
                        </Grid.Col>
                        <Grid.Col span={8} px={8} py={4}>
                            <Title fz={22} c="secondaryDark">
                                {mapData?.addressDetailsRu}
                            </Title>
                        </Grid.Col>
                    </Grid>
                    <Box mt={30}>
                        <Contacts regionId={mapData?.id} onlyMap />
                    </Box>
                </Paper>
            }
        </Paper>
    );
};
