import { BackgroundImage, Box } from "@mantine/core";
import { Outlet, useLocation } from "react-router-dom";
import { Footer, Header } from "src/components";

export const Layout = () => {
    const { pathname } = useLocation();

    const isSignInPage = pathname.startsWith("/sign-in");

    return (
        <BackgroundImage
            src="/assets/bg.webp"
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                backgroundSize: "100%",
                backgroundRepeat: "repeat",
            }}
        >
            {!isSignInPage && <Header />}
            <Box>
                <Outlet />
            </Box>
            {!isSignInPage && <Footer />}
        </BackgroundImage>
    );
};
