import { Container, Grid, NumberInput, Paper, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, CustomInputText } from "src/components";
import { defaultMapImportance } from "src/constants";
import { useCreateMapMutation, useUpdateMapMutation } from "src/store";
import { notify } from "src/utils";

type Props = {
    map?: MapResponseType;
};

export const MapForm = ({ map }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const regionTypes = [
        { value: "CITY", label: t(`table.CITY`) },
        { value: "REGION", label: t(`table.REGION`) },
    ];

    const [createMap] = useCreateMapMutation();
    const [updateMap] = useUpdateMapMutation();

    const form = useForm<MapRequestType>({
        initialValues: {
            mapPointType: map?.mapPointType || "",
            addressDetailsEn: map?.addressDetailsEn || "",
            addressDetailsKg: map?.addressDetailsKg || "",
            addressDetailsRu: map?.addressDetailsRu || "",
            cityOrRegionNameEn: map?.cityOrRegionNameEn || "",
            cityOrRegionNameKg: map?.cityOrRegionNameKg || "",
            cityOrRegionNameRu: map?.cityOrRegionNameRu || "",
            importance: map?.importance || defaultMapImportance,
        },
        validate: {
            addressDetailsKg: (value) =>
                !value && t("form.validation.required"),
            addressDetailsRu: (value) =>
                !value && t("form.validation.required"),
            cityOrRegionNameKg: (value) =>
                !value && t("form.validation.required"),
            cityOrRegionNameRu: (value) =>
                !value && t("form.validation.required"),
            mapPointType: (value) => !value && t`form.validation.required`,
        },
    });

    const [selectState, setSelectState] = useState<string | undefined>(
        form.values.mapPointType
    );

    const handleSubmit = async (values: MapRequestType) => {
        if (map) {
            try {
                await updateMap({ id: map?.id, body: values }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/map_point");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createMap(values).unwrap();
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/map_point");
            } catch {
                notify(false);
            }
        }
    };

    return (
        <Container size="xl" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <CustomInputText
                                {...form.getInputProps("cityOrRegionNameEn")}
                                label={t`form.label.cityOrRegionNameEn`}
                                error={form.errors.cityOrRegionNameEn}
                                formData={form.values.cityOrRegionNameEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <CustomInputText
                                {...form.getInputProps("cityOrRegionNameKg")}
                                label={t`form.label.cityOrRegionNameKg`}
                                required
                                error={form.errors.cityOrRegionNameKg}
                                formData={form.values.cityOrRegionNameKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <CustomInputText
                                {...form.getInputProps("cityOrRegionNameRu")}
                                label={t`form.label.cityOrRegionNameRu`}
                                required
                                error={form.errors.cityOrRegionNameRu}
                                formData={form.values.cityOrRegionNameRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <CustomInputText
                                {...form.getInputProps("addressDetailsEn")}
                                label={t`form.label.addressDetailsEn`}
                                error={form.errors.addressDetailsEn}
                                formData={form.values.addressDetailsEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <CustomInputText
                                {...form.getInputProps("addressDetailsKg")}
                                label={t`form.label.addressDetailsKg`}
                                required
                                error={form.errors.addressDetailsKg}
                                formData={form.values.addressDetailsKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <CustomInputText
                                {...form.getInputProps("addressDetailsRu")}
                                label={t`form.label.addressDetailsRu`}
                                required
                                error={form.errors.addressDetailsRu}
                                formData={form.values.addressDetailsRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                onChange={(value) => {
                                    setSelectState(value ? value : "");
                                    if (value) form.values.mapPointType = value;
                                }}
                                required
                                label="Тип"
                                defaultValue={selectState}
                                value={selectState}
                                data={regionTypes}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 2 }}>
                            <NumberInput
                                label={t`form.label.importance`}
                                required
                                error={form.errors.importance}
                                {...form.getInputProps("importance")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(`/map_point/${i18n.language}`)
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
