import { Center, Flex, Grid, Image, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { getTitleByLanguage } from "src/locales";

interface Props {
    data: GosSymbolType;
}

export const SymbolItem = ({ data }: Props) => {
    const largeScreen = useMediaQuery("(max-width: 1024px)");
    const subTitle = getTitleByLanguage({
        titleRu: data?.subTitleRu,
        titleKg: data?.subTitleKg,
    });
    const description = getTitleByLanguage({
        titleRu: data?.descriptionRu,
        titleKg: data?.descriptionKg,
    });
    const authors = getTitleByLanguage({
        titleKg: data.authorsKg,
        titleRu: data.authorsRu,
    });

    const translation = data.translationRu ? getTitleByLanguage({
        titleKg: data.translationKg,
        titleRu: data.translationRu,
    }) : null;

    return (
        <Paper p={{ base: 10, sm: 32 }} shadow="xl">
            <Grid gutter={30}>
                <Grid.Col span={largeScreen ? 12 : 3}>
                    <Center>
                        <Image
                            mt={{ base: 0, md: data?.type === "logo" ? 80 : 0 }}
                            width="100%"
                            maw={data?.type === "logo" ? 800 : 400}
                            mb={{ base: 0, md: 20 }}
                            src={data.img}
                        />
                    </Center>
                    {data.titleRu.includes("гимн") && (
                        <figure style={{ width: "100%" }}>
                            <audio
                                style={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    marginLeft: "-35px",
                                }}
                                controls
                                src="/assets/gimn.ogg"
                            ></audio>
                        </figure>
                    )}
                    <Text lh={1.2} fz={18} c="secondaryDark">
                        {subTitle !== "-" && subTitle}
                    </Text>
                </Grid.Col>
                <Grid.Col span={largeScreen ? 12 : 8}>
                    <Flex
                        h="100%"
                        direction="column"
                        justify="space-between"
                        gap={30}
                    >
                        <div>
                            <Title
                                mb={{ base: 5, lg: 10 }}
                                c="primaryDark"
                                fz={{ base: 23, md: 28 }}
                            >
                                {getTitleByLanguage(data)}
                            </Title>
                            <Flex gap={100} w="fit-content">
                                <Text
                                    ta="justify"
                                    style={{ whiteSpace: "break-spaces" }}
                                    w={"100%"}
                                    fz={18}
                                    c="primaryDark"
                                >
                                    {data.type === "gimn"
                                        ? data.descriptionKg
                                        : description}
                                </Text>
                                {data.type === "gimn" && (
                                    <Text
                                        ta="justify"
                                        style={{ whiteSpace: "break-spaces" }}
                                        w={"100%"}
                                        fz={18}
                                        c="primaryDark"
                                    >
                                        {data.descriptionRu}
                                    </Text>
                                )}
                            </Flex>
                        </div>
                        {data?.authorsRu && data?.authorsKg ? (
                            <Text
                                style={{ whiteSpace: "break-spaces" }}
                                c="secondaryDark"
                                fz={20}
                            >
                                {authors} <br />
                                {translation}
                            </Text>
                        ) : null}
                    </Flex>
                </Grid.Col>
            </Grid>
        </Paper>
    );
};
