import { BackgroundImage, Box, Flex, Image, Title } from "@mantine/core";
import { useInViewport } from "@mantine/hooks";
import { getTitleByLanguage } from "src/locales";
import { fadeIn } from "src/utils";

interface Props {
    item: HistoryType;
}

export const SimpleHistoryItem = ({ item }: Props) => {
    const { ref, inViewport } = useInViewport();

    return (
        <Box ref={ref} bg="primary">
            <BackgroundImage
                py={50}
                mb={150}
                src="/assets/bg.webp"
                pos="relative"
                style={{ zIndex: 3 }}
            >
                {item.img && item.titleRu ? (
                    <Flex
                        className="container"
                        key={item.id}
                        gap={40}
                        align="center"
                        wrap="wrap"
                        justify="space-around"
                    >
                        <Title
                            style={{
                                ...fadeIn("left", inViewport),
                                whiteSpace: "pre-wrap",
                            }}
                            order={4}
                            fw={400}
                            lh={2}
                            c="primaryDark"
                            ta="justify"
                            maw={900}
                        >
                            {getTitleByLanguage(item)}
                        </Title>
                        <Image
                            style={fadeIn("right", inViewport)}
                            miw={item.img.isHuman ? 350 : 550}
                            mih={
                                item.img.isHuman ? 300 : { base: 500, xl: 600 }
                            }
                            h="100%"
                            src={item.img.src}
                            alt=""
                        />
                    </Flex>
                ) : (
                    item.img && (
                        <Flex
                            className="container"
                            style={fadeIn("right", inViewport)}
                            key={item.id}
                            justify="end"
                        >
                            <Image src={item.img.src} alt="" />
                        </Flex>
                    )
                )}
            </BackgroundImage>
        </Box>
    );
};
