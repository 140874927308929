import { Grid } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ButtonGroup, CustomInputText, CustomModal } from "src/components";
import { NewsFilterType } from "src/types/data-models/news";

type Props = {
    open: boolean;
    filter?: NewsFilterType;
    handleClose: () => void;
    onReset: () => void;
    setSearchParams: Function;
};

export const PageListAccordionFilter = ({
    open,
    filter,
    handleClose,
    onReset,
    setSearchParams,
}: Props) => {
    const { t } = useTranslation();

    const form = useForm<NewsFilterType>({
        initialValues: {
            title: "",
        },
    });

    useEffect(() => {
        if (filter) {
            form.setValues({
                title: filter.title || "",
            });
        }
    }, [filter]);

    const onClickReset = () => {
        form.reset();
        handleClose();
        onReset();
    };

    const handleSubmit = async (values: NewsFilterType) => {
        form.reset();
        handleClose();
        for (let key in values) {
            if (values[key as keyof NewsFilterType] === "") {
                delete values[key as keyof NewsFilterType];
            }
        }
        setSearchParams(values);
    };

    return (
        <CustomModal
            title={t`modal.filter`}
            open={open}
            handleClose={handleClose}
            button={false}
            size="xl"
        >
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Grid>
                    <Grid.Col span={12}>
                        <CustomInputText
                            {...form.getInputProps("title")}
                            label={t`form.label.title`}
                            error={form.errors.title}
                            formData={form.values.title}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <ButtonGroup
                            labelCancel="reset"
                            labelSubmit="search"
                            onClickCancel={onClickReset}
                            type="submit"
                        />
                    </Grid.Col>
                </Grid>
            </form>
        </CustomModal>
    );
};
