import {
    Button,
    Container,
    Flex,
    Grid,
    Image,
    Modal,
    Paper,
    TypographyStylesProvider,
    UnstyledButton,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    ButtonGroup,
    CustomDropzone,
    CustomInputText,
    TextEditor,
} from "src/components";
import { nowDate } from "src/constants";
import { useCreateBannerMutation, useUpdateBannersMutation } from "src/store";
import { useGetByPhotoAlbumQuery } from "src/store/reducers";
import { notify } from "src/utils";

type Props = {
    banner?: BannerGetType;
};

export const BannerForm = ({ banner }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [photos, setPhotos] = useState<IPhoto[]>();

    const [createBanner] = useCreateBannerMutation();
    const [updateBanner] = useUpdateBannersMutation();

    const [opened, { close, open }] = useDisclosure(false);
    const { data: images } = useGetByPhotoAlbumQuery(
        banner?.photoAlbum?.id || 0
    );

    const [htmlContent, setHtmlContent] = useState("");
    const handleDeletePhoto = (id: number) => {
        const newArr = photos?.filter((el) => el.id !== id);
        setPhotos(newArr);
        form.setFieldValue(
            "toDeleteAttachments",
            form.values.toDeleteAttachments?.length
                ? [...form.values.toDeleteAttachments, id]
                : [id]
        );
    };
    useEffect(() => {
        if (images) {
            setPhotos(images);
        }
    }, [images]);
    const form = useForm<BannerType>({
        initialValues: {
            id: banner?.id || 0,
            date: banner?.date || "",
            descriptionEn: banner?.content?.titleEn || "",
            descriptionKg: banner?.content?.titleKg || "",
            descriptionRu: banner?.content?.titleRu || "",
            isDisabled: false,
            linkApp: banner?.linkApp || "",
            linkPlay: banner?.linkPlay || "",
            titleEn: banner?.titleEn || "",
            titleKg: banner?.titleKg || "",
            titleRu: banner?.titleRu || "",
            attachments: null,
        },
        validate: {
            date: (value) => !value && t("form.validation.required"),
            descriptionKg: (value) => !value && t("form.validation.required"),
            descriptionRu: (value) => !value && t("form.validation.required"),
            titleKg: (value) => !value && t("form.validation.required"),
            titleRu: (value) => !value && t("form.validation.required"),
        },
    });
    const handleSubmit = async ({ attachments, ...values }: BannerType) => {
        if (banner) {
            try {
                await updateBanner({
                    id: banner?.id,
                    body: values,
                    attachments,
                }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/banner");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createBanner({ attachments, ...values }).unwrap();
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/banner");
            } catch {
                notify(false);
            }
        }
    };
    const handlePreviewButton = (val: string) => {
        setHtmlContent(val);
        open();
    };

    return (
        <Container size="xl" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={12}>
                            <DateTimePicker
                                maxDate={nowDate}
                                {...form.getInputProps("date")}
                                label={t`form.label.dateFrom`}
                                error={form.errors.date}
                                value={
                                    form.values.date
                                        ? new Date(form.values.date)
                                        : undefined
                                }
                                required
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleKg")}
                                label={t`form.label.titleKg`}
                                required
                                error={form.errors.titleKg}
                                formData={form.values.titleKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleRu")}
                                label={t`form.label.titleRu`}
                                required
                                error={form.errors.titleRu}
                                formData={form.values.titleRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleEn")}
                                label={t`form.label.titleEn`}
                                error={form.errors.titleEn}
                                formData={form.values.titleEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <CustomInputText
                                {...form.getInputProps("linkApp")}
                                label={t`form.label.linkApp`}
                                required
                                error={form.errors.linkApp}
                                formData={form.values.linkApp}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <CustomInputText
                                {...form.getInputProps("linkPlay")}
                                label={t`form.label.linkPlay`}
                                required
                                error={form.errors.linkPlay}
                                formData={form.values.linkPlay}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.contentRu`}
                                labelButton={
                                    <Button
                                        variant="subtle"
                                        color="blue"
                                        size="sm"
                                        radius="sm"
                                        ml={5}
                                        onClick={() =>
                                            handlePreviewButton(
                                                form.values.descriptionRu
                                            )
                                        }
                                    >
                                        {t`button.preview`}
                                    </Button>
                                }
                                required
                                error={form.errors.descriptionRu}
                                formData={form.values.descriptionRu}
                                {...form.getInputProps("descriptionRu")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.contentKg`}
                                labelButton={
                                    <Button
                                        variant="subtle"
                                        color="blue"
                                        size="sm"
                                        radius="sm"
                                        ml={5}
                                        onClick={() =>
                                            handlePreviewButton(
                                                form.values.descriptionKg
                                            )
                                        }
                                    >
                                        {t`button.preview`}
                                    </Button>
                                }
                                required
                                error={form.errors.descriptionKg}
                                formData={form.values.descriptionKg}
                                {...form.getInputProps("descriptionKg")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.contentEn`}
                                labelButton={
                                    <Button
                                        variant="subtle"
                                        color="blue"
                                        size="sm"
                                        radius="sm"
                                        ml={5}
                                        onClick={() =>
                                            handlePreviewButton(
                                                form.values.descriptionEn
                                            )
                                        }
                                    >
                                        {t`button.preview`}
                                    </Button>
                                }
                                required
                                error={form.errors.descriptionEn}
                                formData={form.values.descriptionEn}
                                {...form.getInputProps("descriptionEn")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomDropzone
                                label={t`form.label.photo`}
                                error={form.errors.attachments}
                                {...form.getInputProps("attachments")}
                                accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                            />
                            {photos && (
                                <Flex mt={10} wrap="wrap" gap={10}>
                                    {photos?.map(
                                        (el) =>
                                            "id" in el && (
                                                <Flex
                                                    direction="column"
                                                    align="center"
                                                    key={el.id}
                                                >
                                                    <UnstyledButton
                                                        onClick={() =>
                                                            handleDeletePhoto(
                                                                el.id
                                                            )
                                                        }
                                                    >
                                                        <IconTrash color="red" />
                                                    </UnstyledButton>
                                                    <Image
                                                        w={100}
                                                        alt=""
                                                        src={`data:image/png;base64,${el.miniature}`}
                                                    />
                                                </Flex>
                                            )
                                    )}
                                </Flex>
                            )}
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(`/banner/${i18n.language}`)
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
            <Modal
                opened={opened}
                onClose={close}
                size={"60%"}
                title="Предпросмотр"
            >
                <TypographyStylesProvider>
                    <div
                        style={{ color: "#2D4263" }}
                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                    />
                </TypographyStylesProvider>
            </Modal>
        </Container>
    );
};
