"use client"
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Box, Text, Tooltip } from "@mantine/core";
import { MAP_POINT_TYPE_IDS } from "src/constants";


type Props = {
  data: SearchDiagramResponseContentType;
  activeRegion?: number;
  handleClickRegion?: (p: number) => void;
  activeColor?: string
  color?: string;
  strokeColor?: string;
  withActiveRegionNames?: boolean;
  withRegionNames?: boolean
  textColor?: string;
  activeTextColor?: string
}

type KgMapJsonType = {
  titleRu: string;
  titleKg: string;
  dotCenter: string;
  dotOutline: string;
  d: string;
  id: number;
  id2: number;
  code: keyof typeof MAP_POINT_TYPE_IDS;
}


export const KgMapDiagram = ({
  data,
  color = "rgba(255,255,255, 0.5)",
  activeColor = "rgba(255,255,255, 0.8)",
  strokeColor = "black",
  withActiveRegionNames = true,
  withRegionNames = true,
  textColor = "#555F71",
  activeTextColor = "white"
}: Props) => {
  const [mapJson, setMapJson] = useState<any[]>()
  const { i18n } = useTranslation()

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);


  useEffect(() => {
    ; (async () => {
      const res = await axios('/json/kg-map-coordinates.json')
      setMapJson(res.data)
    })()
  }, [])

  return (
    <svg width="100%" height="100%" viewBox="0 0 1202 594" fill="none" xmlns="http://www.w3.org/2000/svg">
      {mapJson?.map((item: KgMapJsonType,idx) => {
        const currentRegion = data?.diagramFields?.filter(el => el.region?.id ? MAP_POINT_TYPE_IDS[item.code].includes(el.region?.id) : null)
        return (
          <Tooltip key={item.id} styles={{ tooltip: { background: currentRegion?.length ? "rgba(38,51,152,1)" : "transparent" } }} label={
            currentRegion?.length ? <Box w={200} bg="rgba(38,51,152,1)">
              {currentRegion?.map((el) => (
                <Box key={el.id}>
                  <Text c="white" fz={10} fw={400}>
                    {i18n.language === "ru" ? el.titleRu : el.titleKg}
                  </Text>
                  <Text fz={12} c="white" fw={500}>
                    {el.fieldValue}
                  </Text>
                </Box>
              ))}
            </Box> : null
          }>
            <path
              d={item.d}
              fill={hoveredIndex === idx ? "#C6C9D9" : color}
              style={{
                cursor: "pointer", transition: "350ms",
              }}
              stroke={strokeColor}
              strokeOpacity="0.7"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
            />
          </Tooltip>
        )
      })}
      {withActiveRegionNames ?
        <>
          {mapJson?.map((item: KgMapJsonType) =>
            <React.Fragment key={item.id}>
              <path
                d={item.dotCenter}
                style={{ cursor: "pointer", transition: "350ms" }}
                fill="#0A164F" />
              <path
                d={item.dotOutline}
                style={{ cursor: "pointer", transition: "350ms" }}
                stroke="white" strokeWidth="2" />
            </React.Fragment>)}
          {mapJson?.map((item: KgMapJsonType) =>
            <path key={item.id}
              d={item.titleRu}
              fill={textColor} />)}
        </> : null}
      {withRegionNames ?
        <>
          {mapJson?.map((item: KgMapJsonType) => (
            <div key={item.id}>
              <path
                d={item.dotCenter}
                style={{ cursor: "pointer", transition: "350ms" }}
                fill="#5686FF" />
              <path
                d={item.dotOutline}
                style={{ cursor: "pointer", transition: "350ms" }}
                stroke="white" strokeWidth="2" />
            </div>
          ))}
          {mapJson?.map((item: KgMapJsonType) => (
            <path key={item.id}
              d={i18n.language === "ru" ? item.titleRu : item.titleKg}
              fill={textColor} />
          ))}
        </> : null}
    </svg>
  )
}
