import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const infoBlockApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createInfoBlock: builder.mutation<
            InfoBlockResponseType,
            InfoBlockRequestType
        >({
            query: ({ attachmentsFile, attachmentsPhoto, ...body }) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });
                formData.append("request", data);

                attachmentsFile &&
                    attachmentsFile.map((file) =>
                        formData.append("attachmentsFile", file)
                    );

                attachmentsPhoto &&
                    attachmentsPhoto.map((file) =>
                        formData.append("attachmentsPhoto", file)
                    );
                return {
                    url: Path.InfoBlock.create,
                    method: "POST",
                    body: formData,
                };
            },
        }),
        updateInfoBlock: builder.mutation<
            InfoBlockResponseType,
            {
                id: number;
                attachmentsPhoto: File[] | null;
                attachmentsFile: File[] | null;
                body: Partial<InfoBlockRequestType>;
            }
        >({
            query: ({ id, attachmentsFile, attachmentsPhoto, body }) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });
                formData.append("request", data);
                attachmentsFile &&
                    attachmentsFile.map((file) =>
                        formData.append("attachmentsFile", file)
                    );
                attachmentsPhoto &&
                    attachmentsPhoto?.map((file) =>
                        formData.append("attachmentsPhoto", file)
                    );
                return {
                    url: Path.InfoBlock.update(id),
                    method: "PUT",
                    body: formData,
                };
            },
            invalidatesTags: ["InfoBlock"],
        }),
        getPublishInfoBlock: builder.query<InfoBlockResponseType[], unknown>({
            query: () => Path.InfoBlock.getAll,
            providesTags: ["InfoBlock"],
        }),
        getAllByTypeInfoBlock: builder.query<InfoBlockSearchResponseContentType[], number | string>({
            query: (id) => ({
                url: Path.InfoBlock.getAllType(id),
            }),
            providesTags: ["InfoBlock"],
        }),
        getInfoBlock: builder.query<InfoBlockResponseType, string>({
            query: (id) => ({
                url: Path.InfoBlock.getById(id),
            }),
            providesTags: ["InfoBlock"],
        }),
        infoBlockDelete: builder.mutation({
            query: (id) => ({
                url: Path.InfoBlock.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["InfoBlock"],
        }),
        infoBlockSearch: builder.mutation<
            InfoBlockSearchResponseType,
            InfoBlockSearchRequestType
        >({
            query: (postData) => ({
                url: Path.InfoBlock.search,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
            invalidatesTags: ["InfoBlock"],
        }),
    }),
});

export const {
    useCreateInfoBlockMutation,
    useGetAllByTypeInfoBlockQuery,
    useGetInfoBlockQuery,
    useGetPublishInfoBlockQuery,
    useInfoBlockDeleteMutation,
    useInfoBlockSearchMutation,
    useUpdateInfoBlockMutation,
} = infoBlockApiSlice;
