import { Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getTitleByLanguage } from "src/locales";

export const PageLinks = ({ pages }: { pages?: PageListType[] }) => {
    const { i18n } = useTranslation();
    return (
        <nav style={{ gap: "0" }}>
            {pages?.map(
                (el) =>
                    el.id !== 3 && (
                        <Link target="_blank"
                            key={el.id}
                            to={
                                el.position
                                    ? `/${el.position}/${i18n.language}`
                                    : `/${i18n.language}`
                            }
                        >
                            <Title
                                c="primaryDark"
                                order={3}
                                fz={{ base: 16, sm: 18 }}
                                lh={1}
                                mb={{ base: 10, sm: 20 }}
                            >
                                {getTitleByLanguage(el)}
                            </Title>
                        </Link>
                    )
            )}
        </nav>
    );
};
