import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { getTitleByLanguage } from "src/locales";
import React, { useState } from "react";
import { Box, Flex, Image } from "@mantine/core";

type Props = {
    data: SearchDiagramResponseContentType;
    isSmall?: boolean;

};

export const AreaChat = ({ data, isSmall = false}: Props) => {
    const refactoredData = data?.diagramFields?.map(el => ({
        ...el,
        values: el.values ? [...el.values] : []
    }));

    const categories = [
        ...(refactoredData?.[0]?.values || []),
        ...(refactoredData?.[1]?.values || []),
    ]
        .map(el => getTitleByLanguage(el))
        .filter((value, index, self) => self.indexOf(value) === index);

    const title1 = refactoredData?.[0]?.titleKg ? getTitleByLanguage(refactoredData[0]) : "Мужчины";
    const title2 = refactoredData?.[1]?.titleKg ? getTitleByLanguage(refactoredData[1]) : "Женщины";

    const chartData = categories.map((category, index) => ({
        category,
        [title1]: refactoredData?.[0]?.values?.[index]?.value || 0,
        [title2]: refactoredData?.[1]?.values?.[index]?.value || 0,
    }));

    let totalMales = 0;
    let totalFemales = 0;

    chartData.forEach(item => {
        totalMales += (item["Мужчины"] ?? item["Эркектер"] ?? item['мужской']) as number;
        totalFemales += (item["Женщины"] ?? item["Кыздарики"] ?? item['женский'])  as number;
    });

    console.log(chartData);

    const total = totalMales + totalFemales;

    const malePercentage = (totalMales / total) * 100;
    const femalePercentage = (totalFemales / total) * 100;

    return (
        <div style={{ }}>
            <div style={{
                position: "absolute",
                top: 28,
                right: 20,
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                padding: "8px 12px",
                borderRadius: "8px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                fontSize: "14px",
                fontWeight: "bold",
            }}>
                <Flex gap="8px" align="center" mr="16px">
                    <Image src="/icons/blue-avatar.svg" />
                    {!isNaN(malePercentage) ? malePercentage.toFixed(2) : 0.0}%
                </Flex>
                <Flex gap="8px" align="center">
                    <Image src="/icons/red-avatar.svg" />
                    {!isNaN(femalePercentage) ? femalePercentage.toFixed(2) : 0.0}%
                </Flex>
            </div>

            <ResponsiveContainer width="100%" height={isSmall ? 300 : 500}>
                <AreaChart data={chartData}>
                    <XAxis dataKey="category" />
                    <YAxis />
                    <Tooltip />

                    <defs>
                        <linearGradient id="colorMale" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#263398" stopOpacity={0.5} />
                            <stop offset="100%" stopColor="#FFFFFF00" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorFemale" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#901D5B" stopOpacity={0.5} />
                            <stop offset="100%" stopColor="#901D5B00" stopOpacity={0} />
                        </linearGradient>
                    </defs>

                    <Area
                        type="monotone"
                        dataKey={title1}
                        stroke="#263398"
                        fill="url(#colorMale)"
                        strokeWidth={3}
                        dot={{ fill: "#FFF", stroke: "#263398", strokeWidth: 2, r: 5 }}
                    />
                    <Area
                        type="monotone"
                        dataKey={title2}
                        stroke="#901D5B"
                        fill="url(#colorFemale)"
                        strokeWidth={3}
                        dot={{ fill: "#FFF", stroke: "#901D5B", strokeWidth: 2, r: 5 }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};
