import { Carousel } from "@mantine/carousel";
import { Box, Grid, ScrollArea } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect } from "react";
import { CustomLoader, NewsItem } from "src/components";
import { NEWS_100_YEAR_ID } from "src/constants";
import { useNewsSearchMutation } from "src/store";
import styles from './styles.module.scss';

interface Props {
    isScrollArea?: boolean;
    is100yearPage?: boolean
}

export const PanelNews = ({ isScrollArea = false, is100yearPage }: Props) => {
    const largeScreen = useMediaQuery("(max-width: 1200px)");
    const [searchNews, { data: newsData, isLoading }] = useNewsSearchMutation();
    const getNewsData = async () => {
        try {
            await searchNews({
                filter: {
                    tags: is100yearPage ? [NEWS_100_YEAR_ID] : undefined
                },
                pageRequest: { limit: 6, page: 0 },
                sorting: {
                    sortBy: "PUBLISHED_AT",
                    sortDirection: "DESC",
                },
            });
        } catch {
            console.error("error");
        }
    }
    useEffect(() => {
        getNewsData()
    }, [])

    const body = () => {
        if (isLoading) {
            return (
                <Box pos="relative" w="100%" h="20vh">
                    <CustomLoader />
                </Box>
            )
        } else {
            if (!isScrollArea) {
                if (largeScreen) {
                    return (
                        <Carousel
                            orientation="horizontal"
                            withControls={false}
                            withIndicators={false}
                            slideSize={{ base: "98%", xs: "460px" }}
                            w="100%"
                            slideGap={{ base: "xs", md: "md" }}
                            loop
                            align="start"
                            slidesToScroll={1}
                            className={styles.other_news}
                        >
                            {newsData?.content?.map((el) => (
                                <Carousel.Slide key={el.id}>
                                    <NewsItem
                                        textLimit={25}
                                        order={6}
                                        news={el}
                                    />
                                </Carousel.Slide>
                            ))}
                        </Carousel>
                    )
                } else {
                    return (
                        <>
                            {newsData?.content
                                ?.map((el) => (
                                    <NewsItem
                                        key={el.id}
                                        order={6}
                                        news={el}
                                    />
                                ))}
                        </>
                    )
                }
            } else {
                if (!largeScreen) {
                    return (
                        <ScrollArea scrollbarSize={3} h={800} mb={{ base: 24, md: 40 }}>
                            {newsData?.content
                                ?.map((el) => (
                                    <NewsItem
                                        isPressPage
                                        key={el.id}
                                        order={6}
                                        news={el}
                                    />
                                ))}
                        </ScrollArea>
                    )
                } else return null
            }
        }

    }

    return (
        <Grid.Col
            span={{ base: 12, lg: 4.9 }}
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
            }}
        >
            {body()}
        </Grid.Col>
    )
}
