import { IconPencil } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { AppTable, MenuDropDown } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { HeadCell } from "src/types";
import { Path } from "src/utils";

export const ReferenceTable = ({ filter }: { filter?: ReferenceFilter }) => {
    const { t } = useTranslation();

    const actions = (id: number) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/reference/${id}/edit-reference`,
        },
    ];

    const headCells: HeadCell<Reference>[] = [
        {
            label: t`table.title`,
            render: (n) => getTitleByLanguage(n),
        },
        {
            label: t`actions.title`,
            render: (n) => (
                <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
            ),
        },
    ];

    return (
        <AppTable<Reference>
            headCells={headCells}
            searchApiUrl={Path.Reference.search}
            extraFilter={filter}
            sorting={{ sortBy: "ID", sortDirection: "DESC" }}
        />
    );
};
