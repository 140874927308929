import { Carousel } from "@mantine/carousel";
import {
    BackgroundImage,
    Box,
    Button,
    Flex,
    Image,
    Paper,
    Text,
    Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getTitleByLanguage } from "src/locales";
import styles from "./styles.module.scss";

interface BannerCardProps {
    banner: BannerGetAll;
    idx: number;
}

export const BannerCard = ({ banner }: BannerCardProps) => {
    const { t, i18n } = useTranslation();
    const largeScreen = useMediaQuery("(max-width: 1200px)");
    return (
        <Carousel.Slide>
            <Paper variant="slide" w="100%">
                <BackgroundImage
                    src={`data:image/webp;base64,${banner?.photoAlbum?.photo}`}
                    style={{
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: largeScreen ? "left" : "center",
                    }}
                >
                    <Flex className={styles.slider_item}>
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                        >
                            <Box mb={30}>
                                <Title
                                    fz={{ base: 18, sm: 24, xl: 30 }}
                                    lh={1}
                                    c="primary"
                                    fw={700}
                                    ta="center"
                                >
                                    {getTitleByLanguage(banner)}
                                </Title>
                                {banner && (
                                    <Text
                                        ta="center"
                                        fz={{ base: 13, sm: 16, xl: 18 }}
                                        c="primary"
                                        lh={1}
                                        dangerouslySetInnerHTML={{
                                            __html: getTitleByLanguage(
                                                banner.content
                                            ),
                                        }}
                                    />
                                )}
                            </Box>
                            {(banner?.linkPlay !== "-" ||
                                banner?.linkApp !== "-") && (
                                    <Flex justify="center" gap="1rem">
                                        {banner?.linkPlay?.startsWith("/") ? (
                                            <Link to={banner?.linkPlay}>
                                                <Button
                                                    bg="primary"
                                                    c="primaryDark"
                                                    className={styles.banner_btn}
                                                >
                                                    {t("statics.online-reception")}
                                                </Button>
                                            </Link>
                                        ) : (
                                            banner?.linkPlay?.startsWith(
                                                "https"
                                            ) && (
                                                <a
                                                    href={banner?.linkPlay}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Button
                                                        bg="primary"
                                                        c="primaryDark"
                                                        className={
                                                            styles.banner_btn
                                                        }
                                                    >
                                                        {t("statics.download")}
                                                        <Box ml={9}>
                                                            <Image src="/icons/android-black.svg" />
                                                        </Box>
                                                    </Button>
                                                </a>
                                            )
                                        )}
                                        {banner?.linkApp?.startsWith("/") ? (
                                            <Link
                                                to={`${banner?.linkApp}/${i18n.language}`}
                                            >
                                                <Button
                                                    bg="primary"
                                                    c="primaryDark"
                                                    className={styles.banner_btn}
                                                >
                                                    {t("statics.online-reception")}
                                                </Button>
                                            </Link>
                                        ) : null}
                                    </Flex>
                                )}
                        </Flex>
                    </Flex>
                </BackgroundImage>
            </Paper>
        </Carousel.Slide>
    );
};
