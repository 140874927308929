import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";


export const googleAnalyticsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getGoogleAnalyticsData: builder.query<GoogleAnalyticsResponse, unknown>({
            query: () => ({
                url: Path.GoogleAnalytics.getData,
                method: "GET",
            }),
        }),

    }),
});

export const {
    useGetGoogleAnalyticsDataQuery
} = googleAnalyticsApiSlice;
