import { Button, Flex, Image, Input } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SearchNewsResults } from "../forms/search-news-results";
import styles from "./styles.module.scss";

export const Search = ({ searchShowed }: { searchShowed: boolean }) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    const [resultsShowed, setResultsShowed] = useState(false);
    const largeScreen = useMediaQuery("(max-width: 1200px");
    const { pathname } = useLocation();

    useEffect(() => {
        if (searchValue !== "") {
            setResultsShowed(true);
        } else {
            setResultsShowed(false);
        }
    }, [searchValue]);

    useEffect(() => {
        setSearchValue("");
    }, [pathname]);

    return (
        <Flex
            className={styles.search_container}
            display={{ base: "none", sm: "flex" }}
            p={20}
            style={{
                transform: searchShowed
                    ? largeScreen
                        ? "translate(5vw)"
                        : "translateY(0)"
                    : largeScreen
                        ? "translate(5vw, -150%)"
                        : "translateY(-150%)",
            }}
            pos="absolute"
            top={80}
            right="5vw"
            align="center"
            justify="start"
            bg={searchShowed ? "#0a164fd3" : "primaryDark"}
        >
            <Input
                display={searchShowed ? "flex" : "none"}
                rightSection={
                    <Button
                        display={searchShowed ? "flex" : "none"}
                        variant="simple"
                    >
                        <Image width={20} src="/icons/search.svg" />
                    </Button>
                }
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                c="primary"
                className={styles.search_input}
                placeholder={t("statics.search")}
            />
            {resultsShowed && <SearchNewsResults searchValue={searchValue} />}
        </Flex>
    );
};
