import { Box, Text } from "@mantine/core";
import { getTitleByLanguage } from "src/locales";
import styles from "./styles.module.scss";
interface Props {
    data?: PageListAccordion;
}
export const TextModule = ({ data }: Props) => {
    return (
        <Box
            className={styles.textContainer}
            fz={{ base: "md", xl: "lg" }}
            c="blueDark"
        >
            <Text
                className={styles.text}
                c="primaryDark"
                lh={1.6}
                fz={{ base: 16, sm: 18 }}
                dangerouslySetInnerHTML={{
                    __html: getTitleByLanguage({
                        titleRu: data?.contentRu || "",
                        titleKg: data?.contentKg || "",
                    })
                }}
            />
        </Box>
    );
};
