import {
    Flex,
    FloatingPosition,
    Menu,
    Text,
    UnstyledButton
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import {
    IconChevronDown,
    IconChevronRight,
    IconUserScan,
} from "@tabler/icons-react";
import { useState } from "react";
import { MenuItemType } from "src/types/data-models/global";
import { MenuItem } from "./menu-item";

type Props = {
    label?: string | null;
    item: MenuItemType[];
    position?: FloatingPosition;
    className?: string;
    isProfile?: boolean;
};

export const MenuDropDown = ({
    label,
    item,
    position = "bottom",
    className,
    isProfile = false,
}: Props) => {
    const { classes, cx } = useStyles();
    const [open, setOpen] = useState<boolean>();
    const Icon = isProfile
        ? IconUserScan
        : open
            ? IconChevronDown
            : IconChevronRight;

    return (
        <Menu
            shadow="md"
            withArrow
            openDelay={110}
            onChange={(opened) => setOpen(opened)}
            position={position}
        >
            <Menu.Target>
                <UnstyledButton
                    w="100%"
                    className={cx(classes.menuButton, className)}
                >
                    <Flex
                        ta="center"
                        w="100%"
                        justify={{ base: "center", md: "start" }}
                    >
                        <Text display={{ base: "none", md: "block" }} c="gray">
                            {label}
                        </Text>
                        <Icon size={25} stroke={1.5} />
                    </Flex>
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown
                w={230}
                py={24}
                px={16}
                bg="primaryDark"
                style={{ border: "none" }}
                className={classes.menuContent}
            >
                {item?.map((item) => (
                    <MenuItem key={item?.title} item={item} />
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};

const useStyles = createStyles({
    menuButton: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "& div": {
            fontSize: 14,
        },
    },
    menuContent: {
        "& a": {
            padding: "6px 7px",
        },
    },
});
