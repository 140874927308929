import { Box, Center, Flex, Image, Modal, Paper, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";
import { NewsResponse } from "src/types/data-models/news";
import { getAttachmentUrl } from "src/utils";

type Props = {
    data: NewsResponse;
};
export const VideoNewsItem = ({ data }: Props) => {
    const [opened, { toggle }] = useDisclosure()
    const { i18n } = useTranslation()

    const title = getTitleByLanguage(data)
    const slicedTitle = title.length > 70 ? title.slice(0, 70) + "..." : title

    return (
        <>
            <Paper style={{ cursor: "pointer" }}
                onClick={toggle} p={10} w={300} h={280}>
                <Box w="100%" h={200}>
                    {data?.photoAlbum?.photo ?
                        <Image mb={10} style={{ objectFit: "contain" }}
                            src={`data:image/png;base64,${data?.photoAlbum?.photo}`} />
                        :
                        <Center h="100%" w="auto" >
                            <Image h="100%" w="auto" src="/logo.svg" />
                        </Center>
                    }
                </Box>
                <Flex align="center" gap={10}>
                    <Text fz={12} c="primaryDark">
                        {dayjs(data.publishedAt).format(
                            i18n.language === "ru"
                                ? "D MMMM YYYY"
                                : "D-MMMM YYYY"
                        )}
                    </Text>
                    <Image src="/icons/calendar.svg" />
                </Flex>
                <Text c="primaryDark" fz={12}>
                    {slicedTitle}
                </Text>
            </Paper>
            {data?.attachmentsRu?.length ?
                <Modal withCloseButton={false} centered size="xl" opened={opened} onClose={toggle}>

                    <video autoPlay width="100%" controls >
                        <source src={
                            getAttachmentUrl(
                                data?.attachmentsRu?.[0]?.id,
                                data?.attachmentsRu?.[0]?.uuid)}
                            type="video/mp4" />
                    </video>
                    <Flex align="center" gap={10}>
                        <Text fz={16} c="primaryDark">
                            {dayjs(data.publishedAt).format(
                                i18n.language === "ru"
                                    ? "D MMMM YYYY"
                                    : "D-MMMM YYYY"
                            )}
                        </Text>
                        <Image src="/icons/calendar.svg" />
                    </Flex>
                    <Text c="primaryDark" fz={18}>
                        {getTitleByLanguage(data)}
                    </Text>
                </Modal> : null
            }

        </>
    );
};
