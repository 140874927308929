import { Flex, Image, Text } from "@mantine/core";
import { getTitleByLanguage } from "src/locales";

interface AboutHistoryItemProps {
    item: HistoryType;
}

export const AboutHistoryItem = ({ item }: AboutHistoryItemProps) =>
    item.img && item.titleRu ? (
        <Flex
            key={item.id}
            gap={40}
            align="center"
            wrap="wrap"
            justify="space-around"
        >
            <Image mb={{ base: -40, xs: 0 }} src={item.img.src} alt="" />
            <Text ta="justify" maw={900}>
                {getTitleByLanguage(item)}
            </Text>
        </Flex>
    ) : item.img ? (
        <Flex key={item.id} justify="end">
            <Image src={item.img.src} alt="" />
        </Flex>
    ) : (
        <Flex key={item.id} mx="auto" w={{ base: "95vw", sm: "70vw" }}>
            <Text ta="justify" c="primaryDark" fz={{ base: 18, sm: 20 }}>
                {getTitleByLanguage(item)}
            </Text>
        </Flex>
    );
