import { Flex, FlexProps, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export const RouteTitles = ({
    title = "",
    styles = { mt: 110, mb: 32 },
    lastDisabled = false,
    isPrevDisabled = false,
    allDisabled = false
}: {
    title?: string;
    styles?: FlexProps;
    lastDisabled?: boolean;
    isPrevDisabled?: boolean;
    allDisabled?: boolean
}) => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    const path = pathname.split("/").filter((el) => el !== "ru" && el !== "kg");
    path[0] = "main";

    return (
        <Flex
            className="container"
            mx="auto"
            align="center"
            gap={10}
            {...styles}
        >
            {path.map((el, idx) => {
                const isNotLast = path.length !== idx + 1;
                const isPrevLast = path.length !== idx + 2;
                const isIdLink = !isNaN(Number(el)) && t(`links.${el}`) === "";
                return (
                    <Flex
                        key={el}
                        align="center"
                        justify="center"
                        gap={{ base: 5, sm: 15 }}
                    >
                        <Link
                            className={
                                isIdLink ||
                                    (((lastDisabled) && !isNotLast) ||
                                        (isPrevDisabled && !isPrevLast)) || allDisabled
                                    ? "disabled"
                                    : ""
                            }
                            to={
                                el === "main"
                                    ? `/${i18n.language}`
                                    : `/${el}/${i18n.language}`
                            }
                        >
                            <Text
                                fz={{ base: 12, xs: 16 }}
                                c={isNotLast ? "weakGray" : "dark"}
                            >
                                {isIdLink || (title && !isNotLast)
                                    ? title
                                    : t(`links.${el}`)}
                            </Text>
                        </Link>
                        {isNotLast ? (
                            <IconChevronRight
                                color="var(--weak-dark)"
                                size={20}
                            />
                        ) : null}
                    </Flex>
                );
            })}
        </Flex>
    );
};
