import { Flex, Indicator, Paper, Title } from "@mantine/core";
import { DatePicker, DateValue } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import "dayjs/locale/ru";
import { useTranslation } from "react-i18next";
import { PanelCards } from "src/components";
import { nowDate } from "src/constants";
import styles from "../styles.module.scss";

interface NewsPanelProps {
    calendarShowed: boolean;
    date: Date | null;
    setDate: (date: DateValue) => void;
}

export const NewsPanel = ({
    calendarShowed,
    date,
    setDate,
}: NewsPanelProps) => {
    const { t } = useTranslation();
    const largeScreen = useMediaQuery("(max-width: 1200px)");
    const handleSelectDate = (value: DateValue) => {
        if (date?.getDate() === value?.getDate()) {
            setDate(null);
        } else {
            setDate(value);
        }
    };

    return (
        <Flex gap={32} direction="column" align="center">
            <Paper
                px={30}
                py={20}
                w={370}
                h={400}
                mt={25}
                className={styles.calendar}
                style={{
                    transition: "300ms",
                    transform: largeScreen
                        ? calendarShowed
                            ? "translateX(0)"
                            : "translateX(200%)"
                        : "",
                }}
            >
                <Flex direction="column" align="center" justify="center">
                    <Title order={2} mb={20}>
                        {t("titles.news-dates")}
                    </Title>
                    <DatePicker
                        onChange={handleSelectDate}
                        value={date}
                        locale="ru"
                        maxDate={nowDate}
                        renderDay={(date) => {
                            const day = date.getDate();
                            const today =
                                day !== nowDate.getDate() ||
                                date.getMonth() !== nowDate.getMonth();
                            return (
                                <Indicator
                                    size={6}
                                    color="green"
                                    offset={-2}
                                    disabled={today}
                                >
                                    <div>{day}</div>
                                </Indicator>
                            );
                        }}
                    />
                </Flex>
            </Paper>
            <PanelCards />
        </Flex>
    );
};
