import { Flex, Image, Paper, Text, Title, TitleOrder } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getTitleByLanguage } from "src/locales";
import { NewsResponse } from "src/types/data-models/news";
import { sliceText } from "src/utils";
import { DateBox } from "../forms";

interface NewsItemProps {
    order?: TitleOrder;
    news: NewsResponse;
    textLimit?: number;
    isHomePage?: boolean;
    isPressPage?: boolean
}
const textLength = {
    lg: 80,
    xl: 100,
    default: 130,
};

export const NewsItem = ({
    news,
    order = 3,
    textLimit = 50,
    isHomePage = false,
    isPressPage
}: NewsItemProps) => {
    const { t, i18n } = useTranslation();
    const sm = useMediaQuery("(max-width: 768px)");
    const lg = useMediaQuery("max-width: 1200px");
    const newsTitle = getTitleByLanguage(news);

    return (
        <Paper
            variant="bordered"
            pos="relative"
            w="100%"
            h={isHomePage && !sm ? "208px" : undefined}
            mt={isHomePage ? "-8px" : "25px"}
            mb={isHomePage ? "16px" : undefined}
            px={{ base: "10px", sm: "20px" }}
            pb={isPressPage ? "16px" : { base: 12, sm: "30px" }}
            pt={isPressPage ? "16px" : { base: 18, sm: "30px" }}
        >
            <DateBox date={news.publishedAt.toString()} calendar />
            {isHomePage && !sm ? (
                <Link to={`/allnews/${news.id}/${i18n.language}`}>
                    <Flex h="100%" direction="column" justify="space-between">
                        <Title
                            mb={-20}
                            fw={400}
                            fz={{ base: 16, md: 18 }}
                            order={4}
                            lh={1.3}
                        >
                            {sliceText(
                                sm ? "sm" : lg ? "lg" : "xl",
                                textLength,
                                newsTitle
                            )}
                        </Title>
                        <Text c="primaryDark">{t("statics.more")}</Text>
                    </Flex>
                </Link>
            ) : (
                <Link
                    key={news?.id}
                    to={`/allnews/${news?.id}/${i18n.language}`}
                >
                    <Flex
                        gap={{ base: 8, sm: 20 }}
                        direction="row"
                        align="center"
                        justify="start"
                    >
                        <Flex
                            display={
                                isHomePage ? { base: "block", sm: "none" } : {}
                            }
                            w={{ base: 160, sm: 190, xl: 250 }}
                        >
                            {news && news?.photoAlbum?.photo ? (
                                <Image
                                    src={`data:image/webp;base64,${news?.photoAlbum?.photo}`}
                                    miw={isPressPage ?
                                        { base: 120, sm: 150, xl: 200 } :
                                        { base: 160, sm: 190, xl: 250 }}
                                    h={{ base: 100, xl: 140 }}
                                    alt=""
                                />
                            ) : (
                                <Flex
                                    miw={{ base: 160, sm: 190, xl: 250 }}
                                    h={{ base: 100, xl: 140 }}
                                    style={{ borderRadius: "4px" }}
                                    bg={"primaryDark"}
                                    align="center"
                                    justify="center"
                                >
                                    <Image height="100%" src="/logo.png" />
                                </Flex>
                            )}
                        </Flex>
                        <Flex direction="column" justify="space-between">
                            <Title
                                c="blueDark"
                                order={order}
                                fw="normal"
                                lh={1.2}
                                fz={isPressPage ? { base: 12, sm: 16, lg: 18 } : { base: 12, xs: 14, sm: 18, lg: 20 }}
                                mb={isPressPage ? 0 : isHomePage ? {} : { base: 10, xl: 20 }}
                                w="100%"
                            >
                                {sliceText("xl", { xl: textLimit }, newsTitle)}
                            </Title>
                            <Text
                                fz={isPressPage ? { base: 12, sm: 14 } : { base: 12, sm: 14, lg: 18 }}
                                c="primaryDark"
                            >
                                {t("statics.more")}...
                            </Text>
                        </Flex>
                    </Flex>
                </Link>
            )
            }
        </Paper >
    );
};
