import { Button, Flex, Grid, Text, Title } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconTrash } from "@tabler/icons-react";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomInputText, TextEditor } from "src/components";

interface Props {
    form: UseFormReturnType<
        ConsFormType,
        (values: ConsFormType) => ConsFormType
    >;
    active: number;
}

export const SectionForm = ({ form, active }: Props) => {
    const { t } = useTranslation();
    const handleDeleteChild =
        (parentIndex: number, childIndex: number) => () => {
            const id = form.values.list[parentIndex].list[childIndex].id;
            if (form.values.type === "CHAPTER") {
                const prev = form.values.toDeleteConsSections || [];
                form.setFieldValue(
                    "toDeleteConsSections",
                    id ? [...prev, id] : []
                );
            } else {
                const prev = form.values.toDeleteConsChapters || [];
                form.setFieldValue(
                    "toDeleteConsChapters",
                    id ? [...prev, id] : []
                );
            }
            form.removeListItem(`list.${parentIndex}.list`, childIndex);
        };
    const handleDeleteParent = (parentIndex: number) => () => {
        const id = form.values.list[parentIndex].id;
        if (form.values.type === "CHAPTER") {
            const prev = form.values.toDeleteConsSections || [];
            form.setFieldValue("toDeleteConsChapters", id ? [...prev, id] : []);
        } else {
            const prev = form.values.toDeleteConsChapters || [];
            form.setFieldValue("toDeleteConsSections", id ? [...prev, id] : []);
        }
        form.removeListItem(`list`, parentIndex);
    };

    const handleChangeIndex = (
        parentIndex: number,
        fromIndex: number,
        toIndex: number
    ) => {
        const listCopy = [...form.values.list[parentIndex]?.list];
        const oldItemInIndex = { ...listCopy[toIndex] };
        const newItemToIndex = { ...listCopy[fromIndex] };
        listCopy[toIndex] = newItemToIndex;
        listCopy[fromIndex] = oldItemInIndex;
        // form.setFieldValue("list", listCopy)
        form.insertListItem(
            `list.${parentIndex}.list.${fromIndex}`,
            oldItemInIndex
        );
    };

    const fields = form.values.list.map((_item, index) => {
        const childFields = form.values.list[index].list.map(
            (el, childIndex) => (
                <ChildField
                    length={form.values.list[index].list?.length}
                    handleChangeIndex={handleChangeIndex}
                    childIndex={childIndex}
                    form={form}
                    handleDeleteChild={handleDeleteChild}
                    index={index}
                />
            )
        );
        if (index === active) {
            return (
                <>
                    <Button
                        onClick={handleDeleteParent(index)}
                        variant="simple"
                    >
                        <Text c="primaryDark" mr={10}>
                            {t("button.delete")}
                        </Text>
                        <IconTrash color="red" />
                    </Button>
                    <Grid
                        my={20}
                        style={{
                            borderBottom: "1px solid black",
                            borderTop: "1px solid black",
                        }}
                        key={index}
                        align="end"
                    >
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps(`list.${index}.nameKg`)}
                                label={t`form.label.sectionTitleKg`}
                                formData={form.values.list[index].nameKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps(`list.${index}.nameRu`)}
                                label={t`form.label.sectionTitleRu`}
                                formData={form.values.list[index].nameRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps(
                                    `list.${index}.articles`
                                )}
                                label={t`form.label.articles`}
                                formData={form.values.list[index].articles}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                value={form.values.list[index].contentRu}
                                label={t`form.label.sectionContentRu`}
                                required
                                formData={form.values.list[index].contentRu}
                                {...form.getInputProps(
                                    `list.${index}.contentRu`
                                )}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                value={form.values.list[index].contentKg}
                                label={t`form.label.sectionContentKg`}
                                required
                                formData={form.values.list[index].contentKg}
                                {...form.getInputProps(
                                    `list.${index}.contentKg`
                                )}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            {childFields}
                            <Button
                                mt={20}
                                onClick={() =>
                                    form.insertListItem(`list.${index}.list`, {
                                        articles: "",
                                        list: [],
                                        contentKg: "",
                                        contentRu: "",
                                        nameKg: "",
                                        nameRu: "",
                                    })
                                }
                            >
                                {t("button.add-more")}
                            </Button>
                        </Grid.Col>
                    </Grid>
                </>
            );
        }
        return null;
    });
    useEffect(() => { }, [form]);
    return <div>{fields}</div>;
};

type ChildProps = {
    form: UseFormReturnType<
        ConsFormType,
        (values: ConsFormType) => ConsFormType
    >;
    childIndex: number;
    index: number;
    handleDeleteChild: (index: number, childIndex: number) => () => void;
    length: number;
    handleChangeIndex: (
        parentIndex: number,
        fromIndex: number,
        toIndex: number
    ) => void;
};

const ChildField = ({
    form,
    childIndex,
    index,
    handleDeleteChild,
    length,
    handleChangeIndex,
}: ChildProps) => {
    const { t } = useTranslation();
    const [newIndex, setNewIndex] = useState(childIndex);
    const changed = newIndex !== childIndex;
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const idx = parseInt(event.target.value);
        if (idx > length) {
            setNewIndex(length - 1);
        } else {
            setNewIndex(idx - 1 || 0);
        }
    };

    useEffect(() => {
        setNewIndex(childIndex);
    }, [form]);
    return (
        <Grid key={childIndex} align="end">
            <Grid.Col span={12}>
                <Flex align="center" gap={10}>
                    <Title fz={{ base: 20, md: 24 }}>
                        {childIndex + 1}{" "}
                        {t(
                            `statics.${form.values.type === "CHAPTER"
                                ? "section"
                                : "chapter"
                            }`
                        )}
                    </Title>
                    <Button
                        onClick={handleDeleteChild(index, childIndex)}
                        variant="simple"
                    >
                        <IconTrash color="red" />
                    </Button>
                </Flex>
            </Grid.Col>
            {/* <Grid.Col span={2} mt={15} >
                <Flex>
                    <input
                        max={100}
                        min={0}
                        style={{ width: "80px", border: "1px solid black", padding: "0 10px", background: "none", color: "black" }}
                        onChange={handleOnChange}
                        value={newIndex + 1 || 1} />
                    <UnstyledButton
                        onClick={() => changed && handleChangeIndex(index, childIndex, newIndex)}
                        bg={changed ? "green" : "gray"} py={8} px={8}>
                        <CheckIcon color="white" size={20} />
                    </UnstyledButton>
                </Flex>
            </Grid.Col> */}
            <Grid.Col span={6}>
                <CustomInputText
                    {...form.getInputProps(
                        `list.${index}.list.${childIndex}.articles`
                    )}
                    label={t`form.label.articles`}
                    formData={form.values.list[index].list[childIndex].articles}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <CustomInputText
                    {...form.getInputProps(
                        `list.${index}.list.${childIndex}.nameKg`
                    )}
                    label={t`form.label.subSectionTitleKg`}
                    formData={form.values.list[index].list[childIndex].nameKg}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <CustomInputText
                    {...form.getInputProps(
                        `list.${index}.list.${childIndex}.nameRu`
                    )}
                    label={t`form.label.subSectionTitleRu`}
                    formData={form.values.list[index].list[childIndex].nameRu}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <TextEditor
                    value={form.values.list[index].list[childIndex].contentRu}
                    label={t`form.label.subSectionContentRu`}
                    required
                    formData={
                        form.values.list[index].list[childIndex].contentRu
                    }
                    {...form.getInputProps(
                        `list.${index}.list.${childIndex}.contentRu`
                    )}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <TextEditor
                    value={form.values.list[index].list[childIndex].contentKg}
                    label={t`form.label.subSectionContentKg`}
                    required
                    formData={
                        form.values.list[index].list[childIndex].contentKg
                    }
                    {...form.getInputProps(
                        `list.${index}.list.${childIndex}.contentKg`
                    )}
                />
            </Grid.Col>
        </Grid>
    );
};
