import { Box, Image, Paper } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CustomLoader,
    CustomModal,
    CustomText,
    DateFormatTo,
    TextInfo,
} from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useGetNewsQuery } from "src/store";

type Props = {
    newsId: string;
};

export const NewsDetails = ({ newsId }: Props) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const [isOpened, setIsOpened] = useState(false);

    const { data: newsData, isLoading } = useGetNewsQuery(newsId, {
        refetchOnMountOrArgChange: true,
    });

    const organCard = [
        {
            label: t`data.news.dateFrom`,
            value: DateFormatTo(newsData?.publishedAt, "DD-MM-YYYY HH:mm"),
        },
        {
            label: t`data.news.title`,
            value: getTitleByLanguage(newsData),
        },
    ];

    return (
        <Paper shadow="sm" p="xl">
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <Paper shadow="sm" p="xl">
                    <TextInfo
                        data={organCard}
                        display={true}
                        spanFirst={4}
                        spanTwo={8}
                    />
                    <Box mt={30}>
                        {newsData?.photoAlbum && (
                            <Image
                                mb={20}
                                style={{ cursor: "pointer" }}
                                onClick={() => setIsOpened(true)}
                                src={`data:image/webp;base64,${newsData?.photoAlbum?.photo}`}
                                w={"auto"}
                                h={200}
                                alt=""
                            />
                        )}
                        <CustomModal
                            size="xl"
                            labelSubmit={"delete"}
                            open={isOpened}
                            handleClose={() => setIsOpened(false)}
                            button={false}
                        >
                            <Image
                                src={
                                    newsData?.photoAlbum
                                        ? `data:image/webp;base64,${newsData?.photoAlbum?.photo}`
                                        : ""
                                }
                                w={750}
                                alt=""
                            />
                        </CustomModal>
                        {newsData?.content && (
                            <Box className={classes.content}>
                                <CustomText
                                    label={t`data.news.content`}
                                    value={
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: getTitleByLanguage(
                                                    newsData.content
                                                ),
                                            }}
                                        />
                                    }
                                />
                            </Box>
                        )}
                    </Box>
                </Paper>
            }
        </Paper>
    );
};

const useStyles = createStyles({
    content: {
        "& img": {
            maxWidth: "70rem",
        },
    },
});
