import { LoadingOverlay } from "@mantine/core";

interface Props {
    bg?: string;
    color?: string
}

export const CustomLoader = ({ color = "#0A164F", bg = "transparent" }: Props) => {
    return (
        <LoadingOverlay
            loaderProps={{ size: "md", color: color, variant: "bars" }}
            style={{ opacity: "1", color: "#c5c5c5" }}
            overlayProps={{ styles: { root: { background: bg } } }}
            h="100%"
            visible={true}
        />
    );
};
