import { Box, Flex } from "@mantine/core"
import { HistoryModule } from "../about"

export const Prosecutor100YearsModule = () => {
    return (
        <Flex direction="column" gap={{ base: 40, sm: 100 }}>
            {/* <Box className="container" w="80%" style={{ aspectRatio: "2 / 0.8" }}>
                <iframe
                    src="https://www.youtube.com/embed/foMXL2ktsxU?si=Pb6Gdy1CVskcxFSf"
                    title="100 жыл"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    width="100%"
                    height="100%"
                    allowFullScreen>
                </iframe>
            </Box> */}
            <HistoryModule />
        </Flex>
    )
}
