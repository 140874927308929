import { Box, Title } from "@mantine/core";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppTable, CustomModal, MenuDropDown } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useDeleteCardMutation } from "src/store";
import { HeadCell } from "src/types";
import { Path, notify } from "src/utils";
type Props = {
    parentId?: number | null;
    opened: boolean;
    filter?: CardInfoFilterType;
};
export const CardInfoTable = ({ opened, parentId, filter }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [cardDelete] = useDeleteCardMutation();

    const [id, setId] = useState<number | null>();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (id: number) => {
        setOpen(true);
        setId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await cardDelete(id);
            notify(true, t("notify.deleted"));
        } catch {
            notify(false);
        }
        setOpen(false);
    };

    const actions = (id: number) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/card_info/${id}/edit`,
        },
        {
            icon: IconEye,
            title: t`actions.view`,
            to: `/card_info/${id}`,
        },
        {
            icon: IconTrash,
            title: t`actions.delete`,
            onClick: () => handleOpen(id),
        },
    ];

    const headCells: HeadCell<CardType>[] = [
        {
            label: t`table.fullName`,
            render: (n) => getTitleByLanguage(n.fullName),
        },
        {
            label: t`form.label.importance`,
            render: (n) => n.importance,
        },
        {
            label: t`table.position`,
            render: (n) => getTitleByLanguage(n.position),
        },
        {
            label: t`actions.title`,
            render: (n) => (
                <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
            ),
        },
    ];
    return (
        <Box style={{ marginLeft: opened ? 420 : 0, transition: "0.7s" }}>
            <AppTable<CardType>
                headCells={headCells}
                defaultFilter={
                    parentId !== 4
                        ? {
                            acceptsRequests: parentId === 3 ? true : false,
                            active: parentId !== 2,
                            position: parentId === 2 ? "ex-prokurors" : "",
                        }
                        : undefined
                }
                doubleClickPath={(n) =>
                    navigate(`/card_info/${n.id}/${i18n.language}`)
                }
                sorting={{ sortBy: "IMPORTANCE", sortDirection: "ASC" }}
                searchApiUrl={Path.CardInfo.search}
                extraFilter={filter}
            />
            <CustomModal
                labelSubmit={"delete"}
                open={open}
                handleClose={handleClose}
                onClick={handleDelete}
            >
                <Title
                    order={3}
                    fw={400}
                    mt="5px"
                    mb="2rem"
                    style={{ textAlign: "center" }}
                >
                    {t`button.delete`}
                </Title>
            </CustomModal>
        </Box>
    );
};
