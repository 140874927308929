import { Flex, Image, Paper, Text } from "@mantine/core";
import dayjs from "dayjs";
import "dayjs/locale/ky"; // Импортируем только что созданный локальный модуль для кыргызского языка
import { useTranslation } from "react-i18next";

interface DateBoxProps {
    date: string | undefined;
    calendar?: boolean;
    isMain?: boolean
}

export const DateBox = ({ date, calendar = false, isMain = false }: DateBoxProps) => {
    const { i18n } = useTranslation();
    dayjs.locale(i18n.language === "ru" ? "ru" : "ky");

    return (
        <Paper
            variant="bordered"
            p={5}
            style={{
                border: "1px solid rgba(var(--primary-dark), 0.3)",
                borderRadius: "2px",
            }}
            display="flex"
            pos="absolute"
            top="-20px"
            h={isMain ? 40 : 35}
            left={isMain ? { base: 12, sm: 40 } : { base: 10, sm: 24 }}
        >
            <Flex p={10} justify="center" align="center" gap={10}>
                <Text
                    variant="sm"
                    fz={calendar ? { base: "xs", sm: "sm" } : { base: 18, sm: "24px" }}
                    fw="500"
                    c="primaryDark"
                >
                    {calendar
                        ? date &&
                        dayjs(date).format(
                            i18n.language === "ru"
                                ? "D MMMM YYYY"
                                : "D-MMMM YYYY"
                        )
                        : date}
                </Text>
                {calendar && <Image src="/icons/calendar.svg" />}
            </Flex>
        </Paper>
    );
};
