import {
    Checkbox,
    Container,
    Grid,
    NumberInput,
    Paper,
    Select,
} from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    ButtonGroup,
    CustomDropzone,
    CustomInputText,
    CustomSelect,
    TextEditor,
} from "src/components";
import { getTitleByLanguage } from "src/locales";
import {
    useCreateCardsMutation,
    useGetAllProsecutorOfficesQuery,
    useUpdateCardMutation,
} from "src/store";
import { notify } from "src/utils";

type Props = {
    card?: CardType;
};

export const CardInfoForm = ({ card }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [createCard] = useCreateCardsMutation();
    const [updateCard] = useUpdateCardMutation();

    const { data: officeID } = useGetAllProsecutorOfficesQuery([], {
        refetchOnMountOrArgChange: true,
    });

    const officeSelect = officeID?.map((el) => ({
        value: `${el.id}`,
        label: getTitleByLanguage(el),
    }));

    const form = useForm<CardInfoFormType>({
        initialValues: {
            acceptsRequests: card?.acceptsRequests || false,
            educationEn: card?.education?.titleEn || "",
            educationKg: card?.education?.titleKg || "",
            educationRu: card?.education?.titleRu || "",
            fullNameEn: card?.fullName?.titleEn || "",
            fullNameKg: card?.fullName?.titleKg || "",
            fullNameRu: card?.fullName?.titleRu || "",
            positionEn: card?.position?.titleEn || "",
            positionKg: card?.position?.titleKg || "",
            positionRu: card?.position?.titleRu || "",
            attachment: null,
            laborActivityKg: card?.laborActivity?.titleKg || "",
            laborActivityEn: card?.laborActivity?.titleEn || "",
            laborActivityRu: card?.laborActivity?.titleRu || "",
            active: card?.active || false,
            shortBiographyEn: card?.shortBiography?.titleEn || "",
            shortBiographyKg: card?.shortBiography?.titleKg || "",
            shortBiographyRu: card?.shortBiography?.titleRu || "",
            awardsEn: card?.awards?.titleEn || "",
            awardsKg: card?.awards?.titleKg || "",
            awardsRu: card?.awards?.titleRu || "",
            prosecutorOfficeId: card?.prosecutorOffice?.id || null,
            importance: card?.importance || 50,
        },
        validate: {
            fullNameRu: (value) => !value && t("form.validation.required"),
            fullNameKg: (value) => !value && t("form.validation.required"),
            positionKg: (value) => !value && t("form.validation.required"),
            positionRu: (value) => !value && t("form.validation.required"),
        },
    });
    const positionIds = [
        { label: "Бывшие Генеральный прокурор", value: "ex-prokurors" },
        { label: "Настоящий прокурор", value: "now-prokurors" },
    ];
    const handleSubmit = async ({
        attachment,
        ...values
    }: CardInfoFormType) => {
        if (card) {
            try {
                await updateCard({
                    id: card.id,
                    attachment,
                    body: values,
                }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/card_info");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createCard({ attachment, ...values }).unwrap();
                notify(true, t("notify.success"));
                form.reset();
                navigate("/card_info");
            } catch {
                notify(false);
            }
        }
    };
    return (
        <Container size="xl" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={12} mt={23}>
                            <Checkbox
                                error={form.errors.active}
                                label={t(`form.label.active`)}
                                checked={form.values.active}
                                onChange={(el) =>
                                    form.setFieldValue(
                                        "active",
                                        el.currentTarget.checked
                                    )
                                }
                            />
                        </Grid.Col>
                        <Grid.Col span={12} mt={23}>
                            <Checkbox
                                error={form.errors.active}
                                label={t(`form.label.acceptsRequests`)}
                                checked={form.values.acceptsRequests}
                                onChange={(el) =>
                                    form.setFieldValue(
                                        "acceptsRequests",
                                        el.currentTarget.checked
                                    )
                                }
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <NumberInput
                                label={t`form.label.importance`}
                                required
                                error={form.errors.importance}
                                {...form.getInputProps("importance")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomSelect
                                {...form.getInputProps("prosecutorOfficeId")}
                                placeholder={t`form.label.prosecutorOfficeId`}
                                required
                                value={`${form.values.prosecutorOfficeId}`}
                                error={form.errors.prosecutorOfficeId}
                                formData={form.values.prosecutorOfficeId}
                                data={officeSelect || []}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                {...form.getInputProps("positionEn")}
                                label={t`form.label.typeId`}
                                error={form.errors.positionEn}
                                data={positionIds}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <CustomInputText
                                {...form.getInputProps("fullNameKg")}
                                label={t`form.label.fullNameKg`}
                                required
                                error={form.errors.fullNameKg}
                                formData={form.values.fullNameKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <CustomInputText
                                {...form.getInputProps("fullNameRu")}
                                label={t`form.label.fullNameRu`}
                                required
                                error={form.errors.fullNameRu}
                                formData={form.values.fullNameRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <CustomInputText
                                {...form.getInputProps("fullNameEn")}
                                label={t`form.label.fullNameEn`}
                                error={form.errors.fullNameEn}
                                formData={form.values.fullNameEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("positionKg")}
                                label={t`form.label.positionKg`}
                                required
                                error={form.errors.positionKg}
                                formData={form.values.positionKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("positionRu")}
                                label={t`form.label.positionRu`}
                                required
                                error={form.errors.positionRu}
                                formData={form.values.positionRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.educationEn`}
                                required
                                error={form.errors.educationEn}
                                formData={form.values.educationEn}
                                {...form.getInputProps("educationEn")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.educationKg`}
                                required
                                error={form.errors.educationKg}
                                formData={form.values.educationKg}
                                {...form.getInputProps("educationKg")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.educationRu`}
                                required
                                error={form.errors.educationRu}
                                formData={form.values.educationRu}
                                {...form.getInputProps("educationRu")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.awardsRu`}
                                required
                                error={form.errors.awardsRu}
                                formData={form.values.awardsRu}
                                {...form.getInputProps("awardsRu")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.awardsKg`}
                                required
                                error={form.errors.awardsKg}
                                formData={form.values.awardsKg}
                                {...form.getInputProps("awardsKg")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.awardsEn`}
                                error={form.errors.awardsEn}
                                formData={form.values.awardsEn}
                                {...form.getInputProps("awardsEn")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.shortBiographyRu`}
                                required
                                error={form.errors.attachmentsRu}
                                formData={form.values.shortBiographyRu}
                                {...form.getInputProps("shortBiographyRu")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.shortBiographyKg`}
                                required
                                error={form.errors.shortBiographyKg}
                                formData={form.values.shortBiographyKg}
                                {...form.getInputProps("shortBiographyKg")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.shortBiographyEn`}
                                error={form.errors.shortBiographyEn}
                                formData={form.values.shortBiographyEn}
                                {...form.getInputProps("shortBiographyEn")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.receptionTimeRu`}
                                required
                                error={form.errors.laborActivityRu}
                                formData={form.values.laborActivityRu}
                                {...form.getInputProps("laborActivityRu")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextEditor
                                label={t`form.label.receptionTimeKg`}
                                required
                                error={form.errors.laborActivityKg}
                                formData={form.values.laborActivityKg}
                                {...form.getInputProps("laborActivityKg")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomDropzone
                                label={t`form.label.photo`}
                                error={form.errors.attachment}
                                {...form.getInputProps("attachment")}
                                accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                            />
                        </Grid.Col>
                        <Grid.Col mb={100} span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(`/card_info/${i18n.language}`)
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
