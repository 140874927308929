import { Box } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ButtonToScroll, CustomLoader, Footer, Header, Layout } from "./components";
import { scrollToTop, useLocalStorage } from "./hooks";
import i18n from "./locales/locales";
import ClosePagesFallback from "./pages/close-part/fallback";
import { routes } from "./route-config";
import { TECH_WORK } from "./types";
import { RequireAuth, getJsonFiles } from "./utils";
const ErppPage = lazy(() => import("./pages/open-part/qr-appeal-data"));
const NotFoundPage = lazy(() => import("./pages/404"));
const TechnicalWorkPage = lazy(() => import("./pages/technical-work"));
const HomePage = lazy(() => import("./pages/open-part/home"));

const openPages = routes.filter(el => el.public)?.map(el => el.path)

export const App = () => {
    const { classes } = useStyles();
    const [techWork, setTechWork] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const lng = useLocalStorage<"ru" | "kg" | null>("i18nextLng").getItem();


    const suspenseComponent = () => {
        const rootPath = pathname.split("/")?.[1]
        const isOpenPage = [...openPages, "/kg", "/ru", "/erpp.html", "/position"].includes(`/${rootPath}`)
        if (!isOpenPage) {
            return <ClosePagesFallback />

        } else return (
            <>
                <Header />
                <Box pos='relative' top={200} h="70vh" w='100vw'>
                    <CustomLoader />
                </Box>
                <Footer />
            </>
        )
    }

    useEffect(() => {
        if (!pathname.includes("erpp")) {
            const langFromPath = pathname.split("/").pop() || "";
            if (pathname === "/") {
                navigate(lng === "ru" ? "/ru" : "/kg");
            } else if (["ru", "kg"].includes(langFromPath)) {
                i18n.changeLanguage(langFromPath);
            } else if (!pathname.includes("ru") && !pathname.includes("kg")) {
                navigate(`${pathname}/${lng}`);
            }
        }
        scrollToTop(0);
    }, [pathname]);


    useMemo(async () => {
        const techWork: TECH_WORK = await getJsonFiles("TECH_WORK", true);
        setTechWork(techWork.TECH_WORK);
    }, []);
    return (
        <Box className={classes.container}>
            <Suspense
                fallback={suspenseComponent()}
            >
                <Routes>
                    {techWork ? (
                        <Route path="*" element={<TechnicalWorkPage />} />
                    ) : (
                        <>
                            {routes.map((it) =>
                                it.public ? (
                                    <Route key={it.path} element={<Layout />}>
                                        <Route
                                            path={it.path + "/:lang"}
                                            element={it.page}
                                        />
                                    </Route>
                                ) : (
                                    <Route
                                        element={<RequireAuth />}
                                        key={it.path}
                                    >
                                        <Route
                                            path={`/${it.path}/:lang`}
                                            key={it.path}
                                            element={it.page}
                                        />
                                    </Route>
                                )
                            )}
                            <Route element={<Layout />}>
                                <Route path={"/erpp.html"} element={<ErppPage />} />
                            </Route>
                            <Route index element={<HomePage />} />
                            <Route path="*" element={<NotFoundPage />} />
                        </>
                    )}
                </Routes>
            </Suspense>
            <ButtonToScroll />
        </Box>
    );
};

const useStyles = createStyles({
    container: {
        backgroundColor: "rgba(250, 250, 250, 1)",
        transition: "all 0.3s ease",
    },
});

export default App;
