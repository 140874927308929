import { Tabs } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { DIAGRAM_REFERENCE_TYPES } from "src/constants";
import {
    useDiagramSearchMutation,
    useDiagramSpecificGetAllQuery,
    useGetAllByTypeCodeQuery,
} from "src/store";
import { TypeCodesEnum } from "src/types";
import { StatisticBody } from "../statistic-body";
import { StatisticTabs } from "../tabs";
interface StatisticModuleProps {
    activeYear?: string | number | null;
}

export const StatisticDetailModule = ({ activeYear }: StatisticModuleProps) => {

    const [activeQuartal, setActiveQuartal] = useState<string>("FIRST");
    const [activeCategory, setActiveCategory] = useState<SelectCategoryType | undefined>(undefined);
    const [searchDiagramByDateQuartal, { data: statisticData, isLoading }] = useDiagramSearchMutation();
    const { data: categoriesData } = useDiagramSpecificGetAllQuery({});
    const { data: typeCodes } = useGetAllByTypeCodeQuery(TypeCodesEnum.NEWS_CATEGORY)
    const typeId = useMemo(() => {
        return typeCodes?.find(el => el.titleEn === DIAGRAM_REFERENCE_TYPES.STATISTIC)?.id
    }, [typeCodes])

    const filteredCategoriesData = useMemo(() => {
        const data =
            categoriesData
                ?.filter((el) => el.diagramSpecificationDtoList?.length)
                ?.map((el) => {
                    const childrens = el?.diagramSpecificationDtoList || [];
                    return {
                        id: el?.id,
                        diagramSpecificationDtoList: [...childrens].sort((a, b) => a.order - b.order),
                        titleKg: el?.titleKg,
                        titleRu: el?.titleRu,
                        order: el?.order,
                    };
                })?.sort((a, b) => a.order - b.order) || [];
        return data
    }, [categoriesData]);

    const handleSearchDiagram = async () => {
        try {
            await searchDiagramByDateQuartal({
                filter: {
                    year: parseInt(`${activeYear}`),
                    diagramQuarter: activeQuartal,
                    diagramTypeId: typeId,
                    diagramSpecificationId:
                        !activeCategory?.childrenId && !activeCategory?.parentId
                            ? null
                            : !activeCategory?.childrenId
                                ? activeCategory.parentId
                                : activeCategory?.childrenId,
                },
                pageRequest: { limit: 40, page: 0 },
                sorting: {
                    sortBy: "ORDER",
                    sortDirection: "ASC",
                },
            });
        } catch {
            console.error("error");
        }
    };
    useEffect(() => {
        if (filteredCategoriesData) {
            setActiveCategory({ parentId: filteredCategoriesData[0]?.id, childrenId: filteredCategoriesData[0]?.diagramSpecificationDtoList?.[0]?.id })
        }
    }, [filteredCategoriesData])
    useEffect(() => {
        handleSearchDiagram();
    }, [activeCategory, activeQuartal, activeYear]);
    return (
        <Tabs
            h="100%"
            mih={400}
            variant="pills"
            defaultValue="FIRST"
            onChange={(value) => value && setActiveQuartal(value)}
            p="8px 16px 16px"
            style={{ borderRadius: "2px" }}
        >
            <StatisticBody
                activeYear={parseInt(`${activeYear}`)}
                categories={filteredCategoriesData}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
                activeQuartal={activeQuartal}
                isLoading={isLoading}
                data={statisticData?.content}
            />
        </Tabs>
    );
};
