import { BackgroundImage, Card, Grid, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { useGetGoogleAnalyticsDataQuery } from "src/store"

export const GoogleAnalyticsDataBox = () => {
    const { data } = useGetGoogleAnalyticsDataQuery({})
    const { t } = useTranslation();

    return (
        <Card
            style={{
                backdropFilter: "blur(10px)",
                border: "1px solid #0A164F"
            }}
            px={16} py={6}
            w={250}>
            <BackgroundImage
                w="100%"
                style={{ objectFit: "contain", objectPosition: "right", width: "50%" }}
                h="100%" src="/assets/statistic.svg">
                <Grid align="center">
                    <Grid.Col span={8}>
                        <Text lh={1.2} c="#101828">
                            {t("titles.all-time")}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text fw={500} c="#101828">
                            {data?.newUsersAllTime || 0}
                        </Text>
                    </Grid.Col>
                </Grid>
                <Grid align="center">
                    <Grid.Col span={8}>
                        <Text lh={1.2} c="#101828">
                            {t("titles.per-week")}:
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text fw={500} c="#101828">
                            {data?.activeUsersWeek || 0}
                        </Text>
                    </Grid.Col>
                </Grid>
                <Grid align="center">
                    <Grid.Col span={8}>
                        <Text lh={1.2} c="#101828">
                            {t("titles.per-day")}:
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text c="#101828" fw={500}>
                            {data?.activeUsersToday || 0}
                        </Text>
                    </Grid.Col>
                </Grid>

            </BackgroundImage>
        </Card>
    )
}
