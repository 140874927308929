import { Button, Flex, List, Paper, Title } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getTitleByLanguage } from "src/locales";
import { useNewsSearchMutation } from "src/store";
import { NewsResponse } from "src/types/data-models/news";
import { notify } from "src/utils";

interface SearchResultsProps {
    searchValue: string;
}

export const SearchNewsResults = ({ searchValue }: SearchResultsProps) => {
    const { t, i18n } = useTranslation();
    const [results, setResults] = useState<NewsResponse[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [searchNews] = useNewsSearchMutation();

    const searchNewsHandler = useCallback(async () => {
        setLoading(true);
        try {
            const response = await searchNews({
                filter: {
                    title: searchValue,
                },
                pageRequest: {
                    limit: 10,
                    page: 0,
                },
                sorting: {
                    sortBy: "PUBLISHED_AT",
                    sortDirection: "DESC",
                },
            });
            if ("data" in response) {
                setResults(response.data.content);
            }
        } catch {
            notify();
        }
        setLoading(false);
    }, [searchValue]);

    useEffect(() => {
        searchNewsHandler();
    }, [searchValue]);
    return (
        <Paper
            style={{ overflowY: "scroll" }}
            top={65}
            right={0}
            pos="absolute"
            mih={100}
            mah={300}
            w={{ base: "90vw", xs: 350 }}
            px={20}
            c="primary"
            bg="primaryDark"
        >
            <Title maw={300} c="primary" fz={14} fw={300} fs="italic">
                {t("statics.search-request")}: {searchValue} <br />
                {t("statics.results")}: {results?.length}
            </Title>
            {loading ? (
                <Flex py={20}>{t("statics.loading")}</Flex>
            ) : (
                <List type="ordered">
                    {results?.map((el) => (
                        <Link
                            to={`/allnews/${el.id}/${i18n.language}`}
                            key={el.id}
                        >
                            <List.Item>
                                <Flex
                                    w={{ base: "90vw", xs: 300 }}
                                    align="center"
                                    justify="space-between"
                                >
                                    <Title
                                        fz={{ base: 10, sm: 12 }}
                                        order={5}
                                        c="primary"
                                    >
                                        {getTitleByLanguage(el).slice(0, 50)}...
                                    </Title>
                                    <Button
                                        px={15}
                                        py={0}
                                        bg="primaryDark"
                                        variant="simple"
                                    >
                                        <IconChevronRight color="var(--primary)" />
                                    </Button>
                                </Flex>
                            </List.Item>
                        </Link>
                    ))}
                </List>
            )}
        </Paper>
    );
};
