import {
    ComboboxItem,
    ComboboxItemGroup,
    Select,
    SelectProps,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { ReactNode, useState } from "react";
import { getTitleByLanguage } from "src/locales";
import { SelectDto } from "src/types/data-models/global";

interface Option {
    label: string;
    value: string;
}

interface SelectFieldProps {
    label: string;
    disabled?: boolean;
    error?: ReactNode;
    required?: boolean;
    formData?: string | number | null;
    onChange?: (value: string | null) => void;
    localData?: (string | ComboboxItem | ComboboxItemGroup)[];
    props?: SelectProps;
    withAsterisk?: boolean;
}

export const SelectField: React.FC<SelectFieldProps> = ({
    label,
    disabled,
    error,
    required,
    formData,
    onChange,
    localData,
    withAsterisk,
    ...props
}: SelectFieldProps) => {
    const [focused, setFocused] = useState(false);
    const { classes } = useStyles({ floating: Boolean(formData) || focused });
    const [data] = useState<SelectDto[] | undefined>([]);

    const options: Option[] =
        data?.map((item) => {
            return {
                key: item.id,
                label: getTitleByLanguage(item),
                value: item.id.toString(),
            };
        }) ?? [];

    if (localData) {
        return (
            <Select
                withAsterisk={withAsterisk}
                {...props}
                w="100%"
                data={localData}
                placeholder={label}
                required={required}
                error={error}
                clearable
                disabled={disabled}
                onChange={onChange}
                classNames={classes}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                className={classes.root}
                mt={"md"}
            />
        );
    }

    return (
        <>
            <Select
                withAsterisk={withAsterisk}
                {...props}
                data={options}
                placeholder={label}
                required={required}
                error={error}
                clearable
                disabled={disabled}
                onChange={onChange}
                classNames={classes}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                className={classes.root}
                mt={"md"}
            />
        </>
    );
};

const useStyles = createStyles(
    (theme, { floating }: { floating: boolean }) => ({
        root: {
            position: "relative",
        },
        label: {
            position: "absolute",
            zIndex: 2,
            top: 7,
            left: theme.spacing.sm,
            pointerEvents: "none",
            color: floating
                ? theme.colorScheme === "light"
                    ? theme.colors.dark[4]
                    : theme.white
                : theme.colorScheme === "dark"
                    ? theme.colors.dark[3]
                    : theme.colors.gray[5],
            transition:
                "transform 150ms ease, color 150ms ease, font-size 150ms ease",
            transform: floating
                ? `translate(-${theme.spacing.sm}px, -28px)`
                : "none",
            fontSize: floating ? theme.fontSizes.xs : theme.fontSizes.sm,
            fontWeight: 600,
        },

        required: {
            transition: "opacity 150ms ease",
            opacity: floating ? 1 : 0.5,
        },

        input: {
            "&::placeholder": {
                transition: "color 150ms ease",
                color: !floating ? "transparent" : undefined,
            },
        },
    })
);
