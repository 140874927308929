import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const bannerApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createBanner: builder.mutation<BannerFormType, BannerType>({
            query: ({ attachments, ...body }) => {
                const formData = new FormData();

                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });
                formData.append("bannerRequest", data);

                const file: File[] | null = attachments;

                file &&
                    file.map((file) => formData.append("attachments", file));
                return {
                    url: Path.Banners.create,
                    method: "POST",
                    body: formData,
                    cache: "no-cache",
                };
            },
            invalidatesTags: ["Banner"],
        }),
        updateBanners: builder.mutation<
            BannerGetAll,
            {
                id: number;
                body: Partial<BannerFormType>;
                attachments: File[] | null;
            }
        >({
            query: ({ id, body, attachments }) => {
                const formData = new FormData();

                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });
                formData.append("request", data);

                attachments &&
                    attachments.map((file) =>
                        formData.append("attachments", file)
                    );
                return {
                    url: Path.Banners.updateBanners(id),
                    method: "PUT",
                    body: formData,
                    cache: "no-cache",
                };
            },
        }),
        getAllBanners: builder.query<BannerGetAll[], unknown>({
            query: () => ({
                url: Path.Banners.getAll,
                cache: "no-cache",
            }),
        }),
        getBanner: builder.query<BannerGetType, string>({
            query: (id) => ({
                url: Path.Banners.get(id),
                cache: "no-cache",
            }),
        }),
        disableBanner: builder.mutation({
            query: (body) => ({
                url: Path.Banners.disable,
                method: "POST",
                body,
                cache: "no-cache",
            }),
        }),
        bannerDelete: builder.mutation({
            query: (id) => ({
                url: Path.Banners.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["Banner"],
        }),
        searchBanners: builder.mutation({
            query: (postData) => ({
                url: Path.Banners.bannerSearch,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
        }),
    }),
});

export const {
    useCreateBannerMutation,
    useDisableBannerMutation,
    useGetAllBannersQuery,
    useGetBannerQuery,
    useBannerDeleteMutation,
    useUpdateBannersMutation,
    useSearchBannersMutation,
} = bannerApiSlice;
