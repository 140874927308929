import { Grid } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ButtonGroup, CustomInputPassword, CustomModal } from "src/components";
import { useUpdatePasswordMutation } from "src/store/reducers/profile-api-slice/profileApiSlice";
import { notify } from "src/utils";

type Props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export const ChangePassword = ({ open, setOpen }: Props) => {
    const { t } = useTranslation();
    const [updatePassword] = useUpdatePasswordMutation();

    const handleClose = () => {
        setOpen(false);
    };

    const form = useForm<PasswordForm>({
        initialValues: {
            newValue: "",
            oldValue: "",
            confirmValue: "",
        },
        validate: {
            oldValue: (value) => {
                if (!value) return t`form.validation.required`;
                if (value.length < 6)
                    return t`form.validation.hasSixCharacters`;
            },
            newValue: (value) => {
                if (!value) return t`form.validation.required`;
                if (value.length < 6)
                    return t`form.validation.hasSixCharacters`;
                if (!/[0-9]/.test(value))
                    return t`form.validation.includesNumber`;
                if (!/[a-z]/.test(value))
                    return t`form.validation.includesLowercaseLetter`;
                if (!/[A-Z]/.test(value))
                    return t`form.validation.includesUppercaseLetter`;
            },
            confirmValue: (value) => {
                if (!value) return t`form.validation.required`;
                if (value !== form.values.newValue)
                    return t`form.validation.passwordMismatch`;
            },
        },
    });

    const handleSubmit = async (values: PasswordForm) => {
        try {
            await updatePassword(values);
            notify(true, t("notify.updated"));
            handleClose();
            form.reset();
        } catch (error) {
            notify(false);
        }
    };

    return (
        <>
            <CustomModal
                title={t`modal.changePassword`}
                open={open}
                handleClose={handleClose}
                button={false}
            >
                <form
                    onSubmit={form.onSubmit((values) => handleSubmit(values))}
                >
                    <Grid>
                        <Grid.Col span={12}>
                            <CustomInputPassword
                                {...form.getInputProps("oldValue")}
                                label={t`form.label.oldPassword`}
                                required
                                error={form.errors.oldValue}
                                formData={form.values.oldValue}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputPassword
                                {...form.getInputProps("newValue")}
                                label={t`form.label.newPassword`}
                                required
                                error={form.errors.newValue}
                                formData={form.values.newValue}
                                bar={true}
                            />
                        </Grid.Col>
                        <Grid.Col span={12} mb={7}>
                            <CustomInputPassword
                                {...form.getInputProps("confirmValue")}
                                label={t`form.label.confirmPassword`}
                                required
                                error={form.errors.confirmValue}
                                formData={form.values.confirmValue}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={handleClose}
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </form>
            </CustomModal>
        </>
    );
};
