import { Box, Flex, Menu, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getTitleByLanguage } from "src/locales";
import { SelectDropdownItems } from "./select-dropdown-item";
import styles from "./styles.module.scss";

interface IProps {
    value?: number | null;
    onChange: (value: number | null) => void;
    data: DiagramSpecificResponseType[] | null;
    isAdminPage?: boolean;
}
const defaultSelect = {
    titleRu: "Категории",
    titleKg: "Категориялар",
};
export const SelectDropdown = ({
    data,
    onChange,
    value,
    isAdminPage = false,
}: IProps) => {
    const [isHovered, setIsHovered] = useState(-1);
    const [isOpened, setOpened] = useState(false);

    const activeTitle = () => {
        let result;
        if (value) {
            result = data
                ?.map((parent) => {
                    return parent.diagramSpecificationDtoList?.find(
                        (child) => child?.id === value
                    );
                })
                ?.find((el) => el?.id);
        } else {
            result = defaultSelect;
        }
        return result;
    };
    const xSmallScreen = useMediaQuery("(max-width: 550px)");
    useEffect(() => {
        setOpened(false);
    }, [value]);
    return (
        <Menu
            opened={xSmallScreen ? isOpened : undefined}
            trigger={isAdminPage ? "click" : "click-hover"}
            closeOnClickOutside
            position="bottom-start"
        >
            <Menu.Target>
                <Flex
                    px={{ base: 20, sm: 15 }}
                    onClick={() => setOpened((prev) => !prev)}
                    className={styles.select_title}
                >
                    <Title fz={{ base: 15, sm: 16 }} fw={400} lh={1}>
                        {getTitleByLanguage(activeTitle() || defaultSelect)}
                    </Title>
                    <Box mt={7}>
                        <IconChevronDown size={18} />
                    </Box>
                </Flex>
            </Menu.Target>
            <Menu.Dropdown className={styles.select_dropdown}>
                {data?.map((item) => (
                    <SelectDropdownItems
                        value={value}
                        key={item.id}
                        isHovered={isHovered}
                        handleMouseLeave={() => setIsHovered(-1)}
                        handleMouseEnter={() => setIsHovered(item.id)}
                        onChange={onChange}
                        item={item}
                    />
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};
