import { Box, Flex, Paper, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { CustomLoader, PersonnelCardAccordion, TextInfo } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useGetCardQuery } from "src/store";

type Props = {
    cardId: string;
};

export const CardInfoDetails = ({ cardId }: Props) => {
    const { t } = useTranslation();

    const { data: cardsData, isLoading } = useGetCardQuery(cardId, {
        refetchOnMountOrArgChange: true,
    });

    const actsCard = [
        {
            label: t`data.news.title`,
            value: getTitleByLanguage(cardsData?.fullName),
        },
    ];
    return (
        <Paper shadow="sm" p="xl">
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <Paper shadow="sm" p="xl">
                    <TextInfo
                        data={actsCard}
                        display={true}
                        spanFirst={4}
                        spanTwo={8}
                    />
                    <Box mt={30}>
                        <Text mb={20} ta="center">
                            Результат:
                        </Text>
                        <Flex my={40} direction="column">
                            {cardsData ? <PersonnelCardAccordion person={cardsData} /> : null}
                        </Flex>
                    </Box>
                </Paper>}
        </Paper>
    );
};
