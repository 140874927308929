import { IconArrowUp } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { scrollToTop } from "src/hooks";
import styles from "./styles.module.scss";

export const ButtonToScroll = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight / 2) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <>
            {isVisible ? (
                <button
                    className={styles.button}
                    onClick={() => scrollToTop(0)}
                >
                    <IconArrowUp size={35} color="var(--primaryDark)" />
                </button>
            ) : null}
        </>
    );
};
