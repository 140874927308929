"use client"
import { Box, Divider, Flex, Popover, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { getTitleByLanguage } from "src/locales";
import { getJsonFiles } from "src/utils";

type Props = {
    activeCountry: WorldMapJsonType | null;
    handleClickCountry: (data: WorldMapJsonType) => () => void,
    datas?: ISelectArr[]
    allData?: {
        value: string;
        code: string;
        title: string
    }[]
}

const colors = {
    xxl: "#000F86",
    xl: "#0217BE",
    lg: "#0174FF",
    md: "#0134FF",
    sm: "#02A7FF",
    xs: "#D6DCEA",
}

export const NewWorldMap = ({
    activeCountry,
    handleClickCountry,
    datas,
    allData
}: Props) => {
    const [worldMap, setWorldMap] = useState<WorldMapJsonType[]>()

    const getFillColor = (item: WorldMapJsonType) => {
        const count = parseInt(`${datas?.find(el => el.label === item.titleEn)?.value}`) || 0
        if (item.id === activeCountry?.id) {
            return colors.xxl
        } else if (count > 60) {
            return colors.xl
        } else if (count > 30) {
            return colors.lg
        } else if (count > 5) {
            return colors.md
        } else if (count >= 1) {
            return colors.sm
        } else if (item.titleEn === "Kyrgyzstan") {
            return colors.xl
        } else
            return colors.xs
    }

    const dropdown = (item: WorldMapJsonType) => {
        const currentData = allData?.filter(el => el.code === item.titleEn)
        const countryName = getTitleByLanguage(item)
        if (countryName && countryName !== "-" && countryName !== "not found") {
            return (
                <Popover.Dropdown className="xl-box-shadow" style={{ border: "none" }}
                    maw="90vw"
                    px={{ base: 8, md: 16 }}>
                    <Box h="100%" w="100%">
                        <Text fw={500} c="primaryDark" fz={{ base: 14, md: 16 }}>
                            {countryName}
                        </Text>
                        {currentData?.length ?
                            <Divider my={5} c="primaryDark" w="100%" />
                            : null}
                        {currentData?.map((el, idx) => (
                            <Flex key={idx} gap={10} align="center">
                                <Text fw={500} fz={{ base: 16, md: 18 }} c="primaryDark">
                                    {el?.value || 0}
                                </Text>
                                <Text fz={{ base: 14, md: 16 }} c="primaryDark">{el?.title}</Text>
                            </Flex>
                        ))}
                    </Box>
                </Popover.Dropdown>
            )
        }
        return null
    }

    useEffect(() => {
        getJsonFiles("world-coordinates").then((data) => {
            setWorldMap(data);
        });
    }, [])
    return (
        <svg width="1168" height="100%" viewBox="0 0 1168 785" fill="none" xmlns="http://www.w3.org/2000/svg">
            {worldMap?.map((item: WorldMapJsonType, idx: number) => {
                return (
                    <Popover key={idx}>
                        <Popover.Target>
                            <path key={idx}
                                d={item.d}
                                onClick={handleClickCountry(item)}
                                fill={getFillColor(item)}
                                style={{ cursor: "pointer", transition: "350ms" }}
                                stroke={(item.id === 129 || item.id === 128) ? "green" : "white"}
                                strokeOpacity="0.7"
                                strokeWidth="1"
                                strokeLinecap="round"
                                strokeLinejoin="round" />
                        </Popover.Target>
                        {dropdown(item)}
                    </Popover>
                )
            })}
        </svg>
    )
}
