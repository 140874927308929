import { Box, Divider, Group, Paper, Stack, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { createStyles } from "@mantine/styles";
import { IconUsers } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    CustomButton,
    CustomInputPassword,
    CustomInputText,
} from "src/components";
import { usePageTitle } from "src/hooks";
import { UseActions } from "src/hooks/use-actions";
import { useLoginMutation } from "src/store";
import { notify } from "src/utils";

export const SignInForm = () => {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const { setCredentials } = UseActions();
    const { t } = useTranslation();
    usePageTitle(t("links.auth"));
    const [login] = useLoginMutation();

    const form = useForm<SignInType>({
        initialValues: {
            login: "",
            password: "",
        },

        validate: {
            login: (value) => {
                if (!value) return "Это поле обязательно";
                if (value.length < 14)
                    return "Должно быть не менее 14 символов";
            },
            password: (value) => {
                if (!value) return "Это поле обязательно";
                if (value.length < 6) return "Должно быть не менее 6 символов";
            },
        },
    });

    const handleSubmit = async (values: SignInType) => {
        try {
            const profileData = await login(values).unwrap();
            notify(true, t("notify.wellcome"));
            setCredentials({ ...profileData });
            navigate(`/${profileData.menu[0].parent.toLowerCase()}`);
        } catch (e) {
            notify(false, t("notify.error"));
        }
    };

    return (
        <Paper
            w={{ base: "320px", xs: "auto" }}
            shadow="lg"
            p={{ base: 15, md: "xl" }}
            radius={15}
            mt={10}
        >
            <Box className={classes.mainBlock} mx="auto">
                <form
                    className={classes.root}
                    onSubmit={form.onSubmit((values) => handleSubmit(values))}
                >
                    <div className={classes.titleBlock}>
                        <IconUsers color={"black"} size={45} />
                        <Title
                            order={5}
                            style={{
                                fontWeight: 500,
                                fontSize: 25,
                                textAlign: "center",
                            }}
                        >
                            {t("links.auth")}
                        </Title>
                    </div>
                    <Divider />
                    <Box style={{ alignItems: "center", gap: "50px" }}>
                        <Stack gap={10}>
                            <CustomInputText
                                label="ИНН"
                                required
                                max={14}
                                min={14}
                                error={form.errors.login}
                                variant="filled"
                                {...form.getInputProps("login")}
                                formData={form.values.login}
                            />

                            <CustomInputPassword
                                label="Пароль"
                                required
                                variant="filled"
                                error={form.errors.password}
                                {...form.getInputProps("password")}
                                formData={form.values.password}
                            />
                        </Stack>
                    </Box>
                    <Divider />
                    <Group className={classes.buttonBlock} mt="md">
                        <CustomButton
                            label={"Войти"}
                            type="submit"
                            size="sm"
                            fullWidth
                        />
                    </Group>
                </form>
            </Box>
        </Paper>
    );
};

const useStyles = createStyles({
    root: {
        padding: 0,
        height: "100%",
        width: "300px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        "& > label": {
            justifyContent: "flex-end",
        },
        "& > div": {
            textAlign: "center",
        },
        "& > div > svg": {
            fontSize: "50px",
        },
        gap: 18,
    },
    mainBlock: {
        height: "100%",
        width: "100%",
    },
    titleBlock: {
        paddingTop: "20px",
        height: "30%",
        textAlign: "center",
    },
    buttonBlock: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "15px",
        height: "20%",
        alignItems: "center",
    },
    input: {
        width: "100%",
        "& > div": {
            borderRadius: "4px",
            color: "black",
            fontSize: "13px",
            fontWeight: "bold",
        },
        marginBottom: "10px",
    },
});
