import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const mapApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createMap: builder.mutation({
            query: (body) => ({
                url: Path.Map.create,
                method: "POST",
                body,
                cache: "no-cache",
            }),
        }),
        getAllMap: builder.query<MapResponseType[], unknown>({
            query: () => ({
                url: Path.Map.getAll,
                method: "GET",
                cache: "no-cache",
            }),
        }),
        getMap: builder.query<MapResponseType, string>({
            query: (id) => ({
                url: Path.Map.getById(id),
                method: "GET",
                cache: "no-cache",
            }),
        }),
        updateMap: builder.mutation<
            MapRequestType,
            { id: number; body: Partial<MapRequestType> }
        >({
            query: ({ id, body }) => ({
                url: Path.Map.update(id),
                method: "PUT",
                cache: "no-cache",
                body,
            }),
        }),
        searchMap: builder.mutation<
            MapSearchResponseType,
            MapSearchRequestType
        >({
            query: (body) => ({
                url: Path.Map.search,
                method: "POST",
                body,
                cache: "no-cache",
            }),
        }),
        deleteMap: builder.mutation({
            query: (id) => ({
                url: Path.Map.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["Map"],
        }),
    }),
});

export const {
    useCreateMapMutation,
    useDeleteMapMutation,
    useGetAllMapQuery,
    useGetMapQuery,
    useSearchMapMutation,
    useUpdateMapMutation,
} = mapApiSlice;
