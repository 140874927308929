import { Card, Flex, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { createStyles } from "@mantine/styles";
import {
    IconArrowsHorizontal,
    IconCategory2
} from "@tabler/icons-react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "src/hooks";
import { useGetGoogleAnalyticsDataQuery } from "src/store";
import { LinksGroup } from "./link-group";

interface Props {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export const Sidebar = ({ open, setOpen }: Props) => {
    const { classes } = useStyles({ open });
    const middleScreen = useMediaQuery("(max-width: 992px)");
    const profile = useLocalStorage<Profile>("profile").getItem();
    const { data } = useGetGoogleAnalyticsDataQuery({})
    const { t } = useTranslation()
    return (
        <Flex
            direction="column"
            align="center"
            justify="space-between"
            w="100%"
            p="md" px={0}
            className={classes.navbar}
        >
            <Flex mb={32} direction="column" align="center">
                {middleScreen && <IconArrowsHorizontal size={30} color="white" />}
                <Flex
                    display={{ base: "none", md: "flex" }}
                    mb={20}
                    justify="flex-end"
                    w={open ? "100%" : 30}
                    onClick={() => setOpen(!open)}
                >
                    <IconCategory2 size={30} color="var(--primary)" />
                </Flex>
                <Flex
                    style={{ overflowX: middleScreen ? "scroll" : "hidden" }}
                    direction={{ base: "row", md: "column" }}
                    gap={{ base: 20, md: 0 }}
                    justify={{ base: "space-between", md: "start" }}
                    w="100%"
                >
                    {profile?.menu
                        ?.filter(el =>
                            el.parent !== "REFERENCE_LIST" &&
                            el.parent !== "CONTENT_PAGE")
                        ?.map((item) => (
                            <LinksGroup
                                key={item.parent}
                                item={item.parent}
                                children={item?.children}
                                open={middleScreen ? false : open}
                                setOpen={setOpen}
                            />
                        ))}
                </Flex>
            </Flex>
            <Card w="100%">
                <Title fw={600} lh={1.2} fz={16}>
                    {t("titles.sessions-count")}:
                </Title>
                <Flex wrap="wrap"
                    direction={{ base: "row", md: "column" }}
                    w="100%"
                    justify="space-between">
                    <Text fz={14} c="black">
                        {t("titles.per-day")}: {data?.activeUsersToday || 0}
                    </Text>
                    <Text fz={14} c="black">
                        {t("titles.per-week")}: {data?.activeUsersWeek || 0}
                    </Text>
                    <Text fz={14} c="black">
                        {t("titles.all-time")}: {data?.newUsersAllTime || 0}
                    </Text>
                </Flex>
            </Card>
        </Flex>
    );
};

const useStyles = createStyles((theme, { open }: { open: boolean }) => ({
    navbar: {
        transitionDuration: "300ms",
        minWidth: "100%",
        height: "100%",
        transition: "all 0.3s ease",
        backgroundColor: "var(--primaryDark)",
        paddingBottom: 0,
        "& svg:first-of-type": {
            cursor: "pointer",
        },
        borderRight: "none",
        overflow: "scroll",
    },
    header: {
        padding: open ? "0 13px" : "0 20px",
        marginLeft: open ? -15 : -20,
        color: theme.white,
        borderBottom: `1px solid ${theme.colors.white}`,
    },
    links: {
        color: "white",
        overflow: "auto",
        scrollbarWidth: "none",
        marginLeft: -theme.spacing.md,
        marginRight: -theme.spacing.md,
        width: "100%",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
}));
