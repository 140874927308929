import { Container, Grid, Paper, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, CustomInputText } from "src/components";
import {
    useCreateReferenceMutation,
    useGetAllReferenceTypeCodeQuery,
    useUpdateReferenceMutation,
} from "src/store/reducers/all-reducers/reference-api";
import { notify } from "src/utils";

type Props = {
    reference?: Reference;
};

export const ReferenceForm = ({ reference }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { data: typeCodes } = useGetAllReferenceTypeCodeQuery({});
    const referenceTypeIds = typeCodes?.map((el) => ({
        label: t(`titles.${el.code.toLowerCase()}`),
        value: `${el.id}`,
    }));
    const [createReference] = useCreateReferenceMutation();
    const [updateReference] = useUpdateReferenceMutation();

    const form = useForm<ReferenceForm>({
        initialValues: {
            parentId: reference?.parent?.id,
            titleEn: reference?.titleEn || "",
            titleKg: reference?.titleKg || "",
            titleRu: reference?.titleRu || "",
            typeId: reference?.type.id,
        },
        validate: {
            titleRu: (value) => !value && t("form.validation.required"),
            titleKg: (value) => !value && t("form.validation.required"),
        },
    });

    const handleSubmit = async (values: ReferenceForm) => {
        if (reference) {
            try {
                await updateReference({
                    id: reference.id,
                    body: values,
                }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/reference");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createReference(values).unwrap();
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/reference");
            } catch {
                notify(false);
            }
        }
    };

    return (
        <Container size="xl" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Select
                                style={{ border: "1px solid var(--base-dark)" }}
                                {...form.getInputProps("typeId")}
                                placeholder={t("form.label.typeId")}
                                required
                                value={`${form.values.typeId}`}
                                data={referenceTypeIds || []}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleKg")}
                                label={t`form.label.titleKg`}
                                required
                                error={form.errors.titleKg}
                                formData={form.values.titleKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleRu")}
                                label={t`form.label.titleRu`}
                                required
                                error={form.errors.titleRu}
                                formData={form.values.titleRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleEn")}
                                label={t("form.label.titleEn")}
                                error={form.errors.titleEn}
                                formData={form.values.titleEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(`/reference/${i18n.language}`)
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
