import { Text, UnstyledButton } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { AppTable } from "src/components/app-table";
import { HeadCell } from "src/types";
import { Path } from "src/utils";

export const AppealTable = ({ filter }: { filter?: AppealFilterType }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const headCells: HeadCell<AppealResponseType>[] = [
        {
            label: t`table.number`,
            render: (n) => n.id,
        },
        {
            label: t`table.appealFullName`,
            render: (n) => n.fullName,
        },
        {
            label: t`table.title`,
            render: (n) => n.subjectOfTheAppeal,
        },
        {
            label: t`table.appealDate`,
            render: (n) => <Text>{n.date}</Text>,
        },
        {
            label: t`actions.title`,
            render: (n) => (
                <UnstyledButton component={Link} to={`/online_appeal/${n.id}`} c="blue">
                    {t("actions.view")}
                </UnstyledButton>
            ),
        },
    ];
    return (
        <>
            <AppTable<AppealResponseType>
                headCells={headCells}
                doubleClickPath={(n) => navigate(`/online_appeal/${n.id}`)}
                searchApiUrl={Path.OnlineAppeal.search}
                extraFilter={filter}
            />
        </>
    );
};
