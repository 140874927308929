import { Accordion, Card, Grid, Text } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { AccordionItem, TextModule } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useGetPageQuery, usePageListAccordionSearchMutation } from "src/store";

interface Props {
    active?: number;
    isAccordion?: boolean;
    isList?: boolean
}

export const InterCoopModule = ({ active, isAccordion, isList }: Props) => {
    const [data, setData] = useState<PageListAccordion[]>();
    const [searchPageData] = usePageListAccordionSearchMutation();
    const { data: page } = useGetPageQuery(`${active}`, {
        skip: !active
    })
    const getPageData = useCallback(async () => {
        try {
            const response = await searchPageData({
                filter: {
                    pageListId: active,
                },
            });
            if ("data" in response) {
                setData(response.data.content);
            }
        } catch {
            console.error("error");
        }
    }, [active]);

    useEffect(() => {
        getPageData();
    }, [active]);
    return (
        <>
            {isAccordion ? (
                <Accordion>
                    {data?.map((el) => <AccordionItem key={el.id} data={el} />)}
                </Accordion>
            ) : (
                data?.map((el) => <TextModule key={el.id} data={el} />)
            )}
            {isList ?
                <Grid gutter={15}>
                    {page?.childrens?.map((item, index) => {
                        const isSkip = index % 2 !== 0
                        return (
                            <Grid.Col pos="relative"
                                offset={isSkip ? { base: 1, md: 4 } : 0}
                                span={isSkip ? 12 : { base: 11, xs: 10, sm: 8 }}>
                                <Grid gutter={10} key={item.id} align="center">
                                    <Grid.Col span={{ base: 1, xs: 0.5 }}>
                                        <Text c="#C4C9E2" fw={700} fz={{ base: 26, md: 32, lg: 70 }}>
                                            {index + 1}
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col span={isSkip ? { base: 10, xs: 10.5, md: 7.5 } : { base: 11, xs: 11.5 }}>
                                        <Card py={8} px={16} shadow="lg">
                                            <Text fw="500" fz={{ base: 12, xs: 14, md: 16 }}
                                                c="#797E8B">
                                                {getTitleByLanguage(item)}
                                            </Text>
                                        </Card>
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                        )
                    })}
                </Grid > : null
            }
        </>

    );
};
