import { Box, Title } from "@mantine/core";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppTable, CustomModal, MenuDropDown } from "src/components";
import { IS_MINUS_ONE } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { useDeleteDiagramSpecificMutation } from "src/store";
import { HeadCell } from "src/types";
import { Path, notify } from "src/utils";

type Props = {
    parentId?: number | null;
    opened: boolean;
    filter?: DiagramSpecificFilterType;
};

export const DiagramCategoryTable = ({ filter, opened, parentId }: Props) => {
    const { t } = useTranslation();
    const [deleteSpecific] = useDeleteDiagramSpecificMutation();
    const [id, setId] = useState<number | null>();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (id: number) => {
        setOpen(true);
        setId(id);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await deleteSpecific(id);
            notify(true, t("notify.deleted"));
        } catch (error) {
            notify(false);
        }
        setOpen(false);
    };

    const actions = (id: number) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/diagram/${id}/edit-category`,
        },
        {
            icon: IconEye,
            title: t`actions.view`,
            to: `/diagram-category/${id}`,
        },
        {
            icon: IconTrash,
            title: t`actions.delete`,
            onClick: () => handleOpen(id),
        },
    ];

    const headCells: HeadCell<DiagramSpecificType>[] = [
        {
            label: t`table.diagram_name`,
            render: (n) => getTitleByLanguage(n),
        },
        {
            label: t`form.label.importance`,
            render: (n) => n.order,
        },
        {
            label: t`actions.title`,
            render: (n) => (
                <MenuDropDown
                    label={t`actions.title`}
                    item={actions(n.id || 0)}
                />
            ),
        },
    ];
    const defaultFilter =
        parentId === IS_MINUS_ONE
            ? {}
            : {
                parentId: parentId,
            };

    return (
        <Box style={{ marginLeft: opened ? 400 : 0, transition: "0.7s" }}>
            <AppTable<DiagramSpecificType>
                headCells={headCells}
                defaultFilter={defaultFilter}
                searchApiUrl={Path.DiagramSpecification.searchSpecific}
                extraFilter={filter}
                sorting={{ sortBy: "ORDER", sortDirection: "ASC" }}
            />
            <CustomModal
                labelSubmit={"delete"}
                open={open}
                handleClose={handleClose}
                onClick={handleDelete}
            >
                <Title
                    order={3}
                    fw={400}
                    mt="5px"
                    mb="2rem"
                    style={{ textAlign: "center" }}
                >
                    {t("button.delete")}
                </Title>
            </CustomModal>
        </Box>
    );
};
