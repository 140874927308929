import { Button, Flex, List, Paper, Title } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getTitleByLanguage } from "src/locales";
import { getJsonFiles } from "src/utils";
interface SearchResultsProps {
    searchValue: string;
}

type searchFromType = {
    id: number;
    titleRu: string;
    titleKg: string;
    keywords: string[];
    path: string;
};

type searchKeywordsFuncType = (
    searchString: string,
    searchPages?: SearchPages[]
) => searchFromType[];

const searchInKeywords: searchKeywordsFuncType = (
    searchString,
    searchPages
) => {
    const results: searchFromType[] = [];
    const word = searchString.toLowerCase();

    if (searchPages) {
        for (let i = 0; i < searchPages.length; i++) {
            for (let k = 0; k < searchPages[i].keywords.length; k++) {
                const currentString = searchPages[i].keywords[k].toLowerCase();
                if (
                    currentString.includes(word) &&
                    !results.includes(searchPages[i])
                ) {
                    results.push(searchPages[i]);
                }
            }
        }
    }

    return results;
};

export const SearchPagesResults = ({ searchValue }: SearchResultsProps) => {
    const [searchPages, setSearchPages] = useState<SearchPages[]>();
    const { t, i18n } = useTranslation();
    const results = searchInKeywords(searchValue, searchPages);

    useEffect(() => {
        getJsonFiles("search-pages").then((data) => {
            setSearchPages(data);
        });
    }, []);
    return (
        <Paper
            style={{ overflowY: "scroll", zIndex: 3 }}
            top={100}
            right={0}
            pos="absolute"
            mih={100}
            mah={300}
            w={{ base: "90vw", xs: 350 }}
            px={20}
            c="primaryDark"
            bg="primary"
        >
            <Title maw={300} c="primaryDark" fz={14} fw={300} fs="italic">
                {t("statics.search-request")}: {searchValue} <br />
                {t("statics.results")}: {results?.length}
            </Title>
            <List type="ordered">
                {results?.map((el) => (
                    <Link to={`${el.path}/${i18n.language}`} key={el.id}>
                        <List.Item>
                            <Flex
                                w={{ base: "90vw", xs: 300 }}
                                align="center"
                                justify="space-between"
                            >
                                <Title
                                    fz={{ base: 10, sm: 12 }}
                                    order={5}
                                    c="primaryDark"
                                >
                                    {getTitleByLanguage(el)}
                                </Title>
                                <Button
                                    px={15}
                                    py={0}
                                    bg="primary"
                                    variant="simple"
                                >
                                    <IconChevronRight color="var(--primaryDark)" />
                                </Button>
                            </Flex>
                        </List.Item>
                    </Link>
                ))}
            </List>
        </Paper>
    );
};
