import { Flex, Image, Paper } from "@mantine/core";
import axios from "axios";
import { getAttachmentUrl } from "src/utils";

interface Props {
    item: InfoBlockSearchResponseContentType;
}

export const JournalItem = ({ item }: Props) => {
    const handleDownlaod = async () => {
        try {
            const response = await axios(
                getAttachmentUrl(
                    (item.files[0].id && item.files[0].id) || 0,
                    (item.files[0].uuid && item.files[0].uuid) || ""
                ),
                { responseType: "arraybuffer" }
            );
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.click();
            window.URL.revokeObjectURL(url);
        } catch {
            console.error("error");
        }
    };
    return (
        <Paper
            h={400}
            style={{ cursor: "pointer" }}
            onClick={handleDownlaod}
            p={16}
        >
            <Flex w="100%" h="100%" direction="column" align="center">
                <Image
                    key={item.id}
                    w="100%"
                    h="100%"
                    src={
                        item.photos
                            ? getAttachmentUrl(
                                item?.photos[0].id,
                                item?.photos[0].uuid
                            )
                            : ""
                    }
                    alt=""
                />
            </Flex>
        </Paper>
    );
};
