import { Accordion, Flex, Paper, ScrollArea, Title } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";
import { regionsId } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { useOfficeSearchMutation, useSearchMapMutation } from "src/store";
import { notify } from "src/utils";
import { KgMap } from "./map";
import { OfficeItem } from "./office-item";
import styles from "./styles.module.scss";

type mapPointNameType = {
    id: number;
    cityOrRegionNameEn: string;
    cityOrRegionNameKg: string;
    cityOrRegionNameRu: string;
};

interface IProps {
    direction?: "row" | "column" | "column-reverse" | "row-reverse";
    regionId?: number;
    officeId?: number;
    onlyMap?: boolean;
}

export const Contacts = ({
    direction,
    regionId,
    officeId,
    onlyMap = false,
}: IProps) => {
    const [selected, setSelected] = useState(regionId || regionsId.bishkek);
    const [mapPoints, setMapPoints] = useState<MapResponseType[] | null>(null);
    const [prosecutorOffices, setProsecutorOffices] = useState<
        ProsecutorOffice[]
    >([]);

    const [searchMappoints] = useSearchMapMutation();
    const [searchOffices] = useOfficeSearchMutation();

    const handleSearchMaps = useCallback(async () => {
        try {
            const response = await searchMappoints({
                filter: {},
                pageRequest: { limit: 30, page: 0 },
            });
            if ("data" in response && "content" in response.data) {
                setMapPoints(response.data.content as MapResponseType[]);
            }
        } catch {
            notify();
        }
    }, []);

    const getProsecutorOffices = useCallback(async (mapPointId: number) => {
        try {
            const response = await searchOffices({
                filter: { mapPointId: mapPointId },
                pageRequest: { limit: 30, page: 0 },
            });
            if ("data" in response) {
                return response?.data?.content || [];
            }
        } catch (error) {
            console.error(
                `Error fetching prosecutor offices for map point ${mapPointId}`
            );
            return [];
        }
    }, []);

    useEffect(() => {
        handleSearchMaps();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const prosecutorOfficesData = await Promise.all(
                (mapPoints || []).map(async (el: mapPointNameType) => {
                    const content = await getProsecutorOffices(el.id);
                    return {
                        content,
                        mapPointId: el.id,
                        mapPointName: {
                            titleRu: el.cityOrRegionNameRu,
                            titleKg: el.cityOrRegionNameKg,
                            titleEn: el.cityOrRegionNameEn,
                        },
                    };
                })
            );
            setProsecutorOffices(prosecutorOfficesData as ProsecutorOffice[]);
        };
        fetchData();
    }, [mapPoints]);

    return (
        <Flex
            direction={direction || "row"}
            wrap={{ base: "wrap", xl: "nowrap" }}
            align="start"
            justify={
                direction === "column"
                    ? "center"
                    : { base: "center", xl: "space-between" }
            }
            gap={10}
            mt={{ base: -100, xs: -50, md: -60 }}
        >
            <KgMap selected={selected} setSelected={setSelected} />
            {!onlyMap &&
                prosecutorOffices?.map((item) =>
                    item.mapPointId === selected ? (
                        <Paper
                            key={item?.mapPointId}
                            p={{ base: 10, sm: 20 }}
                            pt={0}
                            className={styles.addresses}
                        >
                            <Title
                                c="secondary"
                                fz={{ base: 18, md: 24 }}
                                fw="bold"
                                mb={{ base: -10, sm: 0 }}
                            >
                                {getTitleByLanguage(item?.mapPointName)}
                            </Title>
                            <ScrollArea scrollbarSize={4} h={500}>
                                <Accordion
                                    className={styles.contacts_accordion}
                                    chevronSize={30}
                                    px={-20}
                                    chevron={<IconChevronDown size={20} />}
                                >
                                    {officeId
                                        ? item.content
                                            .filter(
                                                (el) => el.id === officeId
                                            )
                                            .map((el) => (
                                                <OfficeItem
                                                    key={el.id}
                                                    item={el}
                                                />
                                            ))
                                        : item?.content?.map((el) => (
                                            <OfficeItem
                                                key={el.id}
                                                item={el}
                                            />
                                        ))}
                                </Accordion>
                            </ScrollArea>
                        </Paper>
                    ) : null
                )}
        </Flex>
    );
};
