import {
    Accordion,
    Box,
    Center,
    Flex,
    Grid,
    Image,
    Popover,
    ScrollArea,
    Tabs,
    Text,
    Title
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomLoader } from "src/components";
import { DIAGRAM_TYPES, getStatisticQuarterData } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { HorizontalChart, KgMapDiagram, PieChart, VerticalChart } from "./charts";
import styles from "./styles.module.scss";
import { AreaChat } from "./charts/area-chart";
import { StatisticTabs } from "src/modules/open-part/statistic/tabs";

interface StatisticCategoryStateType {
    parentId?: number;
    childrenId?: number;
}
type withId = { id: number };
interface StatisticModuleProps {
    data?: SearchDiagramResponseContentType[];
    activeCategory?: StatisticCategoryStateType;
    setActiveCategory: (a: StatisticCategoryStateType | undefined) => void;
    isLoading: boolean;
    categories: DiagramSpecificResponseType[];
    withDefaultChevron?: boolean;
    activeYear: number;
    activeQuartal: string;
}



const DOMESTIC_VIOLENCE = {
    emptyTitleRu: "До декабря 2021 года, в Уголовном кодексе Кыргызской Республики нормы, предусматривающие уголовное наказание за преступления по фактам семейного насилия, отсутствовали, вследствие чего статистических данных за период 2020 – 2021 гг. не имеется.",
    emptyTitleKg: "2021-жылдын декабрь айына чейин үй-бүлөлүк зомбулук тууралуу кылмыш-жаза кодексинде атайын берене болгон эмес, ошол себептен үй-бүлөлүк зомбулук боюнча  2020 жана 2021-жылдарда статистикалык маалыматтар жок.",
    id: 83
}

export const StatisticBody = ({
    data,
    categories,
    activeCategory,
    setActiveCategory,
    isLoading,
    withDefaultChevron = true,
    activeYear,
    activeQuartal
}: StatisticModuleProps) => {
    const [active, setActive] = useState<string | null>();
    const { t } = useTranslation();
    const quarterData = getStatisticQuarterData(t);
    const middleScreen = useMediaQuery(`(max-width: 992px)`);
    const [opened, { toggle, close }] = useDisclosure(false);

    const categoryChangeHandler = (item: withId, child?: withId) => () => {
        const parentId = item.id;
        const childrenId = child?.id;
        setActiveCategory(
            parentId === activeCategory?.parentId &&
                childrenId === activeCategory?.childrenId
                ? undefined
                : { parentId, childrenId }
        );
        if (child?.id) {
            close();
        }
    };

    const getComponent = (data?: SearchDiagramResponseContentType, isSmall = false) => {
        switch (data?.type) {
            case DIAGRAM_TYPES.CLASSIC:
                return <VerticalChart isSmall={isSmall} data={data} />
            case DIAGRAM_TYPES.RADIUS_PIE:
                return <PieChart isSmall={isSmall} data={data} />
            case DIAGRAM_TYPES.VERTICAL_BAR:
                return <VerticalChart isSmall={isSmall} data={data} />
            case DIAGRAM_TYPES.HORIZONTAL_BAR:
                return <HorizontalChart isSmall={isSmall} data={data} />
            case DIAGRAM_TYPES.PIE:
                return <PieChart isSmall={isSmall} data={data}  />
            case DIAGRAM_TYPES.KG_MAP:
                return <KgMapDiagram data={data} />
            case DIAGRAM_TYPES.AREA:
                return <AreaChat isSmall={isSmall} data={data} />
            default:
                return <HorizontalChart isSmall={isSmall} data={data} />
        }
    }


    const categoryModule = () => {
        if (middleScreen) {
            return (
                <Popover opened={opened}  onChange={toggle} position="bottom">
                    <Popover.Target>
                        <Center onClick={toggle} style={{ cursor: "pointer" }}>
                            <Text c="primaryDark" fw={400} fz={20}>
                                {t("titles.statistic-categories")}
                            </Text>
                        </Center>
                    </Popover.Target>
                    <Popover.Dropdown w="90vw" style={{ borderRadius: "none" }}>
                        <Accordion
                            chevron={withDefaultChevron ? undefined : false}
                        >
                            {categories?.map((item) => (
                                <Accordion.Item
                                    data-background={
                                        activeCategory?.parentId === item.id
                                    }
                                    className={styles.accordion_item}
                                    key={item.id}
                                    value={`${item.id}`}
                                >
                                    <Accordion.Control
                                        onClick={categoryChangeHandler(item)}
                                    >
                                        <Flex
                                            align="center"
                                            justify="space-between"
                                        >
                                            <Text
                                                c="primaryDark"
                                                fw={400}
                                                fz={20}
                                            >
                                                {getTitleByLanguage(item)}
                                            </Text>
                                            {!withDefaultChevron &&
                                                item.diagramSpecificationDtoList
                                                    ?.length && (
                                                    <Box>
                                                        <IconChevronDown
                                                            size={16}
                                                        />
                                                    </Box>
                                                )}
                                        </Flex>
                                    </Accordion.Control>
                                    <Accordion.Panel>
                                        <Flex direction="column" gap={15}>
                                            {item.diagramSpecificationDtoList?.map(
                                                (child) => (
                                                    <Text
                                                        style={{
                                                            cursor: "pointer",
                                                            opacity:
                                                                activeCategory?.childrenId ===
                                                                    child?.id
                                                                    ? 0.5
                                                                    : 1,
                                                        }}
                                                        onClick={categoryChangeHandler(
                                                            item,
                                                            child
                                                        )}
                                                        key={child.id}
                                                        c={
                                                            activeCategory ===
                                                                child.id
                                                                ? "green"
                                                                : "primaryDark"
                                                        }
                                                        fw={400}
                                                        fz={18}
                                                    >
                                                        {getTitleByLanguage(
                                                            child
                                                        )}
                                                    </Text>
                                                )
                                            )}
                                        </Flex>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Popover.Dropdown>
                </Popover>
            );
        }
        return (
            <ScrollArea scrollbarSize={6} style={{borderRadius:'4px'}} bg={'#F5F6FB'} h={600}>
                <Accordion
                    chevron={withDefaultChevron ? undefined : false}
                    value={active}
                    onChange={setActive}
                >
                    {categories?.map((item) => (
                        <Accordion.Item
                            data-background={
                                activeCategory?.parentId === item.id
                            }
                            className={styles.accordion_item}
                            key={item.id}
                            value={`${item.id}`}
                        >
                            <Accordion.Control
                                onClick={categoryChangeHandler(item)}
                            >
                                <Flex align="center" justify="space-between">
                                    <Text c="primaryDark" fw={400} fz={16}>
                                        {getTitleByLanguage(item)}
                                    </Text>
                                    {!withDefaultChevron &&
                                        item.diagramSpecificationDtoList
                                            ?.length && (
                                            <Box>
                                                <IconChevronDown size={16} />
                                            </Box>
                                        )}
                                </Flex>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Flex direction="column" gap={15}>
                                    {item.diagramSpecificationDtoList?.map(
                                        (child) => (
                                            <Text
                                                style={{
                                                    cursor: "pointer",
                                                    opacity:
                                                        activeCategory?.childrenId ===
                                                            child.id
                                                            ? 0.5
                                                            : 1,
                                                }}
                                                onClick={categoryChangeHandler(
                                                    item,
                                                    child
                                                )}
                                                key={child.id}
                                                c="primaryDark"
                                                fw={400}
                                                fz={18}
                                            >
                                                {getTitleByLanguage(child)}
                                            </Text>
                                        )
                                    )}
                                </Flex>
                            </Accordion.Panel>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </ScrollArea>
        );
    };

    return (
        <Grid pos="relative" justify="space-between">
            <Grid.Col
                mr={10}
                mb={{ base: 8, md: 0 }}
                style={{ borderRadius: "2px" }}
                span={{ base: 12, md: 3.8 }}
            >
                {categoryModule()}
            </Grid.Col>
            <Grid.Col
                style={{ borderRadius: "2px" }}
                span={{ base: 12, md: 8 }}
            >
                <StatisticTabs activeQuartal={activeQuartal} />

                <ScrollArea pos="relative" h={600} styles={{root: {overflow: 'visible'}}}>
                    {isLoading ?
                        <Box pos="relative" w="100%" mih="40vh" h="100%">
                            <CustomLoader />
                        </Box> : quarterData.map((items) => (
                            <Tabs.Panel
                                h="100%"
                                value={items.value}
                                key={items.value}
                                mr={20}
                            >
                                {data?.length ?
                                    (activeYear === 2025 && activeCategory?.childrenId === undefined && activeCategory?.parentId) ?
                                        <Grid gutter={20}>
                                            {data?.map((datas) => (
                                                <Grid.Col key={datas.id} span={{ base: 12, sm: 6, lg: 4 }} pos={'relative'}>
                                                    <Box bg="#F5F6FB" h={400}
                                                        ta="center"
                                                        py={{ base: 12, md: 30 }}
                                                        key={datas.id}
                                                         mt={25}
                                                    >
                                                        <Title
                                                            fz={16}
                                                            c="primaryDark"
                                                            ta="start"
                                                            lh={1.2}
                                                            mt={0}
                                                            ml={10}
                                                        >
                                                            {getTitleByLanguage(datas)}
                                                        </Title>
                                                        {datas?.id === 276 ?
                                                            <KgMapDiagram data={datas} />
                                                            : getComponent(datas, true)
                                                        }
                                                    </Box>
                                                </Grid.Col>
                                            ))}
                                        </Grid> :
                                        data?.map((datas) => {
                                            return (
                                                <Box
                                                    ta="center"
                                                    py={{ base: 12, md: 30 }}
                                                    my={10}
                                                    key={datas.id}
                                                    style={{ position: "relative" }}
                                                >
                                                    <Title
                                                        fz={{
                                                            base: 14,
                                                            md: 18,
                                                            lg: 24,
                                                        }}
                                                        c="primaryDark"
                                                        lh={1.2}
                                                        mt={0}
                                                    >
                                                        {getTitleByLanguage(datas)}
                                                    </Title>
                                                    {getComponent(datas)}
                                                    {datas?.cardTitleKg && datas?.cardTitleRu && datas?.value ?
                                                        <Box style={{ position: "absolute", top: 65, right: 0, background: datas?.color || "#3239cf", borderRadius: "4px", padding: "4px 16px" }}>
                                                            <Text fz={18} fw={400} lh={1.2} c="white">
                                                                {getTitleByLanguage({
                                                                    titleKg: datas?.cardTitleKg,
                                                                    titleRu: datas?.cardTitleRu,
                                                                })}
                                                            </Text>
                                                            <Flex gap={4} align="center">
                                                                {datas?.cardArrowShow &&
                                                                    <img
                                                                    style={{ transform: datas?.cardArrowToTop ? "rotate(180deg) scaleX(-1)" : undefined }}
                                                                    width={28} height={28} src="/assets/trending-down.svg" />}
                                                                <Text fw={500} fz={24} lh={1.2} c="white">
                                                                    {datas?.value}
                                                                </Text>
                                                            </Flex>
                                                        </Box>
                                                        : null}
                                                </Box>
                                            );
                                        })
                                    : (
                                        <Flex
                                            align="center"
                                            w="100%"
                                            h={550}
                                            justify="center"
                                            direction="column"
                                        >
                                            <Image
                                                w={{ base: 120, md: 200 }}
                                                src={"/assets/statisticPlaceholder.webp"}
                                            />
                                            {activeCategory?.parentId === DOMESTIC_VIOLENCE.id && (activeYear === 2021 || activeYear === 2020) ?
                                                <Title ta="center" fz={14} c="#0A164F" lh={1.4}>
                                                    {getTitleByLanguage({
                                                        titleRu: DOMESTIC_VIOLENCE.emptyTitleRu,
                                                        titleKg: DOMESTIC_VIOLENCE.emptyTitleKg,
                                                    })}
                                                </Title> : null}
                                        </Flex>
                                    )
                                }
                            </Tabs.Panel>
                        ))}
                </ScrollArea>
            </Grid.Col>
        </Grid>
    );
};
