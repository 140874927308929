import { Box, Button, Flex, Paper, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomSelectOpen, RouteTitles } from "src/components";
import { scrollToTop } from "src/hooks";

interface LayoutWithSubHeaderProps {
    children: ReactNode;
    setActive: (a: number | string) => void;
    active: number | string;
    header: { id: number | string; title: string }[];
    lastTitle?: string;
    useSelectInMobile?: boolean;
    withContainer?: boolean;
    isPrevDisabled?: boolean;
}

export const LayoutWithSubHeader = ({
    children,
    header,
    setActive,
    active,
    lastTitle,
    useSelectInMobile = false,
    withContainer = true,
    isPrevDisabled = false,
}: LayoutWithSubHeaderProps) => {
    const [title, setTitle] = useState<string | number>("");
    const { pathname } = useLocation();
    const smallScreen = useMediaQuery("(max-width: 768px)");
    useEffect(() => {
        const foundItem = header.find((el) => el.id === active);
        setTitle(lastTitle || foundItem?.title || "");
    }, [pathname, active, header]);

    const handleSelect = (option: string) => {
        const selected = header.find((el) => el.title === option)?.id || 0;
        setActive(selected);
    };

    useEffect(() => {
        scrollToTop(0);
    }, [active]);
    return (
        <Box pt={useSelectInMobile ? { base: 180, sm: 230, xxl: 180 } : 180}>
            <Paper
                className="box-shadow"
                pos="fixed"
                px={{ base: "2.7vw", sm: "4.7vw" }}
                style={{ zIndex: 10 }}
                top={0}
                w="100vw"
                mt={90}
                mb={24}
                py={useSelectInMobile && smallScreen ? 5 : 15}
            >
                {useSelectInMobile && smallScreen ? (
                    <CustomSelectOpen
                        data={header.map((el) => el.title)}
                        onChange={handleSelect}
                        value={
                            header.find((el) => el.id === active)?.title || ""
                        }
                        checkedIcon={false}
                        bg="primary"
                        color="primaryDark"
                        boxShadow={false}
                        width="100%"
                    />
                ) : (
                    <Flex ml={-10} gap={20} wrap="wrap">
                        {header.map((el, idx) => (
                            <Button
                                w="fit-content"
                                style={{
                                    position: "relative",
                                    zIndex: "10",
                                    transition: "200ms",
                                }}
                                key={idx}
                                onClick={() => setActive(el.id)}
                                variant="simple"
                            >
                                <Title lh={1.2} fz="md" textWrap="wrap" ta="start"
                                    fw={active === el.id ? 500 : 400} c="primaryDark">
                                    {el.title}
                                </Title>
                            </Button>
                        ))}
                    </Flex>
                )}
            </Paper>
            <Flex
                className={withContainer ? "container" : ""}
                mx="auto"
                direction="column"
            >
                {withContainer && (
                    <RouteTitles
                        isPrevDisabled={isPrevDisabled}
                        lastDisabled={true}
                        title={title.toString()}
                        styles={{ mb: 12, py: 2 }}
                    />
                )}
                {children}
            </Flex>
        </Box>
    );
};
