import { Box, Title } from "@mantine/core";
import { IconCheck, IconPencil, IconTrash, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppTable, CustomModal, MenuDropDown } from "src/components";
import { IS_MINUS_ONE } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { useDeletePageMutation } from "src/store";
import { HeadCell } from "src/types";
import { InformationFilterType } from "src/types/data-models/information";
import { Path, notify } from "src/utils";

type Props = {
    parentId?: number | null;
    opened: boolean;
    filter?: InformationFilterType;
};

export const PageTable = ({ opened, parentId, filter }: Props) => {
    const { t } = useTranslation();

    const [pagesDelete] = useDeletePageMutation();

    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<number | null>();

    const handleOpen = (id: number) => {
        setOpen(true);
        setId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await pagesDelete(id).unwrap();
            notify(true, t("notify.deleted"));
        } catch {
            notify(false);
        }
        setOpen(false);
    };

    const actions = (id: number) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/page/${id}/edit-page`,
        },
        {
            icon: IconTrash,
            title: t`actions.delete`,
            onClick: () => handleOpen(id),
        },
    ];

    const headCells: HeadCell<PageType>[] = [
        {
            label: t`table.title`,
            render: (p) => getTitleByLanguage(p),
        },
        {
            label: t`form.label.isAccordion`,
            render: (p) =>
                p.menuItem ? (
                    <IconCheck color="green" />
                ) : (
                    <IconX color="green" />
                ),
        },
        {
            label: t`actions.title`,
            render: (p) => (
                <MenuDropDown label={t`actions.title`} item={actions(p?.id)} />
            ),
        },
    ];

    return (
        <Box style={{ marginLeft: opened ? 420 : 0, transition: "0.7s" }}>
            <AppTable<PageType>
                headCells={headCells}
                searchApiUrl={Path.Search.pageSearch}
                extraFilter={{
                    parentId: parentId === IS_MINUS_ONE ? undefined : parentId,
                    ...filter,
                }}
            />
            <CustomModal
                labelSubmit={"delete"}
                open={open}
                handleClose={handleClose}
                onClick={handleDelete}
            >
                <Title
                    order={2}
                    fw={400}
                    mt="5px"
                    mb="2rem"
                    style={{ textAlign: "center" }}
                >
                    {t`button.delete`}
                </Title>
            </CustomModal>
        </Box>
    );
};
