import { Box } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getTitleByLanguage } from "src/locales";
import { StructureChildrens } from "src/types/data-models/global";
import { HoverText } from "../forms/hover-text";
import { StructureGroup } from "./structure-group";

type Props = {
    child: StructureChildrens[];
    setChildId: (id: number | null) => void;
    active: string;
    setActive: (id: string) => void;
};

export const StructureItem = ({
    child,
    setChildId,
    active,
    setActive,
}: Props) => {
    const { classes, cx } = useStyles();

    return (
        <>
            {child.map((item) => {
                if (item?.childrens && item.childrens.length > 0) {
                    return (
                        <StructureGroup
                            key={item.id}
                            active={active}
                            setActive={setActive}
                            parent={{
                                id: item.id,
                                title: getTitleByLanguage(item),
                            }}
                            child={item.childrens}
                            setChildId={setChildId}
                        />
                    );
                }
                return (
                    <Box
                        my={10}
                        c="primaryDark"
                        key={item.id}
                        onClick={(event) => {
                            event.preventDefault();
                            setChildId(item.id);
                            setActive(getTitleByLanguage(item));
                        }}
                        className={cx(classes.text, {
                            [classes.active]:
                                active === getTitleByLanguage(item),
                        })}
                    >
                        {getTitleByLanguage(item).length > 38 ? (
                            <HoverText
                                text={
                                    getTitleByLanguage(item).slice(0, 38) +
                                    "..."
                                }
                                hoverText={getTitleByLanguage(item)}
                                width={300}
                            />
                        ) : (
                            getTitleByLanguage(item)
                        )}
                    </Box>
                );
            })}
        </>
    );
};

const useStyles = createStyles((theme) => ({
    text: {
        whiteSpace: "nowrap",
        padding: "3px 11px",
        fontSize: theme.fontSizes.sm,
        cursor: "pointer",
        color: theme.black,
        "&:hover": {
            backgroundColor: "#f2f2f2",
        },
    },
    active: {
        backgroundColor: "#f2f2f2",
    },
}));
