import { Box, Flex, Image, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Search } from "src/components";
import { BurgerMenu } from "./menu/burger-menu";
import { LaptopMenu } from "./menu/laptop-menu";
import styles from "./styles.module.scss";

export const Header = () => {
    const { t, i18n } = useTranslation();
    const [searchActive, setSearchActive] = useState(false);
    const pathname = useLocation();

    useEffect(() => {
        setSearchActive(false);
    }, [pathname]);

    return (
        <header className={styles.header}>
            <Flex
                className="container"
                mx="auto"
                align="center"
                justify={{ base: "end", sm: "space-between" }}
            >
                <Flex
                    component={Link}
                    ml={-6}
                    to={`/${i18n.language}`}
                    gap={14}
                >
                    <Title
                        display={{ base: "none", sm: "block" }}
                        fz={{ base: 14, sm: 18 }}
                        lh={1.2}
                        miw={200}
                        maw={{ base: 300, xl: 800 }}
                        c={"primary"}
                        tt="uppercase"
                    >
                        {t("titles.logo")}
                    </Title>
                </Flex>
                <a href={`/${i18n.language}`} className={styles.logo_container}>
                    <Image w={100} src="/logo.webp" />
                </a>
                <Flex pos="relative" align="center" gap={{ base: 10, lg: 20 }}>
                    <LaptopMenu setSearchActive={setSearchActive} />
                    <Box
                        display={{ base: "none", xs: "block" }}
                        mx={{ base: -10, lg: -20 }}
                        component={Link} to="/100-year-prosecutor"
                    >
                        <Image w={130} src="/assets/logo100jyl.webp" />
                    </Box>
                    <BurgerMenu
                        searchActive={searchActive}
                        setSearchActive={setSearchActive}
                    />
                </Flex>
                <Search searchShowed={searchActive} />
            </Flex>
        </header>
    );
};
