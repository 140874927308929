import { BackgroundImage, Box, Flex, Image, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { ParallaxLayer } from "src/components";
import { getJsonFiles } from "src/utils";
import { AboutHistoryItem } from "./history-items";
import { SimpleHistoryItem } from "./simple-item";
import { HistoryParallaxItem } from "./with-parallax";

export const HistoryModule = () => {
    const { t } = useTranslation();
    const mdScreen = useMediaQuery("(min-width: 992px)");
    const [historyData, setHistoryData] = useState<HistoryType[]>();

    useEffect(() => {
        getJsonFiles("history-prosecutor").then((data) => {
            setHistoryData(data);
        });
    }, []);
    if (mdScreen) {
        return (
            <Flex
                style={{ overflow: "hidden", width: "100vw" }}
                direction="column"
                mb={-200}
                mt={-40}
                ta="center"
            >
                <ParallaxProvider>
                    {historyData?.map((item, idx) =>
                        idx % 2 === 0 ? (
                            <Parallax key={item.id} speed={-15}>
                                <HistoryParallaxItem
                                    key={item.id}
                                    item={item}
                                />
                            </Parallax>
                        ) : (
                            <Parallax key={item.id} speed={10}>
                                <SimpleHistoryItem item={item} />
                            </Parallax>
                        )
                    )}
                </ParallaxProvider>
            </Flex>
        );
    } else {
        return (
            <Box style={{ overflow: "hidden" }} mt={-22} ta="center">
                <ParallaxLayer speed={-1}>
                    <Box h={460} pos="relative" style={{ zIndex: "1" }}>
                        <Image
                            style={{ objectPosition: "40%" }}
                            pos="absolute"
                            left={0}
                            top={-70}
                            w="100vw"
                            h={800}
                            src={"/assets/bottom-bg.webp"}
                        />
                    </Box>
                </ParallaxLayer>
                <ParallaxLayer speed={0}>
                    <BackgroundImage
                        src="/assets/bg.webp"
                        style={{ backgroundSize: "100% auto" }}
                    >
                        <Flex
                            className="container"
                            mx="auto"
                            direction="column"
                            mb={50}
                            style={{ zIndex: "2" }}
                            py={32}
                        >
                            <Title
                                mb={32}
                                c="secondaryDark"
                                fz={{ base: 20, md: 28 }}
                            >
                                {t("titles.history")}
                            </Title>
                            <Flex direction="column" gap={40}>
                                {historyData?.map(
                                    (el, idx) =>
                                        idx < 4 && (
                                            <AboutHistoryItem
                                                key={el.id}
                                                item={el}
                                            />
                                        )
                                )}
                            </Flex>
                        </Flex>
                    </BackgroundImage>
                </ParallaxLayer>
                <ParallaxLayer speed={-1}>
                    <Box
                        h={460}
                        mt={-22}
                        pos="relative"
                        style={{ zIndex: "1" }}
                    ></Box>
                </ParallaxLayer>
                <ParallaxLayer speed={0}>
                    <BackgroundImage
                        src="/assets/bg.webp"
                        style={{ backgroundSize: "100% auto" }}
                    >
                        <Flex
                            className="container bg-img"
                            gap={40}
                            direction="column"
                            py={32}
                            pos="relative"
                            style={{ zIndex: "2" }}
                        >
                            {historyData?.map(
                                (el, idx) =>
                                    idx > 3 && (
                                        <AboutHistoryItem
                                            key={el.id}
                                            item={el}
                                        />
                                    )
                            )}
                        </Flex>
                    </BackgroundImage>
                </ParallaxLayer>
            </Box>
        );
    }
};
