import { Box, Flex, Grid, Image, Paper, Text, Title } from "@mantine/core"
import { CustomLoader } from "src/components/forms"
import { getTitleByLanguage } from "src/locales"
import { useGetAllByTypeInfoBlockQuery } from "src/store"

interface Props {
    bottomStatisticId?: number
}

export const SecondInfoBlock = ({ bottomStatisticId }: Props) => {
    const { data: bottomStatistic, isLoading: loadingBottom } = useGetAllByTypeInfoBlockQuery(`${bottomStatisticId}`, {
        skip: !bottomStatisticId
    });
    return (
        <Paper
            pos="relative"
            px={{ base: 24, lg: 40 }}
            py={{ base: 12, lg: 24 }} mih="250" bg="#0217BE">
            <Image
                style={{
                    zIndex: 1,
                }}
                pos="absolute"
                w="150px"
                bottom={24}
                right={30}
                src="/icons/lines-of-statistic.svg"
            />
            <Flex
                mih="200"
                gap={{ base: 24, lg: 24 }}
                align={{ base: "start", xs: "center" }}
                direction={{ base: "column", xs: "row" }}
                justify="space-between">
                {loadingBottom ?
                    <Box pos="relative" w='100%' h={300}>
                        <CustomLoader color="white" />
                    </Box> : bottomStatistic?.map((el) => (
                        <Grid key={el.id} align="start">
                            <Grid.Col mb={{ base: -30, x2s: 0 }} span={{ base: 12, x2s: 6, xs: 12 }}>
                                <Title lh={1.3} fw={400} fz={16} c="primary">
                                    {getTitleByLanguage(el)}
                                </Title>
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, x2s: 6, xs: 12 }}>
                                <Title mb={{ base: -15, xs: 0 }} fz={{ base: 24, sm: 26, md: 32, lg: 40 }} c="primary">
                                    {el.textEn}
                                </Title>
                                <Text lh={1.2} fz={{ base: 12, xs: 14 }} c="primary">
                                    {getTitleByLanguage({
                                        titleKg: el.textKg,
                                        titleRu: el.textRu
                                    })}
                                </Text>
                            </Grid.Col>
                        </Grid>
                    ))}
            </Flex>
        </Paper>
    )
}
