import { Box, Flex, Title } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PersonnelCardAccordion } from "src/components";
import { useSearchCardMutation } from "src/store";

export const ManagementModule = () => {
    const [personnelCards, setPersonnelCards] = useState<CardType[]>([]);
    const [searchCards] = useSearchCardMutation();
    const { t } = useTranslation();
    const getPersonnelCards = useCallback(async () => {
        try {
            const response = await searchCards({
                filter: {
                    active: true,
                    prosecutorOfficeId: 2,
                    acceptsRequests: false,
                },
                pageRequest: {
                    limit: 30,
                    page: 0,
                },
                sorting: {
                    sortBy: "IMPORTANCE",
                },
            });
            if ("data" in response) {
                setPersonnelCards(response.data.content || null);
            }
        } catch {
            console.error("error");
        }
    }, []);

    useEffect(() => {
        getPersonnelCards();
    }, []);
    return (
        <Box mt={{ base: -24, lg: 0 }} mih="100vh">
            <Title
                c="primaryDark"
                mb={-24}
                fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}
            >
                {/* {personnelCards ? getTitleByLanguage(personnelCards[0]?.prosecutorOffice) : ""} */}
                {t("titles.logo")}
            </Title>
            <Flex my={40} direction="column" gap={24}>
                {personnelCards?.map((item) => (
                    <PersonnelCardAccordion key={item.id} person={item} />
                ))}
            </Flex>
        </Box>
    );
};
