import { Box, Center, Flex, Grid, Pagination, Text } from "@mantine/core";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { CustomLoader } from "src/components";
import { NewsSearchResponseType } from "src/types/data-models/news";
import { NewsPanel } from "../panel";

interface Props {
    isLoading: boolean;
    newsData?: NewsSearchResponseType;
    newsContent: ReactNode;
    activePage: number;
    setActivePage: (page: number) => void;
    date: Date | null;
    calendarShowed: boolean
    setDate: (date: Date | null) => void

}

export const NewsBody = ({
    activePage,
    calendarShowed,
    date,
    isLoading,
    newsContent,
    newsData,
    setActivePage,
    setDate
}: Props) => {
    const { t } = useTranslation()
    return (
        <Grid justify="space-between">
            <Grid.Col span={{ base: 12, lg: 7.5, xl: 8.5 }} mb={40}>
                <Flex
                    direction="row"
                    align="start"
                    wrap="wrap" w='100%'
                    gap={14}
                >
                    {isLoading ?
                        <Box pos="relative" w="100%" h={300}>
                            <CustomLoader />
                        </Box> :
                        newsData?.content?.length ? (
                            newsContent
                        ) : (
                            <Center w="100%">
                                <Text c="weakBlue" fz={24} fs="italic">
                                    {t("statics.news-not-found")}
                                </Text>
                            </Center>
                        )}
                    <Center w="100%">
                        <Pagination
                            c="primaryDark"
                            fw="500"
                            bg="primary"
                            defaultChecked
                            value={activePage}
                            onChange={setActivePage}
                            color="gray"
                            total={newsData?.totalPages || 0}
                        />
                    </Center>
                </Flex>
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 4.2, xl: 3 }}>
                <NewsPanel
                    date={date}
                    setDate={setDate}
                    calendarShowed={calendarShowed}
                />
            </Grid.Col>
        </Grid>
    )
}
