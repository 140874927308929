import {
    BackgroundImage,
    Box,
    Button,
    Flex,
    Image,
    Paper,
    Text,
    Title,
} from "@mantine/core";
import { IconChevronRight, IconPhoneCall } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getTitleByLanguage } from "src/locales";

interface PanelCardProps {
    className?: string;
    height?: number;
    width?: number | { base: string | number; lg: number | string };
    card: PanelCardType;
}
export const PanelCard = ({
    card,
    className,
    height,
    width,
}: PanelCardProps) => {
    const { t, i18n } = useTranslation();
    return (
        <Paper
            className={className}
            h={height && height}
            w={width}
            p={16}
            pb={0}
        >
            <BackgroundImage
                style={{
                    backgroundSize: "auto 150% ",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left top",
                }}
                src="/assets/logo-for-cards.svg"
            >
                <Flex
                    direction="column"
                    justify="space-between"
                    align="start"
                    mih={230}
                    gap={24}
                >
                    <Box>
                        <Title mb={-10} lh={1} order={3} fw={400} c="weakDark">
                            {getTitleByLanguage(card)}
                        </Title>
                        <Text lh={1} mb={24} fz="sm" c="strongGray">
                            {getTitleByLanguage({
                                titleRu: card.textRu,
                                titleKg: card.textKg,
                            })}
                        </Text>
                        <Text fz="sm" c="strongGray">
                            {card.description}
                        </Text>
                    </Box>
                    <Flex pb={16} style={{ alignSelf: "flex-end" }}>
                        {card.linkPlay.startsWith("https:") &&
                            card.linkApp.startsWith("https:") ? (
                            <Flex gap={8} justify="center" wrap="wrap">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={card?.linkApp}
                                >
                                    <Button
                                        className="dark_button_hover"
                                        p="10px 16px 10px 24px"
                                        bg="primaryDark"
                                        c="primary"
                                    >
                                        <Flex gap={10}>
                                            {t("statics.download")}
                                            <Image src="/icons/android.svg" />
                                        </Flex>
                                    </Button>
                                </a>
                            </Flex>
                        ) : card?.linkPlay?.startsWith("/reception") ? (
                            <Link to={`${card?.linkPlay}/${i18n.language}`}>
                                <Button
                                    className="dark_button_hover"
                                    p="10px 16px 10px 24px"
                                    bg="primaryDark"
                                    c="primary"
                                >
                                    {t("statics.goto")}
                                    <IconChevronRight
                                        style={{ margin: "0 10px" }}
                                    />
                                </Button>
                            </Link>
                        ) : (
                            <a href={card?.linkPlay}>
                                <Button
                                    className="dark_button_hover"
                                    p="10px 16px 10px 24px"
                                    bg="primaryDark"
                                    c="primary"
                                >
                                    {t("statics.call")}
                                    <IconPhoneCall
                                        size={20}
                                        style={{ margin: "0 10px" }}
                                    />
                                </Button>
                            </a>
                        )}
                    </Flex>
                </Flex>
            </BackgroundImage>
        </Paper>
    );
};
