import { Box, Paper } from "@mantine/core";
import { CustomLoader } from "src/components";
import { useGetConstitutionQuery } from "src/store";

type Props = {
    constId: string;
};

export const ConstitutionDetails = ({ constId }: Props) => {
    const { data: diagramData, isLoading } = useGetConstitutionQuery(constId, {
        refetchOnMountOrArgChange: true,
    });

    return (
        <Paper shadow="sm" p="xl">
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <Paper shadow="sm" p="xl">
                    <Box ta="center" py={30} my={10} key={diagramData?.id}></Box>
                </Paper>
            }
        </Paper>
    );
};
