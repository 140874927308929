import { Container, Grid, NumberInput, Paper, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    ButtonGroup,
    CustomInputText,
    CustomSwitch,
} from "src/components";
import { PageListPositions } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import {
    useCreatePageMutation,
    useSearchPageMutation,
    useUpdatePageMutation,
} from "src/store";
import { notify } from "src/utils";

type Props = {
    page?: PageType;
};
const positionID = [
    {
        value: PageListPositions.CARD,
        label: "Карточка",
    },
    {
        value: PageListPositions.TABS,
        label: "Таб",
    },
    {
        value: PageListPositions.TEXT,
        label: "Текст",
    },
    {
        value: PageListPositions.ACCORDION,
        label: "Аккордион",
    },
    {
        value: PageListPositions.LIST,
        label: "Список",
    },
    {
        value: PageListPositions.DOCUMENT,
        label: "Документы",
    },
];

export const PageForm = ({ page }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [createPage] = useCreatePageMutation();
    const [updatePage] = useUpdatePageMutation();

    const [searchPages, { data: pages }] = useSearchPageMutation();

    const fetchSearchPages = useCallback(async () => {
        try {
            await searchPages({
                filter: {},
                pageRequest: {
                    page: 0,
                    limit: 100,
                },
            });
        } catch (error) {
            notify();
        }
    }, []);

    const pagesID = pages?.content
        // ?.filter((el) => !el.parent)
        .map((item) => ({
            value: `${item.id}`,
            label: getTitleByLanguage(item),
        }));



    const form = useForm<PageFormTypes>({
        initialValues: {
            order: page?.order || 20,
            titleKg: page?.titleKg || "",
            titleRu: page?.titleRu || "",
            titleEn: page?.titleEn || "",
            contentKg: page?.content.titleKg || "",
            contentRu: page?.content.titleRu || "",
            contentEn: page?.content.titleEn || "",
            profileDataType: page?.profileDataType || null,
            position: page?.position || PageListPositions.CARD,
            parentId: page?.parent?.id || undefined,
            menuItem: page?.menuItem === true,
            isActPage: page?.isActPage || false,
            isMeetingPage: page?.isMeetingPage || false,
        },
        validate: {
            titleKg: (value) => !value && t("form.validation.required"),
            titleRu: (value) => !value && t("form.validation.required"),
        },
    });

    const handleSubmit = async ({ ...values }: PageFormTypes) => {
        if (page) {
            try {
                await updatePage({ id: page.id, body: values }).unwrap();
                notify(true, t("notify.updated"));
                form.reset();
                navigate("/page");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createPage(values).unwrap();
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/page");
            } catch {
                notify(false);
            }
        }
    };
    useEffect(() => {
        fetchSearchPages();
    }, []);
    return (
        <Container size="xl" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 2 }}>
                            <NumberInput
                                label={t`form.label.importance`}
                                required
                                error={form.errors.order}
                                {...form.getInputProps("order")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomSwitch
                                label={t`form.label.isAccordion`}
                                error={form.errors.menuItem}
                                formData={form.values.menuItem}
                                {...form.getInputProps("menuItem")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                searchable
                                required
                                value={form.values.position}
                                data={positionID || []}
                                placeholder={t("form.label.type")}
                                {...form.getInputProps("position")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                searchable
                                data={pagesID || []}
                                placeholder={t("form.label.parent")}
                                {...form.getInputProps("parentId")}
                                value={`${form.values.parentId}`}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                label={t`form.label.titleKg`}
                                required
                                error={form.errors.titleKg}
                                formData={form.values.titleKg}
                                {...form.getInputProps("titleKg")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                label={t`form.label.titleRu`}
                                required
                                error={form.errors.titleRu}
                                formData={form.values.titleRu}
                                {...form.getInputProps("titleRu")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                label={t`form.label.titleEn`}
                                error={form.errors.titleEn}
                                formData={form.values.titleEn}
                                {...form.getInputProps("titleEn")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(`/page/${i18n.language}`)
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
