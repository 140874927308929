import { Flex, Grid, Image, Paper, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DateBox } from "../../forms/date-box";

export const Laws = () => {
    const { t, i18n } = useTranslation();
    return (
        <Grid align="center" justify="space-between" pt={40} mb={50}>
            <Grid.Col span={{ base: 12, sm: 6, lg: 4 }}>
                <LawItem
                    link={`https://cbd.minjust.gov.kg/1/edition/989117/${i18n.language}`}
                    label={t(`titles.1993`)}
                    year={1993} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6, lg: 4 }}>
                <LawItem
                    link={`https://cbd.minjust.gov.kg/202913/edition/1137332/${i18n.language}`}
                    label={t(`titles.2010`)}
                    year={2010} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6, lg: 4 }}>
                <LawItem
                    link={`https://cbd.minjust.gov.kg/112215/edition/1078264/${i18n.language}`}
                    label={t(`titles.2021`)}
                    year={2021} />
            </Grid.Col>
        </Grid>
    );
};

type Props = {
    year: number;
    label: string;
    link: string
}

const LawItem = ({ year, label, link }: Props) => {
    const { t } = useTranslation();
    return (
        <Link to={link}>
            <Paper
                pos="relative"
                h={140}
                w="100%"
                miw={{ base: "100%", x2s: 250 }}
                px={20}
                my={10}
                mx="auto"
                variant="bordered"
                style={{ borderRadius: "2px" }}
            >
                <Flex align="center" justify="center" h="100%">
                    <DateBox date={year.toString()} />
                    <Flex
                        display="flex"
                        align="center"
                        ml={10}
                        justify="center"
                        gap={20}
                    >
                        <Image
                            src={"/assets/emblem.webp"}
                            width={50}
                            h="auto"
                            alt=""
                        />
                        <Title
                            fz={{
                                base: "md",
                                sm: "md",
                                md: "xl",
                                lg: "xs",
                                xl: "sm",
                                xxl: "lg",
                            }}
                            lh={1.6}
                            w={{ base: "80%", lg: "auto" }}
                            c="secondary"
                            order={3}
                        >
                            {t("titles.constitution-of-kr")} <br />
                            {label}
                        </Title>
                    </Flex>
                </Flex>
            </Paper>
        </Link>
    );
};
