import { Flex, Text } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAttachmentUrl } from "src/utils";

interface IProps {
    file: PageListAccordion;
    handleDownload: () => void;
}

export const PdfView = ({ file, handleDownload }: IProps) => {
    const [printFile, setPrintFile] = useState<string>("");
    const { t, i18n } = useTranslation();
    const fileId = useMemo(() => {
        return i18n.language === "ru" ? file.fileAttachmentRu?.[0].id : file.fileAttachmentKg?.[0].id
    }, [i18n.language, file])
    const fileUuid = useMemo(() => {
        return i18n.language === "ru" ? file.fileAttachmentRu?.[0].uuid : file.fileAttachmentKg?.[0].uuid
    }, [i18n.language, file])
    const fileDownload = useCallback(async () => {
        if (fileId && fileUuid) {
            try {
                const res = await axios(getAttachmentUrl(fileId, fileUuid), { responseType: "arraybuffer" });
                const blob = new Blob([res.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                setPrintFile(url);
            } catch {
                console.error("error in file downloading");
            }
        }
    }, [file, i18n.language]);

    useEffect(() => {
        fileDownload();
    }, [fileDownload]);

    return (
        <object
            data={printFile}
            type="application/pdf"
            width="100%"
            height="700px"
        >
            <Flex onClick={handleDownload} gap={50}>
                <Text>{t("statics.error-dowload-file")}</Text>
                <IconDownload size={24} color="#000" />
            </Flex>
        </object>
    );
};
