import {
    BackgroundImage,
    Flex,
    Grid,
    Image,
    Paper,
    Text,
    Title
} from "@mantine/core";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getJsonFiles } from "src/utils";
import styles from "../styles.module.scss";

export const LinksItem = ({
    title,
    img,
    link,
}: {
    title: string;
    img: string;
    link: string;
}) => {
    const [socialLinks, setSocialLinks] = useState<SocialLinkType[]>();
    const { i18n, t } = useTranslation();

    useEffect(() => {
        getJsonFiles("social-links").then((data) => {
            setSocialLinks(data);
        });
    }, []);
    return (
        <Paper className={styles.links_item}>
            <Grid
                grow
                gutter={{ base: 0, sm: 50 }}
                align="center"
                justify="start"
                w="100%"
            >
                <Grid.Col span={{ base: 12, sm: 8 }}>
                    <BackgroundImage
                        style={{
                            backgroundPosition: "bottom right",
                            backgroundSize: "50%",
                            backgroundRepeat: "no-repeat",
                        }}
                        src="/assets/books.png"
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            px={{ base: 32, md: 68 }}
                            h={{ base: 270, sm: 350 }}
                            bg="rgba(var(--primary-dark), 0.7)"
                        >
                            <Title
                                fz={{ base: 24, md: 28, lg: 30 }}
                                c="primary"
                                style={{ whiteSpace: "pre-line" }}
                                mb={28}
                            >
                                {t("titles.journals")}
                            </Title>
                            <Flex
                                component={Link}
                                to={`/magazine/${i18n.language}`}
                                align="center"
                                gap={10}
                            >
                                <Text c="primary">{t("statics.more")}</Text>
                                <IconArrowNarrowRight color="white" size={20} />
                            </Flex>
                        </Flex>
                    </BackgroundImage>
                </Grid.Col>
                <Grid.Col
                    style={{ overflow: "hidden" }}
                    span={{ base: 12, sm: 4 }}
                >
                    <BackgroundImage
                        className={styles.bg_image}
                        src="/assets/social-links.svg"
                    >
                        <Flex
                            px={26}
                            direction="column"
                            h={{ base: 270, sm: 350 }}
                            justify="center"
                        >
                            <Title
                                c="primaryDark"
                                fz={{ base: 24, md: 28, lg: 30 }}
                            >
                                {t("titles.we-in-social-media")}:
                            </Title>
                            <nav style={{ display: "flex", gap: "15px" }}>
                                {socialLinks?.map((el) => (
                                    <a key={el.id} href={el.path}>
                                        <Image
                                            className={styles.social_link_icon}
                                            src={el.iconDark}
                                        />
                                    </a>
                                ))}
                            </nav>
                        </Flex>
                    </BackgroundImage>
                </Grid.Col>
            </Grid>
        </Paper>
    );
};
