import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const referenceApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createReference: builder.mutation<Reference, ReferenceForm>({
            query: (body) => ({
                url: Path.Reference.create,
                method: "POST",
                body: body,
            }),
        }),
        updateReference: builder.mutation<
            Reference,
            { id: number; body: Partial<Reference> }
        >({
            query: ({ id, body }) => ({
                url: Path.Reference.update(id),
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Reference"],
        }),
        getReference: builder.query<Reference, string>({
            query: (id) => ({
                url: Path.Reference.get(id),
            }),
            providesTags: ["Reference"],
        }),
        getByTypeCode: builder.query<Reference[], TypeCodes>({
            query: (code) => ({
                url: Path.Reference.getByTypeCode(code),
            }),
            providesTags: ["Reference"],
        }),
        getAllReferenceTypeCode: builder.query<ReferenceTypeCode[], unknown>({
            query: () => ({
                url: Path.Reference.getAllTypeCode,
            }),
            providesTags: ["Reference"],
        }),
        referenceSearch: builder.mutation<
            ReferenceSearchResponse,
            ReferenceSearchRequest
        >({
            query: (postData) => ({
                url: Path.Reference.search,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
            invalidatesTags: ["Reference"],
        }),
    }),
});

export const {
    useCreateReferenceMutation,
    useGetReferenceQuery,
    useReferenceSearchMutation,
    useUpdateReferenceMutation,
    useGetAllReferenceTypeCodeQuery,
    useGetByTypeCodeQuery,
} = referenceApiSlice;
