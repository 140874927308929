import { Flex } from "@mantine/core";
import { useEffect, useState } from "react";
import { getJsonFiles } from "src/utils";
import { PanelCard } from "./card-item";
import styles from "./styles.module.scss";

export const PanelCards = () => {
    const [panelCards, setPanelCards] = useState<PanelCardType[]>();

    useEffect(() => {
        getJsonFiles("panel-cards").then((data) => {
            setPanelCards(data);
        });
    }, []);
    return (
        <Flex
            maw={{ base: "none", lg: 370 }}
            justify="center"
            className={styles.panel_cards_container}
            gap={32}
        >
            {panelCards?.map((item) => (
                <PanelCard
                    width={{ base: "none", lg: 370 }}
                    key={item.id}
                    className={styles.panel_card}
                    card={item}
                />
            ))}
        </Flex>
    );
};
