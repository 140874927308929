import { Box, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { Contacts } from "src/components";

export const ContactsModule = () => {
    const { t } = useTranslation();
    const smallScreen = useMediaQuery("(max-width: 768px)");

    return (
        <Box mx="auto" w={smallScreen ? "95vw" : "90vw"} mih="70vh">
            <Title
                mb={{ base: -50, md: 32 }}
                c="primaryDark"
                fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}
            >
                {t("links.contacts")}
            </Title>
            <Contacts />
        </Box>
    );
};
