import { Select } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { createStyles } from "@mantine/styles";
import {
    ReactNode,
    startTransition,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useInfiniteQuery } from "react-query";
import { useLocalStorage } from "src/hooks";
import { useLazySearchRequestQuery } from "src/store";

interface Option {
    label: string;
    value: string;
}

interface SearchSearchFieldProps {
    path: string;
    label: string;
    disabled?: boolean;
    extraFilter?: Record<string, Exclude<PrimitiveValue, symbol>>;
    error?: ReactNode;
    required?: boolean;
    formData?: number | string | null;
    onChange?: (value: string | null) => void;
}

const LIMIT = 10;

export const SearchField: React.FC<SearchSearchFieldProps> = ({
    path,
    label,
    disabled,
    extraFilter,
    error,
    required,
    formData,
    onChange,
    ...props
}: SearchSearchFieldProps) => {
    const i18lng = useLocalStorage("i18nextLng").getItem();

    const [focused, setFocused] = useState<boolean>(false);
    const { classes } = useStyles({
        floating: Boolean(formData?.toString().trim()) || focused,
    });
    const [scrollPosition] = useState<number>(0);
    const [searchValue, setSearchValue] = useDebouncedState("", 200);
    const [searchRequest] = useLazySearchRequestQuery();

    const handleFetch = useCallback(
        async (pageParam: number) => {
            const { data } = await searchRequest({
                path,
                body: {
                    filter: {
                        title: searchValue.length > 0 ? searchValue : null,
                        ...extraFilter,
                    },
                    pageRequest: {
                        limit: LIMIT,
                        page: pageParam,
                    },
                    sorting: {
                        sortBy: "ID",
                        sortDirection: "ASC",
                    },
                },
            });
            return data;
        },
        [searchRequest, path, searchValue, extraFilter]
    );

    const { data, refetch } = useInfiniteQuery(
        [path, { searchValue, extraFilter }],
        async ({ pageParam = 0 }) => handleFetch(pageParam),
        {
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) =>
                lastPage.page < lastPage.totalPages
                    ? lastPage.page + 1
                    : lastPage.page,
            enabled: focused,
        }
    );

    const listRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const list = listRef.current;
        if (list) {
            list.scrollTop = scrollPosition;
        }
    }, [scrollPosition]);

    useEffect(() => {
        if (formData) {
            startTransition(() => {
                void refetch();
            });
        }
    }, [formData, refetch]);

    const options: Option[] =
        data?.pages
            .map((page) => page.content)
            .flat()
            .flatMap((item: GetEnabledTypes) => ({
                label: i18lng === "RU" ? item.titleRu : item.titleKg,
                value: item.id.toString(),
            })) ?? [];

    return (
        <>
            <Select
                {...props}
                placeholder={label}
                required={required}
                error={error}
                clearable
                searchable
                disabled={disabled}
                classNames={classes}
                value={formData?.toString() ?? ""}
                onChange={onChange}
                onInput={(event) => setSearchValue(event.currentTarget.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                data={options}
                ref={listRef}
                className={classes.root}
                mt={"md"}
            />
        </>
    );
};

const useStyles = createStyles(
    (theme, { floating }: { floating: boolean }) => ({
        root: {
            position: "relative",
        },
        label: {
            position: "absolute",
            zIndex: 2,
            top: 7,
            left: theme.spacing.sm,
            pointerEvents: "none",
            color: floating
                ? theme.colorScheme === "light"
                    ? theme.colors.dark[4]
                    : theme.white
                : theme.colorScheme === "dark"
                    ? theme.colors.dark[3]
                    : theme.colors.gray[5],
            transition:
                "transform 150ms ease, color 150ms ease, font-size 150ms ease",
            transform: floating
                ? `translate(-${theme.spacing.sm}px, -28px)`
                : "none",
            fontSize: floating ? theme.fontSizes.xs : theme.fontSizes.sm,
            fontWeight: 600,
        },

        required: {
            transition: "opacity 150ms ease",
            opacity: floating ? 1 : 0.5,
        },

        input: {
            "&::placeholder": {
                transition: "color 150ms ease",
                color: !floating ? "transparent" : undefined,
            },
        },
    })
);
