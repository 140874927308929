import { Box, Title } from "@mantine/core";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppTable, CustomModal, MenuDropDown } from "src/components";
import { IS_MINUS_ONE } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { useDeleteProsecutorOfficeMutation } from "src/store";
import { HeadCell } from "src/types";
import { Path, notify } from "src/utils";

type Props = {
    parentId?: number | null;
    opened: boolean;
    filter?: ProsecutorFilterType;
};

export const OfficeTable = ({ filter, opened, parentId }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [officeDelete] = useDeleteProsecutorOfficeMutation();

    const [id, setId] = useState<number | null>();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (id: number) => {
        setOpen(true);
        setId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await officeDelete(id);
            notify(true, t("notify.deleted"));
        } catch (error) {
            notify(false);
        }
        setOpen(false);
    };

    const actions = (id: number) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/prosecutor_office/${id}/edit`,
        },
        {
            icon: IconEye,
            title: t`actions.view`,
            to: `/prosecutor_office/${id}`,
        },
        {
            icon: IconTrash,
            title: t`actions.delete`,
            onClick: () => handleOpen(id),
        },
    ];

    const headCells: HeadCell<ProsecutorResponseType>[] = [
        {
            label: t`table.title`,
            render: (n) => getTitleByLanguage(n),
        },
        {
            label: t`form.label.importance`,
            render: (n) => n.importance,
        },
        {
            label: t`form.label.detailAddress`,
            render: (n) =>
                getTitleByLanguage({
                    titleRu: n.addressRu,
                    titleKg: n.addressKg,
                }),
        },
        {
            label: t`table.phone`,
            render: (n) => n.phone,
        },
        {
            label: t`actions.title`,
            render: (n) => (
                <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
            ),
        },
    ];

    return (
        <Box style={{ marginLeft: opened ? 320 : 0, transition: "0.7s" }}>
            <AppTable<ProsecutorResponseType>
                headCells={headCells}
                doubleClickPath={(n) =>
                    navigate(`/prosecutor_office/${n.id}/${i18n.language}`)
                }
                defaultFilter={{
                    mapPointId:
                        parentId === IS_MINUS_ONE ? undefined : parentId,
                }}
                searchApiUrl={Path.ProsecutorOffice.search}
                extraFilter={filter}
            />
            <CustomModal
                labelSubmit={"delete"}
                open={open}
                handleClose={handleClose}
                onClick={handleDelete}
            >
                <Title
                    order={3}
                    fw={400}
                    mt="5px"
                    mb="2rem"
                    style={{ textAlign: "center" }}
                >
                    {t`button.delete`}
                </Title>
            </CustomModal>
        </Box>
    );
};
