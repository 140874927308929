import { Title } from "@mantine/core";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppTable, CustomModal, MenuDropDown } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useDeleteMapMutation } from "src/store";
import { HeadCell } from "src/types";
import { Path, notify } from "src/utils";

export const MapTable = ({ filter }: { filter?: ActsFilterType }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [mapDelete] = useDeleteMapMutation();

    const [id, setId] = useState<number | null>();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (id: number) => {
        setOpen(true);
        setId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await mapDelete(id);
            notify(true, t("notify.deleted"));
        } catch (error) {
            notify(false);
        }
        setOpen(false);
    };

    const actions = (id: number) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/map_point/${id}/edit-map_point`,
        },
        {
            icon: IconEye,
            title: t`actions.view`,
            to: `/map_point/${id}`,
        },
        {
            icon: IconTrash,
            title: t`actions.delete`,
            onClick: () => handleOpen(id),
        },
    ];

    const headCells: HeadCell<MapResponseType>[] = [
        {
            label: t`table.map_region_name`,
            render: (n) =>
                getTitleByLanguage({
                    titleRu: n.addressDetailsRu,
                    titleKg: n.addressDetailsKg,
                }),
        },
        {
            label: t`form.label.importance`,
            render: (n) => n.importance,
        },
        {
            label: t`table.type`,
            render: (n) => t(`table.${n.mapPointType}`),
        },
        {
            label: t`actions.title`,
            render: (n) => (
                <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
            ),
        },
    ];

    return (
        <>
            <AppTable<MapResponseType>
                headCells={headCells}
                doubleClickPath={(n) =>
                    navigate(`/map_point/${n.id}/${i18n.language}`)
                }
                searchApiUrl={Path.Map.search}
                extraFilter={filter}
                sorting={{ sortBy: "IMPORTANCE", sortDirection: "ASC" }}
            />
            <CustomModal
                labelSubmit={"delete"}
                open={open}
                handleClose={handleClose}
                onClick={handleDelete}
            >
                <Title
                    order={3}
                    fw={400}
                    mt="5px"
                    mb="2rem"
                    style={{ textAlign: "center" }}
                >
                    {t`button.delete`}
                </Title>
            </CustomModal>
        </>
    );
};
