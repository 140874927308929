import { Box, Group, Image, Text } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { useTranslation } from "react-i18next";
import { ChangeLanguage } from "src/components/change-language";
import { ProfileActions } from "./profile-actions";

export const Navbar = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    return (
        <Box h={60} className={classes.navbar}>
            <Group gap={3} display="flex">
                <Box mr={10}>
                    <Image width={20} height={60} src="/assets/logo.svg" />
                </Box>
                <Text
                    display={{ base: "none", md: "block" }}
                    c="primaryDark"
                    fz={{ base: 14, md: 18, lg: 24 }}
                    className={classes.text}
                >
                    {t("titles.logo")}
                </Text>
            </Group>
            <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ChangeLanguage />
                <ProfileActions />
            </Box>
        </Box>
    );
};

const useStyles = createStyles((theme) => ({
    navbar: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0 2rem",
        boxShadow: "2px 2px 10px rgba(4, 15, 34, 0.34)",
        borderBottom: "none",
        backgroundColor: theme.colorScheme === "dark" ? "#25272D" : "#fff",
    },
    text: {
        fontWeight: 600,
    },
}));
