import { Box, Title } from "@mantine/core";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppTable, CustomModal, MenuDropDown } from "src/components";
import { IS_MINUS_ONE } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { useDiagramDeleteMutation } from "src/store";
import { HeadCell } from "src/types";
import { Path, notify } from "src/utils";

type Props = {
    parentId?: number | null;
    opened: boolean;
    filter?: DiagramFilterType;
};

export const DiagramTable = ({ filter, opened, parentId }: Props) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<number | null>();

    const navigate = useNavigate();
    const [diagramDelete] = useDiagramDeleteMutation();

    const handleOpen = (id: number) => {
        setOpen(true);
        setId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await diagramDelete(id);
            notify(true, t("notify.deleted"));
        } catch (error) {
            notify(false);
        }
        setOpen(false);
    };

    const actions = (id: number) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/diagram/${id}/edit-diagram`,
        },
        {
            icon: IconEye,
            title: t`actions.view`,
            to: `/diagram/${id}`,
        },
        {
            icon: IconTrash,
            title: t`actions.delete`,
            onClick: () => handleOpen(id),
        },
    ];

    const headCells: HeadCell<DiagramResponseType>[] = [
        {
            label: t`table.ID`,
            render: (n) => n.id,
        },
        {
            label: t`table.order`,
            render: (n) => n.order || 200,
        },
        {
            label: t`table.type`,
            render: (n) => getTitleByLanguage(n.diagramType),
        },
        {
            label: t`table.diagram_name`,
            render: (n) => getTitleByLanguage(n),
        },
        {
            label: t`table.year`,
            render: (n) => n.year,
        },
        {
            label: t`table.diagramQuarter`,
            render: (n) => t(`select.${n.diagramQuarter}`),
        },
        {
            label: t`actions.title`,
            render: (n) => (
                <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
            ),
        },
    ];
    const defalutFilter = {
        diagramSpecificationId:
            parentId !== IS_MINUS_ONE ? parentId : undefined,
    };

    const defaultSorting = {
        sortBy: "ID",
        sortDirection: "DESC",
    };

    return (
        <Box style={{ marginLeft: opened ? 420 : 0, transition: "0.7s" }}>
            <AppTable<DiagramResponseType>
                headCells={headCells}
                doubleClickPath={(n) =>
                    navigate(`/diagram/${n.id}/${i18n.language}`)
                }
                defaultFilter={defalutFilter}
                sorting={defaultSorting}
                searchApiUrl={Path.Diagram.search}
                extraFilter={filter}
            />
            <CustomModal
                labelSubmit={"delete"}
                open={open}
                handleClose={handleClose}
                onClick={handleDelete}
            >
                <Title
                    order={3}
                    fw={400}
                    mt="5px"
                    mb="2rem"
                    style={{ textAlign: "center" }}
                >
                    {t`button.delete`}
                </Title>
            </CustomModal>
        </Box>
    );
};
