import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useLocalStorage } from "../hooks";

export const RequireAuth = () => {
    const profile = useLocalStorage<Profile>("profile").getItem();
    const location = useLocation();

    if (!profile) {
        return (
            <Navigate
                to="/"
                state={{
                    from: location.pathname,
                }}
                replace
            />
        );
    }

    return <Outlet />;
};
