import { Path } from "src/utils";
import { apiSlice } from "../../apiSlice";

export const profileApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        updatePassword: builder.mutation<unknown, PasswordForm>({
            query: (data) => {
                return {
                    url: Path.Profile.passwordUpdate,
                    method: "PUT",
                    body: {
                        newPassword: data.newValue,
                        oldPassword: data.oldValue,
                    },
                };
            },
        }),
    }),
});

export const { useUpdatePasswordMutation } = profileApiSlice;
