import { Accordion, Box, Menu, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { getTitleByLanguage } from "src/locales";
import styles from "./styles.module.scss";

interface IItemProps {
    item: DiagramSpecificResponseType;
    handleMouseEnter: () => void;
    handleMouseLeave: () => void;
    isHovered: number;
    onChange: (e: number | null) => void;
    value?: number | null;
}

export const SelectDropdownItems = ({
    item,
    handleMouseEnter,
    handleMouseLeave,
    isHovered,
    onChange,
    value,
}: IItemProps) => {
    const largeScreen = useMediaQuery(`(max-width: 768px)`);
    const activeParent = item.diagramSpecificationDtoList?.find(
        (el) => el.id === value
    )?.id;
    const changeCategoryHandler = (id: number | null) => () => {
        onChange(id);
    };
    if (largeScreen) {
        return (
            <Accordion
                px={15}
                className={styles.accordion}
                chevron={
                    <IconChevronDown size={18} color="var(--primaryDark)" />
                }
            >
                <Accordion.Item
                    className={styles.accordion_item}
                    key={item.id}
                    value={item.id.toString()}
                >
                    <Accordion.Control
                        className={styles.accordion_item_control}
                    >
                        <Text
                            fz={{ base: 14, xs: 16 }}
                            c={activeParent ? "green" : "primaryDark"}
                        >
                            {getTitleByLanguage(item)}
                        </Text>
                    </Accordion.Control>
                    <Accordion.Panel className={styles.accordion_panel}>
                        {item.diagramSpecificationDtoList?.map(
                            (childrenItem) => (
                                <Box
                                    fz={{ base: 14, xs: 16 }}
                                    className={styles.categories_item}
                                    key={childrenItem.id}
                                    onClick={() => {
                                        handleMouseLeave();
                                        const childrenId = childrenItem.id;
                                        onChange(
                                            childrenId === value
                                                ? null
                                                : childrenId
                                        );
                                    }}
                                >
                                    {getTitleByLanguage(childrenItem)}
                                </Box>
                            )
                        )}
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        );
    }
    return (
        <Menu.Item
            className={styles.menu_item}
            bg="none"
            p={0}
            c="primaryDark"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Menu width={350} trigger="hover" position="right">
                <Menu.Target>
                    <Box
                        onClick={changeCategoryHandler(
                            item.diagramSpecificationDtoList?.[0]?.id || null
                        )}
                        className={styles.select_item}
                        c={activeParent ? "green" : "primaryDark"}
                    >
                        {getTitleByLanguage(item)}
                    </Box>
                </Menu.Target>
                {item.id === isHovered &&
                    item?.diagramSpecificationDtoList?.length && (
                        <Box className={styles.select_categories}>
                            {item.diagramSpecificationDtoList?.map(
                                (childrenItem) => (
                                    <Box
                                        className={styles.categories_item}
                                        key={childrenItem.id}
                                        c={
                                            childrenItem?.id === value
                                                ? "green"
                                                : "none"
                                        }
                                        onClick={changeCategoryHandler(
                                            childrenItem.id === value
                                                ? null
                                                : childrenItem.id
                                        )}
                                    >
                                        {getTitleByLanguage(childrenItem)}
                                    </Box>
                                )
                            )}
                        </Box>
                    )}
            </Menu>
        </Menu.Item>
    );
};
