import { ApexOptions } from "apexcharts";
import ru from "apexcharts/dist/locales/ru.json";
import { diagramColors } from "src/constants";
import { getTitleByLanguage } from "src/locales";

export const generateRowOptions = (data: DataType, withPercentage = true, isSmall = false) => {
    const title = getTitleByLanguage(data);
    const nameValue = data?.diagramFields
        .filter((el) => el.fieldValue)
        .map((field) => ({
            name: getTitleByLanguage(field),
            data: field.fieldValue,
        }));
    const series = {
        name: title,
        data: nameValue.map((el) => el.data),
    }
    const defaultTotal = nameValue?.map(el => el.data)?.reduce((acc, el) => acc + el, 0)

    const options: ApexOptions = {
        colors: diagramColors,
        series: [series],
        chart: {
            type: "bar",
            height: 450,
            locales: [ru],
            defaultLocale: "ru",
            redrawOnParentResize: true,
            toolbar: {
                offsetY:-37,
                autoSelected: "pan",
                export: {
                    csv: {
                        filename: title,
                    },
                    png: {
                        filename: title,
                    },
                    svg: {
                        filename: title,
                    },
                },
            },
            zoom: {
                enabled: true,
                type: "y",
                autoScaleYaxis: true,
                zoomedArea: {
                    fill: {
                        color: "#90CAF9",
                        opacity: 0.4,
                    },
                    stroke: {
                        color: "#0D47A1",
                        opacity: 0.4,
                        width: 1,
                    },
                },
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 2,
                horizontal: true,
                distributed: true,
                barHeight: "90%",
            },
        },
        dataLabels: {
            enabled: !isSmall,
            textAnchor: "middle",
            style: {
                fontSize: "16px",
                colors: ["black"],
                fontWeight: 300,
            },
        },
        grid: {
            column: {
                colors: ["var(--primary)", "var(--weak-gray)"],
            },
        },
        xaxis: {
            categories: nameValue.map((el) => el.name),
            type: "category",
        },
        tooltip: {
            marker: {
                show: true,
                fillColors: diagramColors,
            },
            custom: ({ seriesIndex, dataPointIndex, w }) => {
                const value = nameValue?.[dataPointIndex]?.data
                const name = nameValue?.[dataPointIndex]?.name
                const total = data.totalPercentage || defaultTotal
                const percentage = ((value / total) * 100).toFixed(2)
                return (
                    `<div style="padding: 0px; max-width: 300px;">
                        <div 
                        style="padding: 5px 16px; background: #0A164F; max-width: 300px" >
                            <h3 style="font-weight: 500;
                                font-size: 14px; color: white; 
                                line-height: 14px; display: inline-block; word-wrap: break-word; text-align: start; white-space: pre-line; ">
                            ${title}
                            </h3>
                        </div>
                        <div style="background: white;display: flex; align-items: center; flex-wrap: wrap;
                        justify-content: space-between; width: 100%; gap: 6px; padding: 3px 16px;">
                            <div style="display: flex; align-items: center; gap: 6px;">
                                <span style="color: green; font-size: 30px; line-height">•</span>
                                <p style="text-align: start; margin-top: -10px; white-space: pre-line; word-wrap: break-word; color: black;">
                                ${name}
                                </p>
                            </div>
                            <div style="display: flex; align-items: center; gap: 6px; ">
                                <p style="font-weight: 500; font-size: 15px; color: black; word-wrap: white-space: pre-line; break-word;">
                                ${value.toLocaleString()}
                                </p> 
                                ${withPercentage ?
                        `<span style="color: #0A164F">|</span>
                                <p style="font-weight: 500; color: #0A164F; font-size: 15px;">${percentage}%</p>` : ""}
                            </div>
                        </div>
                    <div>`
                )
            },
            followCursor: true,
        },
        responsive: [
            {
                breakpoint: 576,
                options: {
                    yaxis: { labels: { show: false } },
                    dataLabels: {
                        enabled: true,
                    },
                    xaxis: {
                        labels: {
                            show: false,
                        },
                    },
                },
            },
        ],
    };

    return { options };
};
