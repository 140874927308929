import { Accordion, Button, Flex, Menu, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getTitleByLanguage } from "src/locales";
import { useSearchPageMutation } from "src/store";
import { getJsonFiles } from "src/utils";
import styles from "./styles.module.scss";

interface IPorps {
    isBurger?: boolean;
}

export const PagesList = ({ isBurger = false }: IPorps) => {
    const { i18n } = useTranslation();
    const [pages, setPages] = useState<PageListType[]>();
    const [currentData, setCurrentData] = useState<PageListType[]>();
    const xlargeScreen = useMediaQuery("(max-width: 1600px)");
    const [searchPages] = useSearchPageMutation();
    const fetchSearchPages = async () => {
        try {
            const res = await searchPages({
                filter: {},
                pageRequest: {
                    page: 0,
                    limit: 100,
                },
            });
            if ("data" in res) {
                const response = res.data.content
                    ?.filter((el) => !el.parent)
                    ?.map((el) => ({
                        id: el.id,
                        titleKg: el.titleKg,
                        titleRu: el.titleRu,
                        titleEn: el.titleEn,
                        position: `position/${el.id}`,
                    }))
                    ?.filter(
                        (el) => el.id !== 107 && el.id !== 108 && el.id !== 123
                    );
                await getJsonFiles("default-pages").then(
                    (data: PageListType[]) => {
                        const result: PageListType[] = data?.map((el) =>
                            el.id === 3
                                ? {
                                    ...el,
                                    children: el.children
                                        ? [...el.children, ...response]
                                        : [...response],
                                }
                                : el
                        );
                        setPages(result);
                    }
                );
            }
        } catch (error) {
            console.error("error in fetching page parents");
        }
    };

    useEffect(() => {
        if (isBurger) {
            if (xlargeScreen) {
                setCurrentData(pages);
            } else {
                setCurrentData((pages || []).filter((el) => el.isBurger));
            }
        } else {
            setCurrentData(pages?.filter((el) => !el.isBurger));
        }
    }, [xlargeScreen, pages]);
    useEffect(() => {
        fetchSearchPages();
    }, []);
    return (
        <Flex
            direction={isBurger ? "column" : "row"}
            pos="relative"
            style={{ zIndex: 50, overflow: "scroll" }}
            mt={4}
            gap={!isBurger ? "1.6rem" : undefined}
        >
            {currentData?.map((items) => {
                if (
                    isBurger &&
                    items.children &&
                    !xlargeScreen &&
                    !items.saveAccordion
                ) {
                    return (
                        <>
                            {items?.children.map((childrenItem) => {
                                return (
                                    <Link
                                        key={childrenItem.id}
                                        to={`/${childrenItem?.position}/${i18n.language}`}
                                    >
                                        <Text my={10}
                                            fz={18}
                                            style={{
                                                whiteSpace: "break-spaces",
                                            }}
                                            className={styles.nav_title}
                                        >
                                            {getTitleByLanguage(childrenItem)}
                                        </Text>
                                    </Link>
                                );
                            })}
                        </>
                    );
                }
                if (!items.children) {
                    return (
                        <Link
                            className={isBurger ? styles.menu_item : ""}
                            to={`/${items.position}/${i18n.language}`}
                            key={items.id}
                        >
                            <Button
                                variant="simple"
                                p={0}
                                m={0}
                                bg="none"
                                fw={400}
                                fz={18}
                                lh={1.1}
                                ta="start"
                            >
                                <span style={{ whiteSpace: "pre-line" }}>
                                    {items?.menuItem &&
                                        getTitleByLanguage(items)}
                                </span>
                            </Button>
                        </Link>
                    );
                }
                return (
                    <Menu key={items?.id}>
                        {isBurger ? (
                            <Accordion
                                chevron={
                                    <IconChevronDown
                                        size={18}
                                        color="var(--primary)"
                                    />
                                }
                            >
                                <Accordion.Item
                                    className={styles.menu_item}
                                    value={items.id.toString()}
                                >
                                    <Accordion.Control p={0}>
                                        <Title
                                            lh={0}
                                            c="primary"
                                            fw={400}
                                            fz={18}
                                            style={{
                                                whiteSpace: "break-spaces",
                                            }}
                                        >
                                            {getTitleByLanguage(items)}
                                        </Title>
                                    </Accordion.Control>
                                    <Accordion.Panel mb={10}>
                                        <Flex
                                            direction="column"
                                            align="start"
                                            gap={15}
                                        >
                                            {items?.children.map(
                                                (childrenItem) => {
                                                    return (
                                                        <Link
                                                            key={
                                                                childrenItem.id
                                                            }
                                                            to={`/${childrenItem?.position}/${i18n.language}`}
                                                        >
                                                            <Text
                                                                className={
                                                                    styles.nav_title
                                                                }
                                                            >
                                                                {getTitleByLanguage(
                                                                    childrenItem
                                                                )}
                                                            </Text>
                                                        </Link>
                                                    );
                                                }
                                            )}
                                        </Flex>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                        ) : (
                            <>
                                <Menu.Target>
                                    <Button
                                        c="primary"
                                        p={0}
                                        m={0}
                                        bg="none"
                                        fw={400}
                                        fz={18}
                                        variant="simple"
                                        rightSection={
                                            <IconChevronDown size={18} />
                                        }
                                    >
                                        {getTitleByLanguage(items)}
                                    </Button>
                                </Menu.Target>
                                <Menu.Dropdown
                                    mt={12}
                                    bg="rgba(var(--primary-dark), 0.9)"
                                    maw={220}
                                    px="16px"
                                    pt="24px"
                                    style={{
                                        blur: "4px",
                                        border: "none",
                                        borderRadius: "4px",
                                    }}
                                >
                                    {items?.children.map((childrenItem) => {
                                        return (
                                            <Link
                                                key={childrenItem?.id}
                                                to={`/${childrenItem?.position}/${i18n.language}`}
                                            >
                                                <Menu.Item py={10}>
                                                    <Text
                                                        className={
                                                            styles.nav_title
                                                        }
                                                    >
                                                        {getTitleByLanguage(
                                                            childrenItem
                                                        )}
                                                    </Text>
                                                </Menu.Item>
                                            </Link>
                                        );
                                    })}
                                </Menu.Dropdown>
                            </>
                        )}
                    </Menu>
                );
            })}
        </Flex>
    );
};
