import { useEffect } from "react";
import i18n from "src/locales/locales";

interface SeoMetadataProps {
    title: string;
    description: string;
}
export const useUpdateHead = ({ title, description }: SeoMetadataProps) => {
    useEffect(() => {
        const currentUrl = window.location.href;
        const removedLanguageUrl = currentUrl.slice(0, -2);
        const slicedTitle =
            title.length > 60 ? `${title.slice(0, 60)}...` : title;
        document.documentElement.setAttribute("lang", i18n.language);
        const slicedDescription =
            description.length > 150
                ? `${description.slice(0, 150)}...`
                : description;

        const addOrUpdateMetaTags = () => {
            const titleElement = document.querySelector("title");
            if (titleElement) {
                titleElement.textContent = slicedTitle;
            }
            const titleTag = document.getElementById("title");
            if (titleTag) {
                titleTag.setAttribute("content", slicedTitle);
            }

            const alternateRuTag = document.getElementById("alternateRu");
            if (alternateRuTag) {
                alternateRuTag.setAttribute("href", `${removedLanguageUrl}ru`);
            }
            const alternateKgTag = document.getElementById("alternateKg");
            if (alternateKgTag) {
                alternateKgTag.setAttribute("href", `${removedLanguageUrl}kg`);
            }
            const alternateDefaultTag =
                document.getElementById("alternateDefault");
            if (alternateDefaultTag) {
                alternateDefaultTag.setAttribute(
                    "href",
                    `${removedLanguageUrl}kg`
                );
            }

            const canonicalTag = document.getElementById("canonical");
            if (canonicalTag) {
                canonicalTag.setAttribute("href", currentUrl);
            }
            const descriptionTag = document.getElementById("description");
            if (descriptionTag) {
                descriptionTag.setAttribute("content", slicedDescription);
            }

            const twitterTitleTag = document.getElementById("twitterTitle");
            if (twitterTitleTag) {
                twitterTitleTag.setAttribute("content", slicedTitle);
            }
            const twitterDescriptionTag =
                document.getElementById("twitterDescription");
            if (twitterDescriptionTag) {
                twitterDescriptionTag.setAttribute(
                    "content",
                    slicedDescription
                );
            }

            const ogUrlTag = document.getElementById("ogUrl");
            if (ogUrlTag) {
                ogUrlTag.setAttribute("content", currentUrl);
            }
            const ogTitleTag = document.getElementById("ogTitle");
            if (ogTitleTag) {
                ogTitleTag.setAttribute("content", slicedTitle);
            }
            const ogDescriptionTag = document.getElementById("ogDescription");
            if (ogDescriptionTag) {
                ogDescriptionTag.setAttribute("content", slicedDescription);
            }
        };
        addOrUpdateMetaTags();
    }, [title, description]);
};
