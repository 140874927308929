import { showNotification } from "@mantine/notifications";
import { getTitleByLanguage } from "src/locales";

type notifyType = (
    isSuccess?: boolean,
    message?: string | number
) => Promise<void>;

export const notify: notifyType = async (isSuccess = false, message) => {
    if (!message) {
        message = getTitleByLanguage({
            titleRu: "Повторите попытку позже или сообщите нам ошибку",
            titleKg: "Бираздан кийин аракет кылыңыз же бизге кайрылыңыз",
        });
    }
    showNotification({
        title: !isSuccess
            ? getTitleByLanguage({
                titleRu: "Ошибка",
                titleKg: "Катачылык кетти",
            })
            : getTitleByLanguage({
                titleRu: "Успешно",
                titleKg: "Ийгиликтүү аткарылды",
            }),
        message: message,
        color: isSuccess ? "#90EE90" : "orange",
        bg: "secondaryDark",
        styles: () => ({
            title: {
                color: isSuccess ? "#90EE90" : "orange",
            },
            description: {
                color: "white",
            },
        }),
    });
};
