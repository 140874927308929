import {
    ActionIcon,
    Box,
    Flex,
    Group,
    Paper,
    ScrollArea,
    Text,
    Transition,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { IconRefresh, IconSquarePlus } from "@tabler/icons-react";
import { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { IS_MINUS_ONE } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { StructureChildrens } from "src/types/data-models/global";
import { StructureGroup } from "./structure-group";

type Data = {
    id: number;
    key: number;
    titleRu: string;
    titleKg: string;
    titleEn: string;
    childrens?: StructureChildrens[];
    onClick?: MouseEventHandler<HTMLDivElement>;
};

type Props = {
    data?: Data[];
    title: string;
    width?: number;
    open: boolean;
    button?: boolean;
    type?: string;
    setParentId: (id: number | null) => void;
    setChildId?: (id: number | null) => void;
};

export const Structure = ({
    data,
    title,
    width = 400,
    open,
    setParentId,
    setChildId,
    button,
}: Props) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const [active, setActive] = useState<string>("");
    const [, setOpenM] = useState<boolean>(false);

    return (
        <>
            <Transition
                mounted={open}
                transition="scale-x"
                duration={700}
                timingFunction="ease"
            >
                {(styles) => (
                    <Paper
                        shadow="sm"
                        py="xs"
                        className={classes.paper}
                        style={{
                            ...styles,
                            position: "absolute",
                            top: 5,
                            left: 10,
                            right: 0,
                            width: width,
                        }}
                    >
                        <ScrollArea className={classes.scroll}>
                            <Group
                                m="sm"
                                display="flex"
                                style={{ justifyContent: "space-between" }}
                            >
                                <Box
                                    onClick={() => setParentId(1)}
                                    display="flex"
                                >
                                    <Text c="primaryDark" ml="sm" fw={600}>
                                        {title}
                                    </Text>
                                    <ActionIcon
                                        title={t`actions.reset`}
                                        variant="subtle"
                                        style={{
                                            color: "rgba(0, 41, 111, 0.95)",
                                        }}
                                        onClick={() => {
                                            setParentId(IS_MINUS_ONE);
                                            setChildId &&
                                                setChildId(IS_MINUS_ONE);
                                            setActive("");
                                        }}
                                    >
                                        <IconRefresh size={20} />
                                    </ActionIcon>
                                </Box>
                                {button && (
                                    <ActionIcon
                                        title={t`button.add.type`}
                                        variant="subtle"
                                        style={{
                                            color: "rgba(0, 41, 111, 0.95)",
                                        }}
                                        onClick={() => setOpenM(true)}
                                    >
                                        <IconSquarePlus size={25} />
                                    </ActionIcon>
                                )}
                            </Group>
                            <Flex direction="column" gap={10} p="md">
                                {data?.map((item) => (
                                    <StructureGroup
                                        key={item.id}
                                        active={active}
                                        button={button}
                                        setActive={setActive}
                                        parent={{
                                            id: item.id,
                                            title: getTitleByLanguage(item),
                                        }}
                                        child={item.childrens}
                                        setParentId={setParentId}
                                        setChildId={setChildId}
                                        onClick={item.onClick}
                                    />
                                ))}
                            </Flex>
                        </ScrollArea>
                    </Paper>
                )}
            </Transition>
        </>
    );
};

const useStyles = createStyles({
    paper: {
        minHeight: "37.8rem",
        borderRadius: "8px",
    },
    scroll: {
        height: "36.5rem",
        overflowY: "auto",
        overflowX: "hidden",
        "& svg": {
            borderRadius: 5,
            "&:hover": {
                backgroundColor: "#f2f2f2",
            },
        },
    },
});
