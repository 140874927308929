import { Box, Paper } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { CustomLoader, TextInfo } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useGetInfoBlockQuery } from "src/store/reducers";

type Props = {
    infoBlockId: string;
};

export const InfoBlockDetails = ({ infoBlockId }: Props) => {
    const { t } = useTranslation();

    const { data: infoBlockData, isLoading } = useGetInfoBlockQuery(
        infoBlockId,
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const organCard = [
        {
            label: t`data.news.title`,
            value: getTitleByLanguage(infoBlockData),
        },
    ];

    return (
        <Paper shadow="sm" p="xl">
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <Paper shadow="sm" p="xl">
                    <TextInfo
                        data={organCard}
                        display={true}
                        spanFirst={4}
                        spanTwo={8}
                    />
                    <Box mt={30}></Box>
                </Paper>
            }
        </Paper>
    );
};
