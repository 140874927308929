import { BackgroundImage, Button, Flex, Paper, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";

interface PanelCardProps {
    card: Partial<PageType>;
    path?: string;
}
export const PersonnelCard = ({ card, path }: PanelCardProps) => {
    const { t } = useTranslation();

    return (
        <Paper h={270} w={400} px={24} component="a" href={path}>
            <BackgroundImage
                style={{
                    backgroundSize: "70% 140%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top left",
                }}
                src="/assets/logo-for-cards.svg"
            >
                <Flex
                    bg="rgba(255,255,255,0.5)"
                    direction="column"
                    justify="space-between"
                    align="end"
                    h="264px"
                    pb={16}
                >
                    <Title lh={1.6} order={3} fw={400} c="weakDark">
                        {getTitleByLanguage(card)}
                    </Title>
                    {path ? (
                        <Button
                            className="dark_button_hover"
                            bg="primaryDark"
                            c="primary"
                        >
                            {t("statics.watch")}
                        </Button>
                    ) : null}
                </Flex>
            </BackgroundImage>
        </Paper>
    );
};
