import { Grid } from "@mantine/core";
import { useMemo } from "react";
import { DIAGRAM_INFO_BLOCK_REFERENCE } from "src/constants";
import { useGetAllByTypeCodeQuery } from "src/store";
import { TypeCodesEnum } from "src/types";
import { FirstInfoBlock, MapInfoBlock, SecondInfoBlock } from "./modules";

export const StatisticBottomSection = () => {
    const { data: typeCodes } = useGetAllByTypeCodeQuery(TypeCodesEnum.INFO_BLOCK_TYPE)
    const topStatistId = useMemo(() => {
        return typeCodes?.find(el => el.titleEn === DIAGRAM_INFO_BLOCK_REFERENCE.TOP_STATISTIC)?.id
    }, [typeCodes])

    const bottomStatisticId = useMemo(() => {
        return typeCodes?.find(el => el.titleEn === DIAGRAM_INFO_BLOCK_REFERENCE.BOTTOM_STATISTIC)?.id
    }, [typeCodes])

    return (
        <Grid>
            <Grid.Col span={{ base: 12, md: 5 }}>
                <FirstInfoBlock topStatistId={topStatistId} />
                <SecondInfoBlock bottomStatisticId={bottomStatisticId} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 7 }}>
                <MapInfoBlock />
            </Grid.Col>
        </Grid>

    )
}
