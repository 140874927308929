import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const constitutionApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getConstitution: builder.query<ConstResponseType, string>({
            query: (id) => ({
                url: Path.Constitution.get(id),
            }),
        }),
        searchConstitution: builder.mutation<
            ConstitutionSearchResponseDto,
            ConstitutionSearchRequestDto
        >({
            query: (postData) => ({
                url: Path.Constitution.search,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
        }),

        getSection: builder.query<ConsSectionType, string>({
            query: (id) => ({
                url: Path.Constitution.getSection(id),
            }),
        }),
        searchSection: builder.mutation<
            ConsSectionSearchResponseDto,
            ConsSectionSearchRequestDto
        >({
            query: (postData) => ({
                url: Path.Constitution.searchSection,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
        }),
        createConstitution: builder.mutation<
            ConstResponseType,
            ConstRequestType
        >({
            query: (body) => {
                return {
                    url: Path.Constitution.create,
                    method: "POST",
                    body,
                };
            },
        }),
        updateConstitution: builder.mutation<
            ConstResponseType,
            ConstUpdateRequestType
        >({
            query: (body) => {
                return {
                    url: Path.Constitution.update,
                    method: "PUT",
                    body,
                };
            },
        }),
        getChapter: builder.query<ConsChapterType, string>({
            query: (id) => ({
                url: Path.Constitution.getChapter(id),
            }),
        }),
        searchChapter: builder.mutation<
            ConsChapterSearchResponseDto,
            ConsChapterSearchRequestDto
        >({
            query: (postData) => ({
                url: Path.Constitution.searchChapter,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
        }),
    }),
});

export const {
    useGetChapterQuery,
    useGetConstitutionQuery,
    useGetSectionQuery,
    useSearchChapterMutation,
    useSearchConstitutionMutation,
    useSearchSectionMutation,
    useCreateConstitutionMutation,
    useUpdateConstitutionMutation,
} = constitutionApiSlice;
