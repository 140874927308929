import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const appealApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createAppeal: builder.mutation<
            AppealResponseType,
            AppealRequestFormType
        >({
            query: ({ file, body }) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });
                formData.append("request", data);
                file &&
                    file.map(el => formData.append("file", el))

                return {
                    url: Path.OnlineAppeal.create,
                    method: "POST",
                    body: formData,
                };
            },
        }),
        getAllAppeal: builder.query<AppealResponseType[], unknown>({
            query: () => Path.OnlineAppeal.getAll,
        }),
        getAppeal: builder.query<AppealResponseType, string>({
            query: (id) => ({
                url: Path.OnlineAppeal.get(id),
            }),
        }),
        searchAppeals: builder.mutation<
            AppealResponseType[],
            OnlineAppealSearchType
        >({
            query: (postData) => ({
                url: Path.OnlineAppeal.search,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
        }),
    }),
});

export const {
    useGetAllAppealQuery,
    useCreateAppealMutation,
    useSearchAppealsMutation,
    useGetAppealQuery,
} = appealApiSlice;
