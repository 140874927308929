import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const createPageApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createContentPage: builder.mutation<ContentPageRes, ContentPageReq>({
            query: (body) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });
                formData.append("request", data);

                return {
                    url: Path.ContentPage.create,
                    method: "POST",
                    body: formData,
                };
            },
            invalidatesTags: ["CreatePage"],
        }),
        updateContentPage: builder.mutation<
            ContentPageRes,
            { id: number; body: Partial<ContentPageReq> }
        >({
            query: ({ id, body }) => ({
                url: Path.ContentPage.update(id),
                method: "PUT",
                body,
            }),
            invalidatesTags: ["CreatePage"],
        }),
        getContentPage: builder.query<ContentPageRes, string | undefined>({
            query: (id) => ({
                url: Path.ContentPage.get(id),
            }),
        }),
        getAllContentPage: builder.query<ContentPageRes, string | undefined>({
            query: (id) => ({
                url: Path.ContentPage.get(id),
            }),
        }),
        deleteContentPage: builder.mutation({
            query: (id) => ({
                url: Path.ContentPage.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["CreatePage"],
        }),
    }),
});

export const {
    useCreateContentPageMutation,
    useDeleteContentPageMutation,
    useGetAllContentPageQuery,
    useGetContentPageQuery,
    useUpdateContentPageMutation,
} = createPageApiSlice;
