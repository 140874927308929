import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "./apiSlice";
import { authReducer } from "./reducers";
import { breadcrumbsReducer } from "./reducers/breadcumbs-slice";
import { sidebarReducer } from "./reducers/sidebar-slice";

const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    sidebarStore: sidebarReducer,
    breadcrumbsStore: breadcrumbsReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: {
                warnAfter: 128,
            },
        }).concat([apiSlice.middleware]),
    devTools: false,
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
