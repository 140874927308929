import { Button, Grid, Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useTranslation } from "react-i18next";

interface Props {
    form: UseFormReturnType<
        ConsFormType,
        (values: ConsFormType) => ConsFormType
    >;
}

export const MainForm = ({ form }: Props) => {
    const { t } = useTranslation();
    const selectDto = [
        { value: "SECTION", label: t("statics.first-section") },
        { value: "CHAPTER", label: t("statics.first-chapter") },
    ];
    const selectNameDto = [
        "КОНСТИТУЦИЯ КЫРГЫЗСКОЙ РЕСПУБЛИКИ 1993 года",
        "КОНСТИТУЦИЯ КЫРГЫЗСКОЙ РЕСПУБЛИКИ 2010 года",
        "КОНСТИТУЦИЯ КЫРГЫЗСКОЙ РЕСПУБЛИКИ 2017 года",
        "КОНСТИТУЦИЯ КЫРГЫЗСКОЙ РЕСПУБЛИКИ 2021 года",
    ];
    return (
        <Grid>
            <Grid.Col span={12}>
                <Select
                    mt={15}
                    {...form.getInputProps("name")}
                    placeholder={t`form.label.constitution`}
                    onChange={(value) =>
                        value && form.setFieldValue("name", value)
                    }
                    required
                    error={form.errors.name}
                    data={selectNameDto}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <Select
                    mt={15}
                    {...form.getInputProps("type")}
                    placeholder={t`form.label.type`}
                    onChange={(value) =>
                        form.setFieldValue("type", value as any)
                    }
                    required
                    error={form.errors.type}
                    data={selectDto}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <Button
                    bg="primaryDark"
                    mt={20}
                    onClick={() =>
                        form.insertListItem("list", {
                            articles: "",
                            list: [],
                            contentKg: "",
                            contentRu: "",
                            nameKg: "",
                            nameRu: "",
                        })
                    }
                >
                    {t("button.add-more")}
                </Button>
            </Grid.Col>
        </Grid>
    );
};
