import { Box, Flex, Image, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const images = {
    ruXlImage: "/assets/structure/general/structure14401920.svg",
    ruMdImage: "/assets/structure/general/structure1024768.svg",
    ruXsImage: "/assets/structure/general/structureMobile.svg",
    kgXlImage: "/assets/structure/general/structure14401920Kg.svg",
    kgMdImage: "/assets/structure/general/structure1024768Kg.svg",
    kgXsImage: "/assets/structure/general/structureMobileKg.svg",
    ruXlOfficeImage: "/assets/structure/offices/structure14401920.svg",
    ruMdOfficeImage: "/assets/structure/offices/structure1024768.svg",
    ruXsOfficeImage: "/assets/structure/offices/structureMobile.svg",
    kgXlOfficeImage: "/assets/structure/offices/structure14401920Kg.svg",
    kgMdOfficeImage: "/assets/structure/offices/structure1024768Kg.svg",
    kgXsOfficeImage: "/assets/structure/offices/structureMobileKg.svg",
};

export const StructureModule = () => {
    const [activeSection, setActiveSection] = useState(1);
    const { t, i18n } = useTranslation();
    const mdScreen = useMediaQuery("(min-width: 568px)");
    const xlScreen = useMediaQuery("(min-width: 1440px)");
    const imageSrc =
        activeSection === 1
            ? xlScreen
                ? images.ruXlImage
                : mdScreen
                    ? images.ruMdImage
                    : images.ruXsImage
            : xlScreen
                ? images.ruXlOfficeImage
                : mdScreen
                    ? images.ruMdOfficeImage
                    : images.ruXsOfficeImage;

    const imageSrcKg =
        activeSection === 1
            ? xlScreen
                ? images.kgXlImage
                : mdScreen
                    ? images.kgMdImage
                    : images.kgXsImage
            : xlScreen
                ? images.kgXlOfficeImage
                : mdScreen
                    ? images.kgMdOfficeImage
                    : images.kgXsOfficeImage;

    return (
        <Box className="container" mih="100vh">
            <Title
                mb={20}
                c="primaryDark"
                fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}
            >
                {t("links.structure")}
            </Title>
            <Flex wrap="wrap" gap={8} mb={30}>
                <Text
                    className="box-shadow"
                    onClick={() => setActiveSection(1)}
                    bg={1 === activeSection ? "primaryDark" : "primary"}
                    c={1 !== activeSection ? "primaryDark" : "primary"}
                    style={{ cursor: "pointer" }}
                    p={"10px 16px"}
                >
                    {t("links.structure")}
                </Text>
                <Text
                    className="box-shadow"
                    onClick={() => setActiveSection(2)}
                    bg={2 === activeSection ? "primaryDark" : "primary"}
                    c={2 !== activeSection ? "primaryDark" : "primary"}
                    style={{ cursor: "pointer" }}
                    p={"10px 16px"}
                >
                    {t("links.offices")}
                </Text>
            </Flex>
            {activeSection === 2 ? (
                <Title
                    style={{ whiteSpace: "pre-line" }}
                    c="primaryDark"
                    ta="center"
                    lh={1.3}
                    fz={{ base: 18, sm: 20, md: 24 }}
                >
                    {t("descriptions.offices")}
                </Title>
            ) : null}
            {i18n.language === "ru" ? (
                <Image height="100%" width="100%" src={imageSrc} />
            ) : (
                <Image height="100%" width="100%" src={imageSrcKg} />
            )}
        </Box>
    );
};
