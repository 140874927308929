import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CustomSelectOpen } from "../forms/custom-select";

const maxWidth = 134;
const minWidth = 124;

export const ChangeLanguage = ({
    bg = "rgba(10, 22, 79, 0.9)",
}: {
    bg?: string;
}) => {
    const { pathname } = useLocation();
    const { i18n } = useTranslation();
    const changeLng = (lng: string) => {
        i18n.changeLanguage(lng);
        const currentPath = window.location.pathname;
        const newPath = currentPath.replace(/\/[a-zA-Z]*$/, `/${lng}`);
        if (!pathname.includes("erpp")) {
            window.history.replaceState({}, "", newPath);
        }
    };
    return (
        <CustomSelectOpen
            width={i18n.language === "ru" ? minWidth : maxWidth}
            bg={bg}
            dropdownWidth={140}
            onChange={(value) => changeLng(value === "Русский" ? "ru" : "kg")}
            value={i18n.language === "ru" ? "Русский" : "Кыргызча"}
            data={["Русский", "Кыргызча"]}
            defaultValue="Кыргызча"
            checkedIcon={false}
        />
    );
};
