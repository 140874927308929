import { Button, Grid, NumberInput } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconTrash } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { CustomInputText } from "src/components"

type Props = {
    fieldIndex: number
    valueIndex: number
    form: UseFormReturnType<
        DiagramRequestType,
        (values: DiagramRequestType) => DiagramRequestType
    >;
}

export const FieldValueItem = ({ fieldIndex, valueIndex, form }: Props) => {
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const isUpdatePage = pathname.includes("edit-diagram")

    const handleDeleteFieldValue = (index: number) => {
        form.removeListItem(`fieldRequest.${fieldIndex}.valueRequest`, index)
    }

    return (
        <Grid w="95%" ml="5%">
            <Grid.Col span={{ base: 12, md: 3 }}>
                <CustomInputText
                    {...form.getInputProps(`fieldRequest.${fieldIndex}.valueRequest.${valueIndex}.titleKg`)}
                    label={t`form.label.titleKg`}
                    formData={form.values.fieldRequest?.[fieldIndex]?.valueRequest?.[valueIndex]?.titleKg || ""}
                />
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 3 }}>
                <CustomInputText
                    {...form.getInputProps(`fieldRequest.${fieldIndex}.valueRequest.${valueIndex}.titleRu`)}
                    label={t`form.label.titleRu`}
                    formData={form.values.fieldRequest?.[fieldIndex]?.valueRequest?.[valueIndex]?.titleRu || ""}
                />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 3 }}>
                <CustomInputText
                    {...form.getInputProps(`fieldRequest.${fieldIndex}.valueRequest.${valueIndex}.titleEn`)}
                    label={t`form.label.titleEn`}
                    formData={form.values.fieldRequest?.[fieldIndex]?.valueRequest?.[valueIndex]?.titleEn || ""}
                />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 1.5 }}>
                <NumberInput
                    label={t`form.label.value`}
                    {...form.getInputProps(`fieldRequest.${fieldIndex}.valueRequest.${valueIndex}.value`)}
                    value={form.values.fieldRequest?.[fieldIndex]?.valueRequest?.[valueIndex]?.value || 0}
                    min={0}
                />
            </Grid.Col>
            {isUpdatePage ? null :
                <Grid.Col span={1}>
                    <Button
                        onClick={() => handleDeleteFieldValue(valueIndex)}
                        variant="simple">
                        <IconTrash color="red" />
                    </Button>
                </Grid.Col>}
        </Grid>
    )
}