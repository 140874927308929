import { Table, Text } from "@mantine/core";
import { getTitleByLanguage } from "src/locales";

interface TableRowsProps {
    item: {
        titleRu: string;
        titleKg: string;
        receptionDayRu: string;
        receptionDayKg: string;
        receptionTime: string;
    };
}

export const TableRows = ({ item }: TableRowsProps) => (
    <Table.Tr key={item.titleRu}>
        <Table.Td maw={{ base: 230, sm: 500 }}>
            <Text c="primaryDark" fz={{ base: 12, xs: 14, md: 16 }}>
                {getTitleByLanguage(item)}
            </Text>
        </Table.Td>
        <Table.Td>
            <Text
                c="primaryDark"
                style={{ whiteSpace: "pre-line" }}
                fz={{ base: 12, xs: 14, md: 16 }}
            >
                {getTitleByLanguage({
                    titleRu: item.receptionDayRu,
                    titleKg: item.receptionDayKg,
                })}
            </Text>
        </Table.Td>
        <Table.Td>
            <Text
                c="primaryDark"
                style={{ whiteSpace: "pre-line" }}
                fz={{ base: 12, xs: 14, md: 16 }}
            >
                {item.receptionTime}
            </Text>
        </Table.Td>
    </Table.Tr>
);
