import { Container, Grid, NumberInput, Paper, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, CustomInputText } from "src/components";
import { getTitleByLanguage } from "src/locales";
import {
    useDiagramSpecificCreateMutation,
    useDiagramSpecificSearchMutation,
    useDiagramSpecificUpdateMutation,
} from "src/store";
import { notify } from "src/utils";

export const DiagramCategoryForm = ({
    category,
}: {
    category?: DiagramSpecificType;
}) => {
    const { t, i18n } = useTranslation();
    const [createCategory] = useDiagramSpecificCreateMutation();
    const [updateCategory] = useDiagramSpecificUpdateMutation();
    const navigate = useNavigate();
    const [search, { data: categoriesData }] = useDiagramSpecificSearchMutation(
        {}
    );
    const getDiagramSpecific = async () => {
        try {
            search({
                filter: {
                    onlyParent: true,
                },
                pageRequest: {
                    limit: 100,
                    page: 0,
                },
            });
        } catch (error) {
            console.error("error");
        }
    };
    const categoriesId = categoriesData?.content
        ?.filter((item) => item?.id !== category?.id)
        .map((el) => ({
            value: `${el?.id}`,
            label: getTitleByLanguage(el),
        }));
    const form = useForm<DiagramSpecificType>({
        initialValues: {
            titleKg: category?.titleKg || "",
            titleRu: category?.titleRu || "",
            diagramSpecificationId: category?.diagramSpecificationId || null,
            order: category?.order || 30,
        },
        validate: {
            titleRu: (value) => !value && t("form.validation.required"),
            titleKg: (value) => !value && t("form.validation.required"),
        },
    });
    const handleSubmit = async (values: DiagramSpecificType) => {
        if (category) {
            try {
                await updateCategory({
                    id: category.id || 0,
                    body: values,
                }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/diagram-category");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createCategory(values).unwrap();
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/diagram-category");
            } catch {
                notify(false);
            }
        }
    };
    useEffect(() => {
        getDiagramSpecific();
    }, []);
    return (
        <Container size="100%" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 2 }}>
                            <NumberInput
                                label={t`form.label.importance`}
                                required
                                error={form.errors.order}
                                {...form.getInputProps("order")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                defaultValue={`${form.values.diagramSpecificationId}`}
                                value={`${form.values.diagramSpecificationId}`}
                                placeholder={t`form.label.diagram-specification-parent`}
                                {...form.getInputProps(
                                    "diagramSpecificationId"
                                )}
                                data={categoriesId || []}
                                onChange={(value) =>
                                    value &&
                                    form.setFieldValue(
                                        "diagramSpecificationId",
                                        parseInt(value)
                                    )
                                }
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleKg")}
                                label={t`form.label.titleKg`}
                                required
                                error={form.errors.titleKg}
                                formData={form.values.titleKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleRu")}
                                label={t`form.label.titleRu`}
                                required
                                error={form.errors.titleRu}
                                formData={form.values.titleRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                type="submit"
                                onClickCancel={() =>
                                    navigate(
                                        `/diagram-category/${i18n.language}`
                                    )
                                }
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
