import { Container, Grid, Paper } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, CustomDropzone, CustomInputText } from "src/components";
import {
    useCreateInterCoopFileMutation,
    useUpdateInterCoopFileMutation,
} from "src/store";
import { notify } from "src/utils";

type Props = {
    interCoopFile?: InterCoopFileResponseType;
};

export const InterCoopFileForm = ({ interCoopFile }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [createInterCoopFile] = useCreateInterCoopFileMutation();
    const [updateInterCoopFile] = useUpdateInterCoopFileMutation();

    const form = useForm<InterCoopFileRequestType>({
        initialValues: {
            attachment: null,
            titleRu: interCoopFile?.titleRu || "",
            titleKg: interCoopFile?.titleKg || "",
        },
        validate: {
            titleRu: (value?: string) =>
                !value && t("form.validation.required"),
            titleKg: (value?: string) =>
                !value && t("form.validation.required"),
        },
    });

    const handleSubmit = async (values: InterCoopFileRequestType) => {
        if (interCoopFile) {
            try {
                await updateInterCoopFile({
                    id: interCoopFile?.id,
                    body: values,
                }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/inter_coop_file");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createInterCoopFile(values).unwrap();
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/inter_coop_file");
            } catch {
                notify(false);
            }
        }
    };

    return (
        <Container size="xl" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleRu")}
                                label={t`form.label.titleRu`}
                                required
                                error={form.errors.titleRu}
                                formData={form.values.titleRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleKg")}
                                label={t`form.label.titleKg`}
                                required
                                error={form.errors.titleKg}
                                formData={form.values.titleKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomDropzone
                                label={t`form.label.attachment`}
                                error={form.errors.attachment}
                                {...form.getInputProps("attachment")}
                                accept={[MIME_TYPES.pdf, MIME_TYPES.docx]}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(
                                        `/inter_coop_file/${i18n.language}`
                                    )
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
