import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const interCoopFileApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createInterCoopFile: builder.mutation<
            InterCoopFileResponseType,
            InterCoopFileRequestType
        >({
            query: ({ attachment, ...body }) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });

                formData.append("request", data);
                attachment &&
                    attachment.map((file) =>
                        formData.append("attachment", file)
                    );

                return {
                    url: Path.InterCoopFile.create,
                    method: "POST",
                    body: formData,
                    cache: "no-cache",
                };
            },
        }),
        getInterCoopFile: builder.query<InterCoopFileResponseType, string>({
            query: (id) => ({
                url: Path.InterCoopFile.getById(id),
                method: "GET",
                cache: "no-cache",
            }),
        }),
        updateInterCoopFile: builder.mutation<
            InterCoopFileRequestType,
            { id: number; body: Partial<InterCoopFileRequestType> }
        >({
            query: ({ id, body }) => {
                const { attachment, ...values } = body;
                const formData = new FormData();
                const data = new Blob([JSON.stringify(values)], {
                    type: "application/json",
                });

                formData.append("request", data);
                attachment &&
                    attachment.map((file) =>
                        formData.append("attachment", file)
                    );
                return {
                    url: Path.InterCoopFile.update(id),
                    method: "PUT",
                    cache: "no-cache",
                    body: formData,
                };
            },
        }),
        searchInterCoopFile: builder.mutation<
            InterCoopSearchResponse,
            InterCoopSearchRequest
        >({
            query: (body) => ({
                url: Path.InterCoopFile.search,
                method: "POST",
                body,
                cache: "no-cache",
            }),
        }),
        deleteInterCoopFile: builder.mutation({
            query: (id) => ({
                url: Path.InterCoopFile.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["InterCoopFile"],
        }),
    }),
});

export const {
    useCreateInterCoopFileMutation,
    useDeleteInterCoopFileMutation,
    useSearchInterCoopFileMutation,
    useUpdateInterCoopFileMutation,
    useGetInterCoopFileQuery,
} = interCoopFileApiSlice;
