import { Box, Divider, Flex, Grid, Image, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BannerSection } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { usePageListAccordionSearchMutation } from "src/store";
import { getJsonFiles } from "src/utils";


const MILITARY_ID = 180

export const ProsecutorContactsModule = ({
    prosecutorId,
}: {
    prosecutorId: number;
}) => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0)
    const [data, setData] = useState<ProsecturContactsType>();
    const [militaryAddresses, setMilitaryAddresses] = useState<ProsecturContactsType[]>()

    const [searchPageData, { data: pageData }] = usePageListAccordionSearchMutation();

    const getPageData = async () => {
        try {
            await searchPageData({
                filter: {
                    pageListId: parseInt(`${prosecutorId}`),
                },
                pageRequest: {
                    limit: 100,
                    page: 0,
                },
            });
        } catch {
            console.error("error");
        }
    };

    useEffect(() => {
        getJsonFiles("prosecutor-contacts").then(
            (data: ProsecturContactsType[]) => {
                const res = data.find((el) => el.id === prosecutorId);
                setData(res);
            }
        );
        getJsonFiles("military-prokuratura").then(
            (data: ProsecturContactsType[]) => {
                setMilitaryAddresses(data);
            }
        );
        getPageData()
    }, [prosecutorId]);

    const getComponent = (prosecutorId: number) => {
        if (pageData?.totalElements) {
            if (active === 0) {
                return contactBody(prosecutorId)
            } else {
                return historyBody
            }
        } else {
            return contactBody(prosecutorId)
        }
    }

    const historyBody =
        <Flex>
            {pageData?.content?.map(el => (
                <Text c="primaryDark"
                    dangerouslySetInnerHTML={{
                        __html: getTitleByLanguage({ titleKg: el.contentKg, titleRu: el.contentRu })
                    }} />
            ))}
        </Flex>

    const contactBody = (prosecutorId: number) => {
        if (prosecutorId === MILITARY_ID) {
            return (
                <>
                    <ContactItem data={data} withDivider />
                    <>
                        {militaryAddresses?.map(el =>
                            <ContactItem key={el.id} data={el} withTitle withDivider />)}
                    </>
                </>
            )

        } else {
            return (
                <Grid w={{ base: "100%", lg: "80%" }}>
                    <Grid.Col span={{ base: 12, md: 4 }}>
                        <Title fw={400} c="primaryDark" order={3}>
                            {t("titles.phone-call")}:
                        </Title>
                        <Flex align="center" justify="start" gap={10}>
                            <Image w={30} src="/icons/phone-call.svg" />
                            <Title c="secondaryDark" order={4}>
                                {data?.phone}
                            </Title>
                        </Flex>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4 }}>
                        <Title fw={400} c="primaryDark" order={3}>
                            {t("titles.email")}:
                        </Title>
                        <Flex align="center" justify="start" gap={10}>
                            <Image w={30} src="/icons/email.svg" />
                            <Title c="secondaryDark" order={4}>
                                {data?.email}
                            </Title>
                        </Flex>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4 }}>
                        <Title fw={400} c="primaryDark" order={3}>
                            {t("titles.address")}:
                        </Title>
                        <Flex align="center" justify="start" gap={10}>
                            <Image w={30} src="/icons/navigation.svg" />
                            <Title c="secondaryDark" order={4}>
                                {getTitleByLanguage(data?.address)}
                            </Title>
                        </Flex>
                    </Grid.Col>
                </Grid>
            )
        }
    }


    return (
        <Box className="container">
            <Title c="secondaryDark" fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}>
                {getTitleByLanguage(data)}
            </Title>
            <Box mb={60} mih="40vh">
                {pageData?.totalElements ? <Flex my={10} mb={20} gap={15} wrap="wrap">
                    <Text
                        className="box-shadow"
                        onClick={() => setActive(0)}
                        bg={0 === active ? "primaryDark" : "primary"}
                        c={0 !== active ? "primaryDark" : "primary"}
                        style={{ cursor: "pointer" }}
                        p={"10px 16px"}
                    >
                        {t("titles.contacts")}
                    </Text>
                    <Text
                        className="box-shadow"
                        onClick={() => setActive(1)}
                        bg={1 === active ? "primaryDark" : "primary"}
                        c={1 !== active ? "primaryDark" : "primary"}
                        style={{ cursor: "pointer" }}
                        p={"10px 16px"}
                    >
                        {t("titles.histories")}
                    </Text>
                </Flex> : null}
                {getComponent(prosecutorId)}
            </Box>
            <BannerSection />
        </Box>
    );
};

type Props = {
    data?: ProsecturContactsType
    withTitle?: boolean
    withDivider?: boolean
}

const ContactItem = ({ data, withTitle = false, withDivider = false }: Props) => {
    const { t } = useTranslation()
    return (
        <Grid py={10} w={{ base: "100%", lg: "80%" }}>
            {withTitle ? <Grid.Col mb={-20} span={12}>
                <Title lh={1} fz={{ base: 18, md: 22 }} c="primaryDark">
                    {getTitleByLanguage(data)}
                </Title>
            </Grid.Col> : null}
            <Grid.Col span={{ base: 12, md: 4 }}>
                <Title fw={400} c="primaryDark" order={3}>
                    {t("titles.phone-call")}:
                </Title>
                <Flex align="center" justify="start" gap={10}>
                    <Image w={30} src="/icons/phone-call.svg" />
                    <Title c="secondaryDark" order={4}>
                        {data?.phone}
                    </Title>
                </Flex>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4 }}>
                <Title fw={400} c="primaryDark" order={3}>
                    {t("titles.email")}:
                </Title>
                <Flex align="center" justify="start" gap={10}>
                    <Image w={30} src="/icons/email.svg" />
                    <Title c="secondaryDark" order={4}>
                        {data?.email}
                    </Title>
                </Flex>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4 }}>
                <Title fw={400} c="primaryDark" order={3}>
                    {t("titles.address")}:
                </Title>
                <Flex align="center" justify="start" gap={10}>
                    <Image w={30} src="/icons/navigation.svg" />
                    <Title c="secondaryDark" order={4}>
                        {getTitleByLanguage(data?.address)}
                    </Title>
                </Flex>
            </Grid.Col>
            {withDivider ? <Divider c="primaryDark" size={1} w="100%" /> : null}
        </Grid>
    )
}