import { Button, Title } from "@mantine/core";
import { AppShellCustom, AppTable } from "src/components";

export default function ClosePagesFallback() {
    return (
        <AppShellCustom>
            <Title fz={{ base: 18, md: 32 }} fw="normal" mb="1rem">

            </Title>
            <Button.Group p={5}>
                <Button w={200}
                    variant="default"
                    radius={10}
                ></Button>
                <Button w={200}
                    variant="default"
                    radius={10}
                ></Button>
            </Button.Group>
            <AppTable<any>
                headCells={[{ label: "" }, { label: "" }, { label: "" }, { label: "" }, { label: "" }]}
                searchApiUrl={""}
            />
        </AppShellCustom>
    )
}