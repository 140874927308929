import { useTranslation } from "react-i18next";
import { LinksItem } from "./links-item";

export const Links = () => {
    const { t } = useTranslation();
    return (
        <LinksItem
            link="/magazine"
            title={t("titles.scientific-practical-journal")}
            img={"/assets/journal-photo.png"}
        />
    );
};
