import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const cardApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createCards: builder.mutation<CardType, CardInfoFormType>({
            query: ({ attachment, ...body }) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });
                formData.append("request", data);

                attachment &&
                    attachment?.map((file) =>
                        formData.append("attachment", file)
                    );

                return {
                    url: Path.CardInfo.create,
                    method: "POST",
                    body: formData,
                };
            },
        }),
        updateCard: builder.mutation<
            CardType,
            {
                id: number;
                attachment: File[] | null;
                body: Partial<CardInfoFormType>;
            }
        >({
            query: ({ id, attachment, ...body }) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(body.body)], {
                    type: "application/json",
                });
                formData.append("request", data);
                attachment &&
                    attachment?.map((file) =>
                        formData.append("attachment", file)
                    );
                return {
                    url: Path.CardInfo.update(id),
                    method: "PUT",
                    body: formData,
                };
            },
            invalidatesTags: ["Cards"],
        }),
        getAllCards: builder.query<CardType[], unknown>({
            query: () => Path.CardInfo.getAll,
            providesTags: ["Cards"],
        }),
        getCard: builder.query<CardType, string>({
            query: (id) => ({
                url: Path.CardInfo.getById(id),
            }),
            providesTags: ["Cards"],
        }),
        deleteCard: builder.mutation({
            query: (id) => ({
                url: Path.CardInfo.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["Cards"],
        }),
        searchCard: builder.mutation<
            CardInfoSearchResponseType,
            CardInfoSearchRequestType
        >({
            query: (postData) => ({
                url: Path.CardInfo.search,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
            invalidatesTags: ["Cards"],
        }),
        searchAllCards: builder.mutation<CardType[], CardInfoFilterType>({
            query: (postData) => ({
                url: Path.CardInfo.searchAll,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
            invalidatesTags: ["Cards"],
        }),
    }),
});

export const {
    useCreateCardsMutation,
    useDeleteCardMutation,
    useGetAllCardsQuery,
    useGetCardQuery,
    useSearchCardMutation,
    useUpdateCardMutation,
    useSearchAllCardsMutation,
} = cardApiSlice;
