import { Box, Center, Flex, Grid, Paper, Text, Title } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { CustomLoader } from "src/components/forms"
import { NewWorldMap } from "src/components/world-map"
import { DIAGRAM_REFERENCE_TYPES } from "src/constants"
import { getTitleByLanguage } from "src/locales"
import { useDiagramSearchMutation, useGetAllByTypeCodeQuery } from "src/store"
import { TypeCodesEnum } from "src/types"

export const MapInfoBlock = () => {
    const { t } = useTranslation()
    const [active, setActive] = useState<WorldMapJsonType | null>(null)
    const [activeSection, setActiveSection] = useState<number>(0)
    const { data: typeCodes } = useGetAllByTypeCodeQuery(TypeCodesEnum.NEWS_CATEGORY)
    const middleScreen = useMediaQuery("(max-width: 992px)")
    const typeId = useMemo(() => {
        return typeCodes?.find(el => el.titleEn === DIAGRAM_REFERENCE_TYPES.MAIN_PAGE)?.id
    }, [typeCodes])

    const [search, { data, isLoading }] = useDiagramSearchMutation()

    const getCountryDatas = async () => {
        if (typeId) {

            try {
                await search({
                    filter: {
                        diagramTypeId: typeId
                    },
                    pageRequest: {
                        page: 0,
                        limit: 2
                    }
                })
            } catch (error) {
                console.error("error")
            }
        }
    }


    const hanldeChangeCountry = (data: WorldMapJsonType) => () => {
        setActive(data)
    }
    const mapDatas = useMemo(() => {
        return data?.content?.[activeSection]?.diagramFields?.map(el => ({
            label: el.code,
            value: `${el.fieldValue}`
        }))
    }, [data, activeSection])
    const allData = useMemo(() => {
        return data?.content?.map(parent => parent?.diagramFields?.map(child => ({
            title: getTitleByLanguage(parent),
            code: child.code,
            value: `${child.fieldValue}`
        }))).flat()
    }, [data])
    useEffect(() => {
        getCountryDatas()
    }, [typeId])
    if (middleScreen) {
        return <Paper p={{ base: 8, sm: 16 }}>
            <Grid>
                <Grid.Col span={{ base: 12, x2s: 6 }}>
                    <Text onClick={() => setActiveSection(0)}
                        className="box-shadow"
                        bg={activeSection === 0 ? "primaryDark" : "primary"}
                        c={activeSection === 0 ? "primary" : "primaryDark"}
                        style={{ cursor: "pointer" }}
                        lh={1.2}
                        fz={{ base: 14, sm: 16 }}
                        p={{ base: "10px", sm: "10px 16px" }}
                    >
                        {getTitleByLanguage(data?.content?.[0])}
                    </Text>
                </Grid.Col>
                <Grid.Col span={{ base: 12, x2s: 6 }}>
                    <Text onClick={() => setActiveSection(1)}
                        className="box-shadow"
                        bg={activeSection === 1 ? "primaryDark" : "primary"}
                        c={activeSection === 1 ? "primary" : "primaryDark"}
                        style={{ cursor: "pointer" }}
                        lh={1.2}
                        fz={{ base: 14, sm: 16 }}
                        p={{ base: "10px", sm: "10px 16px" }}
                    >
                        {getTitleByLanguage(data?.content?.[1])}
                    </Text>
                </Grid.Col>
                <Grid.Col>
                    <Box mt={10}>
                        {isLoading ?
                            <Box pos="relative" w="100%" h={400}>
                                <CustomLoader />
                            </Box> :
                            <>
                                <Text my={15} c="primaryDark" fz={14}>
                                    {getTitleByLanguage({
                                        titleKg: data?.content?.[activeSection]?.textKg,
                                        titleRu: data?.content?.[activeSection]?.textRu
                                    })}
                                </Text>
                                <Text fz={12} c="primaryDark">
                                    {t("titles.also-and")}:
                                </Text>
                                {data?.content?.[activeSection]?.diagramFields?.map(field => (
                                    <Flex key={field.id} mb={-15} align="center" gap={4}>
                                        <Title lh={1} fz={{ base: 18, md: 20 }}>
                                            {field.fieldValue}
                                        </Title> -
                                        <Text fz={14} c="primaryDark">
                                            {getTitleByLanguage(field)}
                                        </Text>
                                    </Flex>
                                ))}
                            </>
                        }
                    </Box>
                </Grid.Col>
            </Grid>
            <Center>
                <Flex w={{ base: "90%", sm: "56%" }} justify="center">
                    <NewWorldMap
                        allData={allData}
                        datas={mapDatas}
                        activeCountry={active}
                        handleClickCountry={hanldeChangeCountry} />
                </Flex>
            </Center>
        </Paper>
    }
    return (
        <Paper p={16}>
            <Grid>
                {data?.content?.map((el, idx) => (
                    <Grid.Col key={idx} span={{ base: 12, md: 6 }}>
                        <Text onClick={() => setActiveSection(idx)}
                            className="box-shadow"
                            bg={activeSection === idx ? "primaryDark" : "primary"}
                            c={activeSection === idx ? "primary" : "primaryDark"}
                            style={{ cursor: "pointer" }}
                            p="10px 16px" lh={1.2} fz={{ base: 15, lg: 16 }}
                        >
                            {getTitleByLanguage(el)}
                        </Text>
                        <Text my={15} c="primaryDark" fz={14}>
                            {getTitleByLanguage({
                                titleKg: el.textKg,
                                titleRu: el.textRu
                            })}
                        </Text>
                        <Box mt={30}>
                            {isLoading ?
                                <Box pos="relative" w="100%" h={400}>
                                    <CustomLoader />
                                </Box> :
                                <>
                                    <Text fz={12} c="primaryDark">
                                        {t("titles.also-and")}:
                                    </Text>
                                    {el.diagramFields?.map(field => (
                                        <Flex key={field.id} mb={-15} align="center" gap={4}>
                                            <Title lh={1} fz={{ base: 18, md: 20 }}>
                                                {field.fieldValue}
                                            </Title> -
                                            <Text fz={14} c="primaryDark">
                                                {getTitleByLanguage(field)}
                                            </Text>
                                        </Flex>
                                    ))}
                                </>
                            }
                        </Box>
                    </Grid.Col>))}
            </Grid>
            <Center>
                <Flex w={{ base: "90%", sm: "56%" }} justify="center">
                    <NewWorldMap
                        allData={allData}
                        datas={mapDatas}
                        activeCountry={active}
                        handleClickCountry={hanldeChangeCountry} />
                </Flex>
            </Center>
        </Paper>
    )
}
