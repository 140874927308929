import { Accordion } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { LawsItem } from "./laws-item";

export const LawsModule = ({
    list,
    dataType,
}: {
    list?: ConsSectionList;
    dataType: "SECTION" | "CHAPTER" | undefined;
}) => {
    const exdata = () => {
        if (list?.articles) {
            const data: ConsSectionList = {
                articles: list.articles,
                list: [],
                contentKg: list?.contentKg,
                contentRu: list?.contentRu,
                id: list?.id || 111,
                nameKg: list?.nameKg,
                nameRu: list?.nameRu,
            };
            return data;
        }
    };
    return (
        <>
            {/* {!allData?.contentKg && !allData?.contentRu &&
                (allData?.contentKg?.length ?? 1) > 100 && (allData?.contentRu?.length ?? 1) > 100 && (
                    <Title
                        mb={-10}
                        lh={1.3}
                        fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}
                        order={3}
                        c="secondaryDark"
                    >
                        {getTitleByLanguage({
                            titleRu: allData?.nameRu,
                            titleKg: allData?.nameKg,
                        })}
                    </Title>
                )} */}
            <Accordion
                chevronSize={20}
                chevron={
                    <IconChevronDown size={20} color="var(--primaryDark)" />
                }
            >
                {exdata() && <LawsItem data={exdata()} />}
                {list?.list?.map((el) => <LawsItem key={el.id} data={el} />)}
            </Accordion>
        </>
    );
};
