import {
    Button,
    Container,
    Flex,
    Grid,
    Image,
    Modal,
    Paper,
    Select,
    Text,
    TypographyStylesProvider,
    UnstyledButton,
} from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    ButtonGroup,
    CustomDropzone,
    CustomInputText,
    CustomTextEditor,
} from "src/components";
import { getTitleByLanguage } from "src/locales";
import {
    useCreatePageListAccordionMutation,
    useGetAllPageListQuery,
    useUpdatePageListAccordionMutation,
} from "src/store";
import { notify } from "src/utils";

type Props = {
    pageListAccordion?: PageListAccordion;
};

export const PageListAccordionForm = ({ pageListAccordion }: Props) => {
    const { t, i18n } = useTranslation();
    const [filesRu, setFilesRu] = useState<IAttachment[] | undefined>(pageListAccordion?.fileAttachmentRu)
    const [filesKg, setFilesKg] = useState<IAttachment[] | undefined>(pageListAccordion?.fileAttachmentKg)
    const [photosKg, setPhotosKg] = useState<IAttachment[] | undefined>(pageListAccordion?.photoAttachmentKg)
    const [photosRu, setPhotosRu] = useState<IAttachment[] | undefined>(pageListAccordion?.photoAttachmentRu)
    const navigate = useNavigate();
    const [createPage] = useCreatePageListAccordionMutation();
    const [updatePage] = useUpdatePageListAccordionMutation();
    const [opened, { close, open }] = useDisclosure(false);
    const [htmlContent, setHtmlContent] = useState("");
    const { data: pages } = useGetAllPageListQuery({})

    const pageListId = pages?.map((item) => ({
        value: `${item.id}`,
        label: getTitleByLanguage(item),
    }));
    const handleDeleteAttachments = (key: "ru" | "kg", id: number) => () => {
        if (key === "ru") {
            const prevData = form.values.toDeleteFileAttachmentRu || []
            setFilesRu(prev => prev?.filter(el => el.id !== id))
            form.setFieldValue("toDeleteFileAttachmentRu", [...prevData, id])
        } else if (key === "kg") {
            const prevData = form.values.toDeleteFileAttachmentKg || []
            setFilesKg(prev => prev?.filter(el => el.id !== id))
            form.setFieldValue("toDeleteFileAttachmentKg", [...prevData, id])
        }
    }

    const handleDeletePhoto = (key: "ru" | "kg", id: number) => () => {
        if (key === "kg") {
            const prevData = form.values.toDeletePhotoAttachmentKg || []
            setPhotosKg(prev => prev?.filter(el => el.id !== id))
            form.setFieldValue("toDeletePhotoAttachmentKg", [...prevData, id])
        } else if (key === "ru") {
            const prevData = form.values.toDeletePhotoAttachmentRu || []
            setPhotosRu(prev => prev?.filter(el => el.id !== id))
            form.setFieldValue("toDeletePhotoAttachmentRu", [...prevData, id])
        }
    }

    const form = useForm<PageListAccordionFormType>({
        initialValues: {
            fileAttachmentKg: null,
            fileAttachmentRu: null,
            photoAttachmentKg: null,
            photoAttachmentRu: null,
            contentEn: pageListAccordion?.contentEn || "",
            contentKg: pageListAccordion?.contentKg || "",
            contentRu: pageListAccordion?.contentRu || "",
            titleEn: pageListAccordion?.titleEn || "",
            titleKg: pageListAccordion?.titleKg || "",
            titleRu: pageListAccordion?.titleRu || "",
            pageListId: pageListAccordion?.pageListId || null,
            accordionType: pageListAccordion?.accordionType || "TEXT",
        },
    });

    const handleSubmit = async ({
        fileAttachmentKg,
        fileAttachmentRu,
        photoAttachmentKg,
        photoAttachmentRu,
        ...values
    }: PageListAccordionFormType) => {
        if (pageListAccordion) {
            try {
                await updatePage({
                    id: pageListAccordion.id,
                    fileAttachmentKg,
                    fileAttachmentRu,
                    photoAttachmentKg,
                    photoAttachmentRu,
                    body: values,
                }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/page_list_accordion");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createPage({
                    fileAttachmentKg,
                    fileAttachmentRu,
                    photoAttachmentKg,
                    photoAttachmentRu,
                    ...values
                }).unwrap();
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/page_list_accordion");
            } catch {
                notify(false);
            }
        }
    };

    const handlePreviewButton = (val: string) => {
        setHtmlContent(val);
        open();
    };
    return (
        <Container size="xl" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleKg")}
                                label={t`form.label.titleKg`}
                                required
                                error={form.errors.titleKg}
                                formData={form.values.titleKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleRu")}
                                label={t`form.label.titleRu`}
                                required
                                error={form.errors.titleRu}
                                formData={form.values.titleRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleEn")}
                                label={t`form.label.titleEn`}
                                error={form.errors.titleEn}
                                formData={form.values.titleEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Select
                                searchable
                                {...form.getInputProps("pageListId")}
                                placeholder={t`form.label.pageListId`}
                                error={form.errors.pageListId}
                                value={`${form.values.pageListId}`}
                                data={pageListId || []}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomTextEditor
                                label={t`form.label.contentKg`}
                                labelButton={
                                    <Button
                                        variant="subtle"
                                        color="blue"
                                        size="sm"
                                        radius="sm"
                                        ml={5}
                                        onClick={() =>
                                            handlePreviewButton(
                                                form.values.contentKg
                                            )
                                        }
                                    >
                                        {t`button.preview`}
                                    </Button>
                                }
                                error={form.errors.contentKg}
                                formData={form.values.contentKg}
                                {...form.getInputProps("contentKg")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomTextEditor
                                label={t`form.label.contentRu`}
                                labelButton={
                                    <Button
                                        variant="subtle"
                                        color="blue"
                                        size="sm"
                                        radius="sm"
                                        ml={5}
                                        onClick={() =>
                                            handlePreviewButton(
                                                form.values.contentRu
                                            )
                                        }
                                    >
                                        {t`button.preview`}
                                    </Button>
                                }
                                error={form.errors.contentRu}
                                formData={form.values.contentRu}
                                {...form.getInputProps("contentRu")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomTextEditor
                                label={t`form.label.contentEn`}
                                labelButton={
                                    <Button
                                        variant="subtle"
                                        color="blue"
                                        size="sm"
                                        radius="sm"
                                        ml={5}
                                        onClick={() =>
                                            handlePreviewButton(
                                                form.values.contentEn
                                            )
                                        }
                                    >
                                        {t`button.preview`}
                                    </Button>
                                }
                                error={form.errors.contentEn}
                                formData={form.values.contentEn}
                                {...form.getInputProps("contentEn")}
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <CustomDropzone
                                label={t("form.label.filesRu")}
                                error={form.errors.fileAttachmentRu}
                                {...form.getInputProps("fileAttachmentRu")}
                                accept={[MIME_TYPES.pdf]}
                            >
                                <Flex gap={10}>
                                    {filesRu?.map((el) => "id" in el && (
                                        <Paper key={el.id} p={8}>
                                            <Flex direction="column" align="center">
                                                <UnstyledButton
                                                    onClick={handleDeleteAttachments("ru", el.id)}>
                                                    <IconTrash color="red" />
                                                </UnstyledButton>
                                                <Image w={100} alt="" src="/document.png" />
                                                <Text style={{ overflow: "hidden", width: "200px" }}>{el.name}</Text>
                                            </Flex>
                                        </Paper>
                                    ))}
                                </Flex>
                            </CustomDropzone>
                        </Grid.Col>
                        <Grid.Col>
                            <CustomDropzone
                                label={t("form.label.filesKg")}
                                error={form.errors.fileAttachmentKg}
                                {...form.getInputProps("fileAttachmentKg")}
                                accept={[MIME_TYPES.pdf]}
                            >
                                <Flex gap={10}>
                                    {filesKg?.map((el) => "id" in el && (
                                        <Paper key={el.id} p={8}>
                                            <Flex direction="column" align="center">
                                                <UnstyledButton
                                                    onClick={handleDeleteAttachments("kg", el.id)}>
                                                    <IconTrash color="red" />
                                                </UnstyledButton>
                                                <Image w={100} alt="" src="/document.png" />
                                                <Text style={{ overflow: "hidden", width: "200px" }}>{el.name}</Text>
                                            </Flex>
                                        </Paper>
                                    ))}
                                </Flex>
                            </CustomDropzone>
                        </Grid.Col>
                        {/* <Grid.Col>
                            <CustomDropzone
                                label={t("form.label.photosRu")}
                                error={form.errors.photoAttachmentRu}
                                {...form.getInputProps("photoAttachmentRu")}
                                accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
                            >
                                <Flex gap={10}>
                                    {photosRu?.map((el) => "id" in el && (
                                        <Paper key={el.id} p={8}>
                                            <Flex direction="column" align="center">
                                                <UnstyledButton
                                                    onClick={handleDeletePhoto("ru", el.id)}>
                                                    <IconTrash color="red" />
                                                </UnstyledButton>
                                                <Image w={100} alt="" src={getAttachmentUrl(el.id, el.uuid)} />
                                            </Flex>
                                        </Paper>
                                    )
                                    )}
                                </Flex>
                            </CustomDropzone>
                        </Grid.Col>
                        <Grid.Col>
                            <CustomDropzone
                                label={t("form.label.photosKg")}
                                error={form.errors.photoAttachmentKg}
                                {...form.getInputProps("photoAttachmentKg")}
                                accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
                            >
                                <Flex gap={10}>
                                    {photosKg?.map((el) => "id" in el && (
                                        <Paper key={el.id} p={8}>
                                            <Flex direction="column" align="center">
                                                <UnstyledButton
                                                    onClick={handleDeletePhoto("kg", el.id)}>
                                                    <IconTrash color="red" />
                                                </UnstyledButton>
                                                <Image w={100} alt="" src={getAttachmentUrl(el.id, el.uuid)} />
                                            </Flex>
                                        </Paper>
                                    ))}
                                </Flex>
                            </CustomDropzone>
                        </Grid.Col> */}
                        <Grid.Col mb={100} span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(
                                        `/page_list_accordion/${i18n.language}`
                                    )
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
            <Modal
                opened={opened}
                onClose={close}
                size={"60%"}
                title="Предпросмотр"
            >
                <TypographyStylesProvider>
                    <div
                        style={{ color: "#2D4263" }}
                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                    />
                </TypographyStylesProvider>
            </Modal>
        </Container>
    );
};
