import { Box, Burger, Button, Flex, Image, Menu, ScrollArea } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChangeLanguage, PagesList } from "src/components";
import { getJsonFiles } from "src/utils";
interface BVI {
    Bvi: any;
    isvek: boolean;
}

declare global {
    interface Window {
        isvek: BVI;
    }
}

interface IProps {
    setSearchActive: Dispatch<SetStateAction<boolean>>;
    searchActive: boolean;
}

export const BurgerMenu = ({ setSearchActive, searchActive }: IProps) => {
    const [socialLinks, setSocialLinks] = useState<SocialLinkType[]>();
    const [opened, { toggle, close }] = useDisclosure();
    const pathname = useLocation();

    const bvi = window.isvek;

    useEffect(() => {
        new bvi.Bvi({
            target: ".bvi-class",
            fontSize: 24,
            theme: "black",
        });
    }, [bvi.Bvi]);

    useEffect(() => {
        if (searchActive) {
            close();
        }
    }, [searchActive]);

    useEffect(() => {
        close();
    }, [pathname]);
    useEffect(() => {
        getJsonFiles("social-links").then((data) => {
            setSocialLinks(data);
        });
    }, []);
    return (
        <Box>
            <Menu opened={opened} onChange={toggle} closeOnClickOutside>
                <Menu.Target>
                    <Burger
                        style={{ position: "relative", zIndex: 40 }}
                        opened={opened}
                        color="primary"
                    />
                </Menu.Target>
                <Box>
                    <Menu.Dropdown
                        bg="rgba(var(--primary-dark), 0.9)"
                        style={{
                            border: "none",
                            backdropFilter: "blur(5px)",
                            borderRadius: "none",
                            height: "100vh",
                            position: "fixed",
                            top: "89px",
                        }}
                    >
                        <ScrollArea h="100vh">
                            <Box p={16}>
                                <PagesList isBurger={true} />
                                <Flex
                                    mt={20}
                                    display={{ base: "flex", lg: "none" }}
                                    gap={8}
                                    align="center"
                                >
                                    <Button
                                        display={{ base: "none", sm: "block" }}
                                        onClick={() => setSearchActive(true)}
                                        variant="simple"
                                    >
                                        <Image src="/icons/search.svg" />
                                    </Button>
                                    <Flex align="center" gap={15}>
                                        <a
                                            href="/"
                                            className="bvi-class"
                                            style={{ color: "#868e96" }}
                                        >
                                            <Image
                                                width={22}
                                                src="/icons/icon-eye.svg"
                                            />
                                        </a>
                                        {socialLinks?.map(
                                            (el, idx) =>
                                                idx < 2 && (
                                                    <a
                                                        key={el.id}
                                                        style={{ marginTop: "8px" }}
                                                        href={el.path}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <Image
                                                            mt={-10}
                                                            src={el.iconWhite}
                                                        />
                                                    </a>
                                                )
                                        )}
                                    </Flex>
                                    <ChangeLanguage bg="none" />
                                </Flex>
                            </Box>
                        </ScrollArea>
                    </Menu.Dropdown>
                </Box>
            </Menu>
        </Box>
    );
};
