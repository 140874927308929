import { Box, Grid, Title } from "@mantine/core";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomLoader, PersonCard, RouteTitles } from "src/components";
import { useSearchCardMutation } from "src/store";
import { notify } from "src/utils";

export const ProkurorsModule = () => {
    const { t } = useTranslation();
    const [searchCards, { isLoading, data }] = useSearchCardMutation();

    const searchCardsByOfficeId = useCallback(
        async (position: string) => {
            try {
                await searchCards({
                    filter: {
                        active: false,
                        position: position || null,
                        acceptsRequests: false,
                    },
                    pageRequest: { limit: 40, page: 0 },
                    sorting: {
                        sortBy: "IMPORTANCE",
                    },
                });
            } catch {
                notify();
            }
        },
        [searchCards]
    );

    useEffect(() => {
        searchCardsByOfficeId("ex-prokurors");
    }, [searchCardsByOfficeId]);
    return (
        <Box w={{ base: "95vw", sm: "90vw" }} mx="auto">
            <RouteTitles styles={{ mt: 0, mb: 0 }} />
            <Title
                fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}
                mb={24}
                c="secondaryDark"
            >
                {t("titles.gen-prokurors")}
            </Title>
            <Grid
                pos="relative"
                justify="center"
                align="start"
                w="90vw"
                mx="auto"
                px={0}
            >
                {isLoading ?
                    <Box pos="relative" w="100%" mih="40vh" h="100%">
                        <CustomLoader />
                    </Box> : data?.content?.map((el) => (
                        <PersonCard key={el.id} person={el} />
                    ))}
            </Grid>
        </Box>
    );
};
