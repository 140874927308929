import { Carousel } from "@mantine/carousel";
import { Box, Paper } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { BannerCard, CustomLoader, TextInfo } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useGetBannerQuery } from "src/store";

type Props = {
    bannerId: string;
};
export const BannerDetails = ({ bannerId }: Props) => {
    const { t } = useTranslation();

    const { data: bannerData, isLoading } = useGetBannerQuery(bannerId, {
        refetchOnMountOrArgChange: true,
    });

    const organCard = [
        {
            label: t`data.news.title`,
            value: getTitleByLanguage(bannerData),
        },
    ];

    return (
        <Paper shadow="sm" p="xl">
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <Paper shadow="sm" p="xl">
                    <TextInfo
                        data={organCard}
                        display={true}
                        spanFirst={4}
                        spanTwo={8}
                    />
                    <Box mt={50}>
                        <Carousel
                            withIndicators={true}
                            withControls={false}
                            align="start"
                            height={300}
                            loop={true}
                        >
                            <BannerCard
                                banner={bannerData as BannerGetAll}
                                idx={1}
                            />
                        </Carousel>
                    </Box>
                </Paper>
            }
        </Paper>
    );
};
