import { Button, Flex, Menu } from "@mantine/core";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import React, { useState } from "react";
import style from "./styles.module.scss";
interface CustomeSelectProps {
    color?: string;
    styles?: React.CSSProperties;
    bg?: string;
    value: string;
    data: string[];
    onChange?: (a: string) => void;
    width?: number | string;
    label?: string;
    dropdownBg?: string;
    dropdownColor?: string;
    dropdownWidth?: number | string;
    defaultValue?: string;
    boxShadow?: boolean;
    textLimit?: number;
    checkedIcon?: boolean;
}

export const CustomSelectOpen = ({
    value,
    data,
    onChange,
    styles,
    label,
    dropdownBg,
    dropdownColor,
    dropdownWidth,
    bg = "primaryDark",
    color = "primary",
    width = 260,
    defaultValue,
    boxShadow = true,
    textLimit = 26,
    checkedIcon = true,
}: CustomeSelectProps) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Menu onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
            <Menu.Target>
                <Flex
                    style={{
                        borderRadius: "2px",
                        cursor: "pointer",
                        ...styles,
                    }}
                    py={5}
                    bg={bg}
                    align="center"
                    justify="space-between"
                    className={boxShadow ? "box-shadow" : ""}
                    w={{ base: "100%", xs: width }}
                    px={16}
                >
                    <Button
                        c={color}
                        justify="space-between"
                        variant="simple"
                        fz={16}
                        fw={400}
                        p={0}
                        m={0}
                    >
                        {/* {value &&
                            ? value !== defaultValue && value.length > textLimit
                                ? `${value.slice(0, textLimit)}...`
                                : value
                            : defaultValue} */}
                        {defaultValue === value
                            ? defaultValue
                            : value?.slice(0, textLimit)}
                    </Button>
                    <Button
                        c={color}
                        p={0}
                        m={0}
                        variant="simple"
                        rightSection={
                            <IconChevronDown
                                style={{
                                    transform: `rotate(${isOpen ? "180deg" : 0
                                        })`,
                                }}
                                size={18}
                            />
                        }
                    />
                </Flex>
            </Menu.Target>
            <Menu.Dropdown
                mt={-10}
                mah={400}
                px={boxShadow ? 12 : "3vw"}
                w={width}
                maw={dropdownWidth}
                style={{ overflow: "scroll", border: "none" }}
                bg={dropdownBg || bg}
            >
                {data?.map((item) => {
                    return (
                        <Menu.Item
                            className={style.item_light}
                            key={item}
                            onClick={() => onChange && onChange(item)}
                            c={dropdownColor || color}
                            style={{ opacity: value === item ? 0.7 : 1 }}
                        >
                            <Flex className={style.item_light_title}>
                                {item === value && checkedIcon && (
                                    <IconCheck
                                        color={`var(--${dropdownColor || color
                                            })`}
                                        size={24}
                                    />
                                )}
                                {item}
                            </Flex>
                        </Menu.Item>
                    );
                })}
            </Menu.Dropdown>
        </Menu>
    );
};
