import axios from "axios";
import { baseUrl } from "src/constants";
import { Path } from "./path";

export const getAttachmentUrl = (id: number, uuid: string) =>
    `${baseUrl}/${Path.Attachments.get(id, uuid)}`;

interface IHTMLRenderProps {
    htmlContent: string | undefined;
}

export const HTMLRender = ({ htmlContent }: IHTMLRenderProps) => {
    if (!htmlContent) {
        return null;
    }
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

type sliceTitleType = (
    screenSize: "sm" | "md" | "lg" | "xl",
    textLength: {
        xl?: number;
        lg?: number;
        md?: number;
        sm?: number;
    },
    text: string
) => string;

export const sliceText: sliceTitleType = (screenSize, textLength, text) => {
    switch (screenSize) {
        case "sm":
            return `${text.slice(0, textLength.sm)}...`;
        case "md":
            return `${text.slice(0, textLength.md)}...`;
        case "lg":
            return `${text.slice(0, textLength.lg)}...`;
        default:
            return `${text.slice(0, textLength.xl)}...`;
    }
};

export const getJsonFiles = async (path: string, root = false) => {
    const response = await axios(root ? `/${path}.json` : `/json/${path}.json`);
    return response.data;
};

export const fadeIn = (direction: "right" | "left", inView: boolean) => ({
    overflow: "scroll",
    transform: inView
        ? "translateX(0)"
        : `translateX(${direction === "right" ? "500px" : "-500px"})`,
    transition: "1s",
    opacity: inView ? 1 : 0,
});

type getHeaderType = (
    cons: ConsFormType | null,
    t: (s: string) => string
) => { id: number | string; title: string }[];

export const getHeader: getHeaderType = (cons, t) => {
    if (cons) {
        let result;
        if (cons.type === "CHAPTER") {
            result = cons?.list?.map((el, idx) => ({
                id: el?.id || 1,
                title: t(`chapters.${idx + 1}`),
            }));
        } else {
            result = cons?.list?.map((el, idx) => ({
                id: el?.id || 1,
                title: t(`sections.${idx + 1}`),
            }));
        }
        return result;
    } else return [];
};

export const refactorConstitutionResponse = (data: ConsResponseType) => {
    const list: ConsSectionList[] = [];
    if (data?.type === "SECTION") {
        data?.consSections?.map((root) => {
            list.push({
                articles: root.articles,
                contentKg: root.contentKg,
                contentRu: root.contentRu,
                id: root?.id,
                list: root.consChapterList?.map((childList) => ({
                    articles: childList.articles,
                    contentKg: childList.contentKg,
                    contentRu: childList.contentRu,
                    nameKg: childList.nameKg,
                    nameRu: childList.nameRu,
                    id: childList.id,
                    list: [],
                })),
                nameKg: root.nameKg,
                nameRu: root.nameRu,
            });
        });
    } else {
        data?.consChapters?.map((root) => {
            list.push({
                articles: root.articles,
                contentKg: root.contentKg,
                contentRu: root.contentRu,
                id: root?.id,
                list: root.consSections?.map((childList) => ({
                    articles: childList.articles,
                    contentKg: childList.contentKg,
                    contentRu: childList.contentRu,
                    nameKg: childList.nameKg,
                    nameRu: childList.nameRu,
                    id: childList.id,
                    list: [],
                })),
                nameKg: root.nameKg,
                nameRu: root.nameRu,
            });
        });
    }
    return {
        type: data.type,
        id: data.id,
        name: data.name,
        list: list,
    };
};
