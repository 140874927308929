import { createSlice } from "@reduxjs/toolkit";
import { useLocalStorage } from "src/hooks";
import { RootState } from "src/store/store";

export interface AuthState {
    profile?: Profile;
    menu?: Menu[];
    loading?: boolean;
    error?: string;
    token?: string;
}

const initialState: AuthState = {
    profile: undefined,
    menu: undefined,
    loading: undefined,
    error: undefined,
    token: undefined,
};

export const authSlice = createSlice({
    name: "authApi",
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { menu, authenticationToken } = action.payload as Profile;
            state.profile = action.payload;
            state.menu = menu;
            state.token = authenticationToken;
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useLocalStorage("profile").setItem(action.payload);
        },
        logOut: (state) => {
            state.profile = undefined;
            state.token = undefined;
            state.menu = undefined;
        },
    },
});
export const { setCredentials, logOut } = authSlice.actions;
export const authReducer = authSlice.reducer;

export const selectCurrentProfile = (state: RootState) => state.auth.profile;
export const selectCurrentToken = (state: RootState) => state.auth.token;
