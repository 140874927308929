import { Grid } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonGroup, CustomModal, CustomSelectOpen } from "src/components";
import {
    useGetAllMapQuery,
    useOfficeSearchMutation,
    useSearchCardMutation,
} from "src/store";
import { notify } from "src/utils";

type Props = {
    open: boolean;
    filter?: AppealFilterType;
    handleClose: () => void;
    onReset: () => void;
    setSearchParams: Function;
};

export const AppealFilter = ({
    open,
    filter,
    handleClose,
    onReset,
    setSearchParams,
}: Props) => {
    const { t } = useTranslation();
    const [activeRegion, setActiveRegion] = useState<number | null>(null);
    const [activeOffice, setActiveOffice] = useState<number | null>(null);
    const [offices, setOffices] = useState<ProsecutorResponseType[] | null>(
        null
    );
    const [cards, setCards] = useState<CardType[] | null>(null);
    const [searchOffices] = useOfficeSearchMutation();
    const [searchCards] = useSearchCardMutation();
    const { data: mapPoints } = useGetAllMapQuery({});
    const mapPointNames = mapPoints?.map((el) => el.cityOrRegionNameRu);
    const form = useForm<AppealFilterType>({
        initialValues: {
            cardInfoId: null,
        },
    });
    const getProsecutorOffices = useCallback(
        async (id: number) => {
            try {
                const response = await searchOffices({
                    filter: { mapPointId: id },
                });
                if ("data" in response && "content" in response.data) {
                    setOffices(response.data.content);
                }
            } catch {
                notify();
            }
        },
        [activeRegion]
    );

    const getCards = useCallback(
        async (id: number) => {
            try {
                const response = await searchCards({
                    filter: {
                        prosecutorOfficeId: id,
                    },
                });
                if ("data" in response && "content" in response.data) {
                    setCards(response.data.content);
                }
            } catch {
                notify();
            }
        },
        [activeOffice]
    );

    const handleChangeMapPoint = (value: string) => {
        const id =
            mapPoints?.find((el) => el.cityOrRegionNameRu === value)?.id || 0;
        setActiveRegion(id);
    };
    const handleChangeOffice = (value: string) => {
        const id = offices?.find((el) => el.titleRu === value)?.id || 0;
        setActiveOffice(id);
    };
    const handleChangeCardsInfoId = (value: string) => {
        const id =
            cards?.find(
                (el) =>
                    `${el?.position?.titleRu} - ${el?.fullName?.titleRu}` ===
                    value
            )?.id || 0;
        form.setFieldValue("cardInfoId", id);
    };
    const handleSubmit = async (values: AppealFilterType) => {
        form.reset();
        handleClose();
        for (let key in values) {
            if (values[key as keyof AppealFilterType] === 0) {
                delete values[key as keyof AppealFilterType];
            }
        }
        setSearchParams(values);
    };
    const handleReset = () => {
        form.reset();
        handleClose();
        onReset();
    };
    useEffect(() => {
        if (filter) {
            form.setValues({
                cardInfoId: filter.cardInfoId || 0,
            });
        }
    }, [filter]);
    useEffect(() => {
        if (activeRegion) {
            getProsecutorOffices(activeRegion);
        }
    }, [activeRegion]);
    useEffect(() => {
        if (activeOffice) {
            getCards(activeOffice);
        }
    }, [activeOffice]);
    return (
        <CustomModal
            title={t`modal.filter`}
            open={open}
            handleClose={handleClose}
            button={false}
            size="xl"
        >
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Grid>
                    <Grid.Col span={12}>
                        <CustomSelectOpen
                            styles={{
                                border: "1px solid var(--weak-gray)",
                                boxShadow: "none",
                            }}
                            textLimit={100}
                            bg="primary"
                            width="100%"
                            color="gray"
                            dropdownWidth={750}
                            data={mapPointNames || [""]}
                            label={t("form.label.regionName")}
                            value={
                                mapPoints?.find((el) => el.id === activeRegion)
                                    ?.cityOrRegionNameRu || ""
                            }
                            onChange={handleChangeMapPoint}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <CustomSelectOpen
                            styles={{
                                border: "1px solid var(--weak-gray)",
                                boxShadow: "none",
                            }}
                            textLimit={100}
                            bg="primary"
                            width="100%"
                            color="gray"
                            dropdownWidth={750}
                            data={
                                offices?.map((el) => el.titleRu || "") || [""]
                            }
                            value={
                                offices?.find((el) => el.id === activeOffice)
                                    ?.titleRu || ""
                            }
                            onChange={handleChangeOffice}
                            label={t("form.label.prosecutorOfficeId")}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <CustomSelectOpen
                            styles={{
                                border: "1px solid var(--weak-gray)",
                                boxShadow: "none",
                            }}
                            textLimit={500}
                            bg="primary"
                            width="100%"
                            dropdownWidth={750}
                            color="gray"
                            data={
                                cards?.map(
                                    (el) =>
                                        `${el.position.titleRu} - ${el.fullName.titleRu}`
                                ) || [""]
                            }
                            value={
                                cards?.find(
                                    (el) => el.id === form.values.cardInfoId
                                )?.fullName.titleRu || ""
                            }
                            onChange={handleChangeCardsInfoId}
                            label={t("form.label.chooseCard")}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <ButtonGroup
                            labelCancel="reset"
                            labelSubmit="search"
                            onClickCancel={handleReset}
                            type="submit"
                        />
                    </Grid.Col>
                </Grid>
            </form>
        </CustomModal>
    );
};
