import { Container, Grid, NumberInput, Paper } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, CustomInputText } from "src/components";
import { useCreateCardsMutation, useUpdateCardMutation } from "src/store";
import { notify } from "src/utils";

type Props = {
    card?: CardType;
};

export const CardInfoFormToAppeal = ({ card }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [createCard] = useCreateCardsMutation();
    const [updateCard] = useUpdateCardMutation();

    const form = useForm<CardInfoFormType>({
        initialValues: {
            acceptsRequests: true,
            educationEn: card?.education?.titleEn || "",
            educationKg: card?.education?.titleKg || "",
            educationRu: card?.education?.titleRu || "",
            fullNameEn: card?.fullName?.titleEn || "",
            fullNameKg: card?.fullName?.titleKg || "",
            fullNameRu: card?.fullName?.titleRu || "",
            positionEn: card?.position?.titleEn || "",
            positionKg: card?.position?.titleKg || "",
            positionRu: card?.position?.titleRu || "",
            attachment: null,
            laborActivityKg: card?.laborActivity?.titleKg || "",
            laborActivityEn: card?.laborActivity?.titleEn || "",
            laborActivityRu: card?.laborActivity?.titleRu || "",
            active: card?.active,
            shortBiographyEn: card?.shortBiography?.titleEn || "",
            shortBiographyKg: card?.shortBiography?.titleKg || "",
            shortBiographyRu: card?.shortBiography?.titleRu || "",
            awardsEn: card?.awards.titleEn || "",
            awardsKg: card?.awards.titleKg || "",
            awardsRu: card?.awards.titleRu || "",
            prosecutorOfficeId: card?.prosecutorOffice?.id || null,
            importance: card?.importance || 6,
        },
        validate: {
            fullNameRu: (value) => !value && t("form.validation.required"),
            fullNameKg: (value) => !value && t("form.validation.required"),
            positionKg: (value) => !value && t("form.validation.required"),
            positionRu: (value) => !value && t("form.validation.required"),
        },
    });

    const handleSubmit = async ({
        attachment,
        ...values
    }: CardInfoFormType) => {
        if (card) {
            try {
                await updateCard({
                    id: card.id,
                    attachment,
                    body: values,
                }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/card_info_to_appeal");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createCard({ attachment, ...values }).unwrap();
                notify(true, t("notify.success"));
                form.reset();
                navigate("/card_info_to_appeal");
            } catch {
                notify(false);
            }
        }
    };
    return (
        <Container size="xl">
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p="xl">
                    <Grid>
                        <Grid.Col span={12}>
                            <NumberInput
                                label={t`form.label.importance`}
                                required
                                error={form.errors.importance}
                                {...form.getInputProps("importance")}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <CustomInputText
                                {...form.getInputProps("fullNameKg")}
                                label={t`form.label.fullNameKg`}
                                required
                                error={form.errors.fullNameKg}
                                formData={form.values.fullNameKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <CustomInputText
                                {...form.getInputProps("fullNameRu")}
                                label={t`form.label.fullNameRu`}
                                required
                                error={form.errors.fullNameRu}
                                formData={form.values.fullNameRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <CustomInputText
                                {...form.getInputProps("fullNameEn")}
                                label={t`form.label.fullNameEn`}
                                error={form.errors.fullNameEn}
                                formData={form.values.fullNameEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("positionKg")}
                                label={t`form.label.positionKg`}
                                required
                                error={form.errors.positionKg}
                                formData={form.values.positionKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("positionRu")}
                                label={t`form.label.positionRu`}
                                required
                                error={form.errors.positionRu}
                                formData={form.values.positionRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("positionEn")}
                                label={t`form.label.positionEn`}
                                error={form.errors.positionEn}
                                formData={form.values.positionEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(
                                        `/card_info_to_appeal/${i18n.language}`
                                    )
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
