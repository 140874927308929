import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const photoApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addPhoto: builder.mutation<IPhoto, File[]>({
            query: (attachments) => {
                const formData = new FormData();
                attachments.map((file) => formData.append("attachments", file));
                return {
                    url: Path.Photo.create,
                    method: "POST",
                    body: formData,
                    cache: "no-cache",
                };
            },
        }),
        getPhoto: builder.query<IPhoto, number>({
            query: (id) => ({
                url: Path.Photo.getById(id),
                method: "GET",
                cache: "no-cache",
            }),
        }),
        changeToMain: builder.mutation<IPhoto, number>({
            query: (id) => ({
                url: Path.Photo.isMain(id),
                method: "PUT",
                cache: "no-cache",
            }),
        }),
        getByPhotoAlbum: builder.query<IPhoto[], number | undefined>({
            query: (photoAlbumId) => ({
                url: Path.Photo.getAllByAlbum(photoAlbumId),
                method: "GET",
                cache: "no-cache",
            }),
        }),

        getOriginalsByPhotoAlbum: builder.query<IPhoto[], number | undefined>({
            query: (photoAlbumId) => ({
                url: Path.Photo.getAllOriginalByAlbum(photoAlbumId),
                method: "GET",
                cache: "no-cache",
            }),
        }),
        deletePhoto: builder.mutation({
            query: (id) => ({
                url: Path.Photo.delete(id),
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useDeletePhotoMutation,
    useGetPhotoQuery,
    useAddPhotoMutation,
    useChangeToMainMutation,
    useGetByPhotoAlbumQuery,
    useGetOriginalsByPhotoAlbumQuery,
    useLazyGetPhotoQuery
} = photoApiSlice;
