import { useInViewport } from "@mantine/hooks";
import { useEffect, useState } from "react";

interface Props {
    endValue: number;
}

export const AnimatedCount = ({ endValue }: Props) => {
    const [count, setCount] = useState(1);
    const { inViewport, ref } = useInViewport();
    useEffect(() => {
        if (inViewport) {
            if (count <= endValue) {
                const interval = setInterval(() => {
                    setCount(
                        (prevCount) => prevCount + (endValue / 120)
                    );
                }, 10);

                return () => clearInterval(interval);
            }
        } else {
            setCount(0);
        }
    }, [count, endValue, inViewport]);

    return (
        <span ref={ref}>
            {count <= endValue
                ? count.toLocaleString()
                : endValue.toLocaleString()}
        </span>
    );
};
