import {
    Box,
    Flex,
    Group,
    Pagination,
    Paper,
    ScrollArea,
    Select,
    Table,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { createStyles } from "@mantine/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazySearchRequestQuery } from "src/store";
import { HeadCell } from "src/types";
import { useDeepEqualMemo } from "../../hooks";
import { CustomLoader } from "../forms/custom-loader";
import { HoverText } from "../forms/hover-text";

type Props<E> = {
    headCells: HeadCell<E>[];
    searchApiUrl?: string;
    onRowClick?: (row: E) => void;
    extraFilter?: object;
    doubleClickPath?: (row: E) => void;
    defaultFilter?: object;
    sorting?: { sortBy: string; sortDirection: string };
};

const initResponse = {
    data: { content: [], page: 0, totalElements: 0, totalPages: 0 },
    loading: false,
};

export const AppTable = <Entity extends {}>({
    headCells,
    searchApiUrl,
    extraFilter,
    doubleClickPath,
    defaultFilter,
    sorting,
}: Props<Entity>) => {
    const { t } = useTranslation();

    const [searchRequest, { data: searchData, isLoading, isError }] =
        useLazySearchRequestQuery({
            refetchOnReconnect: true,
            refetchOnFocus: true,
        });

    const rowsPerPageOptions = ["10", "25"];
    const [[page, rowsPerPage], setPagination] = useState([0, 10]);
    const { content, totalElements } = searchData ?? initResponse.data;
    const localFilter = useDeepEqualMemo(extraFilter);
    const { classes } = useStyles();
    const search = async () => {
        return await searchRequest(
            {
                path: searchApiUrl,
                body: {
                    filter: { ...localFilter, ...defaultFilter },
                    pageRequest: { limit: rowsPerPage, page: page },
                    sorting: sorting
                        ? sorting
                        : {
                            sortBy: "ID",
                            sortDirection: "ASC",
                        },
                },
            },
            false
        );
    };
    useEffect(() => {
        if (extraFilter) {
            setPagination([0, rowsPerPage]);
        }
    }, [extraFilter, rowsPerPage]);

    useEffect(() => {
        search();
    }, [
        searchRequest,
        searchApiUrl,
        localFilter,
        rowsPerPage,
        page,
        defaultFilter,
        sorting,
    ]);

    const handleChangePage = (newPage: number) =>
        setPagination([newPage - 1, rowsPerPage]);

    const handleChangeRowsPerPage = (event: string | null) => {
        if (event) {
            setPagination([0, Number.parseInt(event)]);
        }
    };
    const middleScreen = useMediaQuery("(max-width: 992px)");

    const handleDoubleClick = (row: Entity) => doubleClickPath?.(row);

    const head = (
        <Table.Tr style={{ padding: 10 }}>
            {headCells.map((headCell, idx) => {
                if (!headCell.display) {
                    if (middleScreen) {
                        if (idx === 0 || idx === headCells.length - 1) {
                            return (
                                <Table.Th key={headCell.label}>
                                    {headCell.label}
                                </Table.Th>
                            );
                        } else return null;
                    } else {
                        return (
                            <Table.Th key={headCell.label}>
                                {headCell.label}
                            </Table.Th>
                        );
                    }
                } else return null;
            })}
        </Table.Tr>
    );

    const body = content.map((row: Entity, index: number) => (
        <Table.Tr key={index} onDoubleClick={() => handleDoubleClick(row)}>
            {headCells.map((cell, idx) => {
                if (!cell.display) {
                    const value = cell.render?.(row);
                    if (middleScreen) {
                        if (idx === 0 || idx === headCells.length - 1) {
                            return (
                                <Table.Td
                                    key={cell.label}
                                    p={10}
                                    maw={{ base: 150, md: 230 }}
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflowX: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {cell.label === "№" ? (
                                        index + 1 + page * rowsPerPage
                                    ) : value &&
                                        value.toString().length > 50 ? (
                                        <HoverText
                                            width="100%"
                                            text={`${value
                                                .toString()
                                                .slice(0, 50)}...`}
                                            hoverText={value}
                                        />
                                    ) : (
                                        value
                                    )}
                                </Table.Td>
                            );
                        } else return null;
                    } else {
                        return (
                            <Table.Td
                                key={cell.label}
                                p={10}
                                maw={230}
                                style={{
                                    whiteSpace: "nowrap",
                                    overflowX: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {cell.label === "№" ? (
                                    index + 1 + page * rowsPerPage
                                ) : value && value.toString().length > 50 ? (
                                    <HoverText
                                        width="100%"
                                        text={`${value
                                            .toString()
                                            .slice(0, 50)}...`}
                                        hoverText={value}
                                    />
                                ) : (
                                    value
                                )}
                            </Table.Td>
                        );
                    }
                } else return null;
            })}
        </Table.Tr>
    ));

    return (
        <Paper radius="md" mb={{ base: 120, sm: 0 }} className={classes.paper}>
            <ScrollArea
                offsetScrollbars
                scrollbarSize={8}
                style={{ width: "100%", minHeight: "29.1rem" }}
            >
                {isLoading ?
                    <Box pos="relative" w="100%" mih="40vh" h="100%">
                        <CustomLoader />
                    </Box> : isError || !content[0] ? (
                        <Flex align="center" justify="center">
                            {t("notify.list-not-found")}
                        </Flex>
                    ) : (
                        <Table
                            captionSide="bottom"
                            highlightOnHover
                            verticalSpacing="sm"
                            style={{ cursor: "pointer" }}
                        >
                            <Table.Thead>{head}</Table.Thead>
                            <Table.Tbody>{body}</Table.Tbody>
                        </Table>
                    )}
            </ScrollArea>
            <Group display="flex" justify="right">
                <p style={{ fontSize: 13 }}>
                    {t`table.pagination.rowInPage`}:{" "}
                </p>
                <Select
                    style={{ width: "60px", textAlign: "center" }}
                    radius="xl"
                    size="xs"
                    data={rowsPerPageOptions}
                    value={rowsPerPage.toString()}
                    onChange={(e) => handleChangeRowsPerPage(e)}
                />
                <Pagination
                    total={Math.ceil(totalElements / rowsPerPage)}
                    onChange={handleChangePage}
                    size="xs"
                    radius="lg"
                    color={isLoading ? "gray" : "indigo"}
                />
            </Group>
        </Paper>
    );
};

const useStyles = createStyles(() => ({
    paper: {
        padding: "1rem",
        minWidth: "100%",
        minHeight: "37.8rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
    },
}));
