import { Button, Container, Flex, Grid, Paper } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "src/components";
import {
    useCreateConstitutionMutation,
    useUpdateConstitutionMutation,
} from "src/store";
import { notify } from "src/utils";
import { MainForm, SectionForm } from "./modules";

type Props = {
    constitution?: ConstResponseType;
};

const initialValues: ConsFormType = {
    id: 0,
    name: "",
    type: "CHAPTER",
    list: [
        {
            id: Math.floor(Math.random() * 10000) + 1,
            articles: "",
            list: [
                {
                    id: Math.floor(Math.random() * 10000) + 1,
                    articles: "",
                    list: [],
                    contentKg: "",
                    contentRu: "",
                    nameKg: "",
                    nameRu: "",
                },
            ],
            contentKg: "",
            contentRu: "",
            nameKg: "",
            nameRu: "",
        },
    ],
};

export const ConstitutionForm = ({ constitution }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [createConst] = useCreateConstitutionMutation();
    const [updateConst] = useUpdateConstitutionMutation();
    const [active, setActive] = useState(-1);
    const chapters = constitution?.consChapters || [];
    const sections = constitution?.consSections || [];

    const combinedSections = chapters
        .map((chapter) => ({
            id: chapter?.id,
            articles: chapter?.articles,
            list: chapter?.consSections.map((child: ConsSectionDto) => ({
                id: child?.id,
                articles: child?.articles,
                contentKg: child?.contentKg,
                contentRu: child?.contentRu,
                list: [],
                nameKg: child?.nameKg,
                nameRu: child?.nameRu,
            })),
            contentKg: chapter?.contentKg,
            contentRu: chapter?.contentRu,
            nameKg: chapter?.nameKg,
            nameRu: chapter?.nameRu,
        }))
        .concat(
            sections.map((section) => ({
                id: section?.id,
                articles: section?.articles,
                list: section?.consChapterList.map((child) => ({
                    id: child?.id,
                    articles: child?.articles,
                    contentKg: child?.contentKg,
                    contentRu: child?.contentRu,
                    list: [],
                    nameKg: child?.nameKg,
                    nameRu: child?.nameRu,
                })),
                contentKg: section?.contentKg,
                contentRu: section?.contentRu,
                nameKg: section?.nameKg,
                nameRu: section?.nameRu,
            }))
        );

    const form = useForm<ConsFormType>({
        initialValues: !constitution
            ? initialValues
            : {
                id: constitution.id,
                name: constitution.name,
                type: constitution.type,
                list: combinedSections,
            },
    });

    const handleSubmit = async (values: ConsFormType) => {
        if (constitution) {
            let result: ConstUpdateRequestType = {
                consChapterUpdateRequestList: [],
                consSectionUpdateRequestList: [],
                name: "",
                type: values.type,
                id: values.id,
            };
            let sectionResult: ConsSectionRequest[] = [];
            const { list, ...data } = values;
            if (values.type === "CHAPTER") {
                list.map((el) => {
                    const { list, ...others } = el;
                    sectionResult.push({
                        ...others,
                        //@ts-ignore
                        consSectionUpdateRequestList: [...list],
                    });
                    return el;
                });
                result = {
                    id: data.id,
                    name: data.name,
                    type: data.type,
                    toDeleteConsChapters: data.toDeleteConsChapters,
                    toDeleteConsSections: data.toDeleteConsSections,
                    //@ts-ignore
                    consChapterUpdateRequestList: [...sectionResult],
                };
            } else {
                list.map((el) => {
                    const { list, ...others } = el;
                    sectionResult.push({
                        ...others,
                        //@ts-ignore
                        consChapterUpdateRequestList: [...list],
                    });
                    return el;
                });
                result = {
                    id: data.id,
                    name: data.name,
                    type: data.type,
                    toDeleteConsChapters: data.toDeleteConsChapters,
                    toDeleteConsSections: data.toDeleteConsSections,
                    //@ts-ignore
                    consSectionUpdateRequestList: [...sectionResult],
                };
            }
            try {
                await updateConst(result).unwrap();
                notify(true, t("notify.updated"));
                navigate("/constitution");
            } catch {
                notify(false);
            }
        } else {
            let result: ConstRequestType = {
                consChapterRequestList: [],
                consSectionRequestList: [],
                name: "",
                type: values.type,
                id: values.id,
            };
            let sectionResult: ConsSectionRequest[] = [];
            const { list, ...data } = values;
            if (values.type === "CHAPTER") {
                list.map((el) => {
                    const { list, ...others } = el;
                    sectionResult.push({
                        ...others,
                        //@ts-ignore
                        consSectionRequestList: [...list],
                    });
                    return el;
                });
                result = {
                    id: data.id,
                    name: data.name,
                    type: data.type,
                    //@ts-ignore
                    consChapterRequestList: [...sectionResult],
                };
            } else {
                list.map((el) => {
                    const { list, ...others } = el;
                    sectionResult.push({
                        ...others,
                        //@ts-ignore
                        consChapterRequestList: [...list],
                    });
                    return el;
                });
                result = {
                    id: data.id,
                    name: data.name,
                    type: data.type,
                    consSectionRequestList: [...sectionResult],
                };
            }
            try {
                await createConst(result).unwrap();
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/constitution");
            } catch {
                notify(false);
            }
        }
    };
    const render = (active: number) => {
        switch (active) {
            case -1:
                return <MainForm form={form} />;
            default:
                return <SectionForm active={active} form={form} />;
        }
    };

    return (
        <Container size="xl" p={0}>
            <Flex wrap="wrap" gap={10}>
                <Button onClick={() => setActive(-1)} bg="primaryDark">
                    {t("statics.main")}
                </Button>
                {form.values.list.map((el, idx) => (
                    <Flex key={el.id}>
                        <Button
                            style={{ borderRadius: "0" }}
                            bg="primaryDark"
                            onClick={() => setActive(idx)}
                        >
                            {idx + 1}{" "}
                            {t(
                                `statics.${form.values.type === "CHAPTER"
                                    ? "chapter"
                                    : "section"
                                }`
                            )}
                        </Button>
                    </Flex>
                ))}
                <Button
                    onClick={() =>
                        form.insertListItem("list", {
                            articles: "",
                            list: [],
                            contentKg: "",
                            contentRu: "",
                            nameKg: "",
                            nameRu: "",
                        })
                    }
                    bg="primaryDark"
                >
                    <IconPlus size={20} />
                </Button>
            </Flex>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        {render(active)}
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(`/constitution/${i18n.language}`)
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
