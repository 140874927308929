import { Box, Flex, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { regionsId } from "src/constants";
import {
    BatkenMapSvg,
    ChuyMapSvg,
    JalalAbadMapSvg,
    NarynMapSvg,
    OshMapSvg,
    TalasMapSvg,
    YsykKolMapSvg,
} from "./regions";
import styles from "./styles.module.scss";

interface KgMapProps {
    selected: number;
    setSelected: (p: number) => void;
}

interface RegionTitleProps {
    isCitySelected?: boolean;
    isRegionSelected?: boolean;
    top: string;
    left: string;
    region: string;
    onClick: () => void;
    city?: string;
}

export const KgMap = ({ selected, setSelected }: KgMapProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles.kg_map_root}>
            <div className={styles.kg_map}>
                <RegionTitle
                    isRegionSelected={selected === regionsId.talas}
                    top={"60px"}
                    left={"150px"}
                    region={t("regions.talas")}
                    onClick={() => setSelected(regionsId.talas)}
                />
                <TalasMapSvg
                    fill={selected === regionsId.talas ? "#0B1F7C" : "white"}
                    opacity={selected === regionsId.talas ? "0.4" : "1"}
                    onClick={() => setSelected(regionsId.talas)}
                    styles={{
                        position: "absolute",
                        top: "47px",
                        left: "130px",
                        cursor: "pointer",
                    }}
                />
                <RegionTitle
                    isCitySelected={selected === regionsId.bishkek}
                    isRegionSelected={selected === regionsId.chuy}
                    top={"30px"}
                    left={"350px"}
                    region={t("regions.chuy")}
                    onClick={() =>
                        setSelected(
                            selected === regionsId.chuy
                                ? regionsId.bishkek
                                : regionsId.chuy
                        )
                    }
                    city={t("regions.bishkek")}
                />
                <ChuyMapSvg
                    fill={
                        selected === regionsId.chuy ||
                            selected === regionsId.bishkek
                            ? "#0B1F7C"
                            : "white"
                    }
                    opacity={
                        selected === regionsId.chuy ||
                            selected === regionsId.bishkek
                            ? "0.4"
                            : "1"
                    }
                    onClick={() => setSelected(selected === 1 ? 2 : 1)}
                    styles={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "0",
                        left: "308px",
                    }}
                />
                <RegionTitle
                    isRegionSelected={selected === regionsId.ysykKol}
                    top={"80px"}
                    left={"630px"}
                    region={t("regions.ysyk-kol")}
                    onClick={() => setSelected(regionsId.ysykKol)}
                />
                <YsykKolMapSvg
                    fill={selected === regionsId.ysykKol ? "#0B1F7C" : "white"}
                    opacity={selected === regionsId.ysykKol ? "0.4" : "1"}
                    onClick={() => setSelected(regionsId.ysykKol)}
                    styles={{
                        position: "absolute",
                        top: "37px",
                        left: "514px",
                        cursor: "pointer",
                    }}
                />
                <RegionTitle
                    isRegionSelected={selected === regionsId.jalalAbad}
                    top={"150px"}
                    left={"190px"}
                    region={t("regions.jalal-abad")}
                    onClick={() => setSelected(regionsId.jalalAbad)}
                />
                <JalalAbadMapSvg
                    fill={
                        selected === regionsId.jalalAbad ? "#0B1F7C" : "white"
                    }
                    opacity={selected === regionsId.jalalAbad ? "0.4" : "1"}
                    onClick={() => setSelected(regionsId.jalalAbad)}
                    styles={{
                        position: "absolute",
                        top: "115px",
                        left: "73px",
                        cursor: "pointer",
                    }}
                />
                <RegionTitle
                    isRegionSelected={selected === regionsId.naryn}
                    top={"180px"}
                    left={"450px"}
                    region={t("regions.naryn")}
                    onClick={() => setSelected(regionsId.naryn)}
                />
                <NarynMapSvg
                    fill={selected === regionsId.naryn ? "#0B1F7C" : "white"}
                    opacity={selected === regionsId.naryn ? "0.4" : "1"}
                    onClick={() => setSelected(regionsId.naryn)}
                    styles={{
                        position: "absolute",
                        top: "87px",
                        left: "360px",
                        cursor: "pointer",
                    }}
                />
                <RegionTitle
                    isRegionSelected={selected === regionsId.batken}
                    top={"340px"}
                    left={"90px"}
                    region={t("regions.batken")}
                    onClick={() => setSelected(regionsId.batken)}
                />
                <BatkenMapSvg
                    fill={selected === regionsId.batken ? "#0B1F7C" : "white"}
                    opacity={selected === regionsId.batken ? "0.4" : "1"}
                    onClick={() => setSelected(regionsId.batken)}
                    styles={{
                        position: "absolute",
                        top: "317px",
                        left: "-1px",
                        cursor: "pointer",
                    }}
                />
                <RegionTitle
                    isRegionSelected={selected === regionsId.osh}
                    isCitySelected={selected === regionsId.oshCity}
                    top={"290px"}
                    left={"270px"}
                    region={t("regions.osh")}
                    onClick={() =>
                        setSelected(
                            selected === regionsId.osh
                                ? regionsId.oshCity
                                : regionsId.osh
                        )
                    }
                    city={t("regions.osh-city")}
                />
                <OshMapSvg
                    fill={
                        selected === regionsId.osh ||
                            selected === regionsId.oshCity
                            ? "#0B1F7C"
                            : "white"
                    }
                    opacity={
                        selected === regionsId.osh ||
                            selected === regionsId.oshCity
                            ? "0.4"
                            : "1"
                    }
                    onClick={() =>
                        setSelected(
                            selected === regionsId.osh
                                ? regionsId.oshCity
                                : regionsId.osh
                        )
                    }
                    styles={{
                        position: "absolute",
                        top: "230px",
                        left: "181px",
                        cursor: "pointer",
                    }}
                />
            </div>
        </div>
    );
};

const RegionTitle = ({
    isRegionSelected,
    top,
    left,
    region,
    onClick,
    city,
    isCitySelected,
}: RegionTitleProps) => {
    return (
        <Flex
            pos="absolute"
            display="flex"
            align="center"
            justify="center"
            direction="column"
            top={top}
            left={left}
            onClick={onClick}
            style={{
                zIndex: 5,
                cursor: "pointer",
            }}
        >
            <Flex
                mb={5}
                className={styles.map_title_icon}
                bg="none"
                display="flex"
                direction="column"
                align="center"
                justify="center"
                style={{
                    transform: isRegionSelected ? "scale(1.1)" : "scale(1)",
                }}
            >
                <Box
                    className={styles.map_title_icon_dot}
                    bg="primaryDark"
                ></Box>
            </Flex>
            {city && (
                <Text
                    lh={1}
                    w={125}
                    fz="sm"
                    fw="normal"
                    ta="center"
                    mb={5}
                    c={isCitySelected ? "primaryDark" : "secondary"}
                    style={{
                        transform: isCitySelected ? "scale(1.2)" : "scale(1)",
                        transition: "300ms",
                    }}
                >
                    {city}
                </Text>
            )}
            <Text
                lh={1}
                w={125}
                fz="sm"
                fw="normal"
                ta="center"
                c={isRegionSelected ? "primaryDark" : "secondary"}
                style={{
                    transform: isRegionSelected ? "scale(1.2)" : "scale(1)",
                    transition: "300ms",
                }}
            >
                {region}
            </Text>
        </Flex>
    );
};
