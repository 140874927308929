import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Structure } from "src/components";
import { IS_MINUS_ONE } from "src/constants";
import { useGetPageStructureQuery } from "src/store";

type Props = {
    open: boolean;
    setParentId: (id: number | null) => void;
    setChildId: (id: number | null) => void;
};
const defaultData = {
    id: IS_MINUS_ONE,
    key: IS_MINUS_ONE,
    titleKg: "Бардыгы",
    titleRu: "Все",
    titleEn: "All",
};
export const PageStructure = ({ open, setParentId, setChildId }: Props) => {
    const { t } = useTranslation();
    const { data } = useGetPageStructureQuery(
        {},
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const item = data?.map((item: PageType) => ({
        id: item.id,
        key: item.id,
        titleKg: item.titleKg,
        titleRu: item.titleRu,
        titleEn: item.titleEn,
        childrens: item.childrens?.map(child => ({
            id: child.id,
            key: child.id,
            titleKg: child.titleKg,
            titleRu: child.titleRu,
            titleEn: child.titleEn,
        })),
    })) || [];
    useEffect(() => {
        if (data) {
            setParentId(data?.[0]?.id);
        }
    }, [data]);
    return (
        <Structure
            title={t`data.employee.structure`}
            data={item ? [...item, defaultData] : [defaultData]}
            open={open}
            setParentId={setParentId}
            setChildId={setChildId}
        />
    );
};
