import { Carousel } from "@mantine/carousel";
import { Box, Center, Flex, Image, Modal, Paper, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import Autoplay from "embla-carousel-autoplay";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";
import { useGetByPhotoAlbumQuery, useGetPhotoQuery } from "src/store";
import { NewsResponse } from "src/types/data-models/news";

type Props = {
    data: NewsResponse;
};
export const PhotoNewsItem = ({ data }: Props) => {
    const [opened, { toggle }] = useDisclosure()
    const { i18n } = useTranslation()
    const autoplay = useRef(
        Autoplay({ delay: 2500, stopOnInteraction: false })
    );
    const [state, setState] = useState<number>(
        (data?.photoAlbum && data?.photoAlbum?.id) || 1
    );
    const { data: image } = useGetPhotoQuery(state);
    const { data: photos } = useGetByPhotoAlbumQuery(data?.photoAlbum?.id, {
        skip: !data?.photoAlbum?.id
    });
    const title = getTitleByLanguage(data)
    const slicedTitle = title.length > 70 ? title.slice(0, 70) + "..." : title

    const handleSlider = (image: number) => {
        setState(image);
    };
    useEffect(() => {
        if (data?.photoAlbum?.id) {
            setState(data?.photoAlbum?.id);
        }
    }, [data]);

    return (
        <Box>
            <Paper onClick={toggle} style={{ cursor: "pointer" }} p={10} w={300} h={280}>
                <Box w="100%" h={200}>
                    {data?.photoAlbum?.photo ?
                        <Image mb={10} style={{ objectFit: "contain" }}
                            src={`data:image/png;base64,${data?.photoAlbum?.photo}`} />
                        :
                        <Center h="100%" w="auto" >
                            <Image h="100%" w="auto" src="/logo.svg" />
                        </Center>}
                </Box>
                <Flex align="center" gap={10}>
                    <Text fz={12} c="primaryDark">
                        {dayjs(data.publishedAt).format(
                            i18n.language === "ru"
                                ? "D MMMM YYYY"
                                : "D-MMMM YYYY"
                        )}
                    </Text>
                    <Image src="/icons/calendar.svg" />
                </Flex>
                <Text c="primaryDark" fz={12}>
                    {slicedTitle}
                </Text>
            </Paper>
            <Modal centered size="xl" opened={opened} onClose={toggle}>
                {data?.photoAlbum?.photo && (
                    <Paper
                        mt={24}
                        variant="bordered"
                        p={24}
                        w="100%"
                        mb={40}
                        style={{ borderRadius: "2px" }}
                    >
                        <Image
                            src={`data:image/webp;base64,${photos &&
                                photos?.length > 1 ?
                                image?.original : data.photoAlbum?.photo}`}
                            w="100%"
                            h="100%"
                            mb={
                                photos && photos?.length > 1 ? 40 : 0
                            }
                            mah={{
                                base: 300,
                                xs: 450,
                                md: 550,
                            }}
                            alt=""
                        />
                        {photos && photos?.length > 1 && (
                            <Box>
                                <Carousel
                                    withControls={true}
                                    nextControlProps={{
                                        style:
                                            { color: "white", background: "#0A164F" }
                                    }}
                                    previousControlProps={{
                                        style:
                                            { color: "white", background: "#0A164F" }
                                    }}
                                    onSlideChange={(idx) =>
                                        handleSlider(
                                            photos[idx].id
                                        )
                                    }
                                    loop={true}
                                    plugins={[autoplay.current]}
                                    onMouseEnter={
                                        autoplay.current.stop
                                    }
                                    onMouseLeave={
                                        autoplay.current.reset
                                    }
                                    slideSize={"40%"}
                                    align="start"
                                    slideGap="1rem"
                                >
                                    {photos?.map(
                                        (item, index) => (
                                            <CarouselItem
                                                key={item.id}
                                                handleSlider={
                                                    handleSlider
                                                }
                                                index={index}
                                                item={item}
                                            />
                                        )
                                    )}
                                </Carousel>
                            </Box>
                        )}
                    </Paper>
                )}
            </Modal>
        </Box>
    );
};


interface ItemProps {
    item: IPhoto;
    index: number;
    handleSlider: (a: number) => void;
}

export const CarouselItem = ({ item, index, handleSlider }: ItemProps) => {
    const { data } = useGetPhotoQuery(item.id);
    return (
        <Carousel.Slide
            style={{ cursor: "pointer" }}
            onClick={() => handleSlider(item.id)}
        >
            <Image
                bg="black"
                h={{ base: 100, sm: 150 }}
                src={`data:image/png;base64,${data?.original}`}
                alt=""
                w="100%"
                radius="0.5rem"
            />
        </Carousel.Slide>
    );
};