import { Text } from "@mantine/core";
import JoditEditor, { Jodit } from "jodit-react";
import { useMemo, useRef } from "react";

interface IProps {
    label: string;
    onChange: (value: string) => void;
    formData?: string;
    labelButton?: React.ReactNode;
}

export const CustomTextEditor = ({
    label,
    onChange,
    formData,
    labelButton,
}: IProps) => {
    const editor = useRef<Jodit | null>(null);

    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: label || "Start typing...",
            uploader: {
                insertImageAsBase64URI: true,
            },
            controls: {
                font: {
                    list: {
                        "Rubik, sans-serif": "Rubik",
                    },
                },
            },
        }),
        [label]
    );

    return (
        <>
            <Text style={{ fontWeight: 600, fontSize: 12 }}>
                {label}
                {labelButton}
            </Text>
            <JoditEditor
                ref={editor}
                value={formData ? formData : ""}
                config={config}
                onBlur={onChange}
            />
        </>
    );
};
