import { Box, Paper, Title } from "@mantine/core";
import { CustomLoader } from "src/components";
import { getTitleByLanguage } from "src/locales";
import {
    HorizontalChart,
    PieChart,
    VerticalChart,
} from "src/modules/open-part/statistic/charts";
import { useDiagramGetByIdQuery } from "src/store";

type Props = {
    diagramId: string;
};

export const DiagramDetails = ({ diagramId }: Props) => {
    const { data: diagramData, isLoading } = useDiagramGetByIdQuery(diagramId, {
        refetchOnMountOrArgChange: true,
    });

    return isLoading ? (
        <CustomLoader />
    ) : (
        <Paper shadow="sm" p="xl">
            <Paper shadow="sm" p="xl">
                <Box ta="center" py={30} my={10} key={diagramData?.id}>
                    <Title
                        fz={{ base: 18, md: 20, lg: 24 }}
                        c="primaryDark"
                        mt={0}
                    >
                        {getTitleByLanguage(diagramData)}
                    </Title>
                    <Title fz={{ base: 15, md: 18 }} c="primaryDark">
                        {getTitleByLanguage(
                            diagramData?.diagramSpecificationDto
                        )}
                    </Title>
                    {diagramData?.type === "VERTICAL_BAR" ? (
                        <VerticalChart data={diagramData} />
                    ) : diagramData?.type === "HORIZONTAL_BAR" ? (
                        <HorizontalChart data={diagramData} />
                    ) : (
                        <PieChart data={diagramData} />
                    )}
                </Box>
            </Paper>
        </Paper>
    );
};
