import { Title } from "@mantine/core";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppTable, CustomModal, MenuDropDown } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useNewsDeleteMutation } from "src/store";
import { HeadCell } from "src/types";
import { News, NewsFilterType } from "src/types/data-models/news";
import { Path, notify } from "src/utils";

export const NewsTable = ({ filter }: { filter?: NewsFilterType }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [newsDelete] = useNewsDeleteMutation();

    const [id, setId] = useState<number | null>();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (id: number) => {
        setOpen(true);
        setId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await newsDelete(id);
            notify(true, t("notify.deleted"));
        } catch (error) {
            notify(false);
        }
        setOpen(false);
    };

    const actions = (id: number) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/news/${id}/edit-news`,
        },
        {
            icon: IconEye,
            title: t`actions.view`,
            to: `/news/${id}`,
        },
        {
            icon: IconTrash,
            title: t`actions.delete`,
            onClick: () => handleOpen(id),
        },
    ];

    const headCells: HeadCell<News>[] = [
        {
            label: t`table.title`,
            render: (n) => getTitleByLanguage(n),
        },
        {
            label: t`table.dateFrom`,
            render: (n) =>
                `${n.publishedAt.split("T")[0]}-${n.publishedAt
                    .split("T")[1]
                    .slice(0, 5)}`,
        },
        {
            label: t`form.values.tags`,
            render: (n) => n.tags?.length ? `${n.tags?.map(el => `${getTitleByLanguage(el)}; `)}` : "",
        },
        {
            label: t`actions.title`,
            render: (n) => (
                <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
            ),
        },
    ];

    return (
        <>
            <AppTable<News>
                headCells={headCells}
                doubleClickPath={(n) =>
                    navigate(`/news/${n.id}/${i18n.language}`)
                }
                searchApiUrl={Path.News.search}
                extraFilter={filter}
                sorting={{ sortBy: "PUBLISHED_AT", sortDirection: "DESC" }}
            />
            <CustomModal
                labelSubmit={"delete"}
                open={open}
                handleClose={handleClose}
                onClick={handleDelete}
            >
                <Title
                    order={3}
                    fw={400}
                    mt="5px"
                    mb="2rem"
                    style={{ textAlign: "center" }}
                >
                    {t`button.delete`}
                </Title>
            </CustomModal>
        </>
    );
};
