import {
    Container,
    Flex,
    Grid,
    Image,
    Paper,
    Select,
    UnstyledButton
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, CustomDropzone, CustomInputText } from "src/components";
import { getTitleByLanguage } from "src/locales";
import {
    useCreateInfoBlockMutation,
    useGetAllByTypeCodeQuery,
    useUpdateInfoBlockMutation
} from "src/store/reducers";
import { TypeCodesEnum } from "src/types";
import { getAttachmentUrl, notify } from "src/utils";

type Props = {
    infoBlock?: InfoBlockResponseType;
};

export const InfoBlockForm = ({ infoBlock }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [photos, setPhotos] = useState<IAttachment[] | undefined>(
        infoBlock?.photos
    );
    const { data: typeCodes } = useGetAllByTypeCodeQuery(TypeCodesEnum.INFO_BLOCK_TYPE)
    const [files, setFiles] = useState<IAttachment[] | undefined | null>(
        infoBlock?.files
    );
    const typesId = typeCodes?.map(el => ({
        label: getTitleByLanguage(el),
        value: `${el.id}`
    }))

    const [createNews] = useCreateInfoBlockMutation();
    const [updateNews] = useUpdateInfoBlockMutation();

    const form = useForm<InfoBlockRequestType>({
        initialValues: {
            attachmentsFile: null,
            attachmentsPhoto: null,
            infoBlockTypeId: infoBlock?.infoBlockType?.id || 0,
            textEn: infoBlock?.textEn || "",
            textKg: infoBlock?.textKg || "",
            textRu: infoBlock?.textRu || "",
            periodFrom: infoBlock?.periodFrom || "",
            periodTo: infoBlock?.periodTo || "",
            titleEn: infoBlock?.titleEn || "",
            titleKg: infoBlock?.titleKg || "",
            titleRu: infoBlock?.titleRu || "",
        },
        validate: {
            titleRu: (value) => !value && t("form.validation.required"),
            titleKg: (value) => !value && t("form.validation.required"),
        },
    });
    const handleDeletePhoto = (id: number) => {
        const newArr = photos?.filter((el) => el.id !== id);
        setPhotos(newArr);
        form.setFieldValue(
            "toDeleteAttachmentsPhoto",
            form.values.toDeleteAttachmentsPhoto?.length
                ? [...form.values.toDeleteAttachmentsPhoto, id]
                : [id]
        );
    };
    const handleDeleteFile = (id: number) => {
        setFiles((files) => files?.filter((el) => el.id !== id));
        form.setFieldValue(
            "toDeleteAttachmentsFile",
            form.values.toDeleteAttachmentsFile?.length
                ? [...form.values.toDeleteAttachmentsFile, id]
                : [id]
        );
    };

    const handleSubmit = async ({
        attachmentsFile,
        attachmentsPhoto,
        ...values
    }: InfoBlockRequestType) => {
        if (infoBlock) {
            try {
                await updateNews({
                    id: infoBlock.id,
                    body: values,
                    attachmentsPhoto,
                    attachmentsFile,
                }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/info_block");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createNews({
                    attachmentsFile,
                    attachmentsPhoto,
                    ...values,
                }).unwrap();
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/info_block");
            } catch {
                notify(false);
            }
        }
    };

    return (
        <Container size="xl" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <Select
                                label={t("form.label.type")}
                                data={typesId}
                                {...form.getInputProps("infoBlockTypeId")}
                                value={`${form.values.infoBlockTypeId}`}
                                placeholder={t("form.label.type")}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <DateTimePicker
                                {...form.getInputProps("periodFrom")}
                                label={t`form.label.periodFrom`}
                                error={form.errors?.periodFrom}
                                value={
                                    form.values?.periodFrom
                                        ? new Date(form.values?.periodFrom)
                                        : undefined
                                }
                                required
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <DateTimePicker
                                {...form.getInputProps("periodTo")}
                                label={t`form.label.periodTo`}
                                error={form.errors?.periodTo}
                                value={
                                    form.values?.periodTo
                                        ? new Date(form.values?.periodTo)
                                        : undefined
                                }
                                required
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleKg")}
                                label={t`form.label.titleKg`}
                                required
                                error={form.errors.titleKg}
                                formData={form.values.titleKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleRu")}
                                label={t`form.label.titleRu`}
                                required
                                error={form.errors.titleRu}
                                formData={form.values.titleRu}
                            />
                        </Grid.Col>
                        {typeCodes?.find(el => el.id == form.values.infoBlockTypeId)?.titleEn === "statistic1" && (
                            <Grid.Col span={12}>
                                <CustomInputText
                                    {...form.getInputProps("textEn")}
                                    label={t("form.label.value")}
                                    error={form.errors.textEn}
                                    formData={form.values.textEn}
                                />
                            </Grid.Col>
                        )}
                        {typeCodes?.find(el => el.id == form.values.infoBlockTypeId)?.titleEn === "statistic2" && (
                            <>
                                <Grid.Col span={12}>
                                    <CustomInputText
                                        {...form.getInputProps("textEn")}
                                        label={t("form.label.value")}
                                        error={form.errors.textEn}
                                        formData={form.values.textEn}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <CustomInputText
                                        {...form.getInputProps("textKg")}
                                        label={t("form.label.textKg")}
                                        error={form.errors.textKg}
                                        formData={form.values.textKg}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <CustomInputText
                                        {...form.getInputProps("textRu")}
                                        label={t("form.label.textRu")}
                                        error={form.errors.textRu}
                                        formData={form.values.textRu}
                                    />
                                </Grid.Col>
                            </>
                        )}
                        <Grid.Col span={12}>
                            <CustomDropzone
                                label={t`form.label.photo_collage`}
                                error={form.errors.attachmentsPhoto}
                                {...form.getInputProps("attachmentsPhoto")}
                                accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                            >
                                {photos && (
                                    <Flex mt={10} wrap="wrap" gap={10}>
                                        {photos?.map(
                                            (el) =>
                                                "id" in el && (
                                                    <Flex
                                                        direction="column"
                                                        align="center"
                                                        key={el.id}
                                                    >
                                                        <UnstyledButton
                                                            onClick={() =>
                                                                handleDeletePhoto(
                                                                    el.id
                                                                )
                                                            }
                                                        >
                                                            <IconTrash color="red" />
                                                        </UnstyledButton>
                                                        <Image
                                                            w={100}
                                                            alt=""
                                                            src={getAttachmentUrl(
                                                                el.id || 0,
                                                                `${el.uuid}`
                                                            )}
                                                        />
                                                    </Flex>
                                                )
                                        )}
                                    </Flex>
                                )}
                            </CustomDropzone>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomDropzone
                                label={t`form.label.file`}
                                error={form.errors.attachmentsFile}
                                {...form.getInputProps("attachmentsFile")}
                                accept={[MIME_TYPES.pdf, MIME_TYPES.docx]}
                            >
                                {files && (
                                    <Flex mt={10} wrap="wrap" gap={10}>
                                        {files?.map(
                                            (el) =>
                                                "id" in el && (
                                                    <Flex
                                                        direction="column"
                                                        align="center"
                                                        key={el.id}
                                                    >
                                                        <UnstyledButton
                                                            onClick={() =>
                                                                handleDeleteFile(
                                                                    el.id
                                                                )
                                                            }
                                                        >
                                                            <IconTrash color="red" />
                                                        </UnstyledButton>
                                                        <Image
                                                            w={100}
                                                            alt=""
                                                            src="/document.png"
                                                        />
                                                    </Flex>
                                                )
                                        )}
                                    </Flex>
                                )}
                            </CustomDropzone>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(`/info_block/${i18n.language}`)
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
