import {
    Box,
    Button,
    Flex,
    Image,
    Input,
    Paper,
    Text,
    Title
} from "@mantine/core";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { SearchPagesResults } from "src/components";
import { useLocalStorage } from "src/hooks";
import { getTitleByLanguage } from "src/locales";
import { getJsonFiles } from "src/utils";
import styles from "./footer.module.scss";
import { GoogleAnalyticsDataBox } from "./google-analytics";
import { PageLinks } from "./page-links";

export const Footer = () => {
    const { t, i18n } = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    const { pathname } = useLocation();
    const [socialLinks, setSocialLinks] = useState<SocialLinkType[]>();
    const [pages, setPages] = useState<PageListType[]>();
    const [offices, setOffices] = useState<ProsecutorOfficeNameType[]>();



    const handleOnChange = (el: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(el.target.value);
    };

    useEffect(() => {
        getJsonFiles("social-links").then((data) => {
            setSocialLinks(data);
        });
        getJsonFiles("footer-pages").then((data) => {
            setPages(data);
        });
        getJsonFiles("prosecutor-offices").then((data) => {
            setOffices(data);
        });
    }, []);
    return (
        <footer>
            {(pathname === "/ru" ||
                pathname === "/kg" ||
                pathname.includes("addresses") ||
                pathname.includes("contact")) && (
                    <Flex
                        className={styles.links}
                        px={10}
                        py={{ base: 10, md: 20 }}
                        bg="weakBlue"
                        pos="relative"
                    >
                        {offices?.map((el) => (
                            <Link
                                key={el.titleRu}
                                className={styles.links_item}
                                to={`${el.path}/${i18n.language}`}
                            >
                                <Title
                                    style={{ whiteSpace: "pre-line" }}
                                    lh={1.2}
                                    fz={{ base: 14, sm: 16 }}
                                    fw="bold"
                                    c="primary"
                                >
                                    {getTitleByLanguage(el)}
                                </Title>
                            </Link>
                        ))}
                    </Flex>
                )}
            <Paper className={styles.footer} pos="relative">
                <Flex
                    className={styles.footer_items}
                    justify="space-between"
                    pos="relative"
                    gap={{ base: 14, xs: 30, md: 120 }}
                    mb={{ base: 0, sm: 50 }}
                    w="100%"
                    style={{ zIndex: 2 }}
                >
                    <Box>
                        <PageLinks pages={pages} />
                        <Box display={{ base: "block", md: "none" }}>
                            <a href="/" className="bvi-class">
                                <Title
                                    fz={{ base: 14, md: 18 }}
                                    lh={1.2}
                                    c="secondaryDark"
                                    fw="normal"
                                    td="underline"
                                >
                                    {t("statics.version-for-people-with-disabilities")}
                                </Title>
                            </a>
                        </Box>
                    </Box>
                    <Box>
                        <Flex
                            maw={{ base: 300, md: "none" }}
                            c="secondary"
                            display="flex"
                            direction="column"
                            gap={15}
                            mb={20}
                        >
                            <Title fz={{ base: 16, md: 18 }} order={4} c="primaryDark">
                                {t("links.contact")}
                            </Title>
                            <a
                                href="https://maps.app.goo.gl/vjp34V9AyTD4KQJZ6"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "var(--primaryDark)" }}
                            >
                                <Text c="primaryDark" fz={{ base: 14, md: 16 }}>
                                    {t("statics.address")} <br />
                                    {t("titles.logo")}
                                </Text>
                            </a>
                            <a
                                href="tel:+996312542818"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "var(--primaryDark)" }}
                            >
                                <Text c="primaryDark" fz={{ base: 14, md: 16 }}>
                                    +996 312 54 28 18
                                </Text>
                            </a>
                            <a
                                href="mailto: statement@prokuror.kg"
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    color: "var(--primaryDark)",
                                    textDecoration: "underline",
                                }}
                            >
                                <Text c="primaryDark" fz={{ base: 14, md: 16 }}>
                                    statement@prokuror.kg
                                </Text>
                            </a>
                        </Flex>
                        <Box>
                            <Title lh={1.2} fz={{ base: 14, md: 18 }} c="primaryDark" mb={10}>
                                {t("titles.gen-prokuratura-in-social-medias")}:
                            </Title>
                            <nav style={{ display: "flex", gap: "15px" }}>
                                {socialLinks?.map((el) => (
                                    <a target="_blank" rel="noreferrer" key={el.id} href={el.path}>
                                        <Image h={25}
                                            className={styles.social_link_icon}
                                            src={el.iconDark}
                                        />
                                    </a>
                                ))}
                            </nav>
                        </Box>
                        <FooterChangeLang
                            display={{ base: "block", md: "none" }}
                        />
                        <Box display={{ base: "none", xs: "block", md: "none" }} mt={24}>
                            <GoogleAnalyticsDataBox />
                        </Box>
                    </Box>
                    <Flex
                        className={styles.footer_items_inputside}
                        direction="column"
                        gap="15px"
                    >
                        <Input
                            className={styles.footer_search}
                            width="100%"
                            placeholder={t("statics.search")}
                            value={searchValue}
                            onChange={handleOnChange}
                            style={{ color: "black" }}
                        />
                        <a href="/" className="bvi-class">
                            <Title
                                order={5}
                                c="secondaryDark"
                                fw="normal"
                                td="underline"
                            >
                                {t(
                                    "statics.version-for-people-with-disabilities"
                                )}
                            </Title>
                        </a>
                        <FooterChangeLang
                            display={{ base: "none", md: "block" }}
                        />
                        <Box display={{ base: "none", md: "block" }} mt={24}>
                            <GoogleAnalyticsDataBox />
                        </Box>
                    </Flex>
                </Flex>
                <Box display={{ base: "block", xs: "none" }} mt={24}>
                    <GoogleAnalyticsDataBox />
                </Box>
                <Box className={styles.footer_logo}>
                    <Flex
                        c="secondary"
                        display={{ base: "none", sm: "inline-flex" }}
                        align="center"
                        pt={30}
                        pos="relative"
                    >
                        <Image height={70} src="/assets/logo.svg" />
                        <Text fs="md" variant="footer">
                            {t(`titles.logo`)} <br />
                            {t("statics.this-year")}
                        </Text>
                    </Flex>
                    <Box
                        className={styles.footer_logo_img}
                        bottom={0}
                        maw="80vw"
                        right={100}
                        style={{ zIndex: 1 }}
                    >
                        <Image src={"/assets/prosecutor-building.svg"} />
                    </Box>
                    <Box
                        w={{ base: "90vw", sm: "80vw" }}
                        mt={{ base: -50, xs: -60 }}
                        display={{ base: "block", md: "none" }}
                    >
                        <Image src={"/assets/prosecutor-building.svg"} />
                    </Box>
                </Box>
                {searchValue && (
                    <SearchPagesResults searchValue={searchValue} />
                )}
            </Paper>
        </footer>
    );
};

const FooterChangeLang = ({
    display,
}: {
    display: { base: string; md: string };
}) => {
    const { i18n } = useTranslation();
    const { getItem, setItem } = useLocalStorage<"ru" | "kg">("i18nextLng");
    const currentLng = getItem() || "kg";
    const changeLng = (lng: "ru" | "kg") => {
        i18n.changeLanguage(lng);
        setItem(lng);
    };
    useEffect(() => {
        if (currentLng) {
            i18n.changeLanguage(currentLng);
        }
    }, [currentLng, i18n]);
    return (
        <Flex mt={10} display={display}>
            <Button h="fit-content"
                style={{
                    color:
                        currentLng === "kg"
                            ? "var(--secondary-bg)"
                            : "rgba(var(--primary-dark), 1)",
                    textDecoration: currentLng === "kg" ? "underline" : "none",
                }}
                onClick={() => changeLng("kg")}
                variant="simple"
            >
                <Text c="secondary" fz={{ base: "sm", md: "md" }}>
                    Кыргызча
                </Text>
            </Button>
            <Button h="fit-content"
                variant="simple"
                onClick={() => changeLng("ru")}
                style={{
                    color:
                        currentLng === "ru"
                            ? "var(--secondary-bg)"
                            : "rgba(var(--primary-dark), 1)",
                    textDecoration: currentLng === "ru" ? "underline" : "none",
                }}
            >
                <Text c="secondary" fz={{ base: "sm", md: "md" }}>
                    Русский
                </Text>
            </Button>
        </Flex>
    );
};
