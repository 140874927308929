import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const commonReferenceApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllByTypeCode: builder.query<GetAllByTypeCodeType[], string>({
            query: (code) => ({
                url: Path.CommonReference.getAllByTypeCode(code),
                method: "GET",
            }),
        }),
    }),
});

export const { useGetAllByTypeCodeQuery } = commonReferenceApiSlice;
