import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const diagramSpecificApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        diagramSpecificCreate: builder.mutation<
            DiagramSpecificType,
            DiagramSpecificType
        >({
            query: (body) => ({
                url: Path.DiagramSpecification.createSpecific,
                method: "POST",
                body,
                cache: "no-store",
            }),
        }),
        diagramSpecificGetAll: builder.query<
            DiagramSpecificResponseType[],
            unknown
        >({
            query: () => ({
                url: Path.DiagramSpecification.getAllSpecific,
                method: "GET",
                cache: "no-store",
            }),
        }),
        diagramSpecificGet: builder.query<DiagramSpecificType, unknown>({
            query: (id: number | string) => ({
                url: Path.DiagramSpecification.getSpecific(id),
                method: "GET",
                cache: "no-store",
            }),
        }),
        diagramSpecificUpdate: builder.mutation<
            DiagramSpecificType,
            {
                id: number;
                body: Partial<DiagramSpecificType>;
            }
        >({
            query: ({ id, body }) => ({
                url: Path.DiagramSpecification.updateSpecific(id),
                method: "PUT",
                body,
                cache: "no-store",
            }),
        }),
        diagramSpecificSearch: builder.mutation<
            DiagramSpecificSearchResponseType,
            DiagramSpecificSearchRequestType
        >({
            query: (body) => ({
                url: Path.DiagramSpecification.search,
                method: "POST",
                cache: "no-store",
                body,
            }),
        }),
        deleteDiagramSpecific: builder.mutation({
            query: (id) => ({
                url: Path.DiagramSpecification.deleteSpecific(id),
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useDiagramSpecificCreateMutation,
    useDiagramSpecificGetAllQuery,
    useDiagramSpecificSearchMutation,
    useDiagramSpecificUpdateMutation,
    useDiagramSpecificGetQuery,
    useDeleteDiagramSpecificMutation,
} = diagramSpecificApiSlice;
