import { Accordion, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";
import styles from "./styles.module.scss";

export const OfficeItem = ({ item }: { item: ProsecutorResponseType }) => {
    const { t } = useTranslation();

    return (
        <Accordion.Item
            className={styles.accordion_item}
            value={item?.id.toString() || "0"}
        >
            <Accordion.Control className={styles?.accordion_control}>
                <Title
                    className={styles?.control_title}
                    c="secondary"
                    order={4}
                    fz={{ base: "sm", sm: "lg" }}
                    mb={0}
                    lh={1}
                >
                    {getTitleByLanguage(item) || ""}
                </Title>
                <Text
                    className={styles?.control_subtitle}
                    fw="normal"
                    fz={{ base: "sm", sm: "md" }}
                    lh={1}
                    mb={8}
                >
                    {getTitleByLanguage({
                        titleRu: item.addressRu,
                        titleKg: item.addressKg,
                    }) === "-"
                        ? ""
                        : getTitleByLanguage({
                            titleRu: item.addressRu,
                            titleKg: item.addressKg,
                        }) || "0"}
                </Text>
            </Accordion.Control>
            <Accordion.Panel>
                <Text fz={{ base: "sm", sm: "md" }} c="primaryDark">
                    {item?.phone === "-" ? "" : item?.phone}
                </Text>
                <Text fz={{ base: "sm", sm: "md" }} c="primaryDark">
                    {item?.email === "-" ? "" : `e-mail: ${item?.email}`}
                </Text>
                <Text c="primaryDark">
                    <span style={{ fontWeight: 500 }}>
                        {t("statics.weekdays")}
                    </span>
                    : {t("statics.working-time")}
                </Text>
            </Accordion.Panel>
        </Accordion.Item>
    );
};
