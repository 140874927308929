import { Box, Flex, Grid, Image, Paper, Title } from "@mantine/core"
import { AnimatedCount } from "src/components/animated-count"
import { CustomLoader } from "src/components/forms"
import { getTitleByLanguage } from "src/locales"
import { useGetAllByTypeInfoBlockQuery } from "src/store"

interface Props {
    topStatistId?: number;
}

export const FirstInfoBlock = ({ topStatistId }: Props) => {
    const { data: topStatistic, isLoading: loadingTop } = useGetAllByTypeInfoBlockQuery(`${topStatistId}`, {
        skip: !topStatistId
    });
    return (
        <Paper mb={18} py={{ base: 8, md: 24 }} style={{ overflow: "hidden" }}>
            <Flex
                px={{ base: 20, md: 40 }}
                h="fit-content"
                mih={240}
                pos="relative"
                align={{ base: "start", xxl: "center" }}
            >
                <Image
                    style={{
                        zIndex: 1,
                        opacity: 0.5,
                        objectPosition: "left bottom",
                    }}
                    pos="absolute"
                    w="100%"
                    h="100%"
                    src="/assets/prosecutor-building.svg"
                />
                <Grid
                    pos="relative"
                    style={{ zIndex: 2 }}
                    h="100%"
                    display="flex"
                    align="center"
                    justify="center"
                    gutter={{ base: 0, md: 19 }}
                    pb={16}
                >
                    {loadingTop ?
                        <Box pos="relative" w="90vw" h={400}>
                            <CustomLoader />
                        </Box> :
                        topStatistic?.map((el, idx) => (
                            <Grid.Col
                                key={idx}
                                span={{ base: 12 }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Grid
                                    grow
                                    gutter={{ base: 0, sm: 8 }} w="100%"
                                    align="center"
                                >
                                    <Grid.Col span={12}>
                                        <Title
                                            mb={-30}
                                            c="#0217BE"

                                            fz={{ base: 16, xs: 24, md: 28, xxl: 32 }}
                                        >
                                            <AnimatedCount
                                                endValue={parseFloat(el.textEn)}
                                            />
                                        </Title>
                                    </Grid.Col>
                                    <Grid.Col span={12}>
                                        <Title
                                            c="blueDark"
                                            fw={{ base: "normal", md: 500 }}
                                            fz={{ base: 12, xs: 14, sm: 16, lg: 18 }}
                                            lh={1.2}
                                        >
                                            {getTitleByLanguage(el)}
                                        </Title>
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                        ))}
                </Grid>
            </Flex>
        </Paper>
    )
}
