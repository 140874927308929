import { Accordion, Box, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";
import { getJsonFiles } from "src/utils";
import { ReceptionTable } from "./reception-table";
import styles from "./styles.module.scss";

export const ReceptionTime = () => {
    const { t } = useTranslation();
    const smallScreen = useMediaQuery("(max-width: 768px)");
    const [receptionTime, setReceptionTime] = useState<ReceptionTimeType[]>();

    useEffect(() => {
        getJsonFiles("reception-time-data").then((data) => {
            setReceptionTime(data);
        });
    }, []);
    return (
        <Box mx="auto" w={smallScreen ? "95vw" : "90vw"} mih="70vh">
            <Title mb={14} c="primaryDark" fz={{ base: 24, md: 30 }}>
                {t("links.reception-time")}
            </Title>
            <Accordion>
                {receptionTime?.map((item) => (
                    <Accordion.Item
                        style={{ borderBottom: "1px solid var(--borders)" }}
                        key={item.titleRu}
                        value={item.titleRu}
                    >
                        <Accordion.Control className={styles.accordion_control}>
                            <Title
                                className={styles.control_title}
                                order={4}
                                fz={{ base: 16, sm: 18 }}
                                fw={400}
                            >
                                {getTitleByLanguage(item)}
                            </Title>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <ReceptionTable item={item.children} />
                        </Accordion.Panel>
                    </Accordion.Item>
                ))}
            </Accordion>
        </Box>
    );
};
