import "@mantine/carousel/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./locales/locales";
import { store } from "./store/store";
import "./styles/_mixins.scss";
import "./styles/index.scss";
import "./styles/styled-mantine.scss";
import "./styles/variables.scss";
import { AppMantineProvider } from "./theme";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AppMantineProvider>
                    <App />
                </AppMantineProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </Provider>
);
