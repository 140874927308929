import { Tabs } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { useTranslation } from "react-i18next";
import { getStatisticQuarterData } from "src/constants";

interface Props {
    activeQuartal: string;
}

export const StatisticTabs = ({ activeQuartal }: Props) => {
    const { t } = useTranslation();
    const { classes } = useStyle();
    const quarterData = getStatisticQuarterData(t);

    return (
        <Tabs.List ml={-7} mb={16}>
            {quarterData.map((items) => (
                <Tabs.Tab
                    key={items.value}
                    value={items.value}
                    style={{
                        background:
                            items.value === activeQuartal
                                ? "rgba(var(--primary-dark), 0.2)"
                                : "none",
                        color: "#0A164F",
                        fontWeight: 500,
                        border: "0.5px solid #0A164F",
                        borderRadius: "2px",
                    }}
                    className={classes.tabsStyle}
                >
                    {items.label}
                </Tabs.Tab>
            ))}
        </Tabs.List>
    );
};

const useStyle = createStyles({
    title: {
        fontWeight: 500,
        color: "#0A164F",
        lineHeight: "38px",
        fontSize: "30px",
    },
    tabsStyle: {
        ":hover": {
            background: "rgba(10, 22, 79, 0.2)!important",
        },
    },
});
