import { apiSlice } from "src/store/apiSlice";
import {
    AllPublishedNewsTypes,
    News,
    NewsFormCreateType,
    NewsFormResponseType,
    NewsSearchReqeustType,
    NewsSearchResponseType,
} from "src/types/data-models/news";
import { Path } from "src/utils";

export const newsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createNews: builder.mutation<NewsFormResponseType, NewsFormCreateType>({
            query: ({
                attachments,
                attachmentsEn,
                attachmentsKg,
                attachmentsRu,
                ...body
            }) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });
                formData.append("requestDto", data);

                attachments &&
                    attachments.map((file) =>
                        formData.append("attachments", file)
                    );

                attachmentsEn &&
                    attachmentsEn.map((file) =>
                        formData.append("attachmentsEn", file)
                    );

                attachmentsRu &&
                    attachmentsRu.map((file) =>
                        formData.append("attachmentsRu", file)
                    );

                attachmentsKg &&
                    attachmentsKg.map((file) =>
                        formData.append("attachmentsKg", file)
                    );

                return {
                    url: Path.News.create,
                    method: "POST",
                    body: formData,
                };
            },
        }),
        updateNews: builder.mutation<
            News,
            {
                id: number;
                body: Partial<NewsFormCreateType>;
                attachments?: File[] | null;
                attachmentsEn?: File[] | null;
                attachmentsKg?: File[] | null;
                attachmentsRu?: File[] | null;
            }
        >({
            query: ({
                id,
                body,
                attachments,
                attachmentsEn,
                attachmentsKg,
                attachmentsRu,
            }) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });
                formData.append("requestDto", data);

                attachments &&
                    attachments.map((file) =>
                        formData.append("attachments", file)
                    );

                attachmentsEn &&
                    attachmentsEn.map((file) =>
                        formData.append("attachmentsEn", file)
                    );

                attachmentsRu &&
                    attachmentsRu.map((file) =>
                        formData.append("attachmentsRu", file)
                    );

                attachmentsKg &&
                    attachmentsKg.map((file) =>
                        formData.append("attachmentsKg", file)
                    );
                return {
                    url: Path.News.update(id),
                    method: "PUT",
                    body: formData,
                };
            },
            invalidatesTags: ["News"],
        }),
        getPublishNews: builder.query<AllPublishedNewsTypes[], unknown>({
            query: () => Path.News.getPublish,
            providesTags: ["News"],
        }),
        getNews: builder.query<News, string>({
            query: (id) => ({
                url: Path.News.get(id),
            }),
            providesTags: ["News"],
        }),
        newsDelete: builder.mutation({
            query: (id) => ({
                url: Path.News.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["News"],
        }),
        newsSearch: builder.mutation<
            NewsSearchResponseType,
            NewsSearchReqeustType
        >({
            query: (postData) => ({
                url: Path.News.search,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
            invalidatesTags: ["News"],
        }),
    }),
});

export const {
    useGetPublishNewsQuery,
    useGetNewsQuery,
    useCreateNewsMutation,
    useUpdateNewsMutation,
    useNewsDeleteMutation,
    useNewsSearchMutation,
} = newsApiSlice;
