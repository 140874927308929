import { Box, Button, Flex, Grid, Image, Paper, Title } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CustomLoader, DateBox, NewsItem } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useNewsSearchMutation } from "src/store";
import { NewsResponse } from "src/types/data-models/news";
import styles from "./styles.module.scss";

export const NewsSection = () => {
    const { t, i18n } = useTranslation();
    const [bigNews, setBigNews] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [newsData, setNewsData] = useState<NewsResponse[] | null>(
        null
    );
    const bigNewsData = newsData && newsData[bigNews];
    const bigNewsTitle = newsData && getTitleByLanguage(newsData[bigNews]);

    const [searchNews] = useNewsSearchMutation<{ title: string }>();
    const getSearchNews = useCallback(async () => {
        setIsLoading(true)
        try {
            const response = await searchNews({
                filter: {},
                pageRequest: { limit: 5, page: 0 },
                sorting: {
                    sortBy: "PUBLISHED_AT",
                    sortDirection: "DESC",
                },
            });
            if ("data" in response && "content" in response.data) {
                setNewsData(response?.data?.content?.filter(el => !el.tags?.length));
            }
        } catch (error) {
            console.error("error");
        }
        setIsLoading(false)
    }, []);
    const handleNextClick = () => {
        setBigNews((prev) => (newsData ? (prev + 1) % newsData.length : 0));
    };
    const handlePrevClick = () => {
        setBigNews((prev) => (prev !== 0 ? prev - 1 : 0));
    };

    useEffect(() => {
        getSearchNews();
    }, []);

    return (
        <>
            <Box w="fit-content">
                <Link to={`/allnews/${i18n.language}`}>
                    <Title
                        c="secondaryDark"
                        fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}
                    >
                        {t("titles.news")}
                    </Title>
                </Link>
            </Box>
            {isLoading ?
                <Box pos="relative" w='100%' h={400}>
                    <CustomLoader />
                </Box> :
                newsData?.length ? (
                    <Grid justify="space-between">
                        <Grid.Col
                            display={{ base: "none", sm: "block" }}
                            my={15}
                            span={{ base: 12, lg: 3.9 }}
                        >
                            <Paper
                                h={{ base: "auto", lg: 460 }}
                                mt={0}
                                py={40}
                                px={40}
                                variant="bordered"
                            >
                                <DateBox calendar isMain date={bigNewsData?.publishedAt} />
                                <Grid
                                    h="100%"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Grid.Col
                                        h={{ base: 200, lg: "60%" }}
                                        span={{ base: 4, lg: 12 }}
                                    >
                                        {bigNewsData?.photoAlbum?.photo ? (
                                            <Image
                                                height="100%"
                                                src={
                                                    newsData &&
                                                    `data:image/webp;base64,${bigNewsData?.photoAlbum?.photo}`
                                                }
                                                alt=""
                                            />
                                        ) : (
                                            <Flex
                                                h="100%"
                                                w="100%"
                                                style={{ borderRadius: "4px" }}
                                                bg={"primaryDark"}
                                                align="center"
                                                justify="center"
                                            >
                                                <Image src="/icons/logo.svg" />
                                            </Flex>
                                        )}
                                    </Grid.Col>
                                    <Grid.Col
                                        h={{ base: "100%", lg: "32%" }}
                                        span={{ base: 8, lg: 12 }}
                                    >
                                        <Flex
                                            gap={16}
                                            h="100%"
                                            direction="column"
                                            justify="space-between"
                                        >
                                            <Title
                                                className={styles.big_news_title}
                                                c="secondary"
                                                fw={400}
                                                order={3}
                                            >
                                                {bigNewsTitle &&
                                                    bigNewsTitle?.length > 75
                                                    ? `${bigNewsTitle?.slice(
                                                        0,
                                                        75
                                                    )}...`
                                                    : bigNewsTitle?.slice(0, 75)}
                                            </Title>
                                            <Flex
                                                align="center"
                                                justify="space-between"
                                            >
                                                <Button
                                                    bg="primaryDark"
                                                    c="primary"
                                                    fz="sm"
                                                    fw="500"
                                                    component={Link}
                                                    to={`/allnews/${bigNewsData?.id}/${i18n.language}`}
                                                >
                                                    {t("statics.more")}
                                                    <IconChevronRight size={25} />
                                                </Button>
                                                <Flex gap={10} display="flex">
                                                    <Button
                                                        bg={
                                                            bigNews === 0
                                                                ? "weakPrimaryDark"
                                                                : "primaryDark"
                                                        }
                                                        c="primary"
                                                        variant="circle"
                                                        onClick={handlePrevClick}
                                                        disabled={bigNews === 0}
                                                    >
                                                        <IconChevronLeft
                                                            color="var(--primary)"
                                                            size={30}
                                                            style={{
                                                                margin: "-3px 5px 0 0 ",
                                                            }}
                                                        />
                                                    </Button>
                                                    <Button
                                                        bg={
                                                            newsData &&
                                                                bigNews ===
                                                                newsData.length - 1
                                                                ? "weakPrimaryDark"
                                                                : "primaryDark"
                                                        }
                                                        c="primary"
                                                        variant="circle"
                                                        onClick={handleNextClick}
                                                        disabled={
                                                            newsData &&
                                                            bigNews ===
                                                            newsData.length - 1
                                                        }
                                                    >
                                                        <IconChevronRight
                                                            color="var(--primary)"
                                                            size={30}
                                                            style={{
                                                                margin: "-3px 0 0 -2px",
                                                            }}
                                                        />
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Grid.Col>
                                </Grid>
                            </Paper>
                        </Grid.Col>
                        <Grid.Col mt={5} span={{ base: 12, lg: 8 }}>
                            <Grid justify="space-between">
                                {newsData?.map(
                                    (el, idx) => idx < 4 ? (
                                        <Grid.Col
                                            mt={{ base: 8, lg: 19 }}
                                            span={{ base: 12, sm: 5.9 }}
                                            key={el?.id} pl={5} pr={{ base: 15, sm: 0 }}
                                        >
                                            <NewsItem
                                                textLimit={80}
                                                isHomePage={true}
                                                news={el}
                                            />
                                        </Grid.Col>
                                    ) : null
                                )}
                            </Grid>
                        </Grid.Col>
                    </Grid>
                ) : (
                    <Title
                        c="secondaryDark"
                        ta="center"
                        fw={400}
                        fs="italic"
                        order={3}
                    >
                        {t("statics.no-news")}
                    </Title>
                )}
        </>
    );
};
