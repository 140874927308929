import i18n from "./locales";

interface MyObject {
    titleRu: string;
    titleKg: string;
    titleEn: string;
    [key: string]: any | undefined;
}

type UniversalObject<T extends MyObject> =
    | Record<string, T[keyof T]>
    | undefined
    | null;

export const getTitleByLanguage = <T extends MyObject>(
    object: UniversalObject<T>
): string => {
    if (!object) return "-";
    const i18lng = i18n.language;
    const lang: string =
        i18lng === "ru" ? "titleRu" : i18lng === "kg" ? "titleKg" : "titleEn";
    return object[lang] ?? "-";
};
