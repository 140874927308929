import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const pageApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllPageList: builder.query<PageType[], unknown>({
            query: () => ({
                url: Path.Page.getAll,
                method: "GET",
            }),
            providesTags: ["PageStructure"],
        }),
        createPage: builder.mutation<PageType, PageFormTypes>({
            query: (body) => ({
                url: Path.Page.create,
                method: "POST",
                body,
            }),
            invalidatesTags: ["PageStructure"],
        }),
        updatePage: builder.mutation<
            PageType,
            { id: number; body: Partial<PageFormTypes> }
        >({
            query: ({ id, body }) => ({
                url: Path.Page.update(id),
                method: "PUT",
                body,
            }),
            invalidatesTags: ["PageStructure"],
        }),
        getPage: builder.query<PageType, string | undefined>({
            query: (id) => ({
                url: Path.Page.get(id),
            }),
        }),
        getPageStructure: builder.query<PageType[], unknown>({
            query: () => ({
                url: Path.Page.structure,
                method: "GET",
            }),
            providesTags: ["PageStructure"],
        }),
        deletePage: builder.mutation({
            query: (id) => ({
                url: Path.Page.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["PageStructure"],
        }),
        searchPage: builder.mutation<
            PageSearchResponseType,
            PageSearchRequestType
        >({
            query: (postData) => ({
                url: Path.Page.search,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
        }),
    }),
});

export const {
    useCreatePageMutation,
    useUpdatePageMutation,
    useGetPageQuery,
    useGetPageStructureQuery,
    useDeletePageMutation,
    useSearchPageMutation,
    useGetAllPageListQuery
} = pageApiSlice;
