import { Tabs } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { DIAGRAM_REFERENCE_TYPES } from "src/constants";
import { useDiagramSearchMutation, useGetAllByTypeCodeQuery } from "src/store";
import { TypeCodesEnum } from "src/types";
import { StatisticBody } from "../statistic-body";
import { StatisticTabs } from "../tabs";

export const MainStatisticsDetailsModule = ({
    activeYear,
}: {
    activeYear?: string | number | null;
}) => {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const quarter = params.get('quarter');
    const categoryId = params.get('categoryId');


    const [activeQuartal, setActiveQuartal] = useState<string>(quarter || "FIRST");
    const [activeCategory, setActiveCategory] = useState<
        SelectCategoryType | undefined
    >(categoryId ? { parentId: parseInt(categoryId) } : undefined);
    const [searchDiagramByDateQuartal, { data: statisticData, isLoading }] = useDiagramSearchMutation();

    const categories: DiagramSpecificResponseType[] = useMemo(() => {
        return statisticData?.content?.map((item, idx) => ({
            id: item.id,
            titleKg: item.titleKg,
            titleRu: item.titleRu,
            order: idx,
            diagramSpecificationDtoList: null,
        })) || [];
    }, [statisticData])

    const { data: typeCodes } = useGetAllByTypeCodeQuery(TypeCodesEnum.NEWS_CATEGORY)
    const typeId = useMemo(() => {
        return typeCodes?.find(el => el.titleEn === DIAGRAM_REFERENCE_TYPES.ORGAN)?.id
    }, [typeCodes])

    const handleSearchDiagram = async () => {
        try {
            await searchDiagramByDateQuartal({
                filter: {
                    diagramTypeId: typeId,
                    year: parseInt(`${activeYear}`),
                    diagramQuarter: activeQuartal,
                },
                pageRequest: { limit: 40, page: 0 },
                sorting: {
                    sortBy: "ORDER",
                    sortDirection: "ASC",
                },
            });
        } catch {
            console.error("error");
        }
    };

    const data = useMemo(() => {
        if (statisticData?.content) {
            if (activeCategory?.childrenId) {
                return statisticData?.content?.filter(
                    (el) => el.id === activeCategory.childrenId
                );
            } else if (activeCategory?.parentId) {
                return statisticData?.content?.filter(
                    (el) => el.id === activeCategory.parentId
                );
            }
        }
    }, [activeCategory, statisticData, activeYear]);
    useEffect(() => {
        handleSearchDiagram();
    }, [activeQuartal, activeYear]);
    useEffect(() => {
        if (categories && !categoryId) {
            setActiveCategory({ parentId: categories?.[0]?.id })
        }
    }, [categories])
    return (
        <Tabs
            h="100%"
            mih={400}
            variant="pills"
            defaultValue="FIRST"
            onChange={(value) => value && setActiveQuartal(value)}
            p="8px 16px 16px"
            style={{ borderRadius: "2px" }}
        >
            <StatisticBody
                activeYear={parseInt(`${activeYear}`)}
                withDefaultChevron={false}
                categories={categories}
                activeQuartal={activeQuartal}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
                isLoading={isLoading}
                data={data}
            />

        </Tabs>
    );
};
