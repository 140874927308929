import { Avatar, Center, Grid } from "@mantine/core";
import { Employee } from "src/types/data-models/employee";
import { ProfileViewBody } from "./profile-view-body";

type Props = {
    employeeData: Employee;
};

export const ProfileView = ({ employeeData }: Props) => {
    return (
        <Grid justify="space-between">
            <Grid.Col span={{ base: 12, md: 3 }}>
                <Center>
                    <Avatar
                        src={employeeData?.photo}
                        alt={`photo`}
                        radius="lg"
                        mb="1rem"
                        style={{ width: 260, height: 260 }}
                    />
                </Center>
            </Grid.Col>
            <Grid.Col mb={100} span={{ base: 12, md: 9 }}>
                {employeeData ? (
                    <ProfileViewBody employeeData={employeeData} />
                ) : null}
            </Grid.Col>
        </Grid>
    );
};
