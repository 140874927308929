import ReactApexChart from "react-apexcharts";
import { VALUES_SHOW_TYPES } from "src/constants";
import { generateRowOptions } from "../options/generate-row-options";

export const HorizontalChart = ({ data, isSmall=false }) => {
    const withPercentage =
        data?.textEn !== VALUES_SHOW_TYPES.WITHOUT_PERCENTAGE;
    const { options } = generateRowOptions(data, withPercentage,isSmall);
    return (
        <ReactApexChart
            options={options}
            series={options.series}
            type="bar"
            height={isSmall ? 300 : 450}
        />
    );
};
