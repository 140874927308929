import { Box } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { IconLock, IconLogout, IconUserHexagon } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MenuDropDown } from "src/components";
import { useAppDispatch, useAppSelector, useLocalStorage } from "src/hooks";
import i18n from "src/locales/locales";
import { ChangePassword } from "src/modules";
import { useLogOutMutation } from "src/store";
import { logOut } from "src/store/reducers/auth-slice";
import { RootState } from "src/store/store";

export const ProfileActions = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { removeItem } = useLocalStorage<Profile>("profile");
    const [signOut] = useLogOutMutation();
    const profile = useAppSelector((state: RootState) => state.auth.profile);

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleLogout = async () => {
        await signOut("");
        removeItem();
        navigate("/" + i18n.language);
        dispatch({ type: logOut.type });
    };

    const actions = [
        {
            icon: IconUserHexagon,
            title: t`sidebar.profile`,
            onClick: () => navigate(`/profile`),
        },
        {
            icon: IconLock,
            title: t`button.changePassword`,
            onClick: () => handleOpen(),
        },
        {
            icon: IconLogout,
            title: t`sidebar.logout`,
            onClick: handleLogout,
        },
    ];

    return (
        <Box>
            <MenuDropDown
                label={profile?.initials}
                item={actions}
                className={classes.menu}
                isProfile
            />
            <ChangePassword open={open} setOpen={setOpen} />
        </Box>
    );
};

const useStyles = createStyles((theme) => ({
    menu: {
        padding: "5px 10px",
        borderRadius: 5,
        color: theme.colorScheme === "dark" ? "#fff" : "#000",
        "&:hover": {
            backgroundColor: "rgba(128, 128, 128, 0.1)",
        },
    },
}));
