import { useMediaQuery } from "@mantine/hooks";
import ReactApexChart from "react-apexcharts";
import { VALUES_SHOW_TYPES } from "src/constants";
import { generateColOptions } from "../options/generate-col-options";

export const VerticalChart = ({ data, isSmall=false }) => {
    const withPercentage =
        data?.textEn !== VALUES_SHOW_TYPES.WITHOUT_PERCENTAGE;
    const { options } = generateColOptions(data, withPercentage,isSmall);
    const mobileScreen = useMediaQuery("(max-width: 576px)");
    
    return (
        <ReactApexChart
            options={options}
            series={options.series}
            type="bar"
            height={mobileScreen ?isSmall  ? 350 :  450 : isSmall ? 300 : 550}
        />
    );
};
