import { Accordion } from "@mantine/core";
import { getTitleByLanguage } from "src/locales";
import { HTMLRender } from "src/utils";

interface Props {
    data: PageListAccordion;
}

export const AccordionItem = ({ data }: Props) => {
    return (
        <Accordion.Item key={data.id} value={`${data.id}`}>
            <Accordion.Control
                py={15}
                style={{ borderBottom: "1px solid var(--borders)" }}
            >
                {getTitleByLanguage(data)}
            </Accordion.Control>
            <Accordion.Panel>
                <HTMLRender
                    htmlContent={getTitleByLanguage({
                        titleRu: data.contentRu,
                        titleKg: data.contentKg,
                    })}
                />
            </Accordion.Panel>
        </Accordion.Item>
    );
};
