import { BackgroundImage, Box, Title } from "@mantine/core";
import { useInViewport, useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";

interface Props {
    item: HistoryType;
}

export const HistoryParallaxItem = ({ item }: Props) => {
    const { t } = useTranslation();
    const { ref, inViewport: inView } = useInViewport();
    const xlScreen = useMediaQuery("(max-width: 1400px)");
    const isFirst = item.id === 1;
    return (
        <Box py={item.img?.isHuman ? 30 : 0} bg="primaryDark">
            <BackgroundImage
                className="container"
                py={30}
                src={item.img ? item.img.src : ""}
                style={{
                    backgroundSize: item.img?.isHuman
                        ? `${xlScreen ? "25%" : "18%"} 100%`
                        : "40% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: item.img?.isHuman
                        ? `calc(${inView ? "0% + 10%" : "0% - 20%"})`
                        : `calc(${inView ? "0%" : "0% - 20%"})`,
                    transition: "1s",
                }}
            >
                <Box
                    ref={ref}
                    h={400}
                    ta="start"
                    pl="40%"
                    style={{
                        overflow: "visible",
                        transform: inView
                            ? "translateX(0)"
                            : "translateX(500px)",
                        transition: "1000ms",
                        opacity: inView ? 1 : 0,
                    }}
                >
                    {isFirst && (
                        <Title
                            ta="justify"
                            c="primary"
                            fz={{ base: "lg", md: "2xl", xl: "3xl" }}
                        >
                            {t("titles.history")}
                        </Title>
                    )}
                    <Title
                        ta="justify"
                        c="primary"
                        fw="normal"
                        lh={1.6}
                        fz="lg"
                    >
                        {getTitleByLanguage(item)}
                    </Title>
                </Box>
            </BackgroundImage>
        </Box>
    );
};
