import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const pageListAccordionSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createPageListAccordion: builder.mutation<
            PageListAccordion,
            PageListAccordionFormType
        >({
            query: ({
                fileAttachmentKg,
                fileAttachmentRu,
                photoAttachmentKg,
                photoAttachmentRu,
                ...values
            }) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(values)], {
                    type: "application/json",
                });
                formData.append("request", data);

                fileAttachmentKg &&
                    fileAttachmentKg.map((file) =>
                        formData.append("fileAttachmentKg", file)
                    );

                fileAttachmentRu &&
                    fileAttachmentRu.map((file) =>
                        formData.append("fileAttachmentRu", file)
                    );

                photoAttachmentKg &&
                    photoAttachmentKg.map((file) =>
                        formData.append("photoAttachmentKg", file)
                    );

                photoAttachmentRu &&
                    photoAttachmentRu.map((file) =>
                        formData.append("photoAttachmentRu", file)
                    );
                return {
                    url: Path.PageListAccordion.create,
                    method: "POST",
                    body: formData,
                };
            },
        }),
        updatePageListAccordion: builder.mutation<
            PageListAccordion,
            {
                id: number;
                fileAttachmentKg?: File[] | null;
                fileAttachmentRu?: File[] | null;
                photoAttachmentKg?: File[] | null;
                photoAttachmentRu?: File[] | null;
                body: Partial<PageListAccordionFormType>
            }
        >({
            query: ({
                id,
                body,
                fileAttachmentKg,
                fileAttachmentRu,
                photoAttachmentKg,
                photoAttachmentRu,
            }) => {
                const formData = new FormData();
                const data = new Blob([JSON.stringify(body)], {
                    type: "application/json",
                });
                formData.append("request", data);

                fileAttachmentKg &&
                    fileAttachmentKg.map((file) =>
                        formData.append("fileAttachmentKg", file)
                    );

                fileAttachmentRu &&
                    fileAttachmentRu.map((file) =>
                        formData.append("fileAttachmentRu", file)
                    );

                photoAttachmentKg &&
                    photoAttachmentKg.map((file) =>
                        formData.append("photoAttachmentKg", file)
                    );

                photoAttachmentRu &&
                    photoAttachmentRu.map((file) =>
                        formData.append("photoAttachmentRu", file)
                    );
                return {
                    url: Path.PageListAccordion.update(id),
                    method: "PUT",
                    body: formData,
                }
            },
            invalidatesTags: ["Accordion"],
        }),
        getPageListAccordion: builder.query<PageListAccordion, any>({
            query: (id) => Path.PageListAccordion.get(id),
            providesTags: ["News"],
        }),
        getAllPageListAccordion: builder.query<PageListAccordion[], unknown>({
            query: () => ({
                method: "POST",
                url: Path.PageListAccordion.getAll,
                body: {},
            }),
            providesTags: ["Accordion"],
        }),
        pageListAccordionDelete: builder.mutation({
            query: (id) => ({
                url: Path.PageListAccordion.delete(id),
                method: "DELETE",
            }),
            invalidatesTags: ["Accordion"],
        }),
        pageListAccordionSearch: builder.mutation<
            PageListAccordionSearch,
            PageListSearchRequestType
        >({
            query: (postData) => ({
                url: Path.PageListAccordion.search,
                method: "POST",
                body: postData,
                cache: "no-store",
            }),
            invalidatesTags: ["Accordion"],
        }),
    }),
});

export const {
    useCreatePageListAccordionMutation,
    useGetAllPageListAccordionQuery,
    useGetPageListAccordionQuery,
    usePageListAccordionDeleteMutation,
    usePageListAccordionSearchMutation,
    useUpdatePageListAccordionMutation,
} = pageListAccordionSlice;
