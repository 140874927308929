import { Accordion, Box, Flex, Title } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import axios from "axios";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";
import { getAttachmentUrl } from "src/utils";
import { PdfView } from "../pdf-view";
interface ListItemProps {
    item: PageListAccordion;
    isDocumentPage?: boolean;
}

export const ListItem = ({ item, isDocumentPage = false }: ListItemProps) => {
    const { i18n } = useTranslation()
    const fileId = useMemo(() => {
        return i18n.language === "ru" ? item.fileAttachmentRu?.[0].id : item.fileAttachmentKg?.[0].id
    }, [i18n.language, item])
    const fileUuid = useMemo(() => {
        return i18n.language === "ru" ? item.fileAttachmentRu?.[0].uuid : item.fileAttachmentKg?.[0].uuid
    }, [i18n.language, item])
    const handleDownlaod = useCallback(async () => {
        if (fileId && fileUuid) {
            try {
                const response = await axios(getAttachmentUrl(fileId, fileUuid), { responseType: "arraybuffer" }
                );
                const blob = new Blob([response.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = item.titleRu;
                link.click();
                window.URL.revokeObjectURL(url);
            } catch {
                console.error("error");
            }
        }
    }, [i18n.language])

    if (isDocumentPage) {
        return (
            <Accordion.Item
                w={{ base: "100%", md: "70%" }}
                value={`${item.id}`}
            >
                <Accordion.Control
                    style={{ borderBottom: "1px solid var(--borders)" }}
                >
                    <Title
                        mr={20}
                        ta="justify"
                        fz={{ base: 14, sm: 18 }}
                        lh={1.5}
                        fw={400}
                        order={4}
                    >
                        {getTitleByLanguage(item)}
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <PdfView handleDownload={handleDownlaod} file={item} />
                </Accordion.Panel>
            </Accordion.Item>
        );
    } else
        return (
            <Flex
                onClick={handleDownlaod}
                justify="space-between"
                align="center"
                gap={20}
                py={10}
                w={"100%"}
                style={{
                    borderBottom: "1px solid var(--borders)",
                    cursor: "pointer",
                }}
            >
                <Title
                    ta="justify"
                    fz={{ base: 14, sm: 18 }}
                    lh={1.5}
                    fw={400}
                    order={4}
                >
                    {getTitleByLanguage(item)}
                </Title>
                <Box onClick={handleDownlaod} w={30}>
                    <IconDownload size={24} color="#000" />
                </Box>
            </Flex>
        );
};
