export type { HeadCell, ListType, MenuItemType } from "./data-models/global";
export type { AppThemeProviderProps } from "./mantine";
export type { RegionMapProps } from "./map";
export type {
    keysType,
    personnelItem,
    personnelSectionType,
    personnelSectionsResponse
} from "./personnel-data";
export type {
    TreeStructureProps,
    coordinateType,
    coordinatesType,
    structureType
} from "./tree";

export enum TypeCodesEnum {
    GENDER = "GENDER",
    EMPLOYEE_RANK = "EMPLOYEE_RANK",
    CARD_INFO_TYPE = "CARD_INFO_TYPE",
    INFO_BLOCK_TYPE = "INFO_BLOCK_TYPE",
    REGION = "REGION",
    NEWS_CATEGORY = "NEWS_CATEGORY",
    NEWS_TAG = "NEWS_TAG",
    DIAGRAM = "DIAGRAM",
    COUNTRY_CODE = "COUNTRY_CODE",

}

export type TECH_WORK = {
    TECH_WORK: boolean;
};
