import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const qrAppealData = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getQrAppealInfo: builder.mutation<
            QrAppealResponseType,
            QrAppealRequestType
        >({
            query: ({ g, p }) => ({
                url: Path.QrAppealInfo.getData(g, p),
                method: "POST",
                body: {},
                cache: "no-cache",
            }),
            invalidatesTags: ["QrDataAppeal"],
        }),
    }),
});

export const { useGetQrAppealInfoMutation } = qrAppealData;
