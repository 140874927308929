import { Box, Button, Flex, Grid, Image, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BannerSection } from "src/components";
import { useUpdateHead } from "src/hooks";
import { getTitleByLanguage } from "src/locales";
import { useGetByTypeCodeQuery } from "src/store/reducers/all-reducers/reference-api";
import { TypeCodesEnum } from "src/types";
import { getJsonFiles } from "src/utils";
import { PanelNews } from "../news/panel-news";

export const PressServiceModule = () => {
    const [pressData, setPressData] = useState<PressServiceType[]>();
    const { t, i18n } = useTranslation();
    const title = t("links.press-service");
    const { data: tags } = useGetByTypeCodeQuery(TypeCodesEnum.NEWS_TAG, {});
    const navigate = useNavigate();

    useUpdateHead({
        title: title,
        description: t("descriptions.symbols"),
    });


    useEffect(() => {
        getJsonFiles("press-service").then((data) => {
            setPressData(data);
        });
    }, []);
    return (
        <Box className="container">
            <Title c="secondaryDark" fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}>
                {t("titles.press-service")}
            </Title>
            <Grid>
                <Grid.Col span={{ base: 12, lg: 7 }}>
                    <Flex className="container" wrap="wrap" gap={16}>
                        {tags?.map((el) => (
                            <Text
                                key={el.id}
                                className="box-shadow"
                                bg={"primary"}
                                p={{ base: "4px 16px", sm: "8px 24px" }}
                            >
                                <Button
                                    onClick={() =>
                                        navigate(
                                            `/allnews/${i18n.language}?activeTag=${el.id}`
                                        )
                                    }
                                    variant="simple"
                                    c={"primaryDark"}
                                    fz={16}
                                    fw={400}
                                >
                                    {getTitleByLanguage(el)}
                                </Button>
                            </Text>
                        ))}
                    </Flex>
                    <Text my={20} c="primaryDark" fz={{ base: 16, md: 18 }}>
                        {t("descriptions.press-service")}
                    </Text>
                    <Flex mih="30vh" direction="column" justify="space-between">
                        <Flex mb={{ base: 24, md: 40 }} wrap="wrap" gap={{ base: 10, md: 50 }}>
                            {pressData?.map((el) => (
                                <Flex
                                    key={el.id}
                                    direction="column"
                                    align="start"
                                    gap={0}
                                >
                                    <Title fw={400} c="primaryDark" order={3}>
                                        {getTitleByLanguage(el)}
                                    </Title>
                                    <a href={el.link} target="_blank" rel="noreferrer">
                                        <Flex align="center" justify="center" gap={10}>
                                            <Image w="100%" src={el.icon} />
                                            <Title c="secondaryDark" order={4}>
                                                {getTitleByLanguage({
                                                    titleRu: el.valueRu,
                                                    titleKg: el.valueKg,
                                                })}
                                            </Title>
                                        </Flex>
                                    </a>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Grid.Col>
                <PanelNews isScrollArea />
                <Grid.Col span={12}>
                    <BannerSection />
                </Grid.Col>
            </Grid>
        </Box>
    );
};
