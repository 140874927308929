import {
    Button,
    Container,
    Flex,
    Grid,
    Image,
    Modal,
    MultiSelect,
    Paper,
    Select,
    TypographyStylesProvider,
    UnstyledButton
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    ButtonGroup,
    CustomDropzone,
    CustomInputText,
    CustomTextEditor,
} from "src/components";
import { nowDate } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { useGetAllMapQuery } from "src/store";
import { useGetByPhotoAlbumQuery } from "src/store/reducers";
import {
    useCreateNewsMutation,
    useUpdateNewsMutation,
} from "src/store/reducers/all-reducers/newsApiSlice";
import { useGetByTypeCodeQuery } from "src/store/reducers/all-reducers/reference-api";
import { TypeCodesEnum } from "src/types";
import {
    NewsFormCreateType,
    NewsFormResponseType,
} from "src/types/data-models/news";
import { notify } from "src/utils";

type Props = {
    news?: NewsFormResponseType;
};

export const NewsForm = ({ news }: Props) => {
    const { t, i18n } = useTranslation();
    const [photos, setPhotos] = useState<IPhoto[]>();
    const navigate = useNavigate();
    const [createNews] = useCreateNewsMutation();
    const [updateNews] = useUpdateNewsMutation();
    const { data: images } = useGetByPhotoAlbumQuery(news?.photoAlbum?.id || 0);

    const [opened, { close, open }] = useDisclosure(false);

    const [htmlContent, setHtmlContent] = useState("");
    const { data: tags } = useGetByTypeCodeQuery(TypeCodesEnum.NEWS_TAG, {});
    const handleDeletePhoto = (id: number) => {
        const newArr = photos?.filter((el) => el.id !== id);
        setPhotos(newArr);
        form.setFieldValue(
            "toDeleteAttachments",
            form.values.toDeleteAttachments?.length
                ? [...form.values.toDeleteAttachments, id]
                : [id]
        );
    };

    const { data: mapPointID } = useGetAllMapQuery([], {
        refetchOnMountOrArgChange: true,
    });

    const incomingDateFormating = () => {
        const originalDate = new Date(
            news ? new Date(news?.publishedAt) : nowDate
        );

        const year = originalDate.getUTCFullYear();
        const month = (originalDate.getUTCMonth() + 1)
            .toString()
            .padStart(2, "0");
        const day = originalDate.getUTCDate().toString().padStart(2, "0");
        const hours = originalDate.getUTCHours().toString().padStart(2, "0");
        const minutes = originalDate
            .getUTCMinutes()
            .toString()
            .padStart(2, "0");
        const seconds = originalDate
            .getUTCSeconds()
            .toString()
            .padStart(2, "0");
        const milliseconds = originalDate
            .getUTCMilliseconds()
            .toString()
            .padStart(3, "0");

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    };

    const mapPointIDSelectData = mapPointID?.map((item) => {
        return {
            value: `${item.id}`,
            label: `${item.cityOrRegionNameRu}`,
        };
    });
    const tagsId = tags?.map((item) => ({
        value: `${item.id}`,
        label: getTitleByLanguage(item),
    }));
    const form = useForm<NewsFormCreateType>({
        initialValues: {
            contentEn: news?.content?.titleEn || "",
            contentKg: news?.content?.titleKg || "",
            contentRu: news?.content?.titleRu || "",
            publishedAt: incomingDateFormating(),
            mapPointId: news?.mapPointId || 1,
            tags: news?.tags?.map(el => el.id) || null,
            titleEn: news?.titleEn || "",
            titleKg: news?.titleKg || "",
            titleRu: news?.titleRu || "",
            attachments: news?.attachments || null,
            attachmentsEn: news?.attachmentsEn || null,
            attachmentsKg: news?.attachmentsKg || null,
            attachmentsRu: news?.attachmentsRu || null,
        },
        validate: {
            titleKg: (value) => !value && t("form.validation.required"),
            titleRu: (value) => !value && t("form.validation.required"),
            contentRu: (value) => !value && t("form.validation.required"),
            contentKg: (value) => !value && t("form.validation.required"),
            publishedAt: (value) => !value && t("form.validation.required"),
        },
    });

    const activeTag = tags?.find(el => el.id === form.values.tags?.[0])

    useEffect(() => {
        if (images) {
            setPhotos(images);
        }
    }, [images]);
    const handleSubmit = async ({
        attachments,
        attachmentsEn,
        attachmentsKg,
        attachmentsRu,
        ...values
    }: NewsFormCreateType) => {
        if (news) {
            try {
                await updateNews({
                    id: news.id,
                    attachments,
                    attachmentsEn,
                    attachmentsKg,
                    attachmentsRu,
                    body: values,
                }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/news");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createNews({
                    attachments,
                    attachmentsEn,
                    attachmentsKg,
                    attachmentsRu,
                    ...values,
                });
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/news");
            } catch {
                notify(false);
            }
        }
    };
    const handlePreviewButton = (val: string) => {
        setHtmlContent(val);
        open();
    };
    return (
        <Container size="xl" w="100%" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 6 }} mt={15}>
                            <MultiSelect
                                styles={{
                                    options:
                                        { background: "rgba(10,22,79, 0.5)", color: "white" },
                                    pill: {
                                        color: "white"
                                    }
                                }}
                                color="white"
                                placeholder={t`form.label.type`}
                                onChange={(el) => {
                                    form.setFieldValue("tags", el ? el.map(el => parseInt(el)) : [])
                                }}
                                value={form.values.tags?.map(el => el.toString())}
                                data={tagsId || []}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <DateTimePicker
                                maxDate={nowDate}
                                {...form.getInputProps("publishedAt")}
                                label={t`form.label.publishedAt`}
                                error={form.errors?.publishedAt}
                                value={
                                    form.values?.publishedAt
                                        ? new Date(form.values?.publishedAt)
                                        : undefined
                                }
                                required
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleKg")}
                                label={t`form.label.titleKg`}
                                error={form.errors.titleKg}
                                formData={form.values.titleKg}
                                required
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleRu")}
                                label={t`form.label.titleRu`}
                                required
                                error={form.errors.titleRu}
                                formData={form.values.titleRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleEn")}
                                label={t`form.label.titleEn`}
                                error={form.errors.titleEn}
                                formData={form.values.titleEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Select
                                mt={15}
                                {...form.getInputProps("mapPointId")}
                                placeholder={t`form.label.mapPointId`}
                                required
                                error={form.errors.mapPointId}
                                value={`${form.values.mapPointId}`}
                                data={mapPointIDSelectData || []}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomTextEditor
                                label={t`form.label.contentKg`}
                                labelButton={
                                    <Button
                                        variant="subtle"
                                        color="blue"
                                        size="sm"
                                        radius="sm"
                                        ml={5}
                                        onClick={() =>
                                            handlePreviewButton(form.values.contentKg)
                                        }
                                    >
                                        {t`button.preview`}
                                    </Button>
                                }
                                error={form.errors.contentKg}
                                formData={form.values.contentKg}
                                {...form.getInputProps("contentKg")}
                            />

                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomTextEditor
                                label={t`form.label.contentRu`}
                                labelButton={
                                    <Button
                                        variant="subtle"
                                        color="blue"
                                        size="sm"
                                        radius="sm"
                                        ml={5}
                                        onClick={() =>
                                            handlePreviewButton(
                                                form.values.contentRu
                                            )
                                        }
                                    >
                                        {t`button.preview`}
                                    </Button>
                                }
                                error={form.errors.contentRu}
                                formData={form.values.contentRu}
                                {...form.getInputProps("contentRu")}
                            />

                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomTextEditor
                                label={t`form.label.contentEn`}
                                labelButton={
                                    <Button
                                        variant="subtle"
                                        color="blue"
                                        size="sm"
                                        radius="sm"
                                        ml={5}
                                        onClick={() =>
                                            handlePreviewButton(
                                                form.values.contentEn
                                            )
                                        }
                                    >
                                        {t`button.preview`}
                                    </Button>
                                }
                                error={form.errors.contentEn}
                                formData={form.values.contentEn}
                                {...form.getInputProps("contentEn")}
                            />

                        </Grid.Col>
                        {activeTag?.titleEn !== "image" ?
                            <>
                                <Grid.Col>
                                    <CustomDropzone
                                        label={activeTag?.titleEn !== "video" ? t`form.label.fileRu` : t("form.label.video")}
                                        error={form.errors.attachmentsRu}
                                        {...form.getInputProps("attachmentsRu")}
                                        accept={
                                            activeTag?.titleEn !== "video" ?
                                                [MIME_TYPES.pdf, MIME_TYPES.docx] :
                                                [MIME_TYPES.mp4]}
                                    />
                                </Grid.Col>
                                {activeTag?.titleEn !== "video" ?
                                    <>
                                        <Grid.Col>
                                            <CustomDropzone
                                                label={t`form.label.fileKg`}
                                                error={form.errors.attachmentsKg}
                                                {...form.getInputProps("attachmentsKg")}
                                                accept={[MIME_TYPES.pdf, MIME_TYPES.docx]}
                                            />
                                        </Grid.Col>
                                        <Grid.Col>
                                            <CustomDropzone
                                                label={t`form.label.fileEn`}
                                                error={form.errors.attachmentsEn}
                                                {...form.getInputProps("attachmentsEn")}
                                                accept={[MIME_TYPES.pdf, MIME_TYPES.docx]}
                                            />
                                        </Grid.Col>
                                    </> : null}
                            </> : null}
                        <Grid.Col span={12}>
                            <CustomDropzone
                                label={t`form.label.photo_collage`}
                                error={form.errors.attachments}
                                {...form.getInputProps("attachments")}
                                accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                            />
                            {photos && (
                                <Flex mt={10} wrap="wrap" gap={10}>
                                    {photos?.map(
                                        (el) =>
                                            "id" in el && (
                                                <Flex
                                                    direction="column"
                                                    align="center"
                                                    key={el.id}
                                                >
                                                    <UnstyledButton
                                                        onClick={() =>
                                                            handleDeletePhoto(el.id)
                                                        }
                                                    >
                                                        <IconTrash color="red" />
                                                    </UnstyledButton>
                                                    <Image
                                                        w={100}
                                                        alt=""
                                                        src={`data:image/png;base64,${el.miniature}`}
                                                    />
                                                </Flex>
                                            )
                                    )}
                                </Flex>
                            )}
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(`/news/${i18n.language}`)
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
            <Modal
                opened={opened}
                onClose={close}
                size={"60%"}
                title="Предпросмотр"
            >
                <TypographyStylesProvider>
                    <div
                        style={{ color: "#2D4263" }}
                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                    />
                </TypographyStylesProvider>
            </Modal>
        </Container>
    );
};
