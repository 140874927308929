import { Box, Paper } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Contacts, CustomLoader, TextInfo } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useGetProsecutorOfficeQuery } from "src/store";

type Props = {
    officeId: string;
};

export const OfficeDetails = ({ officeId }: Props) => {
    const { t } = useTranslation();

    const { data: officeData, isLoading } = useGetProsecutorOfficeQuery(
        officeId,
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const officeCard = [
        {
            label: t`data.news.title`,
            value: getTitleByLanguage(officeData),
        },
    ];

    return (
        <Paper shadow="sm" p="xl">
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <Paper shadow="sm" p="xl">
                    <TextInfo
                        data={officeCard}
                        display={true}
                        spanFirst={4}
                        spanTwo={8}
                    />
                    <Box mt={30}>
                        <Contacts
                            regionId={officeData?.mapPoint?.id}
                            officeId={officeData?.id}
                            direction="column-reverse"
                        />
                    </Box>
                </Paper>
            }
        </Paper>
    );
};
