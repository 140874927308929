import { Container, Grid, NumberInput, Paper, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, CustomInputText } from "src/components";
import { useCreateProsecutorOfficeMutation, useGetAllMapQuery, useUpdateProsecutorOfficeMutation } from "src/store";
import { notify } from "src/utils";

type Props = {
    prosecutorOffice?: ProsecutorResponseType;
};

export const OfficeForm = ({ prosecutorOffice }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [createProsecutorOffice] = useCreateProsecutorOfficeMutation();
    const [updateProsecutorOffice] = useUpdateProsecutorOfficeMutation();

    const { data: mapPointID } = useGetAllMapQuery([], {
        refetchOnMountOrArgChange: true,
    });
    const form = useForm<ProsecutorRequestType>({
        initialValues: {
            titleKg: prosecutorOffice?.titleKg || "",
            titleRu: prosecutorOffice?.titleRu || "",
            titleEn: prosecutorOffice?.titleEn || "",
            email: prosecutorOffice?.email || "",
            phone: prosecutorOffice?.phone || "",
            mapPointId: prosecutorOffice?.mapPoint?.id || null,
            addressRu: prosecutorOffice?.addressRu || "",
            addressKg: prosecutorOffice?.addressKg || "",
            addressEn: prosecutorOffice?.addressEn || "",
            importance: prosecutorOffice?.importance || 6,
        },
        validate: {
            titleRu: (value) => !value && t("form.validation.required"),
            titleKg: (value) => !value && t("form.validation.required"),
            addressKg: (value) => !value && t("form.validation.required"),
            addressRu: (value) => !value && t("form.validation.required"),
            phone: (value) => !value && t("form.validation.required"),
        },
    });

    const handleSubmit = async (values: ProsecutorRequestType) => {
        if (prosecutorOffice) {
            try {
                await updateProsecutorOffice({
                    id: prosecutorOffice?.id,
                    body: values,
                }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/prosecutor_office");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createProsecutorOffice(values).unwrap();
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/prosecutor_office");
            } catch {
                notify(false);
            }
        }
    };
    return (
        <Container size="xl" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: 12, md: "xl" }}>
                    <Grid>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleRu")}
                                label={t`form.label.titleRu`}
                                required
                                error={form.errors.titleRu}
                                formData={form.values.titleRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleKg")}
                                label={t`form.label.titleKg`}
                                required
                                error={form.errors.titleKg}
                                formData={form.values.titleKg}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("titleEn")}
                                label={t`form.label.titleEn`}
                                error={form.errors.titleEn}
                                formData={form.values.titleEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("addressRu")}
                                label={t`form.label.detailAddressRu`}
                                required
                                error={form.errors.addressRu}
                                formData={form.values.addressRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("addressKg")}
                                label={t`form.label.detailAddressKg`}
                                required
                                error={form.errors.addressKg}
                                formData={form.values.addressKg}
                            />
                        </Grid.Col>

                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("addressEn")}
                                label={t`form.label.detailAddressEn`}
                                error={form.errors.addressEn}
                                formData={form.values.addressEn}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <CustomInputText
                                {...form.getInputProps("email")}
                                label={t`form.label.email`}
                                required
                                error={form.errors.email}
                                formData={form.values.email}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <CustomInputText
                                {...form.getInputProps("phone")}
                                label={t`form.label.phone`}
                                required
                                error={form.errors.phone}
                                formData={form.values.phone}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Select
                                value={
                                    mapPointID?.find(
                                        (el) => el?.id === form.values.mapPointId
                                    )?.cityOrRegionNameRu
                                }
                                data={mapPointID?.map(
                                    (el) => el.cityOrRegionNameRu
                                )}
                                onChange={(value) => {
                                    const id = mapPointID?.find(
                                        (el) => el.cityOrRegionNameRu === value
                                    )?.id;
                                    form.setFieldValue("mapPointId", id || 1);
                                }}
                                mt="md"
                                autoComplete="nope"
                                w="100%"
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <NumberInput
                                mt={-8}
                                label={t`form.label.importance`}
                                required
                                error={form.errors.importance}
                                {...form.getInputProps("importance")}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(
                                        `/prosecutor_office/${i18n.language}`
                                    )
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
