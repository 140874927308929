import { Box, Title } from "@mantine/core";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppTable, CustomModal, MenuDropDown } from "src/components";
import { IS_MINUS_ONE } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { usePageListAccordionDeleteMutation } from "src/store";
import { HeadCell } from "src/types";
import { Path, notify } from "src/utils";

export const PageListAccordionTable = ({
    filter,
    opened,
    parentId
}: {
    filter?: PageListAccordionFilter;
    opened: boolean,
    parentId: number | null
}) => {
    const { t } = useTranslation();

    const [pageDelete] = usePageListAccordionDeleteMutation();

    const [id, setId] = useState<number | null>();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (id: number) => {
        setOpen(true);
        setId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await pageDelete(id);
            notify(true, t("notify.deleted"));
        } catch (error) {
            notify(false);
        }
        setOpen(false);
    };

    const actions = (id: number) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/page_list_accordion/${id}/edit-page_list_accordion`,
        },
        {
            icon: IconEye,
            title: t`actions.view`,
            to: `/page_list_accordion/${id}`,
        },
        {
            icon: IconTrash,
            title: t`actions.delete`,
            onClick: () => handleOpen(id),
        },
    ];

    const headCells: HeadCell<PageListAccordion>[] = [
        {
            label: t`table.title`,
            render: (n) => getTitleByLanguage(n),
        },
        {
            label: t`table.type`,
            render: (n) =>
                n.accordionType === "ACCORDION" ? "Аккордион" : "Текст",
        },
        {
            label: t`actions.title`,
            render: (n) => (
                <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
            ),
        },
    ];

    return (
        <Box style={{ marginLeft: opened ? 420 : 0, transition: "0.7s" }}>
            <AppTable<PageListAccordion>
                headCells={headCells}
                searchApiUrl={Path.PageListAccordion.search}
                extraFilter={{
                    pageListId: parentId === IS_MINUS_ONE ? undefined : parentId,
                    ...filter
                }}
                sorting={{ sortBy: "ID", sortDirection: "DESC" }}
            />
            <CustomModal
                labelSubmit={"delete"}
                open={open}
                handleClose={handleClose}
                onClick={handleDelete}
            >
                <Title
                    order={3}
                    fw={400}
                    mt="5px"
                    mb="2rem"
                    style={{ textAlign: "center" }}
                >
                    {t`button.delete`}
                </Title>
            </CustomModal>
        </Box>
    );
};
