import { Table, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { TableRows } from "../table-row";

interface Props {
    item: ReceptionTimeChildrenType[];
}

export const ReceptionTable = ({ item }: Props) => {
    const { t } = useTranslation();

    const headCells = [
        t("titles.role"),
        t("titles.reception-day"),
        t("titles.reception-time"),
    ];
    return (
        <Table verticalSpacing={20} withRowBorders={false}>
            <Table.Thead>
                <Table.Tr>
                    {headCells.map((el) => (
                        <Table.Th key={el}>
                            <Title
                                fz={{
                                    base: 12,
                                    xs: 16,
                                    md: 18,
                                }}
                                order={4}
                            >
                                {el}
                            </Title>
                        </Table.Th>
                    ))}
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {item.map((el) => (
                    <TableRows key={el.titleRu} item={el} />
                ))}
            </Table.Tbody>
        </Table>
    );
};
